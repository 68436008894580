import React from 'react';
import { withTranslation } from 'react-i18next'
import { IoHomeOutline, IoFileTrayFullOutline, IoFolderOutline, IoBusinessOutline, IoBasketOutline } from "react-icons/io5";

class ManagerMenu extends React.Component {
  
  constructor(props) {
    super(props);
    
    // console.log(this.props);
    
    var default_page = this.props.match.params.page ? this.props.match.params.page : "home";
    
    this.state = {
      page: default_page
    }

    this.updatePage = this.updatePage.bind(this);
  }
  
  updatePage(path) {
    var destination_page = `/manager/${path}`;
    this.props.history.push(destination_page);
  }
  
  componentWillReceiveProps(nextProps) {
    if(nextProps.match.params.page) {
      this.setState({page: nextProps.match.params.page});
    }
  }
    
  render() {    
    return (
      <div className="w-full float-left mb-40">
          <div onClick={() => { this.updatePage('home')}} 
            className={"w-full float-left cursor-pointer mt-1 p-3 pl-5 pr-5 rounded-lg font-bold " + (this.state.page === "home" ? "bg-purpleJS-100 text-white" : "text-gray-500") }
          >
            <IoHomeOutline className="float-left mr-5 text-xl" />
            {this.props.t('manager_home_menu')}
          </div>
            
          <div onClick={() => { this.updatePage('offers')}} 
            className={"w-full float-left cursor-pointer mt-1 p-3 pl-5 pr-5 rounded-lg font-bold " + (this.state.page === "offers" || this.state.page === "offer-create" || this.state.page === "offer" ? "bg-purpleJS-100 text-white" : "text-gray-500") }
          >
            <IoFolderOutline className="float-left mr-5 text-xl" />
            {this.props.t('manager_offers_menu')}
          </div>
          
          <div onClick={() => { this.updatePage('templates')}} 
            className={"w-full float-left cursor-pointer mt-1 p-3 pl-5 pr-5 rounded-lg font-bold " + (this.state.page === "templates" || this.state.page === "template" ? "bg-purpleJS-100 text-white" : "text-gray-500") }
          >
            <IoFileTrayFullOutline className="float-left mr-5 text-xl" />
            {this.props.t('manager_templates_menu')}
          </div>
          
          <div onClick={() => { this.updatePage('shops')}} 
            className={"w-full float-left cursor-pointer mt-1 p-3 pl-5 pr-5 rounded-lg font-bold " + (this.state.page === "shops" || this.state.page === "shop" ? "bg-purpleJS-100 text-white" : "text-gray-500") }
          >
            <IoBusinessOutline className="float-left mr-5 text-xl" />
            {this.props.t('manager_shops_menu')}
          </div>
            
          { this.props.user.has_subscriptions ?
          
          <div onClick={() => { this.updatePage('shops')}} 
            className={"w-full float-left cursor-pointer mt-1 p-3 pl-5 pr-5 rounded-lg font-bold " + (this.state.page === "subscriptions" || this.state.page === "subscription" ? "bg-purpleJS-100 text-white" : "text-gray-500") }
          >
            <IoBasketOutline className="float-left mr-5 text-xl" />
            {this.props.t('manager_subscriptions_menu')}
          </div>
          
          :  
            null
          }
      </div>
    )
  }
}

export default withTranslation()(ManagerMenu);