import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class InputNumber extends Component {

    constructor(props) {
        super(props);
        this.handleChange.bind(this);
    }

    handleChange = (event) => {
        this.props.handleChange({
            field: this.props.datas.field,
            value: event.target.value
        });
    }

    render() {
        return (
            <div className="w-full">
                <label className="mt-10 w-full block text-xl text-lightGrey font-medium">{this.props.t(this.props.datas.label)}</label>
                <input pattern="[0-9]*" value={this.props.value == null ? '' : this.props.value} type="number" className="mt-5 resize-none px-5 py-3 text-xl placeholder-greyBorderJS
                font-greyBorderJS font-regular font-sans border-solid rounded-md border
                border-greyBorderJS w-full block" step="100" placeholder={this.props.datas.placeholder} onChange={this.handleChange} />
            </div>
        )
    }
}

export default withTranslation()(InputNumber);