import React from 'react';
import AWS from 'aws-sdk';
import AuthConfig from './AuthConfig';
import { Auth } from 'aws-amplify';
import { withTranslation } from 'react-i18next';
import Error from './Error';
import AdminUserCard from './AdminUserCard';
import AdminOfferCard from './AdminOfferCard';
import loadingIMG from '../img/loading.svg';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker } from 'react-date-range';

export const usersLanguages = [
  {value: "fr", label: "Français"},
  {value: "en", label: "English"},
  {value: "es", label: "Español"},
  {value: "it", label: "Italiano"},
  {value: "pt", label: "Português"},
  {value: "nl", label: "Nederlands"},
];

export const usersLanguagesCountriesMapping = {
  "fr": "FR",
  "en": "GB",
  "es": "ES",
  "it": "IT",
  "pt": "BR",
  "nl": "BE",
};
              
class AdminDashboard extends React.Component {
    constructor(props) {
      super(props);
      
      var today = new Date();
      var startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 3);
      
      this.state = {
        creating_user: false,
        loading: false,
        error: false,
        filter_profile: "offers",
        error_msg: "",
        nextPageToken: "",
        landing_state: 2,
        hide_old: true,
        show_date_range_selector: false,
        created_after: startDate,
        created_before: today,
        front_filters: {
          min_wishlist: 0,
          max_wishlist: 100,
          min_apply: 0,
          max_apply: 0,
          min_contacts: 0,
          max_contacts: 100,
          min_offers: 0,
          max_offers: 1000,
          min_candidates: 0,
          max_candidates: 1000,
          show_closed: true,
        },
        users_list: [],
        new_recruiter: {
          name: "",
          picture: "avatar/icon_recruiter.png",
          email: "",
          profile: "recruiter",
          password: "",
          lang: "fr",
          country_iso: "FR"
        },
        displayed_users: []
      }

      this.allowedLanguagesValues = usersLanguages;

      this.languageCountryMapping = usersLanguagesCountriesMapping;

      this.lastSearchValue = "";
    
      this.full_offers_list = [];
      this.searchTimeout = null;
      this.dynamo = null;
      this.now = new Date().getTime()/1000;
      this.one_day_ago = this.now - (60*60*6);
      
      this.searchUser = this.searchUser.bind(this);
      this.listUsers = this.listUsers.bind(this);
      this.getAttributeValue = this.getAttributeValue.bind(this);
      this.searchTypeChanged = this.searchTypeChanged.bind(this);
      this.getFullUserDescription = this.getFullUserDescription.bind(this);
      this.changeLandingState = this.changeLandingState.bind(this);
      this.handleSelect = this.handleSelect.bind(this);
      this.updateFilters = this.updateFilters.bind(this);
      this.applyFilters = this.applyFilters.bind(this);
      this.setWishlistWithFilter = this.setWishlistWithFilter.bind(this);
      this.createRecruiter = this.createRecruiter.bind(this);
      this.userNewRecruiter = this.userNewRecruiter.bind(this);
    }
    
    componentDidMount() {
      this.init();
    }
    
    userNewRecruiter(attr, value){
      var old_rec = this.state.new_recruiter;
      
      if(attr === "email") value = value.toLowerCase();

      if(attr === "lang") {
        var country_iso = this.languageCountryMapping[value];
        old_rec.country_iso = country_iso;
      }
      
      old_rec[attr] = value;
      this.setState({new_recruiter: old_rec});
    }
    
    
    handleSelect(ranges){      
      var needUpdate = false;
      
      var created_after = this.state.created_after;
      if(ranges.selection.startDate !== created_after) {
        created_after = ranges.selection.startDate;
        needUpdate = true;
      }
      
      var created_before = this.state.created_before;
      if(ranges.selection.endDate !== created_before) {
        created_before = ranges.selection.endDate;
        needUpdate = true;
      }
      
      if(needUpdate) {
        this.setState({
          created_after: created_after,
          created_before: created_before,
          nextPageToken: ""
        });
      
        window.setTimeout(() => {
          this.listUsers();
        }, 200);
      }
      
      // {
      //   selection: {
      //     startDate: [native Date Object],
      //     endDate: [native Date Object],
      //   }
      // }
    }
    
    async createRecruiter(){
      this.setState({
        creating_user: true
      });
      
      var finalState = {
        creating_user: false
      };
      
      var values = this.state.new_recruiter;
      
      if(values.name.length > 4 && values.email.length > 10 && values.password.length > 6) {


        // Check if recruiter email exists
        
        var new_recruiter_email = values.email.toLowerCase().trim();

        // use the new email index
        var search_existing_user = await this.dynamo.query({
          TableName: AuthConfig.DDB.USERS_TABLE,
          IndexName: AuthConfig.DDB.USERS_INDEXE,
          KeyConditionExpression: '#profile = :profile',
          ScanIndexForward: false,
          FilterExpression: "email = :search",
          ExpressionAttributeNames: {
            '#profile': 'profile'
          },
          ExpressionAttributeValues: {
            ':profile': 'recruiter',
            ':search': new_recruiter_email
          }
        }).promise();

        if(search_existing_user.Count) {
          alert("This user already exists");
        } else {

          try {
            
            const lang = values.lang;
            const country_iso = this.languageCountryMapping[lang];

            const { user } = await Auth.signUp({
                username: new_recruiter_email,
                password: values.password,
                attributes: {
                  profile: "recruiter",
                  name: values.name,
                  picture: values.picture,
                  email: new_recruiter_email,
                  "custom:lang": lang,
                  "custom:country_iso": country_iso
                }
            });
            
            console.log(user);

            finalState.new_recruiter = {
              name: "",
              picture: "avatar/icon_recruiter.png",
              email: "",
              profile: "recruiter",
              password: "",
              lang: lang,
              country_iso: country_iso
            };

            var user_created = await this.listUsers(new_recruiter_email);
            console.log(user_created);
            
          } catch(err) {
            console.log(err);
            alert(err.message);
          }
        }
      } else {
        alert("Erreur: Name -> au moins 4 lettres, password -> au moins 6 et email -> au moins 14");
      }
      
      this.setState(finalState);
    }
  
    async init() {
      this.setState({
        loading: true
      });
      
      const user =  await Auth.currentAuthenticatedUser();
      var groups = user.signInUserSession.accessToken.payload["cognito:groups"];

      this.setState({
        loading: false
      });
      
      if(!groups || groups.indexOf('admin') < 0) {
        this.setState({
          error: true,
          error_msg: "Le site est en cours de développement, merci de votre patience.",
        });
      } else {
        this.listUsers();
      }
    }
    
    setWishlistWithFilter(params) {
      this.lastSearchValue = params.job_code;
      
      this.setState({
        displayed_users: [],
        filter_profile: 'wishlist',
        nextPageToken: ""
      });
      
      window.setTimeout(() => {
        this.listUsers();
      }, 300);
    }
    
    
    changeLandingState(new_landing_state) {
      var currentState = this.state.landing_state;
      
      if(currentState !== new_landing_state) {

        this.setState({
          landing_state: new_landing_state,
          nextPageToken: ""
        });
          
        window.setTimeout(() => {
          this.listUsers();
        }, 200);
        
      }
    }
    
    searchTypeChanged(event) {
      // this.lastSearchValue = "";
      
      this.setState({
        displayed_users: [],
        filter_profile: event.target.value,
        nextPageToken: ""
      });
      
      window.setTimeout(() => {
        this.listUsers();
      }, 300);
    }

    updateFilters(event) {
      var filter_name = event.target.name;
      var filter_value = event.target.value;
      
      var oldState = this.state.front_filters;
      if(oldState[filter_name] !== undefined) {
        let prevValue = oldState[filter_name];
        
        if(filter_name === "show_closed") {
          
        } else {
          
          filter_value = parseInt(filter_value);
          if(isNaN(filter_value)) filter_value = prevValue;
        }
        
        oldState[filter_name] = filter_value;
                
        this.setState({front_filters: oldState});
      }
    }
    
    applyFilters(users_list) {
      var displayed_users = [];
      var CACHED_USERS = {};
      
      if(users_list.length) {
        for(var i = 0; i < users_list.length; i++) {
          let user = users_list[i];
          
          if(user.profile === "candidate") {
            if(
              user.wishlist_count >= this.state.front_filters.min_wishlist && 
              user.wishlist_count <= this.state.front_filters.max_wishlist &&
              user.applications_count >= this.state.front_filters.min_apply && 
              user.applications_count <= this.state.front_filters.max_apply &&
              user.contact_history.length >= this.state.front_filters.min_contacts &&
              user.contact_history.length <= this.state.front_filters.max_contacts
            ) {
              if(this.state.front_filters.show_closed) {
                displayed_users.push(user);
              } else if(!this.state.front_filters.show_closed && user.contactable) {
                displayed_users.push(user);
              }
            }
          } else if(user.profile === "recruiter") {
            if(
              user.offers_count >= this.state.front_filters.min_offers && 
              user.offers_count <= this.state.front_filters.max_offers &&
              user.candidates_count >= this.state.front_filters.min_candidates && 
              user.candidates_count <= this.state.front_filters.max_candidates
            ) {
              
              var add_user = false;
              if(this.state.hide_old) {
                
                if(!CACHED_USERS[user.email]) {
                  add_user = true; 
                } else {
                  if(!CACHED_USERS[user.email].userpool) {
                    var previous = displayed_users.find(us => us.email === user.email);
                    if(previous) {
                      var index_user = displayed_users.indexOf(previous);
                      displayed_users.splice(index_user, 1);
                      add_user = true;
                    }
                  }
                }
                
              } else add_user = true;
              
              if(add_user) {
                displayed_users.push(user);
                CACHED_USERS[user.email] = {
                  is_migration: user.is_migration,
                  userpool: user.userpool
                }
              }
            }
          }  else if(user.profile === "manager") {
              displayed_users.push(user);
          } else if(user.profile === "landing") {
            if(this.state.front_filters.show_closed) {
              displayed_users.push(user);
            } else if(!this.state.front_filters.show_closed && user.contactable) {
              displayed_users.push(user);
            }
          } else {
              displayed_users.push(user);
          }
        }
      }
      
      // console.log(displayed_users);
      
      this.setState({
        users_list: users_list,
        displayed_users: displayed_users
      });
    }
    
    getDynamoTimestampFromDate(date) {
      return Math.floor(date.getTime()/1000);
    }
    
    getAttributeValue(user, attribute, defaultVal) {
      if(user[attribute]) defaultVal = user[attribute];
      
      // var userAtt = user.Attributes.find(att => { return (att.Name == attribute) });
      // if(userAtt) return userAtt.Value;
      return defaultVal;
    }
    
    searchUser(emailPart) {
      this.lastSearchValue = emailPart;
      this.setState({
        nextPageToken: ""
      });
      
      if(this.searchTimeout !== null) {
        window.clearTimeout(this.searchTimeout);
      }
      
      this.searchTimeout = window.setTimeout(() => {
        this.searchTimeout = null;
        this.listUsers();
      }, 1500); 
    }
    
    async listUsers(return_user_from_email) {
        
      var given_profile = this.state.filter_profile;
   
   
      if(this.dynamo == null) {
        this.dynamo = new AWS.DynamoDB.DocumentClient({
          "region": AuthConfig.Common.Auth.region,
          "credentials": await Auth.currentCredentials()
        });
      }
   
      this.setState({
        loading: true
      });
      
      var operation = "query";
      
      var reqParams = {
        TableName: AuthConfig.DDB.USERS_TABLE,
        IndexName: AuthConfig.DDB.USERS_INDEXE,
        KeyConditionExpression: '#profile = :profile',
        ScanIndexForward: false,
        ExpressionAttributeNames: {
          '#profile': 'profile'
        },
        ExpressionAttributeValues: {
          ':profile': 'candidate'
        }
      };
      
      if(given_profile === 'manager') this.lastSearchValue = "";

      if(this.lastSearchValue !== "") {
        reqParams.FilterExpression = "contains(Pk, :search) OR contains(email, :search) OR contains(#name, :search) OR contains(phone, :search)";
        reqParams.ExpressionAttributeNames['#name'] = 'name';
        reqParams.ExpressionAttributeValues[':search'] = this.lastSearchValue;
      } else if(given_profile === 'candidate') {
        reqParams.KeyConditionExpression += " AND created BETWEEN :start AND :end";
        reqParams.ExpressionAttributeValues[':start'] = this.getDynamoTimestampFromDate(this.state.created_after);
        reqParams.ExpressionAttributeValues[':end'] = this.getDynamoTimestampFromDate(this.state.created_before);
        // reqParams.Limit = 100;
      }
      
      if(given_profile === 'recruiter') {
        
        reqParams.ExpressionAttributeValues[':profile'] = 'recruiter';
        
      } else if(given_profile === 'manager') {
        
        reqParams.ExpressionAttributeValues[':profile'] = 'manager';
        
      } else if(given_profile === 'wishlist') {
                
        reqParams = {
          TableName: AuthConfig.DDB.WISH_TABLE,
          IndexName: 'gsi-statefilter',
          KeyConditionExpression: '#state = :state',
          ScanIndexForward: false,
          ExpressionAttributeNames: {
            '#state': 'state'
          },
          ExpressionAttributeValues: {
            ':state': 'pending'
          }
        }
        
        if(this.lastSearchValue !== "") {
          reqParams.FilterExpression = "contains(Pk, :search) OR contains(Sk, :search)";
          reqParams.ExpressionAttributeValues[':search'] = this.lastSearchValue;
        }
        
      } else if(given_profile === 'landing') {
        // override here
        reqParams = {
          TableName: AuthConfig.DDB.INTEREST_TABLE,
          IndexName: 'notif_state',
          KeyConditionExpression: 'notif = :state AND (created BETWEEN :start AND :end)',
          FilterExpression: 'attribute_not_exists(user_id)',
          ScanIndexForward: false,
          ExpressionAttributeValues: {
            ':state': this.state.landing_state,
            ':start': this.getDynamoTimestampFromDate(this.state.created_after),
            ':end': this.getDynamoTimestampFromDate(this.state.created_before)
          }, Limit: 200
        }
        
        if(this.lastSearchValue !== "") {
          reqParams.FilterExpression = "contains(Pk, :search) OR contains(firstname, :search) OR contains(lastname, :search) OR contains(phone_number, :search) OR contains(Sk, :search)";
          reqParams.ExpressionAttributeValues[':search'] = this.lastSearchValue;
        }
      } else if(given_profile === 'offers') {
        // override here
        reqParams = {
          TableName: AuthConfig.DDB.OFFER_TABLE,
          IndexName: 'country_list',
          KeyConditionExpression: 'country_iso = :country',
          ScanIndexForward: false,
          ExpressionAttributeValues: {
            ':country': 'FR'
          }
        }
        
        if(this.lastSearchValue !== "") {
          reqParams.FilterExpression = "contains(Sk, :search) OR contains(company_name, :search) OR contains(description, :search) OR contains(city, :search) OR contains(zip_code, :search) OR contains(city, :search)";
          reqParams.ExpressionAttributeValues[':search'] = this.lastSearchValue;
        }
      }
      
      var users_list = this.state.users_list;
      
      if(this.state.nextPageToken !== "") {
        reqParams.ExclusiveStartKey = this.state.nextPageToken;
      } else {
        users_list = [];
      }
     
      var user_items = {Items: [], Count: 0};
     
      if(operation === "query") {
        user_items = await this.dynamo.query(reqParams).promise();
      } else if(operation === "scan") {
        user_items = await this.dynamo.scan(reqParams).promise();
      }
      
      
      var nextPageToken = (user_items.LastEvaluatedKey) ? user_items.LastEvaluatedKey : "";
    
      if(given_profile === 'wishlist') {
        // faut faire un batchget pour récupérer les users associés
        var deduplicate = {};
        
        var batchGetParams = {
          RequestItems: {
            
          }
        };
        
        batchGetParams.RequestItems[AuthConfig.DDB.USERS_TABLE] = {
          Keys: []
        }

        var output_items = [];
        
        for(var i = 0; i < user_items.Items.length; i++) {
          if(!deduplicate[user_items.Items[i].Pk]) {
            batchGetParams.RequestItems[AuthConfig.DDB.USERS_TABLE].Keys.push({
              Pk: user_items.Items[i].Pk
            });
            
            deduplicate[user_items.Items[i].Pk] = true;
            
            if(batchGetParams.RequestItems[AuthConfig.DDB.USERS_TABLE].Keys.length > 90) {
              const batchGetUsers = await this.dynamo.batchGet(batchGetParams).promise();
              output_items = output_items.concat(batchGetUsers.Responses[AuthConfig.DDB.USERS_TABLE]);

              batchGetParams.RequestItems[AuthConfig.DDB.USERS_TABLE].Keys = [];
              deduplicate = {};
              
              // on reset pour continuer les batchGet en boucle
            }
          }
        }
        
        if(batchGetParams.RequestItems[AuthConfig.DDB.USERS_TABLE].Keys.length>0) {
          const batchGetUsers = await this.dynamo.batchGet(batchGetParams).promise();
          output_items = output_items.concat(batchGetUsers.Responses[AuthConfig.DDB.USERS_TABLE]);
        }
        
        var finalUsersWishlist = [];
        
        for(const tmp_wish_item of user_items.Items) {
          var user_found = output_items.find(user => user.Pk === tmp_wish_item.Pk);
          if(user_found) {
            finalUsersWishlist.push(user_found);
          }
        }

        user_items.Items = finalUsersWishlist;
      }

      for(let i = 0; i < user_items.Items.length; i++) {
        if(given_profile === 'offers') {
          user_items.Items[i].profile = "offer";
          user_items.Items[i].Username = user_items.Items[i].Pk;
          
          user_items.Items[i].date_creation = new Date(user_items.Items[i].Data);
          
          this.full_offers_list.push(user_items.Items[i]);
          
        } else {
          if(!user_items.Items[i].profile) user_items.Items[i].profile = "landing";
          user_items.Items[i].Username = user_items.Items[i].Pk;
          user_items.Items[i] = await this.getFullUserDescription(user_items.Items[i]);
        }
        
        delete user_items.Items[i].Pk;
      }

      var finalUsersList = users_list.concat(user_items.Items);
      
      // apply front filters
      
      this.applyFilters(finalUsersList);
      
      this.setState({
        loading: false,
        nextPageToken: nextPageToken
      });
      
      if(return_user_from_email) {
        for(const user of finalUsersList) {
          if(user.email === return_user_from_email) return user;
        }
      }
      
    }
    
    async getUserLimits(user_id) {

      var req = await this.dynamo.get({
        TableName: AuthConfig.DDB.LIMIT_TABLE,
        Key: {
          Pk: user_id
        }
      }).promise();
      
      var result = (req.Item) ? req.Item.plan.actives_offers : null;
      return result;
    }
    
    async getFullUserDescription(input_user) {
      
      input_user.contactable = false;
      
      var attributes_update = [];
        
      if(input_user.profile === "recruiter") {
        
        
        if(input_user.offers_count === undefined) {
          var user_items = await this.dynamo.query({
          TableName: AuthConfig.DDB.OFFER_TABLE,
            KeyConditionExpression: 'Pk = :pkey',
            ExpressionAttributeValues: {
              ':pkey': input_user.Username
            },
            Select: "COUNT"
          }).promise();

          input_user.offers_count = user_items.Count;
          
          attributes_update.push({name: 'offers_count', value: input_user.offers_count});
        }
        
        if(input_user.candidates_count === undefined) {
          var apply_items = await this.dynamo.query({
            TableName: AuthConfig.DDB.APPLY_TABLE,
            IndexName: 'recruiters',
            KeyConditionExpression: '#d = :recruiter_id',
            ExpressionAttributeNames: {'#d' : 'Data', '#ds': 'state'},
            FilterExpression: "#ds = :astate",
            ExpressionAttributeValues: {
              ':recruiter_id': input_user.Username,
              ':astate': 'complete'
            },
            Select: "COUNT"
          }).promise();
          
          input_user.candidates_count = apply_items.Count;
          
          attributes_update.push({name: 'candidates_count', value: input_user.candidates_count});
        }
        
      } else if(input_user.profile === "candidate") {
        
        if(input_user.applications_count === undefined) {
          var user_applications = await this.dynamo.query({
          TableName: AuthConfig.DDB.APPLY_TABLE,
            KeyConditionExpression: 'Pk = :pkey',
            ExpressionAttributeValues: {
              ':pkey': input_user.Username
            },
            Select: "COUNT"
          }).promise();

          input_user.applications_count = user_applications.Count;
          
          attributes_update.push({name: 'applications_count', value: input_user.applications_count});
        }
          
        if(input_user.wishlist_count === undefined) {
          var user_wishlist = await this.dynamo.query({
          TableName: AuthConfig.DDB.WISH_TABLE,
            KeyConditionExpression: 'Pk = :pkey',
            ExpressionAttributeValues: {
              ':pkey': input_user.Username
            },
            Select: "COUNT"
          }).promise();

          input_user.wishlist_count = user_wishlist.Count;

          attributes_update.push({name: 'wishlist_count', value: input_user.wishlist_count});
        }
        
        if(input_user.applications_count < 1 && this.one_day_ago > input_user.created && !input_user.close_history) {
          input_user.contactable = true;
        }
         
      } else if(input_user.profile === "landing") {
        input_user.name = input_user.firstname + " " + input_user.lastname;
        input_user.email = input_user.Username;
        if(input_user.phone_number) input_user.phone = input_user.phone_number;
        else input_user.phone = "No phone number";
        
        if(!input_user.cgu_from) input_user.cgu_from = "jobstory";
        
        // if(input_user.custom) input_user.custom = input_user.custom;
        input_user.contactable = true;
      }
      
      if(attributes_update.length>0) {
        var updateConf = {
          TableName: AuthConfig.DDB.USERS_TABLE,
          Key: { Pk: input_user.Username },
          UpdateExpression: '',
          ExpressionAttributeNames: {},
          ExpressionAttributeValues: {}
        };
        
        for(var j = 0; j < attributes_update.length; j++) {
          let localAtt = attributes_update[j];
          
          if(j === 0) {
            updateConf.UpdateExpression = 'set ';
          } else {
            updateConf.UpdateExpression += ', ';
          }
          
          updateConf.UpdateExpression += '#' + localAtt.name + ' = :' + localAtt.name;
          
          updateConf.ExpressionAttributeNames['#' + localAtt.name] = localAtt.name;
          updateConf.ExpressionAttributeValues[':' + localAtt.name] = localAtt.value;
          
        }
        
        await this.dynamo.update(updateConf).promise();
      }
      
      
      
      if(!input_user.contact_history) input_user.contact_history = [];
      if(!input_user.date_creation) {
        input_user.date_creation = new Date(input_user.created*1000);  
        input_user.date_creation.setHours(input_user.date_creation.getHours()+2);
      }
      
      return input_user;
    }
    
    // The render function, where we actually tell the browser what it should show
    render() {  
        return (
          <div className={this.state.error ?
            "container h-full w-full max-w-none max-h-screen min-h-screen text-black" :
            "container w-full max-w-none"}>
          { this.state.error ?
              <Error text={this.state.error_msg} />
            :
            <div className="dashboard-container">
              <div className="w-1/2 mb-5 flex">
                <div className="w-1/4 flex-auto">
                {
                  this.state.show_date_range_selector ?
                    <div>
                      <DateRangePicker
                        ranges={[{
                          startDate: this.state.created_after,
                          endDate: this.state.created_before,
                          key: 'selection',
                        }]}
                        onChange={this.handleSelect}
                      />
                      <div onClick={(e) => { this.setState({show_date_range_selector: false}); }} className="text-center rounded-md p-3 bg-gray-300 hover:bg-gray-400 cursor-pointer">Fermer selection date</div>
                    </div>
                  :
                  <div onClick={(e) => { this.setState({show_date_range_selector: true}); }} className="text-center rounded-md p-3 bg-gray-300 hover:bg-gray-400 cursor-pointer">Selection date</div>
                }
                </div>
                <div className="w-1/4 flex-auto ml-10">
                { this.state.filter_profile === "recruiter" ?
                  <div className="w-full flex-auto">
                    { this.state.creating_user ?
                      <img alt="loading" src={loadingIMG} className="w-20" />
                    :
                      <div>
                        <input type="text" value={this.state.new_recruiter.name} className="px-3 py-2 placeholder-greyBorderJS font-greyBorderJS border-solid rounded-md border border-greyBorderJS w-full block mb-5" 
                        placeholder="Name" onChange={(e) => { this.userNewRecruiter("name", e.target.value); }} />
                        <input type="text" value={this.state.new_recruiter.email} className="px-3 py-2 placeholder-greyBorderJS font-greyBorderJS border-solid rounded-md border border-greyBorderJS w-full block mb-5" 
                        placeholder="email" onChange={(e) => { this.userNewRecruiter("email", e.target.value); }} />
                        <input type="text" value={this.state.new_recruiter.password} className="px-3 py-2 placeholder-greyBorderJS font-greyBorderJS border-solid rounded-md border border-greyBorderJS w-full block mb-5" 
                        placeholder="password" onChange={(e) => { this.userNewRecruiter("password", e.target.value); }} />
                        <select className="text-center rounded-md p-3 bg-gray-300 mb-5 cursor-pointer" name="lang" value={this.state.new_recruiter.lang} onChange={(e) => { this.userNewRecruiter("lang", e.target.value); }}>
                          { this.allowedLanguagesValues.map(lang => (
                            <option key={lang.value} value={lang.value}>{lang.label}</option>
                          ))}
                        </select>
                        <div onClick={(e) => { this.createRecruiter(); }} className="text-center rounded-md p-3 bg-gray-300 hover:bg-gray-400 cursor-pointer">Créer un recruteur</div>
                      </div>
                    }
                  </div>
                : null }
                </div>
              </div>
            
            
              <select className="text-center rounded-md p-3 bg-gray-300 mb-5 cursor-pointer" name="search_type" value={this.state.filter_profile} onChange={this.searchTypeChanged}>
                <option value="candidate">candidat</option>
                <option value="recruiter">recruteur</option>
                <option value="landing">Landing page</option>
                <option value="offers">Annonces</option>
                <option value="wishlist">Wishlist</option>
              </select>
              
              <label htmlFor="search_string" className="w-full block text-xl font-medium">{this.props.t('search_filter')}</label>
              <input type="text" id="search_string" value={this.lastSearchValue} className="mt-5 px-5 py-3 text-xl placeholder-greyBorderJS
                font-greyBorderJS font-regular font-sans border-solid rounded-md border
                border-greyBorderJS w-full block" placeholder={this.props.t('search_user_by_email')} onChange={(e) => { this.searchUser(e.target.value); }} />
              <div className="flex mt-5 mb-5">
                { this.state.filter_profile === "landing" ?
                  <div className="flex">
                    <div onClick={(e) => { this.changeLandingState(0); }} className={"text-center rounded-md p-3 " + ((this.state.landing_state === 0) ? 'bg-gray-600': 'bg-gray-300 hover:bg-gray-400 cursor-pointer')}>Non-Notifié</div>
                    <div onClick={(e) => { this.changeLandingState(3); }} className={"text-center rounded-md ml-5 p-3 " + ((this.state.landing_state === 3) ? 'bg-gray-600': 'bg-gray-300 hover:bg-gray-400 cursor-pointer')}>EMAIL-Notifié</div>
                    <div onClick={(e) => { this.changeLandingState(2); }} className={"text-center rounded-md ml-5 p-3 " + ((this.state.landing_state === 2) ? 'bg-gray-600': 'bg-gray-300 hover:bg-gray-400 cursor-pointer')}>SMS-Notifié</div>
                  </div>
                : this.state.filter_profile === "candidate" || this.state.filter_profile === "wishlist" ?
                  <div className="text-center w-full rounded-md p-3 bg-gray-300">
                    <p className="w-full text-left mb-5">Filtres rapides</p>
                    <div className="flex">
                      <div className="flex-auto">
                        <input onChange={(e) => { this.updateFilters(e) }} name="min_wishlist" className="w-1/4 p-2 mr-5" type="text" value={this.state.front_filters.min_wishlist} />
                        Wishlist
                        <input onChange={(e) => { this.updateFilters(e) }} name="max_wishlist" className="w-1/4 p-2 ml-5" type="text" value={this.state.front_filters.max_wishlist} />
                      </div>
                      <div className="flex-auto">
                        <input onChange={(e) => { this.updateFilters(e) }} name="min_apply" className="w-1/4 p-2 mr-5" type="text" value={this.state.front_filters.min_apply} />
                        Candidatures
                        <input onChange={(e) => { this.updateFilters(e) }} name="max_apply" className="w-1/4 p-2 ml-5" type="text" value={this.state.front_filters.max_apply} />
                      </div>
                      <div className="flex-auto">
                        <input onChange={(e) => { this.updateFilters(e) }} name="min_contacts" className="w-1/4 p-2 mr-5" type="text" value={this.state.front_filters.min_contacts} />
                        Contacts
                        <input onChange={(e) => { this.updateFilters(e) }} name="max_contacts" className="w-1/4 p-2 ml-5" type="text" value={this.state.front_filters.max_contacts} />
                      </div>
                      <div className="flex-auto">
                        <div onClick={(e) => { this.applyFilters(this.state.users_list); }} className="text-center rounded-md p-3 bg-gray-300 hover:bg-gray-400 cursor-pointer">
                          Appliquer filtres rapides
                        </div>
                      </div>
                    </div>
                  </div>
                : this.state.filter_profile === "recruiter" ?
                  <div className="text-center w-full rounded-md p-3 bg-gray-300">
                    <p className="w-full text-left mb-5">Filtres rapides</p>
                    <div className="flex">
                      <div className="flex-auto">
                        <input onChange={(e) => { this.updateFilters(e) }} name="min_offers" className="w-1/4 p-2 mr-5" type="text" value={this.state.front_filters.min_offers} />
                        Annonces
                        <input onChange={(e) => { this.updateFilters(e) }} name="max_offers" className="w-1/4 p-2 ml-5" type="text" value={this.state.front_filters.max_offers} />
                      </div>
                      <div className="flex-auto">
                        <input onChange={(e) => { this.updateFilters(e) }} name="min_candidates" className="w-1/4 p-2 mr-5" type="text" value={this.state.front_filters.min_candidates} />
                        Candidatures
                        <input onChange={(e) => { this.updateFilters(e) }} name="max_candidates" className="w-1/4 p-2 ml-5" type="text" value={this.state.front_filters.max_candidates} />
                      </div>
                      <div className="flex-auto">
                        <label>
                          <input type="checkbox" className="mr-1 mt-4" checked={this.state.hide_old} onChange={(e) => { this.setState({hide_old: e.target.checked}); }} />
                          Masquer les anciens utilisateurs migrés
                        </label>
                      </div>
                      <div className="flex-auto">
                        <div onClick={(e) => { this.applyFilters(this.state.users_list); }} className="text-center rounded-md p-3 bg-gray-300 hover:bg-gray-400 cursor-pointer">
                          Appliquer filtres rapides
                        </div>
                      </div>
                    </div>
                  </div>
                :
                  null
                }
              </div>
            {
              this.state.filter_profile === "offers" ?
                this.state.displayed_users.map(user => (
                  user.Username ?
                    <AdminOfferCard key={user.profile + '-' + user.Username + '-' + user.Sk} user={user} setWishlistWithFilter={this.setWishlistWithFilter} />
                  : null
                ))
              :
                this.state.displayed_users.map(user => (
                  user.Username ?
                    <AdminUserCard key={user.profile + '-' + user.Username + '-' + user.Sk} user={user} loaded_offers={this.full_offers_list} />
                  : null
                ))
            }
            <p className="text-left rounded-md mt-10 ml-5 p-5">
            { this.state.displayed_users.length } trouvés.
            </p>
            
            {
              this.state.loading ? 
                <img alt="loading" style={{ left: "50%"}}
                    src={loadingIMG} className="transform -translate-x-1/2 -translate-y-1/2 absolute w-20" />
                : null 
            }
            {
              this.state.nextPageToken !== "" ? 
                <div onClick={(e) => { this.listUsers()}} className="text-left rounded-md mt-10 ml-5 p-5 bg-gray-300 hover:bg-gray-400 cursor-pointer">Load more</div>
                : null 
            }
            </div>
          }
          </div>
        )
    }
}

export default withTranslation()(AdminDashboard);