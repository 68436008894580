import React from 'react';
import { Helmet } from 'react-helmet';

const Macros = (props) => {
  return (
    <div className="container h-full w-full max-w-none max-h-screen">
      <Helmet script={[{
        type: 'text/javascript',
        innerHTML: 'try{json_offer = JSON_OFFER;}catch(e){}'
      }]} >
        <title>META_TITLE</title>
        <meta property="og:title" content="META_TITLE" />
        <meta name="description" content="META_DESCRIPTION"></meta>
        <meta property="og:description" content="META_DESCRIPTION" />
        <meta property="og:image" content="META_IMAGE" />
        <meta property="og:url" content="META_URL" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    </div>
  )
}

export default Macros;