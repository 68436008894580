import React from 'react';
import { withTranslation } from 'react-i18next';

import AuthConfig from './AuthConfig';
import Error from './Error';
import Loading from './Loading';
import Thumbs from './Thumbs';
import JSButton from './JSButton';
import VideoStories from './VideoStories';
import PopError from './PopError';

import Miniature from './Miniature';
import { Helmet } from 'react-helmet';
import PopOk from './PopOk';
var TITLE = 'JobStory - feedback';

class Feedback extends React.Component {

  constructor(props) {
    super(props);

    console.log(this.props);

    this.share_id = this.props.share_id ? this.props.share_id : this.props.match.params.share_id;
    this.view_id = null;
    this.error_message = null;
    this.error_form_message = null;
    this.ok_form_message = null;

    this.application = null;

    this.student = false;

    this.feedback = {
      share_id: this.share_id,
      view_id: null,
      name: this.props.feedback_name ? this.props.feedback_name : '',
      comment: '',
      feedback: 0
    }
    
    this.state = {
      error: false,
      error_form: false,
      ok_form: false,
      application_loaded: false,
      activeSend: false
    };

    this.apisEndpoint = AuthConfig.Common.API.endpoints.find((api) => { return api.name === 'apis' }).endpoint;

    // To use the 'this' keyword, we need to bind it to our function
    this.sendFeedback = this.sendFeedback.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.checkReady = this.checkReady.bind(this);
    this.closeError = this.closeError.bind(this);
    this.closeOk = this.closeOk.bind(this);
  }

  sendFeedback() {
    fetch(this.apisEndpoint + '/apis/share-apply', {
      method: 'post',
      body: JSON.stringify(this.feedback),
      headers: {
        contentType: "application/json; charset=utf-8",
      }
    }).then(response => {
      return response.json()
    }).then(response => {
      if (response) {
        if (response.error) {
          this.error_form_message = response.message;
          this.setState({ error_form: true });
        } else {
          this.ok_form_message = this.props.t('feedback_sent_message').replace("%recruiter%", this.application.recruiter.name);
          this.setState({ ok_form: true });
        }

      }
    })
      .catch(error => {
        this.error_form_message = error.message;
        this.setState({ error_form: true });
      });
  }
  closeError() {
    this.setState({ error_form: false });
  }
  closeOk() {
    //this.setState({ ok_form: false });
  }

  checkReady() {
    if (this.feedback.view_id == null)
      return false;
    if (this.feedback.name.length < 3)
      return false;
    // if (this.feedback.comment.length < 3)
    //   return false;
    if (this.feedback.feedback === 0)
      return false;
    return true;
  }

  updateValue(attribute, value) {
    this.feedback[attribute] = value;
    this.setState({ activeSend: this.checkReady() });
  }

  componentDidMount() {
    fetch(this.apisEndpoint + '/apis/share-apply/' + this.share_id)
      .then(response => {
        return response.json()
      }).then(response => {
        if (response.application) {

          this.student = response.application.candidate.student || false;

          console.log(response);

          this.feedback.view_id = response.viewId;
          this.application = response.application;
          this.setState({
            application_loaded: true
          });
        } else if (response.message) {
          if (response.message === 'MaxViewLimit') {
            this.error_message = this.props.t('feedback_max_views');
          } else {
            this.error_message = response.message;
          }
          this.setState({
            error: true
          });
        } else {
          this.error_message = this.props.t('no_result')
          this.setState({
            error: true
          });
        }
      })
      .catch(error => {
        this.error_message = JSON.stringify(error);
        this.setState({
          error: true
        });
      });
  }

  render() {
    return (
      <div className={this.state.error || !this.state.application_loaded ?
        "container h-full w-full max-w-none max-h-screen bg-white text-black" :
        "container h-full w-full max-w-none max-h-screen"}>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        {
          this.state.error ?
            <Error text={this.error_message} />
            :
            this.state.application_loaded ?
              <div className="h-full grid-cols-1 grid md:grid-cols-2">
                <div className="bg-black text-white relative p-2 md:p-10 h-full">
                  <div className="videostories-container m-auto relative">
                    <div className="flex">
                      <Miniature className="h-16 w-16 rounded-full mx-0 mr-6" username={this.application.candidate.name} width="100" height="100" picture={this.application.candidate.picture} />
                      <div className="text-left">
                        <div className="text-xl font-semibold">{this.application.candidate.name}</div>
                        <div className="text-xs">
                          {this.student?
                            <span className="px-1 bg-gradient-to-r from-purpleJS-100 to-blueLightJS rounded-xs mr-1">
                              {this.props.t('student')}
                            </span>
                          : null }
                          <span>
                          {this.application.candidate.phone ? this.application.candidate.phone + " - " : ""}{this.application.candidate.email ? this.application.candidate.email : ""}
                          </span>
                        </div>
                        <div className="text-xs">
                          <span>
                            {this.props.t('feedback_shared_by').replace('%recruiter%', this.application.recruiter.name)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <VideoStories styles={{ borderRadius: "0.5rem" }} loop={true} controls={false} muted={false} current={0} datas={this.application} />
                  </div>
                </div>
                <div className="p-5 md:p-20 flex items-center h-full">
                  <div className="w-full">
                    <label className="w-full block text-xl font-medium">{this.props.t('feedback_enter_name')}</label>
                    <input type="text" value={this.feedback.name} className="mt-5 px-5 py-3 text-xl placeholder-greyBorderJS
                font-greyBorderJS font-regular font-sans border-solid rounded-md border
                border-greyBorderJS w-full block" placeholder={this.props.t('feedback_name')} onChange={(e) => { this.updateValue('name', e.target.value); }} />
                    <label className="mt-10 w-full block text-xl font-medium">{this.props.t('feedback_vote')}</label>
                    <Thumbs name="feedback" action={this.updateValue} />
                    <label className="mt-10 w-full block text-xl font-medium">{this.props.t('feedback_add_comment')}</label>
                    <textarea value={this.feedback.comment} className="mt-5 resize-none p-5 text-xl placeholder-greyBorderJS
                font-greyBorderJS font-regular font-sans border-solid rounded-md border
                border-greyBorderJS w-full block" placeholder={this.props.t('feedback_comment').replace("%candidate%", this.application.candidate.name)} onChange={(e) => { this.updateValue('comment', e.target.value); }} />
                    {
                      !this.state.ok_form ?
                        <JSButton active={this.state.activeSend} text={this.props.t('feedback_send')} action={this.sendFeedback} />
                        : ''}
                    {
                      this.state.error_form ?
                        <PopError className="m-5" closeAction={this.closeError} text={this.error_form_message} />
                        : ''}
                    {
                      this.state.ok_form ?
                        <PopOk className="m-5" text={this.ok_form_message} />
                        : ''}
                  </div>
                </div>
              </div>
              :
              <Loading />
        }
      </div>
    )
  }
}

export default withTranslation()(Feedback);