import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Experience extends Component {

    constructor(props) {

        super(props);
        this.handleChange.bind(this);
        this.choices = this.props.config.candidate_experience;
    }

    handleChange = (event) => {
        this.props.handleChange({
            field: this.props.datas.field,
            value: event.target.value,
        });
    }

    render() {
        return (
            <div className="w-full">
                <h2 className="mt-10 w-full block text-xl text-lightGrey font-medium">{this.props.t(this.props.datas.label)}</h2>
                <form>
                    {this.choices.map((choice, i) => {
                        return <label style={this.props.value === choice.name ? { backgroundColor: "#f6f7f8" } : {}}
                            className="cursor-pointer border-solid rounded-md border-2
                        border-greyBorderJS text-center relative inline-block w-32 h-40 mr-5 mt-3" key={i + "-exp"}>
                            <div style={{ bottom: "5rem" }} className="font-semibold text-lg absolute w-full">{choice.value}</div>
                            <input style={{ bottom: "1rem" }} className="absolute w-full left-0" checked={this.props.value === choice.name} onChange={this.handleChange} type="radio" value={choice.name} />
                        </label>
                    })}
                </form>
            </div>
        )
    }
}

export default withTranslation()(Experience);