import React from 'react';
import { withTranslation } from 'react-i18next';
import CFG from './Config';

import logoWhiteIMG from '../img/small-logo.svg';
// import appleStoreIMG from '../img/applestore.png';
// import playStoreIMG from '../img/playstore.png';
import appleStoreIMG from '../img/appstore.svg';
import playStoreIMG from '../img/playstore.svg';

const Footer = (props) => {

    return (
        // <div className="text-white w-full mt-32 px-5 py-8 pt-0 md:px-20 md:py-10 md:pt-0 bg-purpleJS-100">
        <div className="text-white w-full mt-32 px-5 py-8 md:px-20 md:py-10 bg-purpleJS-100">
            {/* <iframe id="external-newsletter-form2" frameBorder="0" scrolling="no" className="w-full h-64 border-none" src="https://info.jobstory.app"></iframe> */}
            <div className="float-left">
                <a href="/" className="float-left"><img className="mb-2 duration-300 ease-in-out transform hover:scale-110 w-32" alt="logo jobstory" src={logoWhiteIMG} /></a>
                <a className="block hover:underline font-regular text-md mt-2" href="/terms">{props.t('term_of_use')}</a>
                <a className="block hover:underline font-regular text-md mt-2" href="/privacy">{props.t('privacy_policy')}</a>
                <a className="block hover:underline font-regular text-md mt-2" href="/legals">{props.t('legals')}</a>
                <a className="block hover:underline font-regular text-md mt-2" href="mailto:alex@jobstory.app">{props.t('contact')}</a>
                <div style={{ clear: "both" }}></div>
            </div>
            <div className="float-left md:float-right w-full md:w-auto pt-5 md:pt-10 md:flex items-center md:gap-8">
                <a target="_blank" href={CFG.links.appStore} rel="noopener noreferrer"><img alt="Jobstory AppleStore" className="w-40" src={appleStoreIMG} /></a>
                <a target="_blank" href={CFG.links.playStore} rel="noopener noreferrer"><img alt="Jobstory PlayStore" className="mt-2 md:mt-0 w-40" src={playStoreIMG} /></a>
            </div>
            <div style={{ clear: "both" }}></div>
        </div>
    )
}

export default withTranslation()(Footer);
