import React, { Component } from 'react';

export default class JSButton extends Component {
    render() {
        return (
            <button disabled={this.props.active ? false : true} onClick={this.props.action ? this.props.action : null} className={
                this.props.active ? "mt-8 w-30 px-20 py-3 rounded-md font-medium text-xl text-white bg-gradient-to-r from-purpleJS-100 to-blueLightJS"
                    : "cursor-not-allowed mt-8 w-30 px-20 py-3 rounded-md font-medium text-xl text-white bg-lightGrey"
            }>{this.props.text}</button>
        )
    }
}
