import React from 'react';

import { withTranslation } from 'react-i18next'

import { API } from 'aws-amplify';
import AuthConfig from '../AuthConfig';

import ManagerLoading from './ManagerLoading';
import ManagerEmptyList from './ManagerEmptyList';

import { IoAdd, IoPencil } from "react-icons/io5";

class ManagerShops extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      loading: true,
      data: null
    }
    
    // this.changeRouteCallback = this.props.changeRoute;

    this.gotoEntity = this.gotoEntity.bind(this);
  }
  
  componentDidMount() {
    this.loadData();
  }
  
  async loadData() {
    this.dataLoaded(await API.get('apis', '/apis/shops'));
  }

  async gotoEntity(entity_id) {
    this.props.history.push('/manager/shop/' + entity_id);
  }
  
  dataLoaded(data) {
    this.setState({
      loading: false,
      data: data
    });
  }
  
  render() {
    return (
      <div className="w-full">
        { this.state.loading ?
          <div className="w-full flex justify-center">
            <ManagerLoading />  
          </div>
        :
          <div className="w-full">
            <h3 className="mb-10 text-xl font-bold float-left">{this.props.t('manager_shops_menu')}</h3>
            <h3 onClick={() => { this.gotoEntity('new')}} className="bg-purpleJS-100 cursor-pointer float-right p-5 pt-3 pb-3 rounded-lg text-white">
              <IoAdd className="float-left mr-3 text-2xl" />
              {this.props.t('manager_add_new_shop')}
            </h3>
            
            <div className="w-full float-left p-5 bg-white rounded-lg">
              { this.state.data.Items.length > 0 ?
              <table className="w-full table-auto mt-10">
                <thead>
                  <tr className="text-left">
                    <th className="p-5 rounded-l-lg bg-bgPurpleLightJS">{this.props.t('manager_shop_name')}</th>
                    <th className="bg-bgPurpleLightJS">{this.props.t('manager_shop_address')}</th>
                    <th className="bg-bgPurpleLightJS">{this.props.t('manager_shop_infos_recruiter')}</th>
                    <th className="p-5 rounded-r-lg bg-bgPurpleLightJS"></th>
                  </tr>
                </thead>
                <tbody>
                  { this.state.data.Items.map((item) => (
                    <tr className="text-sm" key={item.id}>
                      <td className="border-b border-slate-100 p-5 font-bold">{item.content.shopname}</td>
                      <td className="border-b border-slate-100 p-5 pl-0 font-bold">
                        <p>{item.content.address.street}</p>
                        <p>{item.content.address.zip_code + ' ' + item.content.address.city}</p>
                      </td>
                      <td className="border-b border-slate-100 p-5 pl-0">
                        <div className="float-left mr-5">
                          <img alt="" className="rounded-full" src={AuthConfig.CDN.images + '40x40/' + item.content.recruiter.picture} />
                        </div>
                        <div className="float-left">
                          <p className="font-bold">{item.content.recruiter.name}</p>
                          <p className="text-gray-500">{item.content.recruiter.email}</p>
                        </div>
                      </td>
                      <td className="border-b border-slate-100 p-5 pl-0">
                        <div onClick={() => { this.gotoEntity(item.id) }} className="p-1 pl-3 pr-3 float-left rounded-lg border border-purpleJS-100 text-purpleJS-100 cursor-pointer">
                          <IoPencil className="float-left mr-1 mt-1" />
                          {this.props.t('manager_update_template')}
                        </div>
                      </td>
                    </tr>
                    ))
                  }
                </tbody>
              </table>
              :
              <ManagerEmptyList handleClick={() => {this.gotoEntity('new')}} empty_message={this.props.t('manager_empty_shops_msg')} create_new_label={this.props.t('manager_empty_shops_button')} />
              }
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(ManagerShops);