import React, { Component } from 'react';
import AuthConfig from './AuthConfig';

import { AiFillStar, AiOutlineCheck } from "react-icons/ai";
import { IconContext } from "react-icons";

export default class ManagerCandidateCard extends Component {
    constructor(props) {
      super(props); 
      
      this.state = {
        show_details: true
      }
            
      this.displayDate = this.displayDate.bind(this); 
    }
    
    displayDate(given_date) {
      var returnedDate = given_date.submit;
      
      if(given_date.days > 0) {
        returnedDate = given_date.days + " jours";
      } else if(given_date.hours > 0) {
        returnedDate = given_date.hours + " heures";
      } else if(given_date.minutes > 0) {
        returnedDate = given_date.minutes + " mins";
      } else if(given_date.seconds > 0) {
        returnedDate = given_date.seconds + " sec";
      }
      
      return returnedDate;
    }
                  // <div onClick={() => this.setState({show_details: !this.state.show_details})} className="font-bold select-none hover:underline cursor-pointer">{this.props.candidat.candidate.name}</div>
    
    render() {
        return (
          <div className="float-left p-3 w-full px-5 pb-0">
            <div className="w-full p-3 float-left border-2 rounded-lg">
              <div className="w-full float-left">
                <img alt="" className="font-bold w-10 mr-2 float-left rounded-full" src={AuthConfig.CDN.images + '32x32/' + this.props.candidat.candidate.picture} />
                <div className="float-left">
                  <div className="font-bold">{this.props.candidat.candidate.name}</div>
                  <div className="text-xs text-gray-600">{this.displayDate(this.props.candidat.submit_date)}</div>
                </div>
                <div className="float-right">
                    <IconContext.Provider value={{ color: "#d9bfff" }}>
                    {
                      this.props.candidat.recruiter_state === "new" ?
                           <AiFillStar key="new" />
                    : [
                      this.props.candidat.recruiter_state === "rejected" ?
                           <AiOutlineCheck key="rejected" />
                      :
                        null
                      ]
                    }
                    </IconContext.Provider>
                </div>
              </div>
              { this.state.show_details ?
                <div className="text-xs mt-2 w-full float-left px-3 text-gray-600">
                  <p>{this.props.candidat.candidate.phone}</p>
                  <p>{this.props.candidat.candidate.email}</p>
                </div>
              : null }
              <div onClick={() => this.props.filter(this.props.candidat.offer)} className="text-sm text-gray-600 mt-2 px-3 w-full float-left cursor-pointer rounded-lg hover:bg-gray-300">{this.props.candidat.offer.shopname ? this.props.candidat.offer.shopname : this.props.candidat.offer.city} - {this.props.candidat.offer.title}</div>
              { this.props.candidat.questions ?
              <div onClick={() => this.props.click(this.props.candidat)} className="w-full cursor-pointer float-left mt-2 mb-0 text-center bg-purpleJS-100 rounded-lg p-3 text-white bg-opacity-25 text-purpleJS-100 font-bold">Voir la story</div>
              : null }
              { this.props.candidat.cv_url ?
              <a href={this.props.candidat.cv_url} target="_blank" rel="noopener noreferrer" className="w-full cursor-pointer float-left mt-2 mb-0 text-center rounded-lg p-3 text-white bg-opacity-25 text-purpleJS-100 font-bold">Voir le CV</a>
              : null }
            </div>
          </div>
        )
    }
}
