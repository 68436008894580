import React from 'react';

import { withTranslation } from 'react-i18next'

import AuthConfig from '../AuthConfig';
import { API } from 'aws-amplify';

import ButtonCandidateRecruit from './ButtonCandidateRecruit';
import ManagerLoading from './ManagerLoading';
import ButtonCV from './ButtonCV';
import ButtonStory from './ButtonStory';

import VideoStories from '../VideoStories';
import Feedback from '../Feedback';
import MetricWidget from '../MetricWidget';

class ManagerHome extends React.Component {
  constructor(props) {
    super(props);
    
    
    this.state = {
      user: props.user,
      loading: true,
      current_feedback: null,
      current_story: null,
      data: null
    }
    
    this.dateFormat = this.dateFormat.bind(this);
    this.showStory = this.showStory.bind(this);
  }
    
  dateFormat(datestring) {
    var d = new Date(datestring),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('/');
  }
  
  componentDidMount() {
    this.loadData();
  }
    
  async showStory(candidate) {
      if (this.state.current_story != null) {
        this.setState({current_story: null});
      } else {
        // console.log(candidate);
      
        var feedback_request = await API.post('apis', '/apis/share-apply', {
            body: {
              recruiter_id: candidate.Data,
              job_code: candidate.Sk,
              candidate_id: candidate.Pk,
            }
        });
                
        if(feedback_request.shareItem && feedback_request.shareItem.Pk) {
          this.setState({current_feedback: feedback_request.shareItem.Pk})
        }
      }
  }
  
  async loadData() {
    try {
      var m_data = await API.get('apis', '/apis/manager');
      // console.log(m_data);
      this.dataLoaded(m_data);
    } catch(e) {
      console.log("error");
      console.log(e);
    }
  }
  
  dataLoaded(data) {
    // console.log(data);
    
    if(!data.shops) {
        data.shops = [];
    }
    
    // data.to_recruit = [];
    // data.recruited = [];
    
    // var filter_date = new Date();
    // filter_date.setDate(filter_date.getDate()-90);
    
    // for(var i = 0; i < data.Items.length; i++) {
      // var copied_item = JSON.parse(JSON.stringify(data.Items[i]));
      
      // if(copied_item.recruiter_state === "to_recruit") {
        // data.to_recruit.push(copied_item);
      // } else if(copied_item.recruiter_state === "recruited") {
        // data.recruited.push(copied_item);
      // }
    // }
    
    this.setState({
      loading: false,
      data: data
    });
  }
  
  render() {
    return (
      <div className="w-full">
        { this.state.loading ?
          <div className="w-full flex justify-center">
            <ManagerLoading />  
          </div>
        :
          <div className="w-full">
            <div className="w-full float-left">
              <h3 className="mt-3 text-3xl font-bold float-left">{this.props.t('manager_home_menu')}</h3>
            </div>
            <div className="w-full float-left mb-10">
              <MetricWidget label={this.props.t('manager_shops_menu')} value={this.state.data.shops.length} />
              <MetricWidget label={this.props.t('manager_count_offers')} value={this.state.data.user.offers_count} />
              <MetricWidget label={this.props.t('manager_count_applications')} value={this.state.data.user.candidates_count} />
              <MetricWidget label={this.props.t('manager_actives_offers_count')} value={this.state.data.user.actives_offers_count} />
              <MetricWidget label={this.props.t('manager_count_actives_applications')} value={this.state.data.user.actives_candidates_count} />
              <MetricWidget label={this.props.t('manager_to_recruit')} value={this.state.data.user.to_recruit} />
              <MetricWidget label={this.props.t('manager_recruited')} value={this.state.data.user.recruited} />
            </div>
            <div className="w-full float-left p-10 rounded-lg bg-white">
              <div className="w-full float-left mb-10">
                <h3 className="float-left text-purpleJS-100 font-bold">{this.props.t('manager_candidates_to_recruit')}</h3>
                <span className="float-left bg-purpleJS-100 rounded-full ml-3 mt-1 pl-2 pr-2 text-white text-xs">{this.state.data.user.to_recruit}</span>
              </div>
            { this.state.data.to_recruit_list.length === 0 ?
              <h3>{this.props.t('manager_nobody_to_recruit')}</h3>
            :
              <div className="w-full">
                <table className="w-full table-auto">
                  <thead className="">
                    <tr className="text-left">
                      <th className="p-5 rounded-l-lg bg-bgPurpleLightJS">{this.props.t('manager_candidate_name')}</th>
                      <th className="bg-bgPurpleLightJS">{this.props.t('manager_candidate_email')}</th>
                      <th className="bg-bgPurpleLightJS">{this.props.t('manager_offer')}</th>
                      <th className="bg-bgPurpleLightJS">{this.props.t('manager_shop_infos_recruiter')}</th>
                      <th className="bg-bgPurpleLightJS">{this.props.t('manager_candidate_date')}</th>
                      <th className="bg-bgPurpleLightJS"></th>
                      <th className="bg-bgPurpleLightJS"></th>
                      <th className="rounded-r-lg bg-bgPurpleLightJS"></th>
                    </tr>
                  </thead>
                  <tbody className="text-left">
                    { this.state.data.to_recruit_list.map((item) => (
                      <tr key={item.Pk + "-" + item.Sk}>
                        <td className="border-b border-slate-100 p-5">{item.candidate.name}</td>
                        <td className="border-b border-slate-100 p-4 pl-0">{item.candidate.email ? item.candidate.email : this.props.t('manager_no_email_for_this_candidate')}</td>
                        <td className="border-b border-slate-100 p-4 pl-0">
                          <div className="w-full">{item.offer.title}</div>
                          <div className="w-full text-xs text-gray-500 font-bold">{item.offer.shopname}</div>
                        </td>
                        <td className="border-b border-slate-100 p-4 pl-0">
                          <div className="w-full">{item.offer.recruiter.name}</div>
                          <div className="w-full text-xs text-gray-500 font-bold">{item.offer.recruiter.email}</div>
                        </td>
                        <td className="border-b border-slate-100 p-4 pl-0">{this.dateFormat(item.submit_date)}</td>
                        <td className="border-b border-slate-100 p-4 pl-0">
                          <ButtonStory candidate={item} callback={(cb_item) => { this.showStory(cb_item)}} />
                        </td>
                        <td className="border-b border-slate-100 p-4 pl-0">
                          <ButtonCV cv_url={item.cv_url} />
                        </td>
                        <td className="border-b border-slate-100 p-4 pl-0 pr-0">
                          <ButtonCandidateRecruit candidate={item} recruitCallback={() => {this.componentDidMount()}} />
                        </td>
                      </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            }
            </div>
          </div>
        }
        { this.state.current_feedback !== null ?
          <div className="fixed top-0 left-0 h-full w-full">
            <div onClick={() => this.setState({current_feedback: null})} className="bg-black bg-opacity-75 fixed top-0 left-0 h-full w-full"></div>
            <div className="bg-white absolute rounded-l-lg right-0 z-10 h-full w-3/4">
                <Feedback className="absolute z-10 rounded-lg" share_id={this.state.current_feedback} feedback_name={this.state.user.name} />
            </div>
          </div>
        : null }
        { this.state.current_story !== null ?
          <div className="fixed top-0 left-0 h-full w-full">
            <div onClick={() => this.setState({current_story: null})} className="bg-black bg-opacity-50 fixed top-0 left-0 h-full w-full"></div>
            <div className="bg-white fixed rounded-l-lg top-0 z-10 right-0 h-full p-10 w-2/3 md:w-1/4">
              <div className="w-full float-left">
                <img className="font-bold w-10 mr-2 float-left rounded-full text-purpleJS-100" alt={this.state.current_story.candidate.name} src={AuthConfig.CDN.images + '32x32/' + this.state.current_story.candidate.picture} />
                <div className="float-left">
                  <div className="font-bold text-purpleJS-100">{this.state.current_story.candidate.name}</div>
                  <div className="text-xs">{this.state.current_story.candidate.phone}</div>
                </div>
                <div className="font-bold mt-2 w-full float-left">{this.state.current_story.offer.title}</div>
                {
                  this.state.current_story.offer.shopname ? 
                    <div className="mt-2 w-full float-left font-bold">{this.state.current_story.offer.shopname}</div>                      
                : null }
                <div className="text-xs mt-2 w-full float-left">{this.state.current_story.offer.zip_code}</div>
                <div className="w-full relative float-left">
                  <VideoStories styles={{ borderRadius: "0.5rem" }} loop={true} controls={false} muted={false} current={0} datas={this.state.current_story.questions} />
                </div>
              </div>
            </div>
          </div>
        : null }
      </div>
    )
  }
}

export default withTranslation()(ManagerHome);