import React from 'react';
import { withTranslation } from 'react-i18next';

import AWS from 'aws-sdk';
import AuthConfig from './AuthConfig';
import { Auth } from 'aws-amplify';
import Miniature from './Miniature';
import loadingIMG from '../img/loading.svg';
import Error from './Error';

import InputText from './manager/template-fields/InputText';
import UploadImg from './manager/template-fields/UploadImg';

import { IoAdd, IoPencilSharp, IoTrashSharp } from "react-icons/io5";

class AdminCustomLandings extends React.Component {
    constructor(props) {
      super(props);
      
      this.dynamo = null;
      
      this.state = {
        landing_config: null,
        landings: [],
        loading: true,
        user: null
      };
    
      this.apisEndpoint = AuthConfig.Common.API.endpoints.find((api) => { return api.name === 'apis' }).endpoint;
    
      this.editLanding = this.editLanding.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.saveCurrentLanding = this.saveCurrentLanding.bind(this);
      this.deleteEntry = this.deleteEntry.bind(this);
      this.getWidthRatio = this.getWidthRatio.bind(this);
    }
    
    componentDidMount() {
      this.init();
    }
    
    getWidthRatio(logo_conf) {
      var given_width = parseInt(logo_conf.width);
      var given_height = parseInt(logo_conf.height);
      
      var height_ratio = 100/given_height;
      
      return Math.floor(given_width * height_ratio).toString();
    }
  
    async deleteEntry(landing_entry) {
      if(window.confirm("Supprimer définitivement: " + landing_entry.identifier + " ?")) {
        await this.initDynamoDB();
        
        await this.dynamo.delete({
          TableName: AuthConfig.DDB.LANDING_CUSTOMS,
          Key: {Pk: landing_entry.identifier}
        }).promise();
        
        this.loadLandings();
      }
    }
    
    async editLanding(landing_id) {
      var landing_config = {
        "identifier": "unique_identifier",
        "name": "Company Name",
        "bg_color": "#000000",
        "select_label": "Magasin",
        "select_description": "Séléctionnez le magasin puis l’offre pour laquelle vous allez passer un entretien.",
        "informations_description": "Rentrez vos informations.",
        "company_hash": "Offers company name (ex: Zara)",
        "logo": {
          "width": "300",
          "height": "120",
          "src": "alanding/zara/logo.jpg"
        }
      };
      
      if(landing_id === "new") {
        landing_config = {
          "identifier": "",
          "name": "",
          "bg_color": "#000000",
          "select_label": "Magasin",
          "select_description": "Séléctionnez le magasin puis l’offre pour laquelle vous allez passer un entretien.",
          "informations_description": "Rentrez vos informations.",
          "company_hash": "",
          "logo": {
            "width": "300",
            "height": "120",
            "src": ""
          }
        };
      } else {
        // récupérer l'entrée
        landing_config = JSON.parse(JSON.stringify(landing_id));
      }
      
      this.setState({"landing_config": landing_config});
    }
    
    async init() {
      this.setState({
        loading: true
      });
      
      const user =  await Auth.currentAuthenticatedUser();
      var groups = user.signInUserSession.accessToken.payload["cognito:groups"];

      if(!groups || groups.indexOf('admin') < 0) {
        this.setState({
          error: true,
          loading: false,
          error_msg: "Le site est en cours de développement, merci de votre patience.",
        });
      } else {
        this.loadLandings();
      }
    }
    
    async loadLandings() {

      this.setState({
        landing_config: null,
        loading: true
      }); 
      
      await this.initDynamoDB();
      
      var landings_res = await this.dynamo.scan({
        TableName: AuthConfig.DDB.LANDING_CUSTOMS
      }).promise();
      
      var final_items = [];
      
      if(landings_res.Items.length) {
        for(var i = 0; i < landings_res.Items.length; i++) {
          var item = landings_res.Items[i];
          
          item.identifier = item.Pk;
          delete item.Pk;
          
          final_items.push(item);
          
        }
      }
      
      var new_state = {
        loading: false,
        "landings": final_items,
      };
      
      this.setState(new_state);
    }

    async initDynamoDB() {
      if(this.dynamo === null) {
        var clientConfig = {
          "region": AuthConfig.Common.Auth.region,
          "credentials": await Auth.currentCredentials()
        };

        this.dynamo = new AWS.DynamoDB.DocumentClient(clientConfig);  
      }
    }
    
    
    async saveCurrentLanding() {
      if(this.state.landing_config && this.state.landing_config.identifier.length > 3) {
        await this.initDynamoDB();
        
        var item = this.state.landing_config;
        
        item.Pk = item.identifier;
        delete item.identifier;
        
        item.company_hash = item.name;

        
        await this.dynamo.put({
          TableName: AuthConfig.DDB.LANDING_CUSTOMS,
          Item: item
        }).promise();
        
        this.loadLandings();
      } else {
        alert('Identifier doit faire au moins 3 chars');
      }
    }
    
    async handleChange(config) {

      var landing_config = this.state.landing_config;
      
      var splited = config.field.split(".");
      
      if(splited[1]) {
        landing_config[splited[0]][splited[1]] = config.value;
      } else if(config.field === "identifier") {
        var allowedChars = ["0","1", "2","3","4","5","6","7","8","9","a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z","-","_"]
        var inputString = config.value.toLowerCase();
        
        inputString = inputString.replace(/\s/g, '-');
        
        var outputString = "";
        
        for(var i = 0; i < inputString.length; i++) {

          if(allowedChars.includes(inputString[i])) {
            outputString += inputString[i];
          } else {
            alert('Not allowed: ' + inputString[i] + " , allowed characters are: " + allowedChars.toString() );
          }
        }
        
        landing_config[config.field] = outputString;
    
      } else {
        landing_config[config.field] = config.value;
      }
      
      this.setState({
        landing_config: landing_config
      });
    }
  
    render() {
        return (
          <div className="mt-10">
            { this.state.error ?
                <Error text={this.state.error_msg} />
              : this.state.loading ?
                <img alt="loading" style={{ left: "50%"}}
                    src={loadingIMG} className="transform -translate-x-1/2 -translate-y-1/2 absolute w-20" />
              : 
                <div>
                  { this.state.landing_config !== null ?
                    <div className="w-full p-5">
                      <div className="w-full flex">
                        <div className="mr-10">
                          <InputText value={this.state.landing_config.identifier} handleChange={this.handleChange} datas={{field: "identifier", label: this.props.t('manager_landing_label_identifier')}} />
                          <h5 className="mt-3 text-gray-600 text-xs">{ this.apisEndpoint + "/landing/" + this.state.landing_config.identifier}</h5>
                        </div>
                        <div className="mr-10">
                          <InputText value={this.state.landing_config.name} handleChange={this.handleChange} datas={{field: "name", label: this.props.t('manager_template_company_name_label')}} />
                        </div>
                        <div className="mr-10">
                          <UploadImg value={this.state.landing_config.logo.src} handleChange={this.handleChange} datas={{field: "logo.src", label: this.props.t('manager_template_company_name_picture')}} />
                        </div>
                        <div className="mr-10">
                          <InputText value={this.state.landing_config.logo.width} handleChange={this.handleChange} datas={{field: "logo.width", label: "Largeur du logo (px)"}} />
                        </div>
                        <div className="mr-10">
                          <InputText value={this.state.landing_config.logo.height} handleChange={this.handleChange} datas={{field: "logo.height", label: "Hauteur du logo (px)"}} />
                        </div>
                        <div className="mr-10">
                          <InputText value={this.state.landing_config.bg_color} handleChange={this.handleChange} datas={{field: "bg_color", label: "Couleurs de fond de la banière"}} />
                        </div>
                      </div>
                      <div className="w-full flex">
                        <div className="mr-10 w-1/8"><InputText value={this.state.landing_config.select_label} handleChange={this.handleChange} datas={{field: "select_label", label: "Label du premier select"}} /></div>
                        <div className="mr-10 w-1/2"><InputText value={this.state.landing_config.select_description} handleChange={this.handleChange} datas={{field: "select_description", label: "Description du premier select"}} /></div>
                        <div className="mr-10 w-1/4"><InputText value={this.state.landing_config.informations_description} handleChange={this.handleChange} datas={{field: "informations_description", label: "Description des infos"}} /></div>
                      </div>
                      <div className="w-full flex justify-end mt-10">
                        <div onClick={() => { this.setState({"landing_config": null})}} className="bg-red-800 cursor-pointer rounded-lg mt-5 rounded-md p-5 text-white flex mr-10">
                          <h3 className="">Cancel</h3>
                        </div>
                        <div onClick={() => { this.saveCurrentLanding()}} className="bg-purpleJS-100 cursor-pointer rounded-lg mt-5 px-10 rounded-md p-5 text-white flex">
                          <h3 className="">
                            {this.props.t('save_new_pw_btn')}
                          </h3>
                        </div>
                      </div> 
                    </div>
                  :
                    <div onClick={() => { this.editLanding('new')}} className="bg-purpleJS-100 cursor-pointer rounded-lg mt-5 w-1/6 rounded-md p-5 text-white flex">
                      <IoAdd className="mr-3 text-2xl" />
                      <h3 className="">
                        {this.props.t('manager_add_new_landing')}
                      </h3>
                    </div>
                  }
                  <div className="mt-10 mb-10">
                    <table className="w-full table-auto">
                      <thead className="">
                        <tr className="text-left">
                          <th className="p-5 rounded-l-lg bg-bgPurpleLightJS w-1/6">Identifier</th>
                          <th className="bg-bgPurpleLightJS w-1/6">Company</th>
                          <th className="bg-bgPurpleLightJS w-1/4">URL Landing</th>
                          <th className="bg-bgPurpleLightJS w-1/3">Banière</th>
                          <th className="bg-bgPurpleLightJS w-1/6"></th>
                          <th className="bg-bgPurpleLightJS w-1/12">Editer</th>
                          <th className="bg-bgPurpleLightJS w-1/12">Supprimer</th>
                          <th className="rounded-r-lg bg-bgPurpleLightJS w-32"></th>
                        </tr>
                      </thead>
                      <tbody className="text-left">
                      { 
                        this.state.landings.map((entry, count) => (
                          <tr key={entry.identifier + count}>
                            <td className="border-b border-slate-100 p-5">{entry.identifier}</td>
                            <td className="border-b border-slate-100">{entry.name}</td>
                            <td className="border-b border-slate-100"><a href={ this.apisEndpoint + "/landing/" + entry.identifier} target="_blank" rel="noopener noreferrer">{ this.apisEndpoint + "/landing/" + entry.identifier}</a></td>
                            <td className="border-b border-slate-100 relative rounded-lg w-1/3" style={{ backgroundColor: entry.bg_color, color: 'white' }}>
                              <Miniature className="mx-auto" width={this.getWidthRatio(entry.logo)} height="100" picture={entry.logo.src} username="" />
                            </td>
                            <td className="border-b border-slate-100"></td>
                            <td className="border-b border-slate-100 text-blue-800 relative">
                              <div onClick={() => {this.editLanding(entry)}} className="top-0 left-0 right-0 bottom-0 absolute z-50 cursor-pointer"></div>
                              <IoPencilSharp />
                            </td>
                            <td className="border-b border-slate-100 text-red-800 relative">
                              <div onClick={() => {this.deleteEntry(entry)}} className="top-0 left-0 right-0 bottom-0 absolute z-50 cursor-pointer"></div>
                               <IoTrashSharp />
                            </td>
                          </tr>
                        ))
                      }
                      </tbody>
                    </table>
                  </div>
                </div>
            }
          </div>
        )
    }
}

export default withTranslation()(AdminCustomLandings);
