import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import CFG from './Config';

import Header from './Header.js'
import Footer from './Footer.js'

//main css generated with tailwindcss
// import './../css/main.css';

//assets

const TITLE = 'JobStory - download app';

class DeepLink extends Component {

  // Then we add our constructor which receives our props
  constructor(props) {

    super(props);
    this.job_code = this.props.match.params.job_code;

    this.state = {
    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className="container h-full w-full max-w-none max-h-screen">
        <Helmet>
          <title>{TITLE + this.props.t('home_title')}</title>
          <meta property="og:title" content={TITLE + this.props.t('home_title')} />
          <meta name="description" content={this.props.t('meta_description')}></meta>
          <meta property="og:description" content={this.props.t('meta_description')} />
          <meta property="og:image" content={CFG.links.shareIMG} />
          <meta property="og:url" content={CFG.links.root} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Header location={this.props.location} />
        <article>
          <div className="flex flex-wrap mt-32 lg:mt-48">
            <div className="w-full md:w-6/12 pl-5 md:pl-20 pr-5">
              <h1 className="main-title font-bold text-2xl lg:text-big">{this.props.t('download_app')}</h1>
            </div>
          </div>
          <Footer />
        </article>
      </div>
    );
  }
}

export default withTranslation()(DeepLink);