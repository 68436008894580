import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Feedback from './components/Feedback'; // Import a component from another file
import Postulate from './components/Postulate';
import LandingCustomer from './components/LandingCustomer';
import ChargeBeeCustomer from './components/ChargeBeeCustomer';
import DeepLink from './components/DeepLink';
import JobCreation from './components/JobCreation';
import Unsubscribe from './components/Unsubscribe';
import AdminApp from './components/AdminApp';
import DashboardApp from './components/DashboardApp';
import ManagerApp from './components/manager/ManagerApp';
import LoginApp from './components/login/LoginApp';
import ResetPassword from './components/ResetPassword';
import Home from './components/Home';
import Page from './components/Page';
import Meeting from './components/Meeting'; // Import a component from another file
// import DirectApply from './components/DirectApply'; // Import a component from another file

import Amplify from 'aws-amplify';

import AuthConfig from './components/AuthConfig';
import Macros from './components/Macros';

// Amplify.Logger.LOG_LEVEL = 'DEBUG';

Amplify.configure(AuthConfig.Common);
        // <Route path="/direct-apply/:job_code" render={(props) => <DirectApply {...props} />} />

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/dashboard" render={(props) => <DashboardApp {...props} />} />
        <Route exact path="/login" render={(props) => <ManagerApp {...props} />} />
        <Route exact path="/login/:client_id" render={(props) => <LoginApp {...props} />} />
        <Route path="/manager/:page" render={(props) => <ManagerApp {...props} />} />
        <Route path="/manager" render={(props) => <ManagerApp {...props} />} />
        <Route path="/admin" render={(props) => <AdminApp {...props} />} />
        <Route path="/meeting-web-app/:recruiter_id/:candidate_id/:current_user_id" render={(props) => <Meeting {...props} />} />
        <Route path="/reset-password/:user_id/:reset_code" render={(props) => <ResetPassword {...props} />} />
        <Route path="/feedback/:share_id" render={(props) => <Feedback {...props} />} />
        <Route path="/candidate/offer/:job_code" render={(props) => <Postulate {...props} />} />
        <Route path="/landing/:customer" render={(props) => <LandingCustomer {...props} />} />
        <Route path="/portal/:plan_id/:subscription_id" render={(props) => <ChargeBeeCustomer {...props} />} />
        <Route path="/portal/:plan_id" render={(props) => <ChargeBeeCustomer {...props} />} />
        <Route path="/dla/:job_code" render={(props) => <DeepLink {...props} />} />
        <Route path="/recruiter-macros" render={(props) => <Macros {...props} />} />
        <Route path="/draft/offer/:edition_token/(step)?/:step?" render={(props) => <JobCreation {...props} />} />
        <Route path="/unsubscribe/:token/:topic" render={(props) => <Unsubscribe {...props} />} />
        <Route exact path="/" render={(props) => <Home {...props} />} />
        <Route exact path="/(privacy|terms|legals)" render={(props) => <Page {...props} />} />
        <Route render={(props) => <Page {...props} />} />
      </Switch>
    </Router>
  )
};