import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Textarea extends Component {

    constructor(props) {
        super(props);
        this.handleChange.bind(this);
    }

    handleChange = (event) => {
        this.props.handleChange({
            field: this.props.datas.field,
            value: event.target.value
        });
    }

    render() {
        return (
            <div className="w-full">
                <label className="mt-5 w-full block text-lg font-medium">{this.props.t(this.props.datas.label)}</label>
                <textarea rows="5" value={this.props.value == null ? '' : this.props.value} className="mt-5 resize-y p-5 placeholder-greyBorderJS
                font-greyBorderJS font-regular font-sans border-solid rounded-md border
                border-greyBorderJS w-full block" placeholder={this.props.t(this.props.datas.placeholder)} onChange={this.handleChange} />
            </div>
        )
    }
}

export default withTranslation()(Textarea);