import React, { Component } from 'react';

import loadingIMG from '../img/loading.svg';

export default class Loading extends Component {
    render() {
        return (
            <div>
                <img alt="loading" style={{ left: "50%", top: "50%"}}
                    src={loadingIMG} className="transform -translate-x-1/2 -translate-y-1/2 absolute w-20" />
            </div>
        )
    }
}
