import React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next'

import CFG from './../Config';


const NotFound = (props) => {

  return (
    <div className="w-full p-5 md:p-20">
      <Helmet>
        <title>JobStory - 404</title>
        <meta property="og:title" content="JobStory - 404" />
        <meta name="description" content={props.t('meta_description')}></meta>
        <meta property="og:description" content={props.t('meta_description')} />
        <meta property="og:image" content={CFG.links.shareIMG} />
        <meta property="og:url" content={CFG.links.root} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <h1 className="font-bold text-2xl">404 : Page non trouvée</h1>
      <br />
      <p>La page que vous demandez n'a pas été trouvée.</p>
    </div>
  )
}

export default withTranslation()(NotFound);