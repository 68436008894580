import React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next'


import CFG from './../Config';


const Terms = (props) => {

  return (
    <div className="w-full p-5 pt-20 md:p-20">
      <Helmet>
        <title>JobStory - Conditions générales de vente et d'utilisation</title>
        <meta property="og:title" content="JobStory - Conditions générales de vente et d'utilisation" />
        <meta name="description" content={props.t('meta_description')}></meta>
        <meta property="og:description" content={props.t('meta_description')} />
        <meta property="og:image" content={CFG.links.shareIMG} />
        <meta property="og:url" content={CFG.links.root} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <h1 className="font-bold text-2xl">Conditions générales de vente et d'utilisation</h1>
      <p>Derni&egrave;re date de mise &agrave; jour : 4 octobre 2020</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol>
        <li><strong>1. CHAMP D&rsquo;APPLICATION DES CGVU </strong></li>
      </ol>
      <p>Les pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente et d&rsquo;utilisation (&laquo;&nbsp;CGVU&nbsp;&raquo;) r&eacute;gissent l&rsquo;utilisation de l&rsquo;application mise &agrave; disposition par JOBSTORY par tout utilisateur (&laquo;&nbsp;l&rsquo;Utilisateur&nbsp;&raquo; ou &laquo;&nbsp;vous&nbsp;&raquo;), qu&rsquo;il soit&nbsp;:</p>
      <ul>
        <li>un internaute acc&eacute;dant, visitant et navigant sur l&rsquo;Application&nbsp;;</li>
        <li>un internaute s&rsquo;inscrivant, cr&eacute;ant un compte et acc&eacute;dant &agrave; son compte sur l&rsquo;Application.</li>
      </ul>
      <p>En naviguant sur l&rsquo;Application et en y r&eacute;alisant des actions, l&rsquo;Utilisateur reconna&icirc;t avoir pris connaissance des pr&eacute;sentes CGVU et les accepter sans r&eacute;serve.</p>
      <p>Les CGUV sont mises &agrave; jour de mani&egrave;re r&eacute;guli&egrave;re, et sont donc susceptibles de modification, notamment en vue de l&rsquo;am&eacute;lioration du fonctionnement de l&rsquo;Application et des fonctionnalit&eacute;s offertes. Ainsi, JOBSTORY pourra modifier &agrave; tout moment les pr&eacute;sentes CGVU et vous recommande de les v&eacute;rifier r&eacute;guli&egrave;rement. En tout &eacute;tat de cause, l&rsquo;Utilisateur sera avis&eacute; des versions ult&eacute;rieures contenant des modifications substantielles des CGVU afin de les accepter.&nbsp;</p>
      <p>Les CGVU applicables sont celles en vigueur lors de l&rsquo;acc&egrave;s en cause par l&rsquo;Utilisateur.</p>
      <p>JOBSTORY pourra mettre un terme &agrave; l'utilisation de l&rsquo;Application par l&rsquo;Utilisateur ou supprimer son compte utilisateur pour toute conduite violant les pr&eacute;sentes CGVU.</p>
      <p>Les pr&eacute;sentes CGVU sont compl&eacute;t&eacute;es par la Politique de confidentialit&eacute; qui fait partie int&eacute;grante des dispositions r&eacute;gissant les relations entre JOBSTORY et vous.</p>
      <p>&nbsp;</p>
      <ol start="2">
        <li><strong>2. D&Eacute;FINITIONS</strong></li>
      </ol>
      <p><strong>Application</strong>&nbsp;: d&eacute;signe l&rsquo;application mise &agrave; disposition par JOBSTORY, disponible sur les plateformes ANDROID et APPLE.</p>
      <p><strong>Candidat&nbsp;</strong>: d&eacute;signe une personne physique qui a ouvert un compte utilisateur sur l&rsquo;Application et y publie son profil et/ou postule &agrave; une offre d&rsquo;emploi publi&eacute;e par un Recruteur&nbsp;;</p>
      <p><strong>Recruteur&nbsp;: </strong>d&eacute;signe une personne, physique ou morale, (repr&eacute;sent&eacute;e par son repr&eacute;sentant l&eacute;gal ou toute autre personne dument habilit&eacute;e) qui a ouvert un compte utilisateur sur l&rsquo;Application et y publie sur l&rsquo;Application une offre d&rsquo;emploi et/ou contacte un Candidat pour lui proposer un entretien d&rsquo;embauche&nbsp;;</p>
      <p><strong>Story&nbsp;</strong>: d&eacute;signe une courte vid&eacute;o dans laquelle un Candidats r&eacute;pond &agrave; une question pr&eacute;d&eacute;finie par un Recruteur ayant post&eacute; une offre d&rsquo;emploi, en une minute maximum par question. Chaque Story est d&eacute;truite apr&egrave;s une dur&eacute;e de 12 mois&nbsp;;</p>
      <p><strong>Utilisateur&nbsp;: </strong>personne ayant cr&eacute;&eacute; un compte utilisateur naviguant sur l&rsquo;Application. Il peut s&rsquo;agir d&rsquo;un Candidat ou d&rsquo;un Recruteur.</p>
      <p>&nbsp;</p>
      <p><strong>&nbsp;</strong></p>
      <ol start="3">
        <li><strong>3. PR&Eacute;SENTATION DE L&rsquo;APPLICATION </strong>
          <ul>
            <li><strong>3.1. Objet de l&rsquo;Application</strong></li>
          </ul>
        </li>
      </ol>
      <p>L&rsquo;Application propose un espace d&eacute;di&eacute; &agrave; la rencontre de Recruteurs et Candidats.</p>
      <p>L&rsquo;Application est d&eacute;di&eacute;e uniquement aux Candidats recherchant un emploi ainsi qu&rsquo;aux Recruteurs recherchant du personnel. N&rsquo;utilisez l&rsquo;Application que dans ces cas et ne r&eacute;utilisez pas les contenus qui y sont publi&eacute;s &agrave; d&rsquo;autres fins.</p>
      <p>&nbsp;</p>
      <ul>
        <li><strong>3.2. Acc&egrave;s et fonctionnement de l&rsquo;Application</strong></li>
      </ul>
      <p><strong>&nbsp;</strong></p>
      <p><strong>Acc&egrave;s &agrave; l&rsquo;Application</strong>&nbsp;: L&rsquo;Utilisateur fait son affaire personnelle de la mise en place des moyens informatiques et de t&eacute;l&eacute;communications permettant l'acc&egrave;s &agrave; l&rsquo;Application et des connaissances n&eacute;cessaires &agrave; l'utilisation d'Internet et l'acc&egrave;s &agrave; l&rsquo;Application.</p>
      <p>L&rsquo;Utilisateur conserve &agrave; sa charge les frais de connexion et d'&eacute;quipement li&eacute;s &agrave; l'acc&egrave;s &agrave; Internet et &agrave; l'utilisation de l&rsquo;Application.</p>
      <p>L&rsquo;application est compatible avec les syst&egrave;mes d&rsquo;exploitation IOS et Android.</p>
      <p>L&rsquo;Utilisateur utilise l&rsquo;Application &agrave; ses risques et p&eacute;rils.</p>
      <p>Il est interdit de tenter d'obtenir un acc&egrave;s non autoris&eacute; au service, d'acc&eacute;der &agrave; l&rsquo;Application ou de s&rsquo;y maintenir par un autre moyen que l'interface qui est fournie &agrave; l&rsquo;Utilisateur par JOBSTORY &agrave; cet effet.</p>
      <p>&nbsp;</p>
      <p><strong>Compte utilisateur/abonnement</strong>&nbsp;: La cr&eacute;ation d&rsquo;un compte est n&eacute;cessaire&nbsp;pour naviguer sur l&rsquo;Application et avoir acc&egrave;s aux fonctionnalit&eacute;s qu&rsquo;elle propose ainsi que renseigner / corriger ses informations personnelles.</p>
      <p>L&rsquo;Utilisateur peut cr&eacute;er un compte sur l&rsquo;Application directement&nbsp;en renseignant les donn&eacute;es requises pour ce faire.</p>
      <p>La cr&eacute;ation d&rsquo;un compte utilisateur est r&eacute;serv&eacute; aux personnes &acirc;g&eacute;es de plus de 16 ans.</p>
      <p>La cr&eacute;ation d&rsquo;un compte utilisateur est payante, par le biais d&rsquo;un abonnement.</p>
      <p>L&rsquo;Utilisateur est tenu de s&rsquo;assurer que les donn&eacute;es qu&rsquo;il renseigne sont correctes, &agrave; jour et ne portent pas atteintes aux droits de tiers.</p>
      <p>JOBSTORY ne dispose pas des moyens techniques n&eacute;cessaires &agrave; la v&eacute;rification de l&rsquo;identit&eacute; des Utilisateurs acc&eacute;dant et s&rsquo;inscrivant sur l&rsquo;Application et ne peut, &agrave; ce titre, garantir les Utilisateurs. Toutefois, pour pr&eacute;server la qualit&eacute; de l&rsquo;Application, en cas de doute sur l&rsquo;exactitude des informations fournies par un Utilisateur, JOBSTORY se r&eacute;serve le droit de demander un justificatif officiel attestant de son identit&eacute; &agrave; l&rsquo;Utilisateur mis en cause et/ou de suspendre son acc&egrave;s au compte pendant le temps des v&eacute;rifications n&eacute;cessaires. En l&rsquo;absence de communication des justificatifs demand&eacute;s ou si le caract&egrave;re inexact des donn&eacute;es &eacute;tait av&eacute;r&eacute;, l&rsquo;Utilisateur ne pourra pas s&rsquo;inscrire ou son COMPTE pourra &ecirc;tre suspendu.</p>
      <p>&nbsp;</p>
      <p>Les donn&eacute;es d&rsquo;identification &agrave; son compte et en particulier son mot de passe, sont personnels et confidentiels. L&rsquo;Utilisateur reste seul responsable de l&rsquo;utilisation qu&rsquo;il en fait et toute connexion &agrave; son compte sera pr&eacute;sum&eacute;e &ecirc;tre faite par lui. En cas de perte ou de vol d&rsquo;un mot de passe voire m&ecirc;me en cas de suspicion de perte ou de vol, il est imp&eacute;ratif que l&rsquo;Utilisateur r&eacute;g&eacute;n&egrave;re imm&eacute;diatement un mot de passe en cliquant sur &laquo; mot de passe oubli&eacute; ? &raquo; dans l&rsquo;espace &laquo; connexion &raquo;.</p>
      <p>&nbsp;</p>
      <p><strong>Fonctionnement de l&rsquo;Application&nbsp;: </strong></p>
      <p>Les Recruteurs peuvent&nbsp;:</p>
      <ul>
        <li>Publier des offres d&rsquo;emploi&nbsp;sur l&rsquo;Application. Les Recruteurs d&eacute;cident librement du contenu des offres, la seule condition impos&eacute;e par JOBSTORY &eacute;tant qu&rsquo;elles &eacute;noncent 5 questions auxquels les Candidats devront r&eacute;pondre dans leurs Stories&nbsp;;</li>
        <li>Consulter les Stories de Candidats ayant r&eacute;pondu &agrave; leurs offres ;</li>
        <li>Partager des Stories de Candidats ayant r&eacute;pondu &agrave; leurs offres via un lien web, avec une limitation de 50 visionnages hors Application&nbsp;;</li>
        <li>Contacter les Candidats ayant r&eacute;pondu &agrave; leurs offres pour leur proposer un entretien d&rsquo;embauche&nbsp;;</li>
        <li>Discuter avec des Candidats ayant r&eacute;pondu &agrave; leurs offres, par le biais d&rsquo;un outil de messagerie et de live chat&nbsp;;</li>
        <li>Suivre les offres d&rsquo;emploi qu&rsquo;ils ont publi&eacute;es, notamment les r&eacute;ponses obtenues.</li>
      </ul>
      <p>&nbsp;</p>
      <p>Les Candidats peuvent&nbsp;:</p>
      <ul>
        <li>Publier un profil g&eacute;n&eacute;rique compos&eacute;&nbsp;:
<ul>
            <li>D&rsquo;informations le concernant&nbsp;;</li>
            <li>D&rsquo;informations concernant le type de poste qu&rsquo;il recherche (notamment la localisation, la dur&eacute;e du contrat et le secteur)</li>
            <li>Du type de poste qu&rsquo;il recherche, et dans quel domaine&nbsp;;</li>
          </ul>
        </li>
        <li>Consulter les offres d&rsquo;emploi publi&eacute;es par les Recruteurs&nbsp;;</li>
        <li>Mettre en favori certaines offres d&rsquo;emploi&nbsp;;</li>
        <li>R&eacute;pondre &agrave; des offres d&rsquo;emploi publi&eacute;es par les Recruteurs, par le biais de 3 &agrave; 5 Stories par annonce ;</li>
        <li>Discuter avec des Recruteurs, par le biais d&rsquo;un outil de messagerie et de live chat&nbsp;;</li>
        <li>Suivre les offres d&rsquo;emploi auxquelles ils ont r&eacute;pondu.</li>
      </ul>
      <p><strong>&nbsp;</strong></p>
      <ul>
        <li><strong>3.3. Paiement</strong></li>
      </ul>
      <p>Les Utilisateurs peuvent r&eacute;gler leur abonnement selon les modes de r&egrave;glement offerts par l&rsquo;Application.</p>
      <p>Il est express&eacute;ment convenu entre les parties que seuls les moyens de paiement propos&eacute;s par JOBSTORY sont accept&eacute;s. En aucun cas l&rsquo;Utilisateur ne pourra utiliser d&rsquo;autre moyen tel que la crypto-monnaie.</p>
      <p>&nbsp;</p>
      <p>Les abonnements se font via le magasin d&rsquo;Apple (Apple store) ou le magasin de Google (Google Play).</p>
      <p>&nbsp;</p>
      <p>L&rsquo;Utilisateur reconnait express&eacute;ment avoir pris connaissance et accepte les conditions g&eacute;n&eacute;rales des &eacute;tablissements concern&eacute;s qui seront responsables du traitement des donn&eacute;es personnelles saisies par l&rsquo;Utilisateur relatives aux moyens de paiement.</p>
      <p>&nbsp;</p>
      <p>L&rsquo;Utilisateur garantit qu'il est pleinement habilit&eacute; &agrave; utiliser le moyen de paiement dont il fait usage et que ce moyen de paiement donne acc&egrave;s &agrave; des fonds suffisants pour couvrir les co&ucirc;ts du vote et s'engage &agrave; communiquer &agrave; ces &eacute;tablissements des informations exactes.</p>
      <p>&nbsp;</p>
      <ul>
        <li><strong>3.4. Droit de r&eacute;tractation</strong></li>
      </ul>
      <p>Dans le cas o&ugrave; l&rsquo;Utilisateur serait une entit&eacute; de cinq ou moins de cinq salari&eacute;s et n&rsquo;ayant pas une activit&eacute; principale en lien avec l&rsquo;objet des CGVU, sign&eacute;s hors &eacute;tablissement, l&rsquo;Utilisateur dispose d&rsquo;un d&eacute;lai de quatorze jours de r&eacute;tractation &agrave; compter de la commande de son abonnement, conform&eacute;ment &agrave; l&rsquo;article L. 221-3 du code de la consommation. Un formulaire de r&eacute;tractation est disponible en annexe des CGVU.</p>
      <p>Dans les autres cas, le Client ne dispose d&rsquo;aucun droit &agrave; r&eacute;tractation.</p>
      <p>&nbsp;</p>
      <ol start="4">
        <li><strong>4. RESPONSABILIT&Eacute;</strong>
          <ul>
            <li><strong>4.1. Responsabilit&eacute; de l&rsquo;Utilisateur</strong></li>
          </ul>
        </li>
      </ol>
      <p><strong>Responsabilit&eacute; de tous les Utilisateurs</strong>&nbsp;: L&rsquo;Utilisateur s&rsquo;engage &agrave; utiliser l&rsquo;Application ainsi que son compte utilisateur, de mani&egrave;re responsable et licite.</p>
      <p>L&rsquo;Utilisateur s&rsquo;interdit notamment de&nbsp;:</p>
      <ul>
        <li>T&eacute;l&eacute;charger vers l&rsquo;Application et/ou afficher : un contenu qu&rsquo;il n&rsquo;est pas autoris&eacute; &agrave; transmettre ; un contenu ill&eacute;gal ou pr&eacute;judiciable, notamment pouvant porter atteinte &agrave; l&rsquo;image de JOBSTORY ou de tout tiers (en ce y compris les partenaires de JOBSTORY)&nbsp;; des &eacute;l&eacute;ments publicitaires ou promotionnels non sollicit&eacute;s ou non autoris&eacute;s ; tout &eacute;l&eacute;ment contenant des virus logiciels ou autres codes, fichiers ou programmes informatiques con&ccedil;us pour interrompre, d&eacute;truire ou limiter la fonctionnalit&eacute; de tout logiciel ou mat&eacute;riel informatique ou de tout &eacute;quipement de t&eacute;l&eacute;communication&nbsp;;</li>
        <li>Traiter les donn&eacute;es personnelles pr&eacute;sentes sur l&rsquo;Application de mani&egrave;re non conforme &agrave; la r&eacute;glementation applicable &agrave; de telles donn&eacute;es, notamment en ne respectant pas la finalit&eacute; pour laquelle elles sont trait&eacute;es&nbsp;;</li>
        <li>Perturber ou interrompre le fonctionnement de l&rsquo;Application ou les serveurs connect&eacute;s &agrave; l&rsquo;Application&nbsp;;</li>
        <li>Utiliser des syst&egrave;mes d&rsquo;exploration de donn&eacute;es (ou <em>data mining</em>), robots ou toute autre m&eacute;thode automatis&eacute;e semblable pour collecter et/ou exploiter des donn&eacute;es issues de l&rsquo;Application&nbsp;;</li>
        <li>Agr&eacute;ger, copier ou dupliquer le Contenu de l&rsquo;Application, y compris les offres d&rsquo;emploi, m&ecirc;me expir&eacute;es&nbsp;;</li>
        <li>Usurper l&rsquo;identit&eacute; d&rsquo;une autre personne et notamment d&rsquo;un autre Utilisateur ;</li>
        <li>Se connecter &agrave; un compte utilisateur autre que celui qu&rsquo;il a cr&eacute;&eacute;&nbsp;;</li>
        <li>Acc&eacute;der aux donn&eacute;es qui ne lui sont pas destin&eacute;es ou entrer dans un serveur / un compte auquel il n&rsquo;est pas autoris&eacute; &agrave; avoir acc&egrave;s&nbsp;;</li>
        <li>Supprimer ou modifier (ou tenter de le faire) des contenus publi&eacute;s par une autre personne, notamment un autre Utilisateur&nbsp;;</li>
        <li>Harceler un autre Utilisateur.</li>
      </ul>
      <p><strong>Responsabilit&eacute; suppl&eacute;mentaire des Recruteurs</strong>&nbsp;</p>
      <p>Les Recruteurs s&rsquo;engagent notamment &agrave;&nbsp;:</p>
      <ul>
        <li>Publier sur l&rsquo;Application des annonces licites.</li>
      </ul>
      <p>Pour rappel, la diffusion des offres d&rsquo;emploi aupr&egrave;s du public est r&eacute;glement&eacute;e (articles L1121-1, L.1132-1 et s., L.1142-1, L.5331-1 et s., du Code du travail) et le non-respect de ces r&egrave;gles peut &ecirc;tre p&eacute;nalement sanctionn&eacute;e. Les grands principes &agrave; respecter lors de la r&eacute;daction de vos offres d&rsquo;emploi sont rappel&eacute;s ci-dessous :</p>
      <ul>
        <li>L&rsquo;offre d&rsquo;emploi doit &ecirc;tre dat&eacute;e ;</li>
        <li>L&rsquo;acc&egrave;s &agrave; l&rsquo;offre d&rsquo;emploi doit &ecirc;tre gratuit. Ainsi, le Recruteur s&rsquo;engage notamment &agrave; ne pas mentionner un num&eacute;ro de t&eacute;l&eacute;phone surtax&eacute; dans l&rsquo;offre d&rsquo;emploi ;</li>
        <li>L&rsquo;offre d&rsquo;emploi doit &ecirc;tre r&eacute;dig&eacute;e en fran&ccedil;ais ;</li>
        <li>L&rsquo;offre doit pr&eacute;ciser clairement si l&rsquo;emploi porte sur un travail salari&eacute; (contrat de travail) ou non salari&eacute; (absence de contrat de travail) ;</li>
        <li>L&rsquo;offre d&rsquo;emploi peut &ecirc;tre anonyme. Le nom du Recruteur n&rsquo;apparaitra alors pas dans l&rsquo;offre mais il aura n&eacute;cessairement fourni son v&eacute;ritable nom ou sa v&eacute;ritable raison sociale ainsi que son adresse JOBSTORY lors de la cr&eacute;ation de son compte utilisateur&nbsp;;</li>
        <li>L&rsquo;offre doit correspondre &agrave; un emploi r&eacute;el et disponible ;</li>
        <li>L&rsquo;offre ne doit pas contenir des all&eacute;gations fausses ou susceptibles d&rsquo;induire en erreur&nbsp;;</li>
        <li>L&rsquo;offre ne doit contenir des demandes de renseignements et documents ayant uniquement un lien direct avec l&rsquo;exercice du poste &agrave; pourvoir ;</li>
        <li>sauf exceptions pr&eacute;vues par la loi ou par un r&egrave;glement, l&rsquo;offre d&rsquo;emploi ne doit pas mentionner de crit&egrave;re discriminatoire fond&eacute; sur le sexe, l&rsquo;orientation sexuelle, l&rsquo;identit&eacute; sexuelle, l&rsquo;&acirc;ge, la situation de famille, les m&oelig;urs, l&rsquo;&eacute;tat de grossesse, la particuli&egrave;re vuln&eacute;rabilit&eacute; r&eacute;sultant de sa situation &eacute;conomique apparente ou connue de son auteur, l&rsquo;origine, l&rsquo;appartenance ou la non-appartenance, vraie ou suppos&eacute;e, &agrave; une ethnie, une nation ou une race, les opinions politiques, les activit&eacute;s syndicales ou mutualistes, les convictions religieuses, les caract&eacute;ristiques g&eacute;n&eacute;tiques, l&rsquo;apparence physique, le patronyme, l&rsquo;&eacute;tat de sant&eacute; ou le handicap, ou de sa perte d&rsquo;autonomie, la capacit&eacute; &agrave; s&rsquo;exprimer dans une langue autre que le fran&ccedil;ais.</li>
        <li>Ne pas ins&eacute;rer, dans leurs annonces des noms, logos ou marques de tiers sans autorisation&nbsp;valable&nbsp;;</li>
      </ul>
      <p>Bien que JOBSTORY ne soit pas responsable des offres d&rsquo;emploi publi&eacute;es sur l&rsquo;Application, elle se r&eacute;serve le droit de supprimer toute offre d&rsquo;emploi qui ne respecterait pas les pr&eacute;sentes CGVU ou dont le contenu serait susceptible de lui porter atteinte ou de porter atteinte &agrave; des tiers, y compris les Candidats.</p>
      <p><strong>Responsabilit&eacute; suppl&eacute;mentaire des Candidats</strong>&nbsp;</p>
      <p>Les Candidats s&rsquo;interdisent notamment de&nbsp;:</p>
      <ul>
        <li>Publier un profil ou postuler &agrave; une offre d&rsquo;emploi au nom ou pour le compte d&rsquo;une autre personne&nbsp;;</li>
        <li>Publier un profil qui aurait pour but unique de promouvoir des produits ou services ou de d&eacute;tourner l&rsquo;objectif de l&rsquo;Application.</li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li><strong>4.2. Limitation de responsabilit&eacute; de JOBSTORY</strong></li>
      </ul>
      <p><strong>Limite de responsabilit&eacute; quant au fonctionnement de l&rsquo;Application&nbsp;: </strong>JOBSTORY s&rsquo;engage &agrave; veiller au bon fonctionnement de l&rsquo;Application, cependant sa responsabilit&eacute; ne saurait &ecirc;tre engag&eacute;e pour tous les inconv&eacute;nients ou dommages que l&rsquo;Utilisateur pourrait subir du fait de l&rsquo;utilisation de l&rsquo;Application ou des inconv&eacute;nients ou dommages inh&eacute;rents &agrave; l'utilisation du r&eacute;seau Internet, notamment une rupture de service, une intrusion ext&eacute;rieure ou la pr&eacute;sence de virus informatiques.</p>
      <p><strong>Limite de responsabilit&eacute; quant &agrave; l&rsquo;objet de l&rsquo;Application&nbsp;: </strong>l&rsquo;Utilisateur reconnait que JOBSTORY agit en tant que simple plateforme entre les Candidats et les Recruteurs, permettant de rapprocher les offres et les demandes d'emploi, mais n&rsquo;intervient pas dans le processus de recrutement et ne devient aucunement partie aux relations de travail susceptible d&rsquo;en d&eacute;couler. JOBSTORY ne peut en aucun cas &ecirc;tre assimil&eacute; &agrave; un employeur ni &agrave; l&rsquo;entit&eacute; ayant publi&eacute; une offre d&rsquo;emploi ou un mandataire de ces derniers.</p>
      <p>Les Recruteurs sont seuls responsables du contenu des offres d&rsquo;emploi dont ils demandent la diffusion &agrave; JOBSTORY et de la proc&eacute;dure de recrutement qu&rsquo;il met en place pour pourvoir leurs offres d&rsquo;emploi, y compris &agrave; la suite de la publication d&rsquo;offres sur l&rsquo;Application.</p>
      <p>Aucune obligation de r&eacute;sultat ne peut &ecirc;tre attendue par l&rsquo;Utilisateur quant &agrave; l&rsquo;ad&eacute;quation d&rsquo;une offre d&rsquo;emploi ou d&rsquo;une candidature &agrave; ses besoins. Ainsi&nbsp;:</p>
      <ul>
        <li>Les Candidats acceptent qu&rsquo;ils puissent ne pas trouver d&rsquo;emploi malgr&eacute; l&rsquo;utilisation de l&rsquo;Application&nbsp;;</li>
        <li>Les Recruteurs acceptent qu&rsquo;ils puissent ne pas trouver d&rsquo;employ&eacute;, voire de candidat &agrave; une offre d&rsquo;emploi qu&rsquo;ils auraient publi&eacute;e sur l&rsquo;Application.</li>
      </ul>
      <p>Bien que les Recruteurs restent responsables des offres d&rsquo;emploi qu&rsquo;ils publient, JOBSTORY fait ses meilleurs efforts pour diffuser des annonces de qualit&eacute; et licites. Toutefois, si un Utilisateur voyait une information enfreignant la loi, il s&rsquo;engage &agrave; le signaler &agrave; JOBSTORY.</p>
      <p>Le contenu mis &agrave; disposition sur l&rsquo;Application est fourni &agrave; titre informatif.</p>
      <p>La responsabilit&eacute; de JOBSTORY ne saurait &ecirc;tre engag&eacute;e du fait des informations, opinions et recommandations formul&eacute;es par des tiers.</p>
      <p>&nbsp;</p>
      <ol start="5">
        <li><strong>5. DONN&Eacute;ES PERSONNELLES</strong></li>
      </ol>
      <p><em>Rappel&nbsp;pr&eacute;alable : ne communiquez pas votre num&eacute;ro de s&eacute;curit&eacute; sociale, votre num&eacute;ro de carte de cr&eacute;dit ou vos coordonn&eacute;es bancaires &agrave; des Recruteurs potentiels.</em></p>
      <p>Les Traitements de Donn&eacute;es personnelles r&eacute;alis&eacute;s par JOBSTORY sont pr&eacute;cis&eacute;s dans la Politique de confidentialit&eacute;.</p>
      <p>Cependant, en tant qu&rsquo;Utilisateur, vous allez &eacute;galement &ecirc;tre destinataires de Donn&eacute;es personnelles d&rsquo;autres utilisateurs (principalement des Candidats), que vous serez susceptibles de traiter &agrave; votre tour en tant que Responsable du Traitement ind&eacute;pendant, pour la gestion du recrutement de votre personnel.</p>
      <p>A ce titre, vous vous engagez &agrave;&nbsp;:</p>
      <ul>
        <li>Respecter vos obligations, en tant que Responsable du Traitement que vous op&egrave;rerai pour voter propre compte, en en d&eacute;finissant les finalit&eacute;s et modalit&eacute;s, notamment en termes d&rsquo;information des Candidats relative au nouveau Traitement&nbsp;;</li>
        <li>Transmettre &agrave; JOBSTORY toute demande d&rsquo;exercice d&rsquo;un droit &agrave; l&rsquo;effacement, &agrave; la rectification ou &agrave; la limitation qui pourrait concerner des Donn&eacute;es &agrave; caract&egrave;re personnel qui auraient fait l&rsquo;objet d&rsquo;une communication de la part de JOBSTORY.</li>
      </ul>
      <p>&nbsp;</p>
      <ol start="6">
        <li><strong>6. PROPRI&Eacute;T&Eacute; INTELLECTUELLE</strong>
          <ul>
            <li><strong>6.1. Droits sur l&rsquo;Application</strong></li>
          </ul>
        </li>
      </ol>
      <p>Les &eacute;l&eacute;ments de l&rsquo;Application sont la propri&eacute;t&eacute; de JOBSTORY et/ou de ses partenaires. Certains de ces &eacute;l&eacute;ments (par exemple les marques, logiciels, logos, bases de donn&eacute;es d&rsquo;offres d&rsquo;emplois et de profils, photographies, illustrations, textes, charte graphique ou les combinaisons de ces &eacute;l&eacute;ments) peuvent &ecirc;tre prot&eacute;g&eacute;s par des droits de propri&eacute;t&eacute; intellectuelle, notamment par le droit d&rsquo;auteur et le droit des marques. La divulgation de ces &eacute;l&eacute;ments ne saurait &ecirc;tre interpr&eacute;t&eacute;e comme accordant une quelconque licence sur ceux-ci ou un quelconque droit autre que celui de consulter et faire un usage personnel de l&rsquo;Application, dans le cadre de sa fonction.</p>
      <p>Toute exploitation (notamment reproduction, repr&eacute;sentation, adaptation, etc.) totale ou partielle de l&rsquo;Application ou de ses &eacute;l&eacute;ments, sur quelque support que ce soit (notamment papier ou num&eacute;rique) sans autorisation &eacute;crite pr&eacute;alable de JOBSTORY, expose les contrevenants &agrave; des poursuites judiciaires.</p>
      <ul>
        <li><strong>6.2. Liens hypertextes</strong></li>
      </ul>
      <p>L&rsquo;Application peut proposer des liens vers d'autres sites internet ou applications ou d'autres sources d'informations g&eacute;r&eacute;s par des sites partenaires. Dans la mesure o&ugrave; JOBSTORY ne peut exercer un contr&ocirc;le sur ces services partenaires et ces sources externes, elle ne prend aucun engagement quant &agrave; la mise &agrave; disposition et le contenu de ces sites et sources externes, et d&eacute;cline toute responsabilit&eacute; quant au contenu, publicit&eacute;s, produits, services ou tout autre mat&eacute;riel disponible sur ou &agrave; partir de ces sites ou sources externes. JOBSTORY d&eacute;cline &eacute;galement toute responsabilit&eacute; quant aux dommages ou pertes av&eacute;r&eacute;s ou all&eacute;gu&eacute;s, cons&eacute;cutifs ou en relation avec l'utilisation, ou avec le fait d'avoir fait confiance au contenu, &agrave; des biens ou des services disponibles sur ces services partenaires ou sources externes.</p>
      <p>La mise en place de liens vers l&rsquo;Application n'est conditionn&eacute;e &agrave; aucun accord pr&eacute;alable, mais la personne qui la r&eacute;alise s&rsquo;engage &agrave; respecter la l&eacute;gislation en vigueur. Seule la mention explicite de l&rsquo;Application dans l'intitul&eacute; du lien, est exig&eacute;e. Il convient toutefois d'afficher cette Appllication dans une nouvelle fen&ecirc;tre ou un nouvel onglet du navigateur et de ne pas utiliser la technique de <em>framing</em>. Cependant, JOBSTORY se r&eacute;serve le droit de demander la suppression d'un lien qu'il estime non conforme &agrave; l'objet de l&rsquo;Application (notamment aux pr&eacute;sentes CGVU), diffamant, contrefaisant, parasitant ou contrevenant &agrave; ses droits ou &agrave; ceux des tiers.</p>
      <p>&nbsp;</p>
      <ol start="7">
        <li><strong>7. DIVERS</strong>
          <ul>
            <li><strong>7.1. Loi applicable</strong></li>
          </ul>
        </li>
      </ol>
      <p>Les pr&eacute;sentes CGVU sont soumises &agrave; la loi fran&ccedil;aise.</p>
      <ul>
        <li><strong>7.2. Dur&eacute;e</strong></li>
      </ul>
      <p>Les pr&eacute;sentes CGVU vous restent opposables tant que vous &ecirc;tes Utilisateur de l&rsquo;Application.</p>
      <ul>
        <li><strong>7.3. Tol&eacute;rance&nbsp;</strong></li>
      </ul>
      <p>Le fait pour l&rsquo;une des Parties de ne pas exiger &agrave; un moment quelconque l&rsquo;ex&eacute;cution stricte par l&rsquo;autre Partie d&rsquo;une disposition ou condition quelconque des pr&eacute;sentes CGVU ne sera pas r&eacute;put&eacute; constituer une renonciation d&eacute;finitive &agrave; cette disposition ou condition.</p>
      <ul>
        <li><strong>7.4. Nullit&eacute; d&rsquo;une clause&nbsp;</strong></li>
      </ul>
      <p>Si l&rsquo;une des clauses non essentielles des CGUV, s&rsquo;av&eacute;rait nulle ou inapplicable en vertu d&rsquo;une loi ou d&rsquo;un r&egrave;glement ou &agrave; la suite d&rsquo;une d&eacute;cision ex&eacute;cutoire d&rsquo;une juridiction ou d&rsquo;une autorit&eacute; comp&eacute;tente, les Parties conviennent express&eacute;ment que les pr&eacute;sentes ne seront pas affect&eacute;es par la nullit&eacute; de la clause en question.</p>
      <ul>
        <li><strong>7.5. R&eacute;clamation</strong></li>
      </ul>
      <p>Pour toute r&eacute;clamation, vous pouvez, si vous le souhaitez, contacter le m&eacute;diateur de la consommation &agrave; l&rsquo;adresse suivante&nbsp;: M&eacute;diation - vivons mieux ensemble</p>
      <p>465 avenue de la Lib&eacute;ration</p>
      <p>54000 NANCY</p>
      <p>ou par email&nbsp;: mediation@vivons-mieux-ensemble.fr&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  )
}

export default withTranslation()(Terms);