import React from 'react';

import { AmplifyAuthenticator, AmplifySignIn, AmplifyForgotPassword } from '@aws-amplify/ui-react';
import { withTranslation } from 'react-i18next';

import AuthConfig from './AuthConfig';

import DashboardManager from './DashboardManager'; // Import a component from another file
import ManagerCandidates from './ManagerCandidates'; // Import a component from another file

import logoWhiteIMG from '../img/small-logo.svg';
import loadingIMG from '../img/loading.transparent.svg';

import { Hub, Auth, API } from 'aws-amplify';
      
class DashboardApp extends React.Component {
    constructor(props) {
      super(props);

      this.res_candidates = [];
      this.res_offers = [];
      this.res_landing = [];
      this.res_recruiters = [];

      this.state = {
        page: "home",
        loading: true,
        offers: [],
        date_filter: 'all',
        recruiters: [],
        candidates: [],
        landing: [],
        user: null
      };
      
      Hub.listen('auth', (data) => {
        this.onAuthEvent(data);
      });
      
      this.signOut = this.signOut.bind(this);
      this.setDateFilter = this.setDateFilter.bind(this);
    }
    
    componentDidMount() {
      this.onAuthEvent();
    }
    
    signOut() {
      Auth.signOut();
    }
    
    setDateFilter(date_code) {
      var newState = {
        date_filter: date_code
      };
      
      // newState.stats = {
        // wishlist: 0,
        // new_candidates: 0,
        // candidates: 0,
        // rejects: 0,
        // shortlist: 0,
      // };
      
      var today = new Date();

      var start_date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      
      switch(date_code) {
        case "all":
          start_date.setYear(2018);
          break;
        case "today":
          // start_date.setDate(today.getDate()-0);
          break;
        case "7D":
          start_date.setDate(today.getDate()-7);
          break;
        case "1M":
          start_date.setMonth(today.getMonth()-1);
          break;
        case "3M":
          start_date.setMonth(today.getMonth()-3);
          break;
        case "6M":
          start_date.setMonth(today.getMonth()-6);
          break;
        default:
          start_date.setYear(2018);
          break;
      }

      newState.recruiters = this.res_recruiters;

      newState.offers = [];
      
      for(var i = 0; i < this.res_offers.length ; i++) {
        // Gérer la date
        
        const offer = this.res_offers[i];
        
        // newState.stats.new_candidates += offer.details.new_candidates;
        // newState.stats.candidates += offer.details.total_candidates;
        
        newState.offers.push(offer);
      }
      
      newState.candidates = [];
      for(let i = 0; i < this.res_candidates.length ; i++) {
        const application = this.res_candidates[i];

        if(new Date(application.start_date) >= start_date) {

          application.offer = this.getOfferFromJobCode(application.job_code, this.res_offers);
          
          application.searchField = application.job_code;
          application.searchField += " " + application.candidate.name;
          application.searchField += " " + application.candidate.phone;
          application.searchField += " " + application.offer.title;
          application.searchField += " " + application.offer.area;
          application.searchField += " " + application.offer.city;
          application.searchField += " " + application.offer.company_name;
          application.searchField += " " + application.offer.shopname;
          application.searchField += " " + application.offer.zip_code;
          application.searchField += " " + application.offer.country;
          application.searchField += " " + application.offer.contract_type;

          if(application.candidate.email) {
            application.searchField += " " + application.candidate.email;
          }
          
          application.searchField = application.searchField.toLowerCase();
          
          if(newState.offers.find(offer => offer.job_code === application.job_code)) {
            newState.candidates.push(application);
          }
        }
      }
      
      newState.landing = [];
      for(let i = 0; i < this.res_landing.length ; i++) {
        const application = this.res_landing[i];

        if(new Date(application.start_date) >= start_date) {

          application.offer = this.getOfferFromJobCode(application.job_code, this.res_offers);
          
          application.searchField = application.job_code;
          application.searchField += " " + application.candidate.name;
          application.searchField += " " + application.candidate.phone;
          application.searchField += " " + application.offer.title;
          application.searchField += " " + application.offer.area;
          application.searchField += " " + application.offer.city;
          application.searchField += " " + application.offer.company_name;
          application.searchField += " " + application.offer.shopname;
          application.searchField += " " + application.offer.zip_code;
          application.searchField += " " + application.offer.country;
          application.searchField += " " + application.offer.contract_type;

          if(application.candidate.email) {
            application.searchField += " " + application.candidate.email;
          }
          
          application.searchField = application.searchField.toLowerCase();
          
          newState.landing.push(application);
        }
      }

      console.log(this.res_landing);
      console.log(newState.landing);
          
      this.setState(newState);
    }
    
    getOfferFromJobCode(job_code, offers) {
      var offer = offers.find((offer) => offer.job_code === job_code);
      
      if(!offer) offer = { title: '', shopname: ""};
      else if(!offer.shopname || offer.shopname == null) offer.shopname = "";
      return offer;
    }
    
    async onAuthEvent(data) {      
      var newState = {
        user: null
      };
      
      
      if(data && data.payload && data.payload.event === "signIn") {
        newState.user = data.payload.data;
      } else if(data && data.payload && data.payload.event === "signOut") {
        newState.user = null;
      } else {
        // Load auth
        newState.user = await Auth.currentUserInfo();
      }
      
      
      if(newState.user != null) {
                
        var results = await API.get('apis', '/apis/offer');
        this.res_offers = results.Items;
        
        var results_land = await API.get('apis', '/apis/job-interest');
        this.res_landing = results_land.Items;
                
        var applications_results = await API.get('apis', '/apis/application');
        
        this.res_recruiters = applications_results.recruiters;
        this.res_candidates = applications_results.Items;
              
        this.setDateFilter(this.state.date_filter);


        newState.loading = false;
        
        
      }
      
      this.setState(newState);
    }
    
    render() {
      return (
        <div className="w-full relative">
          <div className="text-white float-left w-full p-10 bg-purpleJS-100">
              <a href="/" className="float-left w-full md:w-auto"><img alt="logo jobstory" src={logoWhiteIMG} /></a>
              
              { this.state.user !== null ? [
                <div key="user-auth" className="w-full md:w-auto">
                  <div onClick={() => {this.setState({page: "home"}); }} className={ this.state.page === "home" ?
                    "float-left ml-40 p-2 border-b-2 border-white text-white font-bold"
                  : "float-left ml-40 p-2 text-white font-bold cursor-pointer"
                  }>Dashboard</div>
                  <div onClick={() => {this.setState({page: "candidates"}); }} className={ this.state.page === "candidates" ?
                    "float-left ml-10 p-2 border-b-2 border-white text-white font-bold"
                  : "float-left ml-10 p-2 text-white font-bold cursor-pointer"
                  }>Candidats</div>
                  { this.state.user !== null ?
                    <img className="float-right mb-2 hover:scale-110 w-12 rounded-full" alt="logo user" src={AuthConfig.CDN.images + '32x32/' + this.state.user.attributes.picture} />
                  : null
                  }
                </div>
              ]
              : null
              }
          </div>
          <div className="w-full p-10 bg-gray-100 min-h-screen float-left">
            { this.state.user != null ?
              <div  key="authenticated" className="w-full">
                <div className="w-full mb-10 float-left">
                
                
                  <div className={
                    this.state.date_filter === "all" ?
                      "select-none border-2 float-left p-2 px-5 border-r-0 cursor-pointer rounded-l-lg bg-gray-300 text-black font-bold"
                    :
                      "select-none border-2 float-left p-2 px-5 border-r-0 cursor-pointer rounded-l-lg text-gray-500"
                  } onClick={() => this.setDateFilter('all')}>All time</div>
                  
                  
                  <div className={
                    this.state.date_filter === "today" ?
                      "select-none border-2 float-left p-2 px-5 border-r-0 cursor-pointer bg-gray-300 text-black font-bold"
                    :
                      "select-none border-2 float-left p-2 px-5 border-r-0 cursor-pointer text-gray-500"
                  } onClick={() => this.setDateFilter('today')}>Today</div>
                  
                  <div className={
                    this.state.date_filter === "7D" ?
                      "select-none border-2 float-left p-2 px-5 border-r-0 cursor-pointer bg-gray-300 text-black font-bold"
                    :
                      "select-none border-2 float-left p-2 px-5 border-r-0 cursor-pointer text-gray-500"
                  } onClick={() => this.setDateFilter('7D')}>7D</div>
                  
                  <div className={
                    this.state.date_filter === "1M" ?
                      "select-none border-2 float-left p-2 px-5 border-r-0 cursor-pointer bg-gray-300 text-black font-bold"
                    :
                      "select-none border-2 float-left p-2 px-5 border-r-0 cursor-pointer text-gray-500"
                  } onClick={() => this.setDateFilter('1M')}>1M</div>
                  
                  <div className={
                    this.state.date_filter === "3M" ?
                      "select-none border-2 float-left p-2 px-5 border-r-0 cursor-pointer bg-gray-300 text-black font-bold"
                    :
                      "select-none border-2 float-left p-2 px-5 border-r-0 cursor-pointer text-gray-500"
                  } onClick={() => this.setDateFilter('3M')}>3M</div>
                  
                  <div className={
                    this.state.date_filter === "6M" ?
                      "select-none border-2 float-left p-2 px-5 cursor-pointer rounded-r-lg bg-gray-300 text-black font-bold"
                    :
                      "select-none border-2 float-left p-2 px-5 cursor-pointer rounded-r-lg text-gray-500"
                  } onClick={() => this.setDateFilter('6M')}>6M</div>

                </div>
                { this.state.loading ?
                  <img alt="loading" src={loadingIMG} className="ml-1/2 mt-10 w-20 mb-100" />                  
                : [
                  this.state.page === "home" ?
                    <DashboardManager key="home" user={this.state.user} offers={this.state.offers} candidates={this.state.candidates} recruiters={this.state.recruiters} />
                  :
                    <ManagerCandidates key="candidates" user={this.state.user} offers={this.state.offers} landing={this.state.landing} candidates={this.state.candidates} recruiters={this.state.recruiters} />
                  ]
                }
              </div>
            :
              <div key="auth" align="center">
                <AmplifyAuthenticator className="amplify-components" usernameAlias="email">
                  <AmplifySignIn 
                    headerText="Identification" 
                    slot="sign-in" 
                    hideSignUp="true" 
                    submitButtonText="S'identifier" 
                    formFields={[
                      { 
                        type: 'email', 
                        label: 'Email', 
                        placeholder: 'Email', 
                        hint: 'Enter Your Email', 
                        required: true 
                      },
                      { 
                        type: "password", 
                        placeholder: "Mot de passe", 
                        label: "Mot de passe" 
                      }
                      
                    ]}
                  />
                  <AmplifyForgotPassword
                    headerText="Mot de passe oublié"
                    slot="forgot-password"
                  ></AmplifyForgotPassword>
                </AmplifyAuthenticator>
              </div>
            }   
          </div>
        </div>
      )
    }
}

export default withTranslation()(DashboardApp);