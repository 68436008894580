import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import AuthConfig from './AuthConfig';
import CFG from './Config';
import Loading from './Loading';
import Error from './Error';
import JSButtonFull from './JSButtonFull';
import Miniature from './Miniature';
//main css generated with tailwindcss
import './../css/main.css';

import { setLanguage } from './controllers/Language';
import { IoCheckmarkSharp } from "react-icons/io5";

//assets
import appleStoreIMG from '../img/stores/applestore.png';
import playStoreIMG from '../img/stores/playstore.png';

class LandingCustomer extends Component {

  // Then we add our constructor which receives our props
  constructor(props) {

    super(props);
    if(this.props.givenConfig) {
      this.givenConfig = this.props.givenConfig;
      this.customer = this.givenConfig.identifier;
    } else {
      this.customer = this.props.match.params.customer;
    }
    
    this.application = {
      email: "",
      firstname: "",
      lastname: "",
      use_cv: false,
      accept_cgu: false,
      shop: null,
    };
        
    this.state = {
      error: false,
      loading: true,
      step: 1,
      config: false,
      offer_selected: null,
      selected_shop: null,
      display_offers: [],
      shops_list: [],
      postulate_active: false,
      sending_application: false,
      apply_email: "",
      apply_success: "",
      apply_error: "",
    };
    
    this.isMobile = false;
    
    this.apisEndpoint = AuthConfig.Common.API.endpoints.find((api) => { return api.name === 'apis' }).endpoint;
    
    this.sendEmailApplication.bind(this);
    this.updateValue.bind(this);
    this.changeSelectedShop.bind(this);
    this.nextStep.bind(this);
    this.selectOffer.bind(this);
  }

  sendEmailApplication = event => {
       
    this.setState({ 
      postulate_active: false,
      sending_application: true
    });

    const email = this.application.email.toLowerCase().trim();
    
    var sentData = {
      job_code: this.state.offer_selected,
      email: email,
      firstname: this.application.firstname,
      lastname: this.application.lastname,
      accept_cgu: this.application.accept_cgu,
      cgu_from: 'landing/' + this.customer,
      use_cv: this.application.use_cv
    }
    
    fetch(this.apisEndpoint + '/apis/submit-interest', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(sentData)
    }).then(response => {
      return response.json()
    }).then(response => {
      this.setState({
        sending_application: false,
        step: 3,
        apply_success: response.message
      });
    })
    .catch(error => {
      this.setState({
        sending_application: false,
        step: 3,
        apply_error: error.message
      });
    });
  }
  
  async updateValue(attribute, value) {
    this.application[attribute] = value;
    this.checkReady()
    
  }
  
  selectOffer(offer_job) {
    console.log(offer_job);
    this.setState({
      offer_selected: offer_job
    });
  }
  
  changeSelectedShop(new_shop) {
    
    var new_display_offers = [];
    var full_offers = this.state.config.offers;
    
    for(var i = 0; i < full_offers.length; i++) {
      if(full_offers[i].shopcode === new_shop) {
        new_display_offers.push(full_offers[i]);
      }
    }
    
    var selected_offer = null;
    if(new_display_offers[0]) {
      selected_offer = new_display_offers[0].job_code;
    }
    
    this.setState({
      offer_selected: selected_offer,
      selected_shop: new_shop,
      display_offers: new_display_offers,
    });
  }
  
  nextStep() {
    if(this.state.step === 1 && this.state.offer_selected) {
      this.setState({step: 2});
    }
  }
  
  checkReady() {
    const email = this.application.email.toLowerCase().trim();

    if(/^[a-zA-Z0-9.+\-_]+@(?:[a-zA-Z0-9\-_]+\.)+[A-Za-z]+$/.test(email) && this.application.accept_cgu && 
      this.application.firstname !== "" && 
      this.application.lastname !== ""
    ) {
      this.setState({ postulate_active: true });
    } else {
      this.setState({ postulate_active: false });
    }
  }

  componentDidMount() {
    // load infos about customer
    // Ce serai pas plus simple avec un petit fichier de correspondance local ?

    var endpoint = this.apisEndpoint + '/apis/landing-config/' + this.customer;
    
    // if(!local) {
      // endpoint = AuthConfig.CDN.images + 'original/alanding/' + this.customer + '/config.json';
    // }

    fetch(endpoint, {
      method: 'get',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json()
    }).then(response => {
      // console.log(response);
      if (response.success && response.config) {
        
        if(response.config.lang) {
          setLanguage(response.config.lang);
        }

        var shops_list = [];
        var shops = {};
        if(response.config.offers.length) {
          for(const offer of response.config.offers) {
            if(!shops[offer.shopcode]) {
              shops_list.push(offer.shopcode);
              shops[offer.shopcode] = true;
            }
          }
        }
        
        shops_list.sort();
        
        if(!response.config.selectlist_label) response.config.selectlist_label = response.config.select_label.toLowerCase();
        
        // response.config.device.iOS = true;
        
        this.setState({
          loading: false,
          config: response.config,
          shops_list: shops_list
        });
      } else {
        this.setState({
          sending_application: false,
          apply_error: response.message
        });
      }
    })
    .catch(error => {
        this.setState({
          sending_application: false,
          apply_error: error.message
        });
    });  
  }

  render() {
    return (
      <div className="w-full">          
        { this.state.error ?
          <Error text={this.state.error} />
          :
          this.state.loading ?
            <Loading />
            :
            <div className="w-full">
            { 
              this.state.config ? 

                  <div className="w-full top-0 bottom-0 bg-white">
                    <div className="h-1/2 w-full sm:w-3/4 md:w-3/5 m-auto mt-10 p-8 pt-4 relative rounded-lg" style={{ backgroundColor: this.state.config.bg_color, color: 'white' }}>
                      <Miniature className="ml-auto mr-auto" width={this.state.config.logo.width} height={this.state.config.logo.height} picture={this.state.config.logo.src} username="" />
                    </div>
                    <div className="w-full sm:w-3/4 md:w-3/5 m-auto mt-10 p-8 pt-4 relative bg-white rounded-lg shadow-lg">
                      { this.state.step === 1 ?
                        <div className="w-full">
                          <div className="w-full text-center">
                            <h3 className="text-xl md:text-3xl font-bold mb-5">{this.props.t('landing_make_interview')}</h3>
                            <h4 className="text-xl md:text-3xl">{this.props.t('landing_step')} {this.state.step}/2</h4>
                          </div>
                          
                          <h4 className="w-full text-center px-10 mt-10 text-gray-500 text-xl md:text-2xl">{this.state.config.select_description}</h4>
                          
                          <label className="w-full block text-xl mt-10 font-medium">{this.state.config.select_label}</label>
                          <select multiple={false} onChange={(e) => { this.changeSelectedShop(e.target.value); }} className="mt-5 resize-none px-5 py-3 placeholder-greyBorderJS
                          font-greyBorderJS font-regular font-sans border-solid rounded-md border
                          border-greyBorderJS w-full block">
                          <option value={null}>{this.state.config.selectlist_label}</option>
                              {this.state.shops_list.map((choice, i) => {
                                  return <option key={i + ' ' + choice} value={choice}>{choice}</option>
                              })}  
                          </select>
                                 
                          <label className="w-full block text-xl mt-10 font-medium">{this.props.t('manager_offer')}</label>
                          <select multiple={false} onChange={(e) => { this.selectOffer(e.target.value); }} className="mt-5 resize-none px-5 py-3 placeholder-greyBorderJS
                          font-greyBorderJS font-regular font-sans border-solid rounded-md border
                          border-greyBorderJS w-full block">
                              {this.state.display_offers.map((choice, i) => {
                                  return <option key={i + ' ' + choice.job_code} value={choice.job_code}>{choice.title}</option>
                              })}  
                          </select>

                          <div className="w-full mt-10"><JSButtonFull active={this.state.offer_selected} text={this.props.t('switch_next_step')} action={(e) => { this.nextStep(); }} /></div>
                        </div>
                      : this.state.step === 2 ?
                        <div className="w-full">
                          <div className="w-full text-center">
                            <h3 className="text-xl md:text-3xl font-bold mb-5">{this.props.t('landing_make_interview')}</h3>
                            <h4 className="text-xl md:text-3xl">{this.props.t('landing_step')} {this.state.step}/2</h4>
                          </div>
                        
                          <h4 className="w-full text-center px-10 mt-10 text-gray-500 text-xl md:text-2xl">{this.state.config.informations_description}</h4>
                          
                          <div className="w-full mt-10">
                            <label className="w-full block text-xl font-medium">{this.props.t('apply_email_label')}</label>
                            <input type="text" value={this.application.email} className="mt-5 px-5 py-3 text-xl placeholder-greyBorderJS
                              font-greyBorderJS font-regular font-sans border-solid rounded-md border
                            border-greyBorderJS w-full block" placeholder={this.props.t('placeholder_email')} onChange={(e) => { this.updateValue('email', e.target.value); }} />
                          </div>

                          <div className="w-full flex mt-10">
                            <div className="w-1/2 mr-5">
                              <label className="w-full block text-xl font-medium">{this.props.t('apply_firstname')}</label>
                              <input type="text" value={this.application.firstname} className="mt-5 px-5 py-3 text-xl placeholder-greyBorderJS
                                font-greyBorderJS font-regular font-sans border-solid rounded-md border
                                border-greyBorderJS w-11/12 block" placeholder={this.props.t('placeholder_firstname')} onChange={(e) => { this.updateValue('firstname', e.target.value); }} />
                            </div>
                            <div className="w-1/2">
                              <label className="w-full block text-xl font-medium">{this.props.t('apply_lastname')}</label>
                              <input type="text" value={this.application.lastname} className="mt-5 px-5 py-3 text-xl placeholder-greyBorderJS
                                font-greyBorderJS font-regular font-sans border-solid rounded-md border
                                border-greyBorderJS w-full block" placeholder={this.props.t('placeholder_lastname')} onChange={(e) => { this.updateValue('lastname', e.target.value); }} />
                            </div>
                          </div>
              
                          <div className="w-full mt-10 float-left">
                            <input id="accept_cgu" type="checkbox" className="px-5 border-solid rounded-md border border-greyBorderJS" 
                              checked={this.application.accept_cgu ? "checked" : ""} 
                              onChange={(e) => { this.updateValue('accept_cgu', e.target.checked); }} 
                            />
                            <label 
                              htmlFor="accept_cgu"
                            >
                              <span className='ml-5 text-lightGrey'>
                                {this.props.t('accept_cgu_prefix')}
                                <a href="/terms" target="_blank" className='ml-2 text-lightGrey no-underline hover:underline'>{this.props.t('cgu_label')}</a>
                              </span>
                            </label>
                          </div>
                          <div className="w-full mt-10"><JSButtonFull active={this.state.postulate_active} text={this.props.t('finish_process')} action={this.sendEmailApplication} /></div>
                        </div>
                      : this.state.step === 3 ?
                        <div className="w-full">
                          <div className="w-full text-center flex mt-10">
                              <span className="rounded-full bg-green-500 text-white mr-auto ml-auto text-3xl font-bold px-4 py-2">
                                <IoCheckmarkSharp />
                              </span>
                          </div>
                          <div className="w-full text-center mt-5">
                            <h3 className="text-xl w-full md:text-3xl font-bold">{this.props.t('ready_for_story')}</h3>
                          </div>
                          <div className="flex w-full mt-10">
                              <div><div className="rounded-full bg-black text-white font-bold text-xl px-4 py-2 mr-5 mt-2">1</div></div>
                              <div className="text-lg font-bold float-left">{this.props.t('download_app_and_create_account')}</div>
                          </div>
                          <div className="w-full mt-10">
                            <center>
                            { this.state.config.device && this.state.config.device.iOS ?
                              <a target="_blank" href={CFG.links.appStore} rel="noopener noreferrer"><img alt="Jobstory AppleStore" className="w-40" src={appleStoreIMG} /></a>
                            : this.state.config.device && this.state.config.device.Android ?
                              <a target="_blank" href={CFG.links.playStore} rel="noopener noreferrer"><img alt="Jobstory PlayStore" className="mt-2 md:mt-0 w-40" src={playStoreIMG} /></a>
                            :
                              <img src={this.apisEndpoint + '/apis/qrcode?redirect=true'} alt="qr-code redirect" className="border-2 rounded-lg" />
                            }
                            </center>
                          </div>
                          <div className="flex w-full mt-10">
                              <div><div className="rounded-full bg-black text-white font-bold text-xl  px-4 py-2 mr-5">2</div></div>
                              <div className="text-lg font-bold mt-2">{this.props.t('click_on_candidate')}</div>
                          </div>
                          <div className="flex w-full mt-10 mb-20">
                              <div><div className="rounded-full bg-black text-white font-bold text-xl  px-4 py-2 mr-5">3</div></div>
                              <div className="text-lg font-bold mt-2">{this.props.t('enter_offer_code')}</div>
                              <div><div className="text-lg bg-gray-200 font-bold ml-10 px-5 py-2">{this.state.offer_selected}</div></div>
                          </div>
                        </div>
                      : null
                      }
                    </div>
                  </div>
              : null
            }
            </div>
        }
      </div>
    );
  }
}

export default withTranslation()(LandingCustomer);