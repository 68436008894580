import React, { Component } from 'react';

import errorIMG from '../img/error.svg';

export default class Error extends Component {
    render() {
        return (
            <div className="text-center absolute transform -translate-x-1/2 -translate-y-1/2 absolute w-100" style={{ left: "50%", top: "50%" }}
            >
                <center><div className="rounded-md p-5" style={{ backgroundColor: "#f57e79" }} ><img alt="error" src={errorIMG} /></div></center>
                <p className="font-medium text-lg text-lightGrey">{this.props.text}</p>
            </div>
        )
    }
}
