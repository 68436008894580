import React from 'react';

import { withTranslation } from 'react-i18next'

import { API } from 'aws-amplify';

import ManagerLoading from './ManagerLoading';

import { IoAdd, IoPencil } from "react-icons/io5";

class ManagerTemplates extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      loading: true,
      data: null
    }
    
    // this.changeRouteCallback = this.props.changeRoute;
    
    this.dateFormat = this.dateFormat.bind(this);
    this.gotoTemplate = this.gotoTemplate.bind(this);
  }
  
  componentDidMount() {
    this.loadData();
  }
  
  async loadData() {
    this.dataLoaded(await API.get('apis', '/apis/user-templates?filter-type=offer'));
  }
  
  async gotoTemplate(template_id) {
    this.props.history.push('/manager/template/' + template_id);
  }
  
  dateFormat(datestring) {
    var d = new Date(datestring),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('/');
  }
  
  dataLoaded(data) {
    
    var list_templates = [];
    
    for(var i = 0; i < data.Items.length; i++) {
      var template = data.Items[i];
      
      list_templates.push({
        Pk: template.id,
        title: template.content.title,
        contract_type: template.content.contract_type,
        start_date: template.content.start_date,
      });
    }
    
    this.setState({
      loading: false,
      data: { 
        Templates: list_templates
      }
    });
  }
  
  render() {
    return (
      <div className="w-full">
        { this.state.loading ?
          <div className="w-full flex justify-center">
            <ManagerLoading />  
          </div>
        :
          <div className="w-full">
            <h3 className="mb-10 text-xl font-bold float-left">{this.props.t('manager_templates_menu')}</h3>
            <h3 onClick={() => { this.gotoTemplate('new')}} className="bg-purpleJS-100 cursor-pointer float-right p-5 pt-3 pb-3 rounded-lg text-white">
              <IoAdd className="float-left mr-3 text-2xl" />
              {this.props.t('manager_add_new_template')}
            </h3>
            
            <div className="w-full float-left p-5 bg-white rounded-lg">
              { this.state.data.Templates.length > 0 ?
              <table className="w-full table-auto mt-10">
                <thead>
                  <tr className="text-left">
                    <th className="p-5 rounded-l-lg bg-bgPurpleLightJS">{this.props.t('manager_template_title')}</th>
                    <th className="bg-bgPurpleLightJS">{this.props.t('manager_template_contract_type')}</th>
                    <th className="bg-bgPurpleLightJS">{this.props.t('manager_template_start_date')}</th>
                    <th className="p-5 rounded-r-lg bg-bgPurpleLightJS"></th>
                  </tr>
                </thead>
                <tbody>
                  { this.state.data.Templates.map((item) => (
                    <tr key={item.Pk}>
                      <td className="border-b border-slate-100 p-5">{item.title}</td>
                      <td className="border-b border-slate-100 p-5 pl-0">{item.contract_type}</td>
                      <td className="border-b border-slate-100 p-5 pl-0">{this.dateFormat(item.start_date)}</td>
                      <td className="border-b border-slate-100 p-5 pl-0">
                        <div onClick={()=>{this.gotoTemplate(item.Pk)}} className="p-1 pl-3 pr-3 float-left rounded-lg border border-purpleJS-100 text-purpleJS-100 cursor-pointer">
                          <IoPencil className="float-left mr-1 mt-1" />
                          {this.props.t('manager_update_template')}
                        </div>
                      </td>
                    </tr>
                    ))
                  }
                </tbody>
              </table>
              :
              <div>
                <p className="w-full float-left">{this.props.t('you_dont_have_template_currently')}</p>
                <h3 onClick={() => { this.gotoTemplate('new')}} className="bg-purpleJS-100 cursor-pointer float-left mt-10 ml-20 mb-10 p-5 pt-3 pb-3 rounded-lg text-white">
                  <IoAdd className="float-left mr-3 text-2xl" />
                  {this.props.t('create_your_first_template')}
                </h3>
              </div>
              }
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(ManagerTemplates);