import React from 'react';

import { withTranslation } from 'react-i18next';

import AuthConfig from '../AuthConfig';

import {
  withRouter
} from "react-router-dom";

import { Hub, Auth } from 'aws-amplify';
      
class LoginApp extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        user: null,
        username: "",
        login: "",
        password: "",
        loading: false,
        require_password: false,
      };
      
      this.apisEndpoint = AuthConfig.Common.API.endpoints.find((api) => { return api.name === 'apis' }).endpoint;
            
      Hub.listen('auth', (data) => {
        this.onAuthEvent(data);
      });
      
      
      this.signOut = this.signOut.bind(this);
      this.reloadUser = this.reloadUser.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.checkUserSAML = this.checkUserSAML.bind(this);
      this.signIn = this.signIn.bind(this);
    }

    handleChange(event) {
      var newState = {};
      newState[event.target.name] = event.target.value;
      this.setState(newState);
    }
    
    async checkUserSAML() {
      // console.log(this.state.username);
      
      try {        
        const response = await fetch(this.apisEndpoint + '/apis/user-connection-identifier', {
          method: 'POST',
          body: JSON.stringify({ "email": this.state.username })
        });
        
        const result = await response.json();
        console.log(result);
      
        if(result.success && result.email_domain && result.identifier) {
          Auth.federatedSignIn({ provider: result.identifier })
        } else if (result.success) {
          // should display password input
          console.log('should display password input');
          this.setState({
            require_password: true
          });
        }
      
        
      } catch(e) {
        console.log(e);
      }
      
    }
    
    async signIn() {
      // console.log(this.state);

      const lowerCaseUsername = this.state.username.toLowerCase();

      var user = await Auth.signIn(lowerCaseUsername, this.state.password);
      console.log(user);
    }
    
    componentDidMount() {
      this.onAuthEvent();
      // La faudrait check la page actuelle pour init le state.page
    }
    
    signOut() {
      Auth.signOut();
    }
        
    async reloadUser() {

      try {
        var user = await Auth.currentAuthenticatedUser();
        
        console.log(user);
        this.setState({user: user, loading: false});
      
        if(user.attributes && user.attributes.profile === 'recruiter') {
          console.log('should redirect to manager');
          var destination_page = `/manager/home`;
          this.props.history.push(destination_page);
        }

      } catch(e) {
        console.log("error");
        console.log(e);
        this.setState({
          user: null, 
          loading: false,
          require_password: false,
          password: ""
        });
      }
    }
    
    async onAuthEvent(data) {
      
      if(data && data.payload && data.payload.event === "signOut") {
        document.location.reload();
      } else {
        this.reloadUser();
      }

    }
    
    render() {
      return (
        <div className="w-full float-left shadow-md p-5 bg-white rounded-lg">
          <div className="w-full">                          
            <div className="w-full mb-10 mt-3">
              <h3 className="w-full text-xl font-medium">Login</h3>
              { this.state.require_password === false ?
                <div className="w-full">
                  <div className="w-full">
                      <label className="mt-5 w-full block text-lg font-medium">{this.props.t("apply_email_label")}</label>
                      <input value={this.state.username} type="text" name="username"
                      className="mt-5 resize-none px-5 py-2 placeholder-greyBorderJS font-greyBorderJS font-regular font-sans border-solid rounded-md border border-greyBorderJS w-full block" 
                      placeholder={this.props.t("placeholder_email")} onChange={this.handleChange} />
                  </div>
                  <div className="w-full float-left">
                    <div onClick={() => { this.checkUserSAML()}} className="mt-5 bg-purpleJS-100 cursor-pointer float-right p-5 pt-3 pb-3 rounded-lg text-white">
                      {this.props.t('continue_login')}
                    </div>
                  </div>  
                </div>
              : 
                <div className="w-full">
                  <div className="w-full">
                      <label className="mt-5 w-full block text-lg font-medium">{this.props.t("password_field")}</label>
                      <input value={this.state.password} type="password" name="password"
                      className="mt-5 resize-none px-5 py-2 placeholder-greyBorderJS font-greyBorderJS font-regular font-sans border-solid rounded-md border border-greyBorderJS w-full block" 
                      placeholder={this.props.t("password")} onChange={this.handleChange} />
                  </div>
                  <div onClick={() => { this.signIn()}} className="mt-5 bg-purpleJS-100 cursor-pointer float-right p-5 pt-3 pb-3 rounded-lg text-white">
                    {this.props.t('login_jobstory')}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      )
    }
}

export default withTranslation()(withRouter(LoginApp));