import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Hub, Auth, API } from 'aws-amplify';

import AuthConfig from './AuthConfig';
import Loading from './Loading';
import Error from './Error';

import { Helmet } from 'react-helmet';

import Header from './Header.js'
import Footer from './Footer.js'
//main css generated with tailwindcss
import './../css/main.css';

//assets
import loadingIMG from '../img/loading.svg';

let META_TITLE = 'JobStory';

// json_offer can be globally defined in the index.html file (useful for SEO)
// eslint-disable-next-line no-undef, no-use-before-define
if (typeof json_offer === "undefined") var json_offer = null;

class ChargeBeeCustomer extends Component {

  // Then we add our constructor which receives our props
  constructor(props) {

    super(props);
    if(this.props.givenConfig) {
      this.givenConfig = this.props.givenConfig;
      this.plan_id = this.givenConfig.identifier;
    } else {
      this.plan_id = this.props.match.params.plan_id;
    }
    
    if(this.props.match.params.subscription_id) {
      this.subscription_id = this.props.match.params.subscription_id;
    } else this.subscription_id = null;
    
    this.edit_sub_id = null;
    
    this.state = {
      error: false,
      loading: true,
      api_loading: false,
      user: null,
      subscription_config: null,
      step: "billing_address_step",
      error_fields: [],
      sub_count: 1,
      sub_users: [this.getEmptySubUser()],
      billing: {
        company_name: "",
        email: "",
        firstname: "",
        lastname: "",
      }
    };
        
    this.apisEndpoint = AuthConfig.Common.API.endpoints.find((api) => { return api.name === 'apis' }).endpoint;

    this.cbInstance = window.Chargebee.init({
        site: "jobstory-test"
    })

    var that = this;
    this.checkout_hpid = null;

    this.cbInstance.setCheckoutCallbacks(function(cart) {
        return {
            success: async function(hpid) {
                console.log('success', hpid);
                that.checkout_hpid = hpid;
                
                var sentData = {
                  action: "hpid_register",
                  sub_id: that.edit_sub_id,
                  hpid: that.checkout_hpid
                };
                
                await API.post('apis', '/apis/subscription', { body: sentData});
                
                that.setState({step: "checkout_success"});
            }
        }
    })

    this.cart = this.cbInstance.getCart();
    
    this.addRecruiterSlot.bind(this);
    this.updateUser.bind(this);
    this.updateBilling.bind(this);
    this.processCheckout.bind(this);
    this.getEmptySubUser.bind(this);
    this.onAuthEvent.bind(this);
    this.updateQuantity.bind(this);

    Hub.listen('auth', (data) => {
      this.onAuthEvent(data);
    });
  }
 
  
  async onAuthEvent(data) {
    
    var newState = {
      user: null,
      step: "billing_address_step",
    }
    
    if(data && data.payload && data.payload.event === "signIn") {

      newState.user = data.payload.data;
      
    } else if(data && data.payload && data.payload.event === "signOut") {
      newState.user = null;
    } else {
      // Load auth
      newState.user = await Auth.currentUserInfo();
    }
    
    if(newState.user !== null) newState.step = "recruiters_subs";

    console.log(newState.user);

    this.setState(newState);
  }
    
  async processCheckout() {
    
  }
  
  async openBillingCart() {

    const product = this.cbInstance.initializeProduct(this.plan_id);
    
    this.cart.replaceProduct(product);
    
    // Dynamically changing Plan quantity using setPlanQuantity
    product.setPlanQuantity(this.state.sub_count);

    // product.setCustomData({
        // cf_data1: "user1@email.com", 
        // cf_data2: "user2@email.com"
    // });
    
    // var company_name = this.state.billing.company_name;
    
    // var user_email = this.state.user.attributes.email;
    // let splited_name = this.state.user.attributes.name.split(" ");
    
    // var user_firstname = splited_name[0];
    // var user_lastname = splited_name[1] ? splited_name[1] : "";

    // var company_country = "FR";

    /*
    const customer = {
        email: user_email, 
        first_name: user_firstname, 
        last_name: user_lastname, 
        company: company_name, 
        billing_address: {
            first_name: user_firstname, 
            last_name: user_lastname, 
            email: user_email, 
            company: company_name, 
            // line1: company_line1, 
            // city: company_city,
            country: company_country,
            // zip: company_zip
        }
    };

    // Setting custom fields
    customer["locale"] = "fr";
    */
    
    this.cart.setCustomer({ "locale": "fr" });
    
    
    // Passing values for custom fields
    // product.setCustomData({associated_users: ["user_1", "user_2", "user_3"]});

    // Opening the checkout
    this.cart.proceedToCheckout();
    
    // this.setState({step: "checkouting"});
  }
  
  async addRecruiterSlot(attribute, value) {
    
    var users = this.state.sub_users;
    users.push(this.getEmptySubUser());
    
    this.setState({sub_users: users});    
  }
  
  async updateUser(index, attribute, value) {
    
    var new_state = {
      sub_users: this.state.sub_users
    };
    
    new_state.sub_users[index][attribute] = value;
    
    this.setState(new_state);    
  }
  
  async updateQuantity(value) {
    
    let sub_qty = parseInt(value);
    
    if(isNaN(sub_qty)) sub_qty = this.state.sub_count;
    
    this.setState({
      sub_count: sub_qty
    });   
  }
  
  async updateBilling(attribute, value) {
    
    var new_state = {
      billing: this.state.billing
    };
    
    new_state.billing[attribute] = value;
    
    this.setState(new_state);    
  }

  getEmptySubUser() {
    return { email: "", name: ""};
  }
  
  
  async processBillingInformations() {
    
    var sentData = this.state.billing;
    
    
    var error_fields = [];
    
    if(sentData.company_name.trim() === "") error_fields.push("company_name");
    if(sentData.email.trim() === "") error_fields.push("email");
    if(sentData.firstname.trim() === "") error_fields.push("firstname");
    if(sentData.lastname.trim() === "") error_fields.push("lastname");

    if(error_fields.length<=0) {
      
      sentData.action = "billing_informations";
      sentData.sub_plan_id = this.plan_id;
      
      this.setState({api_loading: true, error_fields});


      // Sign-up user

      fetch(this.apisEndpoint + '/apis/subscription', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(sentData)
      }).then(response => {
        return response.json()
      }).then(response => {
        if (response) {
          this.setState({
            api_loading: false,
            subscription_config: response,
            step: "recruiters_subs"
          });
          
          var destination_page = `${this.props.location.pathname}/${response.sub_id}`;
          this.props.history.push(destination_page);
          
        } else {
          this.setState({
            api_loading: false
          });
        }
      })
      .catch(error => {
          this.setState({
            api_loading: false
          });
      });
      
    } else {
      this.setState({error_fields});
    }
  }
  
  componentDidMount() {
    this.setState({loading: true});
    
    this.onAuthEvent();
    
    
    // if(this.subscription_id !== null) {
      // fetch(this.apisEndpoint + '/apis/subscription/' + this.subscription_id, {
        // method: 'get',
        // headers: { 'Content-Type': 'application/json' }
      // }).then(response => {
        // return response.json()
      // }).then(response => {
        // if (response) {
          // this.setState({
            // loading: false,
            // subscription_config: response,
            // step: "recruiters_subs"
          // });
        // } else {
          // this.setState({
            // loading: false
          // });
        // }
      // })
      // .catch(error => {
          // this.setState({
            // loading: false
          // });
      // });
    // }
    
    this.setState({loading: false});
  }

  render() {
    return (
      <div className="w-full">
        <Helmet>
          <title>{META_TITLE}</title>
        </Helmet>
        { this.state.error ?
          <Error text={this.state.error} />
          :
          this.state.loading ?
            <Loading />
            :
            <div className="w-full">
            {
              <div className="container h-full w-full max-w-none max-h-screen">
                <Header location={this.props.location} />
                <article className="w-full mt-16">
                  <div className="w-full p-5 pt-20 md:p-20">
                    <h1 className="font-bold text-2xl mb-5">Configuration de votre abonnement Jobstory</h1>
                    
                    { this.state.step === "billing_address_step" ?

                      <div className="w-full">
                        <p>Bonjour, merci de saisir les informations sur l'entreprise à facturer</p>
                        <h3 className="font-bold text-xl mt-5">Informations de Facturation</h3>
                        <div className="w-full">
                          <div className="w-1/4 sm:w-3/4 md:w-3/5 mt-5 rounded-md border border-greyBorderJS px-5 py-3 mr-5 mb-5">
                            <div className="w-full flex">
                              <div className="w-1/3">
                                <label className="w-full block font-medium">Prénom</label>
                                <input value={this.state.billing.firstname} type="string" className={ "mt-3 resize-none px-5 py-3 placeholder-greyBorderJS font-greyBorderJS font-regular font-sans border-solid rounded-md border border-greyBorderJS w-full block" + (this.state.error_fields.includes("firstname") ? " border-red-500" : "") } placeholder="" onChange={(e) => { this.updateBilling("firstname", e.target.value)}} />
                              </div>
                              <div className="w-1/3 ml-5">
                                <label className="w-full block font-medium">Nom</label>
                                <input value={this.state.billing.lastname} type="string" className={ "mt-3 resize-none px-5 py-3 placeholder-greyBorderJS font-greyBorderJS font-regular font-sans border-solid rounded-md border border-greyBorderJS w-full block" + (this.state.error_fields.includes("lastname") ? " border-red-500" : "") } placeholder="" onChange={(e) => { this.updateBilling("lastname", e.target.value)}} />
                              </div>
                            </div>
                            <div className="w-full">
                              <label className="mt-5 w-full block font-medium">Adresse email</label>
                              <input value={this.state.billing.email} type="string" className={ "mt-3 resize-none px-5 py-3 placeholder-greyBorderJS font-greyBorderJS font-regular font-sans border-solid rounded-md border border-greyBorderJS w-full block" + (this.state.error_fields.includes("email") ? " border-red-500" : "") } placeholder="email@exemple.com" onChange={(e) => { this.updateBilling("email", e.target.value)}} />
                            </div>
                            <div className="w-full">
                              <label className="mt-5 w-full block font-medium">Nom entreprise</label>
                              <input value={this.state.billing.company_name} type="string" className={ "mt-3 resize-none px-5 py-3 placeholder-greyBorderJS font-greyBorderJS font-regular font-sans border-solid rounded-md border border-greyBorderJS w-full block" + (this.state.error_fields.includes("company_name") ? " border-red-500" : "") } placeholder="Mon entreprise SAS" onChange={(e) => { this.updateBilling("company_name", e.target.value)}} />
                            </div>
                          </div>
                        </div>
                        <div className="mt-10 p-3 pl-3 pr-3 float-left rounded-lg border border-purpleJS-100 text-purpleJS-100 cursor-pointer" onClick={() => {this.processBillingInformations();}}>
                          { this.state.api_loading ? 
                            <img alt="loading" style={{ left: "50%", top: "50%"}} src={loadingIMG} className="w-1/4" /> 
                          : 
                            "Recruteurs associés" 
                          }
                        </div>
                      </div>
                      
                    : this.state.step === "recruiters_subs" ?

                      <div className="w-full">
                        <p>Afin de souscrire à votre abonnement Jobstory vous pouvez selectionner le nombre de magasin associé à votre entité de facturation en cliquant sur le bouton ci-dessous.</p>
                        <h3 className="font-bold text-xl mt-5">Nombre de points de vente</h3>
                        <div className="w-full mt-5">
                          <input value={this.state.sub_count} type="string" className="mt-3 resize-none px-5 py-3 placeholder-greyBorderJS font-greyBorderJS font-regular font-sans border-solid rounded-md border border-greyBorderJS w-full block" placeholder="1" onChange={(e) => { this.updateQuantity(e.target.value)}} />
                        </div>
                        <div onClick={() => {this.processCheckout();}} className="mt-10 p-3 pl-3 pr-3 float-left rounded-lg border border-purpleJS-100 text-purpleJS-100 cursor-pointer">
                          Prendre mon abonnement
                        </div>
                      </div>
                      
                    : this.state.step === "checkout_success" ?

                      <div className="w-full">
                        <p>Merci de faire confiance à Jobstory, nous allons procéder à la création de vos utilisateurs ainsi que de leurs annonces.</p>
                        <p className="mt-5">Si vous rencontrez un souci ou ne recevez pas les emails de confirmation d'ici quelques minutes, merci de nous contacter avec l'identifiant de souscription: {this.checkout_hpid}</p>
                        
                        <a href={"/manager/subscription/" + this.edit_sub_id } className="mt-10 p-3 pl-3 pr-3 float-left rounded-lg border border-purpleJS-100 text-purpleJS-100 cursor-pointer">
                          Configurer mon abonnement
                        </a>
                      </div>
                      
                    : 
                      null 
                    }
                    
                    
                  </div>
                </article>
                <Footer />
              </div>
            }
            </div>
        }
      </div>
    );
  }
}

export default withTranslation()(ChargeBeeCustomer);