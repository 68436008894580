import React from 'react';
import { withTranslation } from 'react-i18next';

import AWS from 'aws-sdk';
import AuthConfig from './AuthConfig';
import { Auth, API } from 'aws-amplify';
import Miniature from './Miniature';

import { AiFillStar } from 'react-icons/ai';


class AdminUserCard extends React.Component {
    constructor(props) {
      super(props);
      
      if(this.props.user.profile === "recruiter") {
        
        var candidates_count = this.props.user.candidates_count ? this.props.user.candidates_count : 0;
        var recos_count = this.props.user.recos_count ? this.props.user.recos_count : 0;
        var view_candidates = this.props.user.view_candidates ? this.props.user.view_candidates : 0;
        var new_candidates = this.props.user.new_candidates ? this.props.user.new_candidates : 0;
        var to_recruit = this.props.user.to_recruit ? this.props.user.to_recruit : 0;
        var recruited = this.props.user.recruited ? this.props.user.recruited : 0;
        var shortlist_count = this.props.user.shortlist_count ? this.props.user.shortlist_count : 0;
        
        this.props.user.shortlist_count = shortlist_count;
        this.props.user.no_reco_candidates_count = candidates_count - recos_count;
        this.props.user.todo_state_count = view_candidates + new_candidates;
        this.props.user.recruit_state_count = to_recruit + recruited;
        this.props.user.rejected = this.props.user.no_reco_candidates_count - this.props.user.todo_state_count - this.props.user.recruit_state_count - this.props.user.shortlist_count;
        
        
      }
          
          // {name: "actives_offers_count", value: user_counters.actives_offers_count},
          // {name: "candidates_count", value: user_counters.candidates_count},
          // {name: "to_recruit", value: user_counters.to_recruit},
          // {name: "recruited", value: user_counters.recruited},
          // {name: "recommanded_count", value: user_counters.recommanded_count},
          // {name: "recos_count", value: user_counters.recos_count},
          // {name: "new_candidates", value: user_counters.new_candidates},
          // {name: "view_candidates", value: user_counters.view_candidates},
          // {name: "shortlist_count", value: user_counters.shortlist_count},
      
      
      this.state = {
        loading: false,
        user: this.props.user,
        show_call_details: false,
        resend_notif: 0
      }
        
      this.loaded_offers = this.props.loaded_offers;
            
      this.selectedActions = [];
      
      this.availableCallActions = [
        {
          label: "SMS",
          code: "sms"
        }, {
          label: "Email",
          code: "email"
        }, {
          label: "Message vocal",
          code: "voice_msg"
        }, {
          label: "Ne répond pas",
          code: "no_response"
        }, 
        {
          label: "Dit qu'il va postuler",
          code: "will_postulate"
        }
      ];
      
      this.getAttributeValue = this.getAttributeValue.bind(this);
      this.toggleAction = this.toggleAction.bind(this);
      this.saveAction = this.saveAction.bind(this);
      this.getWellFormatedActionsList = this.getWellFormatedActionsList.bind(this);
      this.getSpecificActionColor = this.getSpecificActionColor.bind(this);
      this.getOfferDetailsLine = this.getOfferDetailsLine.bind(this);
      this.loadWishlist = this.loadWishlist.bind(this);
      this.archiveCandidate = this.archiveCandidate.bind(this);
      this.getUserStateColor = this.getUserStateColor.bind(this);
    
    }

    async saveAction() {
        var actions = this.selectedActions;
        
        if(actions.length > 0) {
          this.selectedActions = [];
            
          if(this.dynamo == null) {
            this.dynamo = new AWS.DynamoDB.DocumentClient({
              "region": AuthConfig.Common.Auth.region,
              "credentials": await Auth.currentCredentials()
            });
          }
          
          var contact_history = this.state.user.contact_history;
          
          contact_history.push({
            date: new Date().getTime()/1000,
            actions: actions
          });
          
          var table = AuthConfig.DDB.USERS_TABLE;
          var itemKey = {
            Pk: this.state.user.Username
          };
          
          if(this.state.user.profile === "landing") {
            table = AuthConfig.DDB.INTEREST_TABLE;
            itemKey.Sk = this.state.user.Sk;
          }
          
          await this.dynamo.update({
            TableName: table,
            Key: itemKey,
            UpdateExpression: 'set #att = :s',
            ExpressionAttributeNames: {'#att' : 'contact_history'},
            ExpressionAttributeValues: {
              ':s' : contact_history
            }
          }).promise();
          
          var user = this.state.user;
          
          user.contact_history = contact_history
          
          this.setState({
            user: user,
            show_call_details: false
          });
          
          
        } else {
          alert("Aucune action selectionné");
        }
    }
    
    getOfferDetailsLine(job_code) {
      var offer_string = job_code;
      
      var found = this.loaded_offers.find(el => el.Sk === job_code);
      
      if(found) {
        offer_string = `${found.company_name} - ${job_code} - ${found.title} - ${found.zip_code}` 
      }
      
      return offer_string;
    }
    
    
    getSpecificActionColor() {
      var color = "bg-green-500";
      
      if(!this.state.user.contactable) {
        color = "bg-gray-300";
      } else if(this.state.user.contact_history.length) {
        var last_action = this.state.user.contact_history[this.state.user.contact_history.length-1];
        if(last_action.actions.includes('will_postulate')) {
          color = "bg-yellow-400";
        }
      }
      
      return "flex mt-5 rounded-md p-2 " + color;
    }
    
    
    getSpecificWishlistColor(state) {
      var color = "bg-white";
      
      switch(state) {
        case 'done':
          color = "bg-green-300";
          break;
        case 'applying':
          color = "bg-red-300";
          break;
        default:
          color = "bg-white";
          break;
      }
            
      return "text-xs mt-1 p-1 " + color;
    }
    
    getWellFormatedActionsList(actions) {
      var outputString = " ";
      
      for(let i = 0; i < actions.length; i++) {
        var actionName = this.availableCallActions.find(el => el.code === actions[i]);
        outputString += actionName ? actionName.label : actions[i];
        
        if(actions[i+1]) outputString += ', ';
      }
      
      return outputString;
    }
    
    toggleAction(event) {
      var itemIndex = this.selectedActions.indexOf(event.target.name);
 
      if(event.target.checked) {
        // add to array
        if(itemIndex < 0) {
          this.selectedActions.push(event.target.name);
        }
      } else {
        if(itemIndex >= 0) {
          this.selectedActions.splice(itemIndex, 1);
        }
        // remove from array
      }
    }
    
    async loadWishlist() {
      
      if(this.dynamo == null) {
        this.dynamo = new AWS.DynamoDB.DocumentClient({
          "region": AuthConfig.Common.Auth.region,
          "credentials": await Auth.currentCredentials()
        });
      }
      
      var user_wishlist = await this.dynamo.query({
      TableName: AuthConfig.DDB.WISH_TABLE,
        KeyConditionExpression: 'Pk = :pkey',
        ExpressionAttributeValues: {
          ':pkey': this.state.user.Pk
        }
      }).promise();
            
      if(user_wishlist.Count) {
        var state_user = this.state.user;
        state_user.wishlist = user_wishlist.Items;

        this.setState({user: state_user});
        
      }
    }
    
    getAttributeValue(attribute, defaultVal) {
      // console.log(this.state.user);
      if(this.state.user[attribute]) {
        defaultVal = this.state.user[attribute];
        
        if(attribute === "cgu_from") {
          switch (this.state.user[attribute]) {
            case 'TS':
              defaultVal = "TalentSoft";
              if(this.state.user.custom && this.state.user.custom.customer_name) {
                defaultVal += " " + this.state.user.custom.customer_name;
              }
              break;
            case 'CS':
              defaultVal = "Cornerstone";
              if(this.state.user.custom && this.state.user.custom.customer_name) {
                defaultVal += " " + this.state.user.custom.customer_name;
              }
              break;
            case 'talent':
              defaultVal = "Talent.io";
              break;
            default:
              defaultVal = "Jobstory landing";
              break;
          }
        }
        
      }    
      return defaultVal;
      
      // var userAtt = this.state.user.Attributes.find(att => { return (att.Name === attribute) });
      // if(userAtt) return userAtt.Value;
      // else return defaultVal;
    }
        
    async resendNotifToCandidate() {
      
      this.setState({resend_notif: 1});
      
      var notifData = {
        Pk: this.state.user.email,
        Sk: this.state.user.Sk,
        phone_number: this.state.user.phone_number,
        firstname: this.state.user.firstname,
        lastname: this.state.user.lastname,
      }
        
      await API.post('apis', '/apis/resend-interest-notif', {
          body: notifData
      });
               
      this.setState({resend_notif: 2});
    }
    
    getUserStateColor() {
      var color = "brown";

      if(this.state.user.userpool && this.state.user.userpool === AuthConfig.Common.Auth.userPoolId) {
        if(this.state.user.is_migration) {
          color = "blue"
        } else {
          color = "green"
        }
        
        color = "green"
      }
      
      return color;
    }
      
    async archiveCandidate() {
      if(window.confirm("Etes-vous sur de classer le traitement de ce candidat ?")) { 
        if(this.dynamo == null) {
          this.dynamo = new AWS.DynamoDB.DocumentClient({
            "region": AuthConfig.Common.Auth.region,
            "credentials": await Auth.currentCredentials()
          });
        }
        
        await this.dynamo.update({
          TableName: AuthConfig.DDB.USERS_TABLE,
          Key: {
            Pk: this.state.user.Pk
          },
          UpdateExpression: 'set #att = :s',
          ExpressionAttributeNames: {'#att' : 'close_history'},
          ExpressionAttributeValues: {
            ':s' : true
          }
        }).promise();
        
        var user = this.state.user;
        
        user.contactable = false;
        user.close_history = true;
        
        this.setState({user: user});
      }
    }

    
    render() {
        return (
          <div key={this.state.user.Pk} 
            className={this.getSpecificActionColor()}
          >
            <div className="mr-10">
              {
                this.getAttributeValue("cgu_from", null) !== null ?
                  <span className="w-full">{this.getAttributeValue("cgu_from", "landing")}</span>
                : <Miniature className="h-16 w-16 rounded-md mx-0" username={this.getAttributeValue("name", "Unknown user")} 
              width="100" height="100" picture={this.getAttributeValue("picture", "avatar/icon_recruiter.png")} />
              }
            </div>
            <div className="text-left w-1/2">
              { this.getAttributeValue("profile", "recruiter") === "landing" ?
              <div className="hidden sm:block text-lg font-semibold">{this.getAttributeValue("name", "Unknown user")}</div>
              :
              <a target="_blank" rel="noopener noreferrer" className="hidden sm:block hover:underline text-lg font-semibold" href={"/admin/user/" + this.state.user.Username}>{this.getAttributeValue("name", "Unknown user")}</a>
              }
                <div className="text-xs mt-0 flex">
                  { this.getAttributeValue("profile", "recruiter") !== "landing" ?
                    <AiFillStar className="mt-1 mr-2" color={this.getUserStateColor()} />
                  : null }
                  
                  <div>{this.getAttributeValue("email", "undefined@email.com")}</div>
                  
                  { this.state.user.is_migration ?
                  <div className="ml-1 italic">- migrated</div>
                  : null }
                  
                  
                </div>
                
                { !["recruiter", "manager"].includes(this.getAttributeValue("profile", "recruiter")) ?
                  <div className="text-xl mt-0">{this.getAttributeValue("phone", "+330000000000")}</div>
                : null }
                
                <div className="text-xs mt-0 font-semibold flex">
                  <div>Créé: {this.state.user.date_creation.toISOString().replace('T', ' ').replace('Z', ' ').split('.')[0]}</div>
                  {this.state.user.last_co ?
                    <div className="ml-3">Dernière connection: {this.state.user.last_co.replace('T', ' ').replace('Z', ' ').split('.')[0]}</div>
                  :
                    null
                  }
                </div>
                
                { this.getAttributeValue("profile", "recruiter") === "recruiter" ?
                  <div className="flex">
                    <div className="text-xs mt-0 font-semibold">{this.state.user.actives_offers_count} Annonce{this.state.user.actives_offers_count>1?"s":""}</div>
                    <div className="text-xs mt-0 font-semibold ml-5">{this.state.user.no_reco_candidates_count} Candidature{this.state.user.no_reco_candidates_count>1?"s":""}</div>
                    <div className="text-xs mt-0 font-semibold ml-5">{this.state.user.todo_state_count} A traiter</div>
                    <div className="text-xs mt-0 font-semibold ml-5">{this.state.user.shortlist_count} Shortlist</div>
                    <div className="text-xs mt-0 font-semibold ml-5">{this.state.user.to_recruit} A Recruter</div>
                    <div className="text-xs mt-0 font-semibold ml-5">{this.state.user.recruited} Recruté{this.state.user.recruited>1?"s":""}</div>
                    <div className="text-xs mt-0 font-semibold ml-5">{this.state.user.rejected} Rejeté{this.state.user.rejected>1?"s":""}</div>
                    <div className="text-xs mt-0 font-semibold ml-5">{this.state.user.recos_count ? this.state.user.recos_count : "0"} Recommandation{this.state.user.recos_count>1?"s":""}</div>
                 </div>
                :  this.getAttributeValue("profile", "recruiter") === "manager" ?
                <div className="flex">
                  <div className="text-xs mt-0 font-semibold">{this.state.user.total_boost} Booster{this.state.user.total_boost>1?"s":""}</div>
                </div>
                : this.getAttributeValue("profile", "recruiter") === "candidate" ?
                <div>
                  <div className="flex">
                    <div onClick={(e) => { this.loadWishlist() }} className="text-xs mt-0 font-semibold flex-auto cursor-pointer">{this.state.user.wishlist_count} Wishlist{this.state.user.wishlist_count>1?"s":""}</div>
                    <div className="text-xs mt-0 font-semibold flex-auto">{this.state.user.applications_count} Candidature{this.state.user.applications_count>1?"s":""}</div>
                  </div>
                  { this.state.user.wishlist ?
                    <div>
                    {
                      this.state.user.wishlist.map(item => (
                        <p className={this.getSpecificWishlistColor(item.state)} key={item.Pk + '-' + item.Sk + '-' + item.Data}>{item.state + ' - ' + this.getOfferDetailsLine(item.Sk)}</p>
                      )) 
                    }
                    </div>
                  : null}
                </div>
                :
                <div>
                  <div className="text-xs mt-0 font-semibold">Annonce: <a href={"/candidate/offer/" + this.state.user.Sk} target="_blank" rel="noopener noreferrer">{this.getOfferDetailsLine(this.state.user.Sk)}</a></div>
                </div>
                }
            </div>
            
            
            { this.getAttributeValue("profile", "recruiter") === "landing" ?
              <div className="w-1/3 flex">
                { this.state.user.notif === 3 ?
                  <div>
                    <div>Notif mail: {new Date(this.state.user.notif_0*1000).toISOString().replace('T', ' ').replace('Z', ' ').split('.')[0]}</div>
                      { this.state.resend_notif === 0 ?
                        <div className="text-left rounded-md p-3 bg-gray-300 hover:bg-gray-400 mt-5 cursor-pointer" onClick={(e) => {this.resendNotifToCandidate()}}>Renvoyer la notif à ce candidat</div>
                      : this.state.resend_notif === 1 ?
                        <div className="text-left rounded-md p-3 bg-blue-300 mt-5">En cours...</div>
                      : this.state.resend_notif === 2 ?
                        <div className="text-left rounded-md p-3 bg-green-300 mt-5">Mail envoyé à {this.state.user.email}</div>
                      : null }
                  </div>
                : this.state.user.notif === 2 ?
                  <div>
                    <div>Notif mail: {new Date(this.state.user.notif_0*1000).toISOString().replace('T', ' ').replace('Z', ' ').split('.')[0]}</div>
                    <div>Notif SMS: {new Date(this.state.user.notif_1*1000).toISOString().replace('T', ' ').replace('Z', ' ').split('.')[0]}</div>
                      { this.state.resend_notif === 0 ?
                        <div className="text-left rounded-md p-3 bg-gray-300 hover:bg-gray-400 mt-5 cursor-pointer" onClick={(e) => {this.resendNotifToCandidate()}}>Renvoyer la notif mail</div>
                      : this.state.resend_notif === 1 ?
                        <div className="text-left rounded-md p-3 bg-blue-300 mt-5">En cours...</div>
                      : this.state.resend_notif === 2 ?
                        <div className="text-left rounded-md p-3 bg-green-300 mt-5">Mail envoyé à {this.state.user.email}</div>
                      : null }
                  </div>
                :                 
                  <div>N'a pas encore été notifié.</div>
                }
              </div>
            : null }
            
            <div className="flex w-full ml-10">
              { this.state.user.contact_history && this.state.user.contact_history.length ?
                  <div className="text-left w-1/3 rounded-md ml-5 p-3">
                    { this.state.user.contact_history.map(call_actions => (
                      <div key={call_actions.date}>
                        <span className="font-bold">{new Date(call_actions.date*1000).toISOString().replace('T', ' ').replace('Z', ' ').split('.')[0]}</span> -
                        <span>{this.getWellFormatedActionsList(call_actions.actions)}</span>
                      </div>
                    ))}
                  </div>
              : null }
              { this.state.user.contactable ? 
                <div className="flex w-2/3">
                  { this.state.show_call_details ? 
                      <div className="text-left rounded-md ml-5 p-3 bg-gray-300 flex-auto">
                        { 
                        this.availableCallActions.map(call_action => (
                          <div key={call_action.code}>
                            <input type="checkbox" id={call_action.code} onChange={(e) => { this.toggleAction(e); }} name={call_action.code} />
                            <label htmlFor={call_action.code}> {call_action.label}</label>
                          </div>
                        )) 
                        }
                        <div onClick={(e) => { this.saveAction() }} className="text-center font-bold white cursor-pointer rounded-md p-3 bg-gray-600">Ajouter</div>
                      </div>

                  : !this.state.show_call_details ? 
                      <div onClick={(e) => { this.setState({show_call_details: true}); }} className="text-left rounded-md ml-5 p-3 h-12 bg-gray-300 hover:bg-gray-400 mt-5 cursor-pointer flex-auto">Ajouter action</div>
                  : null
                  }
                  <div onClick={(e) => { this.archiveCandidate() }} className="text-left rounded-md ml-20 p-3 h-12 bg-gray-300 hover:bg-gray-400 mt-5 cursor-pointer flex-end">Classer</div>
                </div>
              : null
              }
            </div>
          </div>
        )
    }
}

export default withTranslation()(AdminUserCard);
