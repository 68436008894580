import React from 'react';
import { withTranslation } from 'react-i18next'

import { IoAdd } from "react-icons/io5";
//assets

class ManagerEmptyList extends React.Component {
  
  render() {
    return (
      <div>
        <p className="w-full float-left">{this.props.empty_message}</p>
        <h3 onClick={() => { this.props.handleClick()}} className="bg-purpleJS-100 cursor-pointer float-left mt-10 ml-20 mb-10 p-5 pt-3 pb-3 rounded-lg text-white">
          <IoAdd className="float-left mr-3 text-2xl" />
          {this.props.create_new_label}
        </h3>
      </div>
    )
  }
}

export default withTranslation()(ManagerEmptyList);