import React from 'react';
import { withTranslation } from 'react-i18next'

import loadingIMG from '../../img/loading.transparent.svg';
//assets

class ManagerLoading extends React.Component {
  render() {
    return (
      <img alt="loading" src={loadingIMG} className="w-20" />  
    )
  }
}

export default withTranslation()(ManagerLoading);