import React from 'react';
import { withTranslation } from 'react-i18next'

import Header from './Header.js'
import Footer from './Footer.js'
import Legals from './pages/Legals.js'
import Privacy from './pages/Privacy.js'
import Terms from './pages/Terms.js'
import NotFound from './pages/NotFound.js'

//assets

class Page extends React.Component {

  renderSwitch() {
    switch (this.props.location.pathname) {
      case '/legals':
        return <article className="relative mt-16"><Legals /></article>
      case '/privacy':
        return <article className="relative mt-16"><Privacy /></article>
      case '/terms':
        return <article className="relative mt-16"><Terms /></article>
        default:
          return <article className="relative mt-16"><NotFound /></article>
    }

  }

  render() {
    return (
      <div className="container h-full w-full max-w-none max-h-screen">
        <Header location={this.props.location} />
        {this.renderSwitch()}
        <Footer />
      </div>
    )
  }
}

export default withTranslation()(Page);