import React from 'react';

import { withTranslation } from 'react-i18next';

import Header from './Header'; // Import a component from another file
import Footer from './Footer'; // Import a component from another file
import JSButton from './JSButton'; // Import a component from another file
import errorIMG from '../img/error.svg';
import { setLanguage } from './controllers/Language';

import { Auth } from 'aws-amplify';

import { IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";

      
class ResetPassword extends React.Component {
    constructor(props) {
      super(props);
            
      var search = this.props.location.search; // could be '?foo=bar'
      var params = new URLSearchParams(search);
      var lang = params.get('lang'); // bar
      
      if(lang) {
        setLanguage(lang);
      }

      
      this.errorMessage = null;
      this.newPassword = "";
      this.renewPassword = "";
      this.user_id = this.props.match.params.user_id;
      this.reset_code = this.props.match.params.reset_code;
      
      this.state = {
        error: false,
        canSavePassword: false,
        notIdenticalPassword: false,
        showPassword: false,
        success: false
      };
      
      this.closeError = this.closeError.bind(this);
      this.setNewPassword = this.setNewPassword.bind(this);
      this.updatePassword = this.updatePassword.bind(this);
    }
    
    closeError() {
      this.setState({
        error: false
      });
    }
    
    setNewPassword(form_name, pass) {
      
      if(form_name === "new_password") {
        this.newPassword = pass;
      } else {
        this.renewPassword = pass;
      }
      
      var canSave = (this.newPassword.length>5 && this.newPassword === this.renewPassword);
      
      var identicalCheck = (this.newPassword.length>5 && this.renewPassword.length>5 && this.newPassword !== this.renewPassword);
      
      var error = false;
      
      if (identicalCheck) {
        this.errorMessage = this.props.t("password_not_identical");
        error = true;
      }
      
      this.setState({
        canSavePassword: canSave,
        error: error
      });
    }
    
    async updatePassword() {
      
      this.setState({
        canSavePassword: false
      });

      this.errorMessage = null;

      try {
        await Auth.forgotPasswordSubmit(this.user_id, this.reset_code, this.newPassword);
      } catch(error) {
        console.log(error);
        this.errorMessage = this.props.t("Amplify.forgotPassword." + error.code);
      }
      
      if(this.errorMessage==null) {
        this.setState({success: true});
      } else {
        this.setState({error: true});
      }
    }

    render() {
      return (
        <div className="container w-full max-w-none">
          <Header location={this.props.location} />
          <div className="container w-full max-w-none p-5 md:p-20">
            { !this.state.success ?
            <div className="w-1/2 mx-auto">
              <div className="w-full mt-40">
              
                <label className="w-full block text-xl font-medium">{this.props.t('set_new_password')}</label>
                
                <div className="relative font-greyBorderJS">
                  <input type={this.state.showPassword ? "text" : "password"} placeholder="password" name="new_password" className="mt-5 px-5 py-3 text-xl placeholder-greyBorderJS font-regular font-sans border-solid rounded-md border border-greyBorderJS w-full block"
                    onChange={(e) => { this.setNewPassword(e.target.name, e.target.value); }} />
                    <div className="absolute inset-y-0 right-0 pr-5 flex items-center text-xl leading-5">
                    {
                      this.state.showPassword ?
                        <IoEyeOffSharp onClick={() => { this.setState({showPassword: false}); }} />
                      : <IoEyeSharp onClick={() => { this.setState({showPassword: true}); }} />
                    }
                    </div>
                </div>
              </div>
              <div className="w-full mt-10">
                <label className="w-full block text-xl font-medium">{this.props.t('reset_new_password')}</label>
                <div className="relative font-greyBorderJS">
                  <input type={this.state.showPassword ? "text" : "password"} placeholder="password" name="renew_password" className="mt-5 px-5 py-3 text-xl placeholder-greyBorderJS font-regular font-sans border-solid rounded-md border border-greyBorderJS w-full block"
                    onChange={(e) => { this.setNewPassword(e.target.name, e.target.value); }} />
                    <div className="absolute inset-y-0 right-0 pr-5 flex items-center text-xl leading-5">
                      {
                        this.state.showPassword ?
                          <IoEyeOffSharp onClick={() => { this.setState({showPassword: false}); }} />
                        : <IoEyeSharp onClick={() => { this.setState({showPassword: true}); }} />
                      }
                    </div>
                </div>
              </div>
          
              <div className="w-full justify-end">
                <JSButton active={this.state.canSavePassword} text={this.props.t('save_new_pw_btn')} action={this.updatePassword} />
              </div>
              
              { this.state.error ?
              <div className="mt-5 relative flex rounded-md overflow-hidden bg-white border-solid border border-greyBorderJS">
                  <div style={{ backgroundColor: "#f57e79" }} className="p-5 flex text-center items-center">
                    <img className="mx-auto w-6" alt="error" src={errorIMG} />
                  </div>
                  <div className="text-left py-2 pl-3 pr-10">
                      <p className="text-md text-black font-medium mt-3">
                          {
                              this.errorMessage.split("\n").map(function (item, idx) {
                                  return (
                                      <span key={idx}>
                                          {item}
                                          <br />
                                      </span>
                                  )
                              })
                          }
                          </p>
                  </div>
                  <div className="cursor-pointer p-1 text-black absolute top-0 right-0 mt-4 mr-2" onClick={() => { this.closeError(); }}>✖</div>
              </div>
              : '' }
            </div>
            :
            <div className="w-1/4 mx-auto mt-40 mb-40">
              <h3>{this.props.t('password_changed_success_message')}</h3>
            </div>
            } 
          </div>
          <Footer />
        </div>
      )
    }
}

export default withTranslation()(ResetPassword);