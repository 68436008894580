import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Storage, Auth } from 'aws-amplify';

import AuthConfig from './AuthConfig';
import Loading from './Loading';
import Error from './Error';
import JSButton from './JSButton';

//main css generated with tailwindcss
import './../css/main.css';

//assets
import locationIMG from '../img/location.svg';
import arrowMoreIMG from '../img/arrowmore.svg';

import checkmarkCircle from '../img/checkmarkCircle.svg';
import cloudUpload from '../img/cloudUpload.svg';


import { setLanguage } from './controllers/Language';

// eslint-disable-next-line no-unused-vars
let TITLE = 'JobStory';

// eslint-disable-next-line no-use-before-define
if (typeof json_offer === "undefined")
  var json_offer = null;

const computeAddressLink = (offer) => {
  let mapLink = offer.street ? (offer.street + ", ") : "";
  mapLink += offer.zip_code ? (offer.zip_code + " ") : '';
  mapLink += offer.city ? offer.city : '';
  mapLink += offer.city === "" || offer.zip_code === "" ? offer.area + ", " + offer.country : "";
  return mapLink;
}
const computeAddress = (offer) => {
  // {this.offer.street}<br />{this.offer.zip_code + " " + this.offer.city}
  //area, city, country, street, zip_code, sub_area
  let tmpAddress = "";
  if (offer.city === "" || offer.zip_code === "") {
    tmpAddress = offer.area + ", " + offer.country
  } else {
    tmpAddress = offer.street + ", " + offer.zip_code + " " + offer.city;
  }

  if(offer.formatted_address) tmpAddress = offer.formatted_address.replace(/,([^,]+)$/, '');


  return tmpAddress.replace(/(\d{5})/, '\n$1');
}

class Postulate extends Component {

  // Then we add our constructor which receives our props
  constructor(props) {

    super(props);
    this.job_code = this.props.match.params.job_code;
    
    this.application = {
      email: "",
      firstname: "",
      lastname: "",
      phone_number_indicatif: "+33",
      phone_number: "",
      use_cv: true,
      cv_file: "",
      accept_cgu: false
    };
    
    this.fileInput = null;
    
    this.state = {
      error: false,
      loading: true,
      jobdetail: false,
      postulate_modal: false,
      postulate_active: false,
      sending_application: false,
      cv_file: null,
      userIdentityId: null,
      apply_email: "",
      apply_success: "",
      apply_error: "",
    };
    
    this.apisEndpoint = AuthConfig.Common.API.endpoints.find((api) => { return api.name === 'apis' }).endpoint;

    this.readmoreToggle.bind(this);
    this.sendEmailApplication.bind(this);
    this.updateValue.bind(this);
    this.showFileSelector.bind(this);
  }

  readmoreToggle = event => {
    event.preventDefault();
    this.setState({ jobdetail: !this.state.jobdetail });
  }

  sendEmailApplication = event => {
       
    this.setState({ 
      postulate_active: false,
      sending_application: true
    });
    
    var sentData = {
      job_code: this.job_code,
      email: this.application.email,
      phone_number: this.application.phone_number_indicatif + this.application.phone_number,
      firstname: this.application.firstname,
      lastname: this.application.lastname,
      accept_cgu: this.application.accept_cgu,
      // cv_file: this.application.cv_file,
      use_cv: this.application.use_cv
    }
    
    if(this.application.cv_file !== "") sentData.cv_file = this.application.cv_file;
    
    fetch(this.apisEndpoint + '/apis/submit-interest', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(sentData)
    }).then(response => {
      return response.json()
    }).then(response => {
      if (response.success) {
        this.setState({
          sending_application: false,
          apply_success: response.message
        });
      } else {
        this.setState({
          sending_application: false,
          apply_error: response.message
        });
      }
    })
    .catch(error => {
        this.setState({
          sending_application: false,
          apply_error: error.message
        });
    });
  }
  
  async updateValue(attribute, value) {

    if(attribute === "cv_file") {
      if(value.files && value.files[0]) {
        var file = value.files[0];
        
        let fileSplit = file.name.split('.');
        let fileExt = fileSplit[fileSplit.length - 1];

        let fileHashName = Math.random().toString(16).slice(-8);
        let objectKey = 'picture/' + fileHashName + '.' + fileExt;

        var result = null;
        try {
          result = await Storage.put(objectKey, file, {
              provider: 'AWSS3',
              level: 'protected',
              contentType: file.type,
              progressCallback(progress) {
                  // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
              },
          });
         
          // l'image est uploadé, le chemin a stoquer est:
          value = this.state.userIdentityId + '/' + result.key;

          this.setState({ cv_file: value });
        } catch(e) {
          console.log(e);
          value = "";
        }
      }
      
    } else if(attribute === "phone_number") {
      var match = value.match(/\d/g);
      
      if(match && match.length) {
        value = match.join('');
      } else value = "";
    }
    
    this.application[attribute] = value;
    this.checkReady()
    
  }

  showFileSelector() {
      if(this.fileInput!=null) {
        this.fileInput.click();
      }
  }
  
  checkReady() {

    
    if(/^[a-zA-Z0-9.+\-_]+@(?:[a-zA-Z0-9\-_]+\.)+[A-Za-z]+$/.test(this.application.email) && this.application.accept_cgu && 
      this.application.firstname !== "" && 
      this.application.lastname !== "" 
      // this.application.phone_number.length > 9 && this.application.phone_number.length <= 10
    ) {
      this.setState({ postulate_active: true });
    } else {
      this.setState({ postulate_active: false });
    }
  }

  componentDidMount() {
    if (typeof json_offer !== "undefined" && json_offer !== null && json_offer.title) {
      this.offer = json_offer;
      let tmpdate = new Date(this.offer.start_date);
      tmpdate = tmpdate.getDate().toString().padStart(2, '0') + '/'
        + (tmpdate.getMonth() + 1).toString().padStart(2, '0') + '/'
        + tmpdate.getFullYear();
      this.offer.formated_start_date = tmpdate;

      TITLE = "JobStory - " + this.offer.title;

      this.setState({
        loading: false
      });
    } else {
      
      Auth.currentUserCredentials().then(res_id => {
        if (res_id.identityId) {
          fetch(this.apisEndpoint + '/apis/list-offers/' + this.job_code, {
            method: 'get'
          }).then(response => {
            return response.json()
          }).then(response => {
            if (response.job_code) {
              this.offer = response;
              let tmpdate = new Date(this.offer.start_date);
              tmpdate = tmpdate.getDate().toString().padStart(2, '0') + '/'
                + (tmpdate.getMonth() + 1).toString().padStart(2, '0') + '/'
                + tmpdate.getFullYear();
              this.offer.formated_start_date = tmpdate;
              TITLE = "JobStory - " + this.offer.title;
              this.mapLink = computeAddressLink(this.offer);
              this.address = computeAddress(this.offer);
              
              
              var givenLang = "fr";
              
              if(this.offer.lang) {
                givenLang = this.offer.lang;
              }

              setLanguage(givenLang);

              var prefix_ind = "+33";
              
              if(this.offer.country_iso === "BE") {
                prefix_ind = "+32";
              }
              
              this.offer.display_cv = true;
              
              this.application.phone_number_indicatif = prefix_ind;

              this.setState({
                loading: false,
                userIdentityId: res_id.identityId
              });
              
              
            } else {
              TITLE = "JobStory - Offre non trouvée";

              this.setState({
                error: this.props.t('error_offer_not_found'),
              });
            }
          })
          .catch(error => {
            console.log(error);
          });
        }
      });
    }
    
    this.fileInput = document.getElementById('file-input-cv');
  }

  render() {
    return (
      <div className="container h-full w-full max-w-none max-h-screen">
        {
        /* 
        <Helmet script={[{
          type: 'text/javascript',
          innerHTML: 'try{json_offer = JSON_OFFER;}catch(e){}'
        }]} >
          <title>META_TITLE</title>
          <meta property="og:title" content="META_TITLE" />
          <meta name="description" content="META_DESCRIPTION"></meta>
          <meta property="og:description" content="META_DESCRIPTION" />
          <meta property="og:image" content="META_IMAGE" />
          <meta property="og:url" content="META_URL" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet> 
        */
        }
        
        <input className="hidden" id="file-input-cv" type="file" accept="application/pdf" onChange={(e) => { this.updateValue('cv_file', e.target); }}/>            

        { this.state.error ?
          <Error text={this.state.error} />
          :
          this.state.loading ?
            <Loading />
            :
            <div className="h-full w-full relative">
              <div className="h-full w-full sm:w-3/4 md:w-3/5 m-auto relative">
                <div className="p-4 sm:p-12">
                  <div className="md:flex p-6">
                    <img alt="Company name" className="h-16 w-16 mx-auto md:mx-0 md:mr-6" src={AuthConfig.CDN.images + '100x100/' + this.offer.company_picture} />
                    <div className="text-center md:text-left">
                      <h2 className="text-xl md:text-3xl font-bold">{this.offer.title}</h2>
                      <div className="font-semibold md:text-xl">{this.offer.company_name}</div>
                    </div>
                  </div>

                <div className="offer-frame relative text-white px-10 py-6 sm:px-16 bg-purpleJS-100 mb-10">
                { 
                this.state.apply_success !== "" ?
                  <div>
                    <center><img className="mt-5" alt="checkmark circle" src={checkmarkCircle} /></center>
                    <h3 className="text-4xl font-bold text-white mb-5 mt-10 text-center">{this.props.t('thx_application')}</h3>
                    <p className="text-2xl mb-5 mt-0 text-white text-center">{this.props.t(this.state.apply_success)}</p>
                  </div>
                : this.state.apply_error !== "" ?
                  <div>
                    <h3 className="text-4xl font-bold text-white mb-5 mt-8 text-center">{this.props.t('error_web_application')}</h3>
                    <p className="text-2xl mb-5 mt-0 text-white text-center">{this.props.t(this.state.apply_error)}</p>
                  </div>
                :
                  <div>
                    <h3 className="text-center text-xl md:text-3xl font-bold mb-5 mt-0">{this.props.t('postulate')}</h3>
                    <label className="w-full block text-xl font-medium">{this.props.t('apply_email_label')}</label>
                    <input type="text" value={this.application.email} className="mt-5 px-5 py-3 text-xl placeholder-greyBorderJS bg-purpleJS-100
                      font-greyBorderJS font-regular text-white font-sans border-solid rounded-md border
                      border-greyBorderJS w-full block" placeholder={this.props.t('placeholder_email')} onChange={(e) => { this.updateValue('email', e.target.value); }} />
                      
                    <div className="w-full sm:flex">
                      <div className="w-full sm:flex-auto mt-10">
                        <label className="w-full block text-xl font-medium">{this.props.t('apply_firstname')}</label>
                        <input type="text" value={this.application.firstname} className="mt-5 px-5 py-3 text-xl placeholder-greyBorderJS bg-purpleJS-100
                          font-greyBorderJS font-regular text-white font-sans border-solid rounded-md border
                          border-greyBorderJS w-full block" placeholder={this.props.t('placeholder_firstname')} onChange={(e) => { this.updateValue('firstname', e.target.value); }} />
                      </div>
                      <div className="w-full sm:flex-auto sm:ml-10 mt-10">
                        <label className="w-full block text-xl font-medium">{this.props.t('apply_lastname')}</label>
                        <input type="text" value={this.application.lastname} className="mt-5 px-5 py-3 text-xl placeholder-greyBorderJS bg-purpleJS-100
                          font-greyBorderJS font-regular text-white font-sans border-solid rounded-md border
                          border-greyBorderJS w-full block" placeholder={this.props.t('placeholder_lastname')} onChange={(e) => { this.updateValue('lastname', e.target.value); }} />
                      </div>
                    </div>
                    
                    {
                      /*
                    <div className="w-full mt-10">
                      <label className="w-full block text-xl font-medium">{this.props.t('phone_number_label')}</label>
                      <div className="w-full sm:flex">
                        <div className="w-1/4 sm:flex-auto">
                          <input type="text" value={this.application.phone_number_indicatif} className="mt-5 px-5 py-3 text-xl placeholder-greyBorderJS bg-purpleJS-100
                            font-greyBorderJS font-regular text-white font-sans border-solid rounded-md border
                            border-greyBorderJS w-full block" placeholder={this.props.t('placeholder_phone_number_indicatif')} onChange={(e) => { this.updateValue('phone_number_indicatif', e.target.value); }} />
                        </div>
                        <div className="w-full sm:flex-auto sm:ml-10">
                          <input type="text" value={this.application.phone_number} className="mt-5 px-5 py-3 text-xl placeholder-greyBorderJS bg-purpleJS-100
                            font-greyBorderJS font-regular text-white font-sans border-solid rounded-md border
                            border-greyBorderJS w-full block" placeholder={this.props.t('placeholder_phone_number')} onChange={(e) => { this.updateValue('phone_number', e.target.value); }} />
                        </div>
                      </div>
                    </div>
                      */
                    }
                    {
                      this.offer.display_cv ?
                      <div>
                        <label className="w-full block text-xl font-medium mt-10">{this.props.t('send_cv_label')}</label>
                        
                        <div className="w-full mt-6 border-dashed border-2 border-light-blue-500 rounded-lg p-6 float-left mb-8">
                          <div onClick={() => document.getElementById('file-input-cv').click()} className="cursor-pointer float-left pr-8 pl-2">
                            <img className="mt-2" src={cloudUpload} alt="Select CV" />
                          </div>
                          <div className="float-left">
                            <h4 className="font-bold text-white mb-2 text-lg">{this.state.cv_file != null ? this.state.cv_file.name : this.props.t('choose_your_cv')}</h4>
                            <p className="text-lightGrey">{this.props.t('send_cv_format')}</p>
                          </div>
                          <div onClick={() => document.getElementById('file-input-cv').click()} className="float-right">
                            <p className="bg-white p-4 text-purpleJS-100 font-bold text-center cursor-pointer rounded-lg">{this.props.t('upload_cv')}</p>
                          </div>
                        </div>
                      </div>
                    : null }   
                    <div className="w-full mt-10">
                      <input id="accept_cgu" type="checkbox" className="form-checkbox select-none h-6 w-6 text-white border-solid border border-greyBorderJS bg-purpleJS-100" 
                        checked={this.application.accept_cgu ? "checked" : ""} 
                        onChange={(e) => { this.updateValue('accept_cgu', e.target.checked); }} 
                      />
                      <label 
                        htmlFor="accept_cgu"
                        className="select-none"
                      >
                        <span className='ml-5 text-lg text-white'>
                          {this.props.t('accept_cgu_prefix') + " "}
                          <a href="/terms" target="_blank" className='text-white no-underline hover:underline'>{this.props.t('cgu_label')}</a>
                        </span>
                      </label>
                    </div>
                    
                    <center>
                      <button disabled={!this.state.postulate_active} onClick={this.sendEmailApplication} className={
                          this.state.postulate_active ? "mt-8 w-full px-20 py-3 rounded-md font-medium text-xl text-purpleJS-100 bg-white"
                              : "cursor-not-allowed mt-8 w-full px-20 py-3 rounded-md font-medium text-xl text-lightPurpleText bg-lightPurple"
                      }>
                        { 
                        this.state.sending_application ?
                          this.props.t('sending_application_email') 
                        : 
                          this.props.t('send_application_email') 
                        }
                      </button>
                    </center>
                   
                  </div>
                }
                </div>


                  <div className={
                    this.state.jobdetail ? 'block' : 'hidden'}>
                    {
                      this.offer.description ?
                        <>
                          <h3 className="text-xs text-lightGrey uppercase font-bold mt-10 mb-3">{this.props.t('offer_description')}</h3>
                          <span style={{ whiteSpace: "pre-line" }}>{this.offer.description}</span>
                        </>
                        : null
                    }
                    {
                      this.offer.candidate_qualification ?
                        <>
                          <h3 className="text-xs text-lightGrey uppercase font-bold mt-10 mb-3">{this.props.t('offer_qualifications')}</h3>
                          <span style={{ whiteSpace: "pre-line" }}>{this.offer.candidate_qualification}</span>
                        </>
                        : null
                    }
                    {(new Date(this.offer.start_date) > new Date()) ?
                      <>
                        <h3 className="text-xs text-lightGrey uppercase font-bold mt-10 mb-3">{this.props.t('offer_starting_date')}</h3>
                        <p className="font-medium text-base">{this.offer.formated_start_date}</p>
                      </>
                      : null
                    }
                    <h3 className="text-xs text-lightGrey uppercase font-bold mt-10 mb-3">{this.props.t('offer_location')}</h3>
                    <div onClick={() => { window.open("https://www.google.com/maps/place/" + encodeURI(this.mapLink), "_blank") }} className="cursor-pointer bg-no-repeat bg-right border-solid px-4 py-2 pr-40
                    inline-block rounded-md border border-gray-300"
                      style={{ backgroundImage: "url(" + locationIMG + ")", backgroundPosition: "15rem center" }}>
                      <p className="font-medium text-base whitespace-pre-line">{this.address}</p>
                    </div>
                  </div>
                  <center>
                    <a onClick={this.readmoreToggle} href="#readmore" className='inline-block text-lightGrey no-underline hover:underline'>
                    {this.state.jobdetail ? this.props.t('see_less') : this.props.t('offer_job_detail')} <img alt="job detail" className={this.state.jobdetail ? 'transform rotate-180 inline-block' : 'inline-block'} src={arrowMoreIMG} />
                    </a>
                  </center>
                </div>

                <div style={{ clear: "both" }}></div>
                <div className="hidden sm:block h-10"></div>
              </div>
                {
                    this.state.postulate_modal ? 
                      <div className="h-full w-full fixed top-0 bottom-0 bg-indigo-600 bg-opacity-50">
                        <div className="h-1/2 w-full sm:w-3/4 md:w-3/5 m-auto mt-10 p-8 pt-4 relative bg-white rounded-lg">
                          <div className="w-full relative text-right">
                            <a href="#close-application" onClick={this.postulateToggle} className='inline-block text-lightGrey no-underline hover:underline'>{this.props.t('close')}</a>
                          </div>
                          <h3 className="text-xl md:text-3xl font-bold mb-5">{this.offer.title}</h3>
                          <label className="w-full block text-xl font-medium">{this.props.t('apply_email_label')}</label>
                          <input type="text" value={this.application.email} className="mt-5 px-5 py-3 text-xl placeholder-greyBorderJS
                            font-greyBorderJS font-regular font-sans border-solid rounded-md border
                            border-greyBorderJS w-full block" placeholder={this.props.t('placeholder_email')} onChange={(e) => { this.updateValue('email', e.target.value); }} />

                          <label className="w-full block text-xl font-medium mt-10">{this.props.t('send_cv_label')}</label>
                          <div className="w-full mt-5">

                          </div>

                          { this.application.use_cv ?
                          <div className="w-full mt-10">
                            <p className="text-lightGrey">{this.props.t('send_cv_format')}</p>
                            <input type="file" onChange={(e) => { this.updateValue('cv_file', e.target); }}/>
                          </div>
                            : null
                          }
                              
                          <div className="w-full mt-10">
                            <input id="accept_cgu" type="checkbox" className="px-5 border-solid rounded-md border border-greyBorderJS" 
                              checked={this.application.accept_cgu ? "checked" : ""} 
                              onChange={(e) => { this.updateValue('accept_cgu', e.target.checked); }} 
                            />
                            <label 
                              htmlFor="accept_cgu"
                            >
                              <span className='ml-5 text-lightGrey'>
                                {this.props.t('accept_cgu_prefix') + " "}
                                <a href="/terms" target="_blank" className='text-lightGrey no-underline hover:underline'>{this.props.t('cgu_label')}</a>
                              </span>
                            </label>
                          </div>

                          <center><JSButton active={this.state.postulate_active} text={this.props.t('send_application_email')} action={this.sendEmailApplication} /></center>
                        </div>
                      </div>
                    : null
                }
            </div>
        }
      </div>
    );
  }
}

export default withTranslation()(Postulate);