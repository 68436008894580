import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translation_FR from './translations/fr_FR.json';
import translation_NL from './translations/nl_BE.json';
import translation_EN from './translations/en_EN.json';
import translation_ES from './translations/es_ES.json';
import translation_IT from './translations/it_IT.json';
import translation_PT from './translations/pt_BR.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    fr: {
        translation: translation_FR
    },
    nl: {
        translation: translation_NL
    },
    en: {
        translation: translation_EN
    },
    es: {
        translation: translation_ES
    },
    it: {
        translation: translation_IT
    },
    pt: {
        translation: translation_PT
    }
};

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "fr",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;