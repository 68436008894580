import React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next'


import CFG from './../Config';


const Privacy = (props) => {

  return (
    <div className="w-full p-5 pt-20 md:p-20">
      <Helmet>
        <title>JobStory - Politique de confidentialité</title>
        <meta property="og:title" content="JobStory - Politique de confidentialité" />
        <meta name="description" content={props.t('meta_description')}></meta>
        <meta property="og:description" content={props.t('meta_description')} />
        <meta property="og:image" content={CFG.links.shareIMG} />
        <meta property="og:url" content={CFG.links.root} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <h1 className="font-bold text-2xl">Politique de confidentialité</h1>
      <p>MENTION DE PREMIER NIVEAU</p>
      <p>&nbsp;</p>
      <p>Vos donn&eacute;es sont trait&eacute;es par JOBSTORY, soci&eacute;t&eacute; par actions simplifi&eacute;e dont le si&egrave;ge social est situ&eacute; 14 rue Mandar &ndash; 75002 Paris, pour les finalit&eacute;s suivantes&nbsp;: la gestion des comptes utilisateurs, la gestion du fonctionnement de l&rsquo;application, la gestion de notre relation avec vous, la gestion de notre communication avec vous, la gestion de l&rsquo;application et l&rsquo;am&eacute;lioration de votre exp&eacute;rience utilisateur, la gestion des demandes d&rsquo;exercice de droit.</p>
      <p>Vous disposez sur vos donn&eacute;es d&rsquo;un droit d&rsquo;acc&egrave;s, de rectification, d&rsquo;effacement, de portabilit&eacute;, de limitation, d&rsquo;opposition, de d&eacute;finir les directives sur le sort de vos donn&eacute;es apr&egrave;s votre d&eacute;c&egrave;s, d&rsquo;un droit d&rsquo;opposition au traitement de vos donn&eacute;es &agrave; des fins de prospection commerciale, y compris de profilage.</p>
      <p>Pour plus d&rsquo;informations, vous pouvez consulter la politique de confidentialit&eacute;.</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>POLITIQUE DE CONFIDENTIALIT&Eacute;</p>
      <p>Derni&egrave;re date de mise &agrave; jour : 4octobre 2020</p>
      <p>&nbsp;</p>
      <p>La pr&eacute;sente Politique de protection des Donn&eacute;es Personnelle s&rsquo;applique aux traitements de donn&eacute;es vous concernant que JOBSTORY r&eacute;alise au travers de l&rsquo;application internet t&eacute;l&eacute;chargeable depuis les Stores et &agrave; l&rsquo;adresse. Elle d&eacute;crit notamment les op&eacute;rations r&eacute;alis&eacute;es sur vos donn&eacute;es &agrave; caract&egrave;re personnel et les droits dont vous disposez sur vos donn&eacute;es, ainsi que les modalit&eacute;s d&rsquo;exercice de ceux-ci. La pr&eacute;sente Politique de Protection des Donn&eacute;es Personnelles vient compl&eacute;ter les CGU de l&rsquo;Application et le formulaire d&rsquo;inscription lors de la cr&eacute;ation d&rsquo;un compte utilisateur.</p>
      <p>Nous vous informerons de changements substantiels que nous pourrons apporter &agrave; la pr&eacute;sente politique de Protection des Donn&eacute;es Personnelles, mais nous vous invitons &agrave; en prendre r&eacute;guli&egrave;rement connaissance.</p>
      <p>&nbsp;</p>
      <ol>
        <li><strong>1. DEFINITIONS</strong></li>
      </ol>
      <p><em>Rappel des notions cl&eacute;s utilis&eacute;es dans le cadre de cette Politique de protection des donn&eacute;es, car ces termes ont un sens bien d&eacute;fini.&nbsp;</em></p>
      <p><strong>Application</strong>&nbsp;: d&eacute;signe l&rsquo;application mise &agrave; disposition par JOBSTORY, disponible sur les plateformes ANDROID et APPLE STORE</p>
      <p><strong>Candidat&nbsp;</strong>: d&eacute;signe une personne physique qui a ouvert un compte utilisateur sur l&rsquo;Application et y publie son profil et peut postuler &agrave; une offre d&rsquo;emploi publi&eacute;e par un Recruteur&nbsp;;</p>
      <p><strong>Donn&eacute;es ou Donn&eacute;es &agrave; caract&egrave;re personnel&nbsp;</strong>: d&eacute;signe toute information se rapportant &agrave; une Personne concern&eacute;e&nbsp;;</p>
      <p><strong>Personne concern&eacute;e</strong>&nbsp;: d&eacute;signe une personne physique identifi&eacute;e ou identifiable&nbsp;; est r&eacute;put&eacute;e &ecirc;tre une personne physique identifiable, une personne physique qui peut &ecirc;tre identifi&eacute;e directement ou indirectement, notamment par r&eacute;f&eacute;rence &agrave; un identifiant, tel qu&rsquo;un nom, un num&eacute;ro d&rsquo;identification, des donn&eacute;es de localisation, un identifiant en ligne, ou &agrave; un ou plusieurs &eacute;l&eacute;ments sp&eacute;cifiques propres &agrave; son identit&eacute; physique, physiologique, g&eacute;n&eacute;tique, psychique, &eacute;conomique, culturelle ou sociale&nbsp;;</p>
      <p><strong>Recruteur&nbsp;: </strong>d&eacute;signe une personne, physique ou morale, (repr&eacute;sent&eacute;e par son repr&eacute;sentant l&eacute;gal ou toute autre personne dument habilit&eacute;e) qui a ouvert un compte utilisateur sur l&rsquo;Application et y publie une/des offre(s) d&rsquo;emploi et/ou contacte un Candidat pour lui proposer un entretien d&rsquo;embauche&nbsp;;</p>
      <p><strong>R&eacute;glementation applicable en mati&egrave;re de protection des Donn&eacute;es</strong>&nbsp;: d&eacute;signe le R&egrave;glement (UE)&nbsp;2016/679 du Parlement europ&eacute;en et du Conseil ainsi que la loi n&deg;78-17 du 6 janvier 1978 modifi&eacute;e et toutes autres dispositions l&eacute;gales applicables et relatives au Traitement et/ou &agrave; la protection des Donn&eacute;es &agrave; caract&egrave;re personnel&nbsp;;</p>
      <p><strong>Responsable du Traitement&nbsp;:</strong> d&eacute;signe la personne physique ou morale, l&rsquo;autorit&eacute; publique, le service ou tout autre organisme qui, seul ou conjointement avec d&rsquo;autres, d&eacute;termine les finalit&eacute;s et les moyens du Traitement de Donn&eacute;es &agrave; caract&egrave;re personnel&nbsp;;</p>
      <p><strong>Story&nbsp;</strong>: d&eacute;signe une courte vid&eacute;o dans laquelle un Candidats r&eacute;pond &agrave; une question pr&eacute;d&eacute;finie par un Recruteur ayant post&eacute; une offre d&rsquo;emploi, en une minute maximum par question. Chaque Story est d&eacute;truite apr&egrave;s une dur&eacute;e de 12 mois&nbsp;;</p>
      <p><strong>Traitement </strong>ou <strong>traiter</strong>&nbsp;: d&eacute;signe&nbsp; toute op&eacute;ration ou ensemble d&rsquo;op&eacute;rations effectu&eacute;e(s) ou non &agrave; l&rsquo;aide de proc&eacute;d&eacute;s automatis&eacute;s et appliqu&eacute;e(s) &agrave; des Donn&eacute;es &agrave; caract&egrave;re personnel, telle que la collecte, l&rsquo;enregistrement, l&rsquo;organisation, la conservation, l&rsquo;adaptation ou la modification, l&rsquo;extraction, la consultation, l&rsquo;utilisation, la communication par transmission, diffusion ou toute autre forme de mise &agrave; disposition, le rapprochement ou l&rsquo;interconnexion, ainsi que le verrouillage, l&rsquo;effacement ou la destruction.</p>
      <p><strong>Utilisateur&nbsp;: </strong>personne naviguant sur l&rsquo;Application. Il peut notamment s&rsquo;agir d&rsquo;un Candidat ou d&rsquo;un Recruteur.</p>
      <p>&nbsp;</p>
      <ol start="2">
        <li><strong>2. IDENTIT&Eacute; ET COORDONN&Eacute;ES DU RESPONSABLE DU TRAITEMENT ET DU D&Eacute;L&Eacute;GU&Eacute; &Agrave; LA PROTECTION DES DONN&Eacute;ES </strong></li>
      </ol>
      <p><em>L&rsquo;identification du Responsable du traitement est n&eacute;cessaire afin que vous sachiez qui est &agrave; l&rsquo;origine et &agrave; l&rsquo;initiative de la collecte ou du Traitement de vos Donn&eacute;es dans le cadre de l&rsquo;utilisation et de votre navigation sur l&rsquo;Application.</em></p>
      <ul>
        <li><strong><u>2.1. Identit&eacute; et coordonn&eacute;es du Responsable de traitement</u></strong></li>
      </ul>
      <p>Les Donn&eacute;es &agrave; caract&egrave;re personnel sont collect&eacute;es et Trait&eacute;es par&nbsp;:</p>
      <p>JOBSTORY, soci&eacute;t&eacute; par actions simplifi&eacute;e (ci-apr&egrave;s JOBSTORY&nbsp;&raquo; ou &laquo;&nbsp;le Responsable du Traitement&nbsp;&raquo;)</p>
      <p>Si&egrave;ge social&nbsp;: 14 rue Mandar &ndash; 75002 Paris</p>
      <p>T&eacute;l&eacute;phone&nbsp;: <span style={{ direction: "rtl", unicodeBidi: "bidi-override" }}>4388622460</span></p>
      <p>Fax&nbsp;:</p>
      <p>Email&nbsp;: <span style={{ direction: "rtl", unicodeBidi: "bidi-override" }}>ppa.yrotsboj@tcatnoc</span></p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol start="3">
        <li><strong>3. COLLECTE DES DONN&Eacute;ES</strong></li>
      </ol>
      <p>&nbsp;</p>
      <ul>
        <li><strong>3.1. Cat&eacute;gories de Donn&eacute;es collect&eacute;es </strong></li>
      </ul>
      <p>Pour fournir nos services et vous permettre d&rsquo;utiliser l&rsquo;Application, nous nous devons traiter certaines Donn&eacute;es personnelles vous concernant. Le type de Donn&eacute;es trait&eacute;es d&eacute;pend notamment de la mani&egrave;re dont vous utilisez l&rsquo;Application et des informations que vous y t&eacute;l&eacute;chargez.</p>
      <p>Dans tous les cas, nous traitons notamment les Donn&eacute;es relatives &agrave; votre d&rsquo;<strong>identification</strong> fournies lors de votre inscription, aux <strong>contenus</strong> que vous cr&eacute;ez (notamment les m&eacute;tadonn&eacute;es des Stories que vous cr&eacute;ez), &agrave; vos <strong>interactions</strong> avec d&rsquo;autres Utilisateurs de l&rsquo;Application, &agrave; votre <strong>utilisation</strong> de l&rsquo;Application, aux <strong>transactions</strong> que vous r&eacute;alisez sur l&rsquo;application, &agrave; votre <strong>g&eacute;olocalisation</strong>. Nous collectons &eacute;galement des donn&eacute;es relatives &agrave; <strong>l&rsquo;appareil depuis lequel vous vous connectez</strong> (marque, syst&egrave;me d&rsquo;exploitation).</p>
      <p>Nous traitons aussi des Donn&eacute;es sur les <strong>appareils</strong> que vous utilisez pour acc&eacute;der &agrave; l&rsquo;Application, telles que le syst&egrave;me d&rsquo;exploitation, les signaux, les donn&eacute;es issues des param&egrave;tres de l&rsquo;appareil comme l&rsquo;acc&egrave;s &agrave; votre localisation, ou les donn&eacute;es issues des cookies (dont le traitement est d&eacute;crit plus pr&eacute;cis&eacute;ment ci-apr&egrave;s).</p>
      <p>&nbsp;</p>
      <ul>
        <li><strong>3.2. Modalit&eacute;s de la collecte</strong></li>
      </ul>
      <p>Nous pouvons collecter vos Donn&eacute;es de diff&eacute;rentes mani&egrave;res, notamment&nbsp;:</p>
      <ul>
        <li>Lorsque vous utilisez l&rsquo;Application&nbsp;;</li>
        <li>Lorsque vous cr&eacute;ez un compte utilisateur sur l&rsquo;Application, en tant que Candidat ou Recruteur, par le biais de la page d&rsquo;inscription ;</li>
        <li>Lorsque vous nous contactez, <em>via</em> notre Application la rubrique &laquo;&nbsp;Contact&nbsp;&raquo; mais aussi lors de nos &eacute;changes relatifs &agrave; l&rsquo;Application par courrier, par e-mail, par t&eacute;l&eacute;phone.</li>
      </ul>
      <p>Ces donn&eacute;es peuvent &ecirc;tre collect&eacute;es&nbsp;:</p>
      <ul>
        <li>Directement, par le biais d&rsquo;une d&eacute;claration de votre part (<u>ex</u>: lorsque vous cr&eacute;ez un compte utilisateur ou lorsque vous publiez une Story ou une offre d&rsquo;emploi)&nbsp;;</li>
        <li>Automatiquement par nos syst&egrave;mes (<u>ex</u>: d&eacute;p&ocirc;t de cookies sur votre terminal).</li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li><strong>3.3. Caract&egrave;re obligatoire ou facultatif de la fourniture de Donn&eacute;es </strong></li>
      </ul>
      <p>JOBSTORY vous indique lorsque la fourniture d&rsquo;une information est obligatoire (notamment en raison d&rsquo;une obligation l&eacute;gale, r&eacute;glementaire, contractuelle ou tout simplement afin de pouvoir traiter votre demande ou vous r&eacute;pondre). Si vous ne renseignez pas ces informations dites &laquo;obligatoires &raquo;, JOBSTORY pourra se trouver dans l&rsquo;impossibilit&eacute; de vous fournir certains services, ou de donner suite &agrave; la demande ou au formulaire en cause.</p>
      <p>&nbsp;</p>
      <p>Les champs d&rsquo;un formulaire qui ne sont pas renseign&eacute;s comme &eacute;tant obligatoires, sont laiss&eacute;s &agrave; votre discr&eacute;tion. Il vous appartient de choisir de les remplir ou non.</p>
      <ul>
        <li><strong>3.4. Particularit&eacute;s li&eacute;es aux donn&eacute;es personnelles des mineurs&nbsp;</strong></li>
      </ul>
      <p>En principe, JOBSTORY ne collecte ni ne traite de Donn&eacute;es personnelles relatives aux enfants de moins de 15 ans.</p>
      <p>&nbsp;</p>
      <p>Si une telle collecte devait &ecirc;tre faite dans le cadre sp&eacute;cifique d&rsquo;une offre d&rsquo;emploi ou de la publication d&rsquo;une Story, l&rsquo;accord pr&eacute;alable du ou des titulaires de la responsabilit&eacute; parentale serait requis pr&eacute;alablement &agrave; la collecte de Donn&eacute;es d&rsquo;enfants de moins de 15 ans.</p>
      <p>&nbsp;</p>
      <p>Par ailleurs, l&rsquo;enfant mineur au moment de la collecte de ses Donn&eacute;es personnelles peut en obtenir l&rsquo;effacement dans les meilleurs d&eacute;lais.</p>
      <p>&nbsp;</p>
      <ol start="4">
        <li><strong>4. FINALITES ET BASES JURIDIQUES DES TRAITEMENTS DE DONNEES</strong></li>
      </ol>
      <p><em>Pourquoi JOBSTORY traite vos donn&eacute;es et sur quelles bases juridiques JOBSTORY fonde ces Traitements&nbsp;? </em></p>
      <p>&nbsp;</p>
      <table className="border-solid border-2 border-lightGreyJS" width="604">
        <tbody>
          <tr>
            <td className="border-solid border-2 border-lightGreyJS" width="359">
              <p>Nous traitons vos donn&eacute;es pour les finalit&eacute;s suivantes&nbsp;:</p>
            </td>
            <td className="border-solid border-2 border-lightGreyJS" width="246">
              <p>Ces traitements sont fond&eacute;s sur les bases juridiques suivantes&nbsp;:</p>
            </td>
          </tr>
          <tr>
            <td className="border-solid border-2 border-lightGreyJS" width="359">
              <p><strong>G&eacute;rer les comptes utilisateurs</strong> notamment en&nbsp;:</p>
              <p>&middot;&nbsp;&nbsp;&nbsp; G&eacute;rant et validant les inscriptions et cr&eacute;ations de comptes utilisateurs&nbsp;;</p>
              <p>&middot;&nbsp;&nbsp;&nbsp; G&eacute;rant l&rsquo;utilisation des comptes utilisateurs (publication de Stories ou offres d&rsquo;emploi, &eacute;tablissement de la liste des favoris, historique des postulations, etc.).</p>
            </td>
            <td className="border-solid border-2 border-lightGreyJS" rowSpan="3" width="246">
              <p>&middot;&nbsp;&nbsp;&nbsp; L&rsquo;ex&eacute;cution de notre contrat avec vous (nos CGU)&nbsp;;</p>
              <p>&middot;&nbsp;&nbsp;&nbsp; Notre int&eacute;r&ecirc;t l&eacute;gitime &agrave; g&eacute;rer notre activit&eacute;.</p>
            </td>
          </tr>
          <tr>
            <td className="border-solid border-2 border-lightGreyJS" width="359">
              <p><strong>G&eacute;rer le fonctionnement de l&rsquo;Application </strong>notamment en&nbsp;:</p>
              <p>&middot;&nbsp;&nbsp;&nbsp; Permettant la recherche et la postulation &agrave; des offres d&rsquo;emploi des Recruteurs&nbsp;;</p>
              <p>&middot;&nbsp;&nbsp;&nbsp; Permettant la publication et la consultation des Stories des Candidats.</p>
            </td>
          </tr>
          <tr>
            <td className="border-solid border-2 border-lightGreyJS" width="359">
              <p><strong>G&eacute;rer notre relation avec vous</strong>, notamment en&nbsp;:</p>
              <p>&middot;&nbsp;&nbsp;&nbsp; &Eacute;tablissant et tenant &agrave; jour un fichier recensant les Candidats et les Recruteurs&nbsp;;</p>
              <p>&middot;&nbsp;&nbsp;&nbsp; R&eacute;aliser un suivi de la relation, des offres / Stories publi&eacute;s et du bon d&eacute;roulement du service que nous vous proposons.</p>
            </td>
          </tr>
          <tr>
            <td className="border-solid border-2 border-lightGreyJS" width="359">
              <p><strong>Communiquer avec vous et r&eacute;pondre aux demandes diverses</strong> de tout Utilisateur de l&rsquo;Application adress&eacute;es directement <em>via</em> l&rsquo;Application ou par tout autre moyen.</p>
            </td>
            <td className="border-solid border-2 border-lightGreyJS" width="246">
              <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp; L&rsquo;ex&eacute;cution de notre contrat avec vous (nos CGU)</p>
              <p>&middot;&nbsp;&nbsp;&nbsp;&nbsp; Notre int&eacute;r&ecirc;t l&eacute;gitime &agrave; g&eacute;rer notre activit&eacute; / r&eacute;pondre aux demandes dans le cadre de l&rsquo;Application</p>
            </td>
          </tr>
          <tr>
            <td className="border-solid border-2 border-lightGreyJS" width="359">
              <p><strong>G&eacute;rer l&rsquo;Application et am&eacute;liorer votre exp&eacute;rience utilisateur</strong>, notamment en&nbsp;:</p>
              <p>&middot;&nbsp;&nbsp;&nbsp; D&eacute;veloppant, analysant, exploitant, am&eacute;liorant, optimisant et mettant &agrave; disposition l&rsquo;Application ;</p>
              <p>&middot;&nbsp;&nbsp;&nbsp; Suivant le parcours, l&rsquo;exp&eacute;rience utilisateur sur l&rsquo;Application et la fr&eacute;quence des visites ; en mesurant l&rsquo;audience de l&rsquo;Application et assurer que les contenus de l&rsquo;Application et de nos courriers &eacute;lectroniques soient pr&eacute;sent&eacute;s de la mani&egrave;re la plus efficace pour vous et sur votre terminal ; personnaliser votre exp&eacute;rience sur l&rsquo;Application</p>
            </td>
            <td className="border-solid border-2 border-lightGreyJS" width="246">
              <p>&middot;&nbsp;&nbsp;&nbsp; L&rsquo;ex&eacute;cution de notre contrat avec vous (nos CGU)</p>
              <p>&middot;&nbsp;&nbsp;&nbsp; Notre int&eacute;r&ecirc;t l&eacute;gitime de pouvoir&nbsp;:</p>
              <p>o&nbsp;&nbsp; &nbsp;Assurer la fourniture de l&rsquo;Application et du service qui y est propos&eacute; ;</p>
              <p>o&nbsp;&nbsp; Am&eacute;liorer et faire &eacute;voluer notre Application, notamment en fonction des Utilisateurs</p>
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td className="border-solid border-2 border-lightGreyJS" width="359">
              <p><strong>G&eacute;rer les demandes d&rsquo;exercice de droits</strong> des Personnes concern&eacute;es sur leurs donn&eacute;es personnelles.</p>
            </td>
            <td className="border-solid border-2 border-lightGreyJS" width="246">
              <p>Respect d&rsquo;une obligation l&eacute;gale nous incombant</p>
            </td>
          </tr>
          <tr>
            <td className="border-solid border-2 border-lightGreyJS" width="359">
              <p>Si vous &ecirc;tes<strong> Recruteur, </strong>pour vous adresser de la <strong>publicit&eacute; et la newsletter de JOBSTORY</strong></p>
            </td>
            <td className="border-solid border-2 border-lightGreyJS" width="246">
              <p>Notre int&eacute;r&ecirc;t l&eacute;gitime &agrave; communiquer sur notre activit&eacute;&nbsp;</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol start="5">
        <li><strong>5. DESTINATAIRES DES DONNEES</strong></li>
      </ol>
      <p><em>A qui JOBSTORY communique vos Donn&eacute;es ?</em></p>
      <p>&nbsp;</p>
      <ul>
        <li><strong>5.1. Cat&eacute;gories de destinataires des donn&eacute;es</strong></li>
      </ul>
      <p>L&rsquo;Application mis &agrave; votre disposition permet une large diffusion de vos Donn&eacute;es, dans le but d&rsquo;optimiser vos opportunit&eacute;s professionnelles.</p>
      <p>JOBSTORY peut &ecirc;tre amen&eacute;e &agrave; transmettre vos Donn&eacute;es avec les cat&eacute;gories de tiers suivants (ci-apr&egrave;s les &laquo; Destinataires &raquo;)&nbsp;:</p>
      <ul>
        <li>En interne&nbsp;: aux &eacute;quipes en charge des finalit&eacute;s en cause, dans le cadre de leurs fonctions&nbsp;;</li>
        <li>&Agrave; ses prestataires de services, sous-traitants ou fournisseurs dans le cadre de leurs prestations en relation avec les traitements identifi&eacute;s ci-dessus (<strong>4</strong>) pour le compte de JOBSTORY ;</li>
        <li>Si vous &ecirc;tes <strong>Candidat</strong> et que vous r&eacute;pondez &agrave; une offre d&rsquo;emploi, la Story que vous avez enregistr&eacute;e sp&eacute;cifiquement pour celle-ci est adress&eacute;e au Recruteur qui l&rsquo;a publi&eacute;e.</li>
      </ul>
      <p>A ce titre, nous appelons votre attention sur le fait qu&rsquo;en tant que destinataires des Donn&eacute;es des Candidats, les Utilisateurs sont susceptibles de devenir Responsable de nouveau(x) Traitement(s), avec les obligations que cela implique. Les Utilisateurs s&rsquo;engagent en cons&eacute;quence, en vertu des CGU de respecter des obligations &agrave; l&rsquo;&eacute;gard de JOBSTORY &agrave; ce titre.</p>
      <ul>
        <li>A d&rsquo;autres tiers&nbsp;si une telle communication est requise par la loi, une disposition r&eacute;glementaire ou une d&eacute;cision judiciaire ou si une telle communication s&rsquo;av&egrave;re raisonnablement n&eacute;cessaire pour respecter les proc&eacute;dures judiciaires, r&eacute;pondre &agrave; d&rsquo;&eacute;ventuelles r&eacute;clamations ou prot&eacute;ger la s&eacute;curit&eacute; de vos Donn&eacute;es ou de vos droits ou ceux de JOBSTORY.</li>
      </ul>
      <p>Nous pouvons partager des informations agr&eacute;g&eacute;es, pseudonymis&eacute;es, voire anonymis&eacute;es lesquelles ne permettent pas de vous identifier, dans le cadre d&rsquo;analyses statistiques de nos objectifs.</p>
      <p>&nbsp;</p>
      <ul>
        <li><strong>5.2. Transferts de donn&eacute;es hors de l&rsquo;Espace &eacute;conomique europ&eacute;enne (EEE)</strong></li>
      </ul>
      <p><em>&nbsp;</em></p>
      <p>Certains des Utilisateurs de l&rsquo;Application peuvent consulter vos Donn&eacute;es depuis des pays &eacute;tablis hors de l&rsquo;EEE, voire dans des pays n&rsquo;ayant pas fait l&rsquo;objet d&rsquo;une d&eacute;cision d&rsquo;ad&eacute;quation. Ce transfert est n&eacute;cessaire afin de fournir les services &eacute;nonc&eacute;s dans les CGU.</p>
      <p>&nbsp;</p>
      <ol start="6">
        <li><strong>6. DUREE DE CONSERVATION DES DONNEES </strong></li>
      </ol>
      <p>D&rsquo;un point de vue g&eacute;n&eacute;ral, JOBSTORY conserve vos Donn&eacute;es le temps de r&eacute;aliser la finalit&eacute; pour laquelle elles ont &eacute;t&eacute; collect&eacute;es, puis archiv&eacute;es pour la dur&eacute;e l&eacute;gale de prescription applicable, pour permettre &agrave; JOBSTORY de r&eacute;pondre &agrave; ses obligations l&eacute;gales, ou &agrave; des fins probatoires.</p>
      <ul>
        <li>Si vous &ecirc;tes Utilisateur : vos Donn&eacute;es sont conserv&eacute;es jusqu&rsquo;&agrave; la suppression de votre compte puis archiv&eacute;es &agrave; des fins probatoires pour la dur&eacute;e de prescription l&eacute;gale applicable.</li>
      </ul>
      <p>En l&rsquo;absence de connexion &agrave; votre compte utilisateur pendant une p&eacute;riode sup&eacute;rieure &agrave; trois ans, votre compte utilisateur sera automatiquement supprim&eacute; de l&rsquo;Application, apr&egrave;s que JOBSTORY vous ait adress&eacute; un avertissement.&nbsp;</p>
      <p>Vos Stories sont supprim&eacute;es 12 mois apr&egrave;s leur publication.</p>
      <ul>
        <li>Si vous &ecirc;tes une Personne concern&eacute;e ayant contact&eacute; JOBSTORY ou fait une demande d&rsquo;exercice d&rsquo;un droit en application de la pr&eacute;sente Politique : vos Donn&eacute;es sont conserv&eacute;es pour la dur&eacute;e n&eacute;cessaire au traitement de votre demande puis archiv&eacute;es &agrave; des fins probatoires pour la dur&eacute;e de prescription l&eacute;gale applicable</li>
        <li>Cookies : Cf. politique cookies ci-dessous.</li>
      </ul>
      <p>&nbsp;</p>
      <ol start="7">
        <li><strong>7. DROITS DONT VOUS DISPOSEZ SUR VOS DONNEES </strong></li>
      </ol>
      <p><strong><em>&nbsp;</em></strong></p>
      <p>Vous avez la possibilit&eacute; de corriger des Donn&eacute;es vous concernant ou de supprimer votre compte utilisateur directement en acc&eacute;dant aux param&egrave;tres de votre compte utilisateur (bien qu&rsquo;une partie des Donn&eacute;es puisse rester dans nos syst&egrave;mes, sans &ecirc;tre accessibles aux autres Utilisateurs, en fonction de la dur&eacute;e d&eacute;termin&eacute;e ci-dessus &ndash; <strong>6</strong>)</p>
      <p>Conform&eacute;ment &agrave; la R&egrave;glementation applicable en mati&egrave;re de protection des Donn&eacute;es et dans les conditions pr&eacute;vues par ladite R&egrave;glementation, vous disposez sur vos Donn&eacute;es&nbsp;:</p>
      <ul>
        <li>d&rsquo;<strong>acc&egrave;s</strong>(notamment pour savoir quelles donn&eacute;es sont trait&eacute;es par JOBSTORY)&nbsp;;</li>
        <li>de <strong>rectification</strong> (pour modifier une donn&eacute;e qui ne serait pas correcte ou plus &agrave; jour)&nbsp;;</li>
        <li>d&rsquo;<strong>effacement</strong> (lorsque vous r&eacute;pondez aux conditions pr&eacute;vues par la R&egrave;glementation et que vous souhaitez que JOBSTORY ne Traite plus toute ou partie de vos Donn&eacute;es)&nbsp;;</li>
        <li>de <strong>portabilit&eacute;</strong> (lorsque vous r&eacute;pondez aux conditions pr&eacute;vues par la R&egrave;glementation et que vous souhaitez r&eacute;cup&eacute;rer vos donn&eacute;es ou les faire r&eacute;cup&eacute;rer par un tiers)&nbsp;;</li>
        <li>de <strong>limitation</strong> (lorsque vous r&eacute;pondez aux conditions pr&eacute;vues par la R&egrave;glementation et que vous souhaiteriez que le Traitement de vos Donn&eacute;es soit suspendu en raison d&rsquo;un &eacute;v&eacute;nement vis&eacute; par ladite R&egrave;glementation)&nbsp;;</li>
        <li>d&rsquo;<strong>opposition</strong> pour des raisons tenant &agrave; votre situation particuli&egrave;re (lorsque vous r&eacute;pondez aux conditions pr&eacute;vues par la R&egrave;glementation et que vous ne souhaitez plus que vos Donn&eacute;es soient trait&eacute;es par JOBSTORY)&nbsp;;</li>
        <li>de <strong>d&eacute;finir des directives sur le sort de vos Donn&eacute;es apr&egrave;s votre d&eacute;c&egrave;s</strong> (lorsque vous r&eacute;pondez aux conditions pr&eacute;vues par la R&egrave;glementation et que vous souhaitez pr&eacute;d&eacute;terminer en amont ce qu&rsquo;il doit advenir de vos donn&eacute;es apr&egrave;s votre d&eacute;c&egrave;s).</li>
      </ul>
      <p>Dans certains cas, nous vous demanderons de justifier de votre identit&eacute; (copie de CNI ou passeport) notamment afin de nous assurer que vous &ecirc;tes bien la personne concern&eacute;e par les donn&eacute;es en cause. Votre pi&egrave;ce d&rsquo;identit&eacute; sera conserv&eacute;e pendant la dur&eacute;e n&eacute;cessaire au traitement de votre demande.</p>
      <p>Vous pouvez exercer ces droits en nous contactant par email (<span style={{ direction: "rtl", unicodeBidi: "bidi-override" }}>ppa.yrotsboj@tcatnoc</span>) ou par courrier, &agrave; l&rsquo;attention de [Data - JobSotry, 14 rue Mandar, 75002, Paris], &agrave; notre adresse ci-dessus</p>
      <p>&nbsp;</p>
      <p>Par ailleurs, vous avez &eacute;galement la possibilit&eacute; d&rsquo;introduire une r&eacute;clamation aupr&egrave;s de la CNIL:</p>
      <ul>
        <li><a rel="noopener noreferrer" target="_blank" href="https://www.cnil.fr/fr/plaintes">directement en ligne</a>;</li>
        <li>Ou par courrier&nbsp;: 3 Place Fontenoy &ndash; TSA 80715 &ndash; 75334 Paris Cedex 07.</li>
      </ul>
    </div>
  )
}

export default withTranslation()(Privacy);