import React, { Component } from 'react';

export default class ManagerCandidateHeader extends Component {
    render() {
        return (
          <div className="ml-5 mb-4 mt-4">
            <span className="text-xl font-bold">{this.props.label}</span>
            <span className="bg-purpleJS-100 ml-5 rounded-lg px-3 py-1 bg-opacity-25 text-purpleJS-100 font-bold">{this.props.value}</span>
          </div>
        )
    }
}
