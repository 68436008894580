import React from 'react';

import { withTranslation } from 'react-i18next'

import { API } from 'aws-amplify';

import ManagerLoading from './ManagerLoading';

import InputText from './template-fields/InputText';
import SelectField from './template-fields/Select';
import AddressInput from './template-fields/AddressInput';
import UploadImg from './template-fields/UploadImg';

import { IoAdd } from "react-icons/io5";

class ManagerShopEdit extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      dataSent: false,
      loading: true,
      manager_users: null,
      data: null,
      raw_data: {
        content: null,
        type: "shop"
      },
      errorFields: []
    }
    
    this.recruiters = [];
    this.config_data = null;
    this.entity_id = this.props.match.params.entity_id;
    
    // this.changeRouteCallback = this.props.changeRoute;
    
    this.gotoEntity = this.gotoEntity.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createUpdateTemplate = this.createUpdateTemplate.bind(this);
  }
  
  componentDidMount() {
      this.loadData();
  }
  
  async createUpdateTemplate() {
    
    var templateData = this.state.raw_data;
    templateData.content = this.state.data;
    
    var errors = [];
  
    if(templateData.content.shopname.trim() === "") errors.push('shopname');
    if(!templateData.content.address) errors.push('address');
    if(!templateData.content.recruiter) errors.push('recruiter');
    if(templateData.content.recruiter.name.trim() === "") errors.push('recruiter.name');
    if(templateData.content.recruiter.email.trim() === "") errors.push('recruiter.email');
    if(templateData.content.recruiter.picture.trim() === "") errors.push('recruiter.picture');

    if(templateData.content.recruiter_id) templateData.recruiter_id = templateData.content.recruiter_id;
    else {
        errors.push('recruiter_id');
        alert('Nouveau utilisateur pas encore dispo');
    }
    
    if(errors.length === 0) {
      // good on envoie
      this.setState({
        loading: true
      });
      
      if(!templateData.id) {
        await API.post('apis', '/apis/shops', { body: templateData});
      } else {
        await API.post('apis', '/apis/shops/' + templateData.id, { body: templateData});
      }
      
      this.gotoEntity();
      
    } else {
      this.setState({
        errorFields: errors
      });
    }
  }
  
  async loadData() {
    var templateData = await API.get('apis', '/apis/shops/' + this.entity_id);
    if(templateData) {
      this.dataLoaded(templateData);
    }
  }
  
  async gotoEntity() {
    this.props.history.push('/manager/shops');
  }
  
  async handleChange(config) {

    var new_state = {
      data: this.state.data
    }

    var sub_field = config.field.split('.');

    
    if(sub_field.length > 1) {
      if(!new_state.data[sub_field[0]]) new_state.data[sub_field[0]] = {};
      new_state.data[sub_field[0]][sub_field[1]] = config.value;
    } else {
      new_state.data[config.field] = config.value;
    }
    
    if(config.field === "recruiter_id") {
      var found_recruiter = this.recruiters.find((recrut) => recrut.sub === config.value);
      if(found_recruiter) {
        console.log(found_recruiter);
        new_state.data.recruiter = found_recruiter;
      } else {
        new_state.data.recruiter = {
          "name": "",
          "email": "",
          "picture": "avatar/icon_recruiter.png"
        };
      }
    }
    
    this.setState(new_state);
  }
  
  async dataLoaded(raw_data) {

    this.config_data = await API.get('apis', '/apis/config/FR');

    this.recruiters = raw_data.recruiters ? raw_data.recruiters : [];
    
    var manager_users = [
    ];
    
    for(var i = 0; i < this.recruiters.length; i++) {
      let manager = this.recruiters[i];
      manager_users.push({name: manager.sub, value: '<' + manager.name + '> ' + manager.email});
    }
    
    var data = null;
    
    if(raw_data.Item && raw_data.Item.type && raw_data.Item.type === "shop") {
      raw_data = raw_data.Item;
      data = raw_data.content;
    } else {
      data = {
        "shopname": "",
        "address": {},
        "recruiter_id": "",
        "recruiter": {
          "name": "",
          "email": "",
          "picture": "avatar/icon_recruiter.png"
        }
      };
      raw_data = {
        content: null,
        type: "shop"
      }
    }
        
    this.setState({
      loading: false,
      data: data,
      manager_users: manager_users,
      raw_data: raw_data
    });
  }
  
  render() {
    return (
      <div className="w-full">
        { this.state.loading ?
          <div className="w-full flex justify-center">
            <ManagerLoading />  
          </div>
        :
          <div className="w-full">
            <h3 className="mb-10 text-xl font-bold float-left">{this.props.t('manager_shop_name')}</h3>            
            <div className="w-full float-left p-5 bg-white rounded-lg">

              <div className="w-1/2 float-left">
                <div className="w-11/12">
                  <InputText value={this.state.data.shopname} handleChange={this.handleChange} datas={{field: "shopname", label: this.props.t('manager_shop_name_full_label'), placeholder: this.props.t('manager_shop_shopname_placeholder')}} />
                  <div className="w-full">
                    <label className="mt-5 w-full block text-lg font-medium">{this.props.t('manager_shop_raw_address_label')}</label>
                    <AddressInput value={this.state.data.address} handleChange={this.handleChange} datas={{field: "address", label: this.props.t('manager_shop_raw_address_label'), placeholder: this.props.t('manager_shop_raw_address_placeholder')}} />
                  </div>
                </div>
              </div>
              <div className="w-1/2 float-left">
              { this.state.manager_users.length > 0 ?
                <SelectField value={this.state.data.recruiter_id} handleChange={this.handleChange} 
                  datas={{field: "recruiter_id", label: this.props.t('manager_users_select_list_label')}} 
                  config={this.state.manager_users} 
                />
              : null }
                { !this.state.data.recruiter.profile ?
                <div className="w-full">
                  <label className="mt-5 w-full block text-lg font-medium">{this.props.t('manager_shop_infos_recruiter')}</label>
                  <InputText value={this.state.data.recruiter.name} handleChange={this.handleChange} datas={{field: "recruiter.name", label: this.props.t('manager_shop_account_name')}} />
                  <InputText value={this.state.data.recruiter.email} handleChange={this.handleChange} datas={{field: "recruiter.email", label: this.props.t('manager_shop_account_email')}} />
                  <UploadImg value={this.state.data.recruiter.picture} handleChange={this.handleChange} datas={{field: "recruiter.picture", label: this.props.t('manager_user_picture')}} />
                </div>
                : null }
              
              </div>
              { this.state.errorFields.length > 0 ?
                <div className="w-full mt-10 p-5 rounded-lg border border-red-500 bg-red-100 text-xs float-left">
                  {this.props.t('fill_all_required_fields')}
                </div>
                : null
              }
              <div className="w-full float-left mt-10">
                <h3 onClick={() => { this.createUpdateTemplate()}} className="bg-purpleJS-100 cursor-pointer float-right p-5 pt-3 pb-3 rounded-lg text-white">
                  <IoAdd className="float-left mr-3 text-2xl" />
                  {
                    ! this.state.raw_data.id ?
                      this.props.t('manager_shop_create_shop')
                    :
                      this.props.t('manager_shop_update_shop')
                  }
                </h3>
              </div>
              
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(ManagerShopEdit);


/*
        "tags": [],
        "start_date": new Date().toISOString(),
*/