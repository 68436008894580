import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import AuthConfig from './AuthConfig';
import JobCreationConfig from './JobCreationConfig';
import { Helmet } from 'react-helmet';
import Loading from './Loading';
import Error from './Error';
import JSButton from './JSButton';
import Step from './Step';
import PopError from './PopError';
import PopOk from './PopOk';

//main css generated with tailwindcss
import './../css/main.css';

//assets
import logoIMG from '../img/small-logo-purple.svg';
import illustrationIMG from '../img/js-illustration.jpg';
import checkmarkIMG from '../img/checkmark.svg';

let TITLE = 'JobStory - creation';

class JobCreation extends Component {

  // Then we add our constructor which receives our props
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: true,
      edition_token: this.props.match.params.edition_token,
      jobConfig: JobCreationConfig,
      config: null,
      datas: null,
      step: this.props.match.params.step ? (this.props.match.params.step - 1) : null,
      lastFilledStep: null,
      configReady: false,
      datasReady: false,
      ok_message: false,
      ko_message: false
    };

    this.apisEndpoint = AuthConfig.Common.API.endpoints.find((api) => { return api.name === 'apis' }).endpoint;

    this.selectStep.bind(this);
    this.finish.bind(this);
    this.readJobSteps.bind(this);
    this.renderSwitch.bind(this);
    this.tryRender.bind(this);
    this.closeError.bind(this);
  }

  selectStep = stp => {
    this.closeError();
    this.setState({ step: stp });
    // this.props.history.push("/creation/job/" + this.state.job_code + "/step/" + (step + 1));
    // window.location.reload(false);
  }
  closeError() {
    this.setState({ ko_message: false });
  }

  finish = () => {
    this.closeError();
    let ko_message = null;
    for (let i = 0; i < this.state.jobConfig.steps.length; i++) {
      for (let j = 0; j < this.state.jobConfig.steps[i].inputs.length; j++) {
        if (!(this.state.jobConfig.steps[i].inputs[j].field in this.state.datas) && (this.state.jobConfig.steps[i].inputs[j].required === true)) {
          if (ko_message === null)
            ko_message = this.props.t('fill_all_required_fields');
          // ko_message += '\n' + (i + 1) + ' : ' + this.props.t(this.state.jobConfig.steps[i].name);
        }
      }
    }
    if (ko_message !== null) {
      this.setState({ ko_message: ko_message });
      return;
    }

    this.setState({ loading: true });
    fetch(this.apisEndpoint + '/apis/draft-offer/' + this.state.edition_token, {
      method: 'post',
      body: JSON.stringify(this.state.datas),
      headers: {
        contentType: "application/json; charset=utf-8",
      }
    }).then(response => {
      return response.json()
    }).then(response => {
      if (response) {
        if (response.Item && response.Item.job_code) {
          this.setState({ loading: false, ok_message: this.props.t('jobcreation_published_offer') });

          window.setTimeout(() => {
            document.location.href = "/candidate/offer/" + response.Item.job_code;
          }, 500);

        } else {
          this.setState({ loading: false, ko_message: this.props.t('jobcreation_error_offer') });
        }
      }
    })
      .catch(error => {
        this.setState({ loading: false, ko_message: this.props.t('jobcreation_error_offer') });
      });
  }

  readJobSteps(job) {
    let newJobConfig = { ...this.state.jobConfig };
    let tmpCurrentStep = null;
    if (!job.pay_currency)
      job.pay_currency = "EUR";
    if (!job.pay_period)
      job.pay_period = "month";
    if (!job.questions)
      job.questions = [{ id: "q1", question: "" }, { id: "q2", question: "" }, { id: "q3", question: "" }];
    for (let i = 0; i < newJobConfig.steps.length; i++) {
      newJobConfig.steps[i].isFilled = true;
      for (let j = 0; j < newJobConfig.steps[i].inputs.length; j++) {
        if ((newJobConfig.steps[i].inputs[j].field in job) /*|| (newJobConfig.steps[i].inputs[j].required === false)*/) {
          // field is filled
          // pour les questions faire plus propre pour les 3 questions minimums TODO
          if (newJobConfig.steps[i].inputs[j].field === "questions" && (job['questions'].length < 3
            || job['questions'][0].question === ""
            || job['questions'][1].question === ""
            || job['questions'][2].question === ""))
            newJobConfig.steps[i].isFilled = false;
        } else {
          newJobConfig.steps[i].isFilled = false;
        }
      }
      if (newJobConfig.steps[i].isFilled)
        tmpCurrentStep = i;
    }
    if (tmpCurrentStep === newJobConfig.steps.length)
      tmpCurrentStep = null;
    this.setState({ loading: false, datas: job, jobConfig: newJobConfig, lastFilledStep: tmpCurrentStep });
  }

  renderSwitch = () => {
    if (this.state.step == null) {
      return <div className="h-full grid-cols-1 grid md:grid-cols-2">
        <div style={{ backgroundImage: "url(" + illustrationIMG + ")" }} className="bg-cover bg-left-top bg-no-repeat"></div>
        <div className="relative p-20">
          <h1 className="text-4xl font-bold">{this.props.t("jobcreation_welcomeback")}</h1>
          <h2 className="text-xl text-lightGrey font-medium">{this.props.t("jobcreation_finish_creation_sentence")}</h2>
          <div className="pt-8">
            {this.state.jobConfig.steps.map((step, i) =>
              // <div onClick={() => (step.isFilled ? this.selectStep(i) : 0)} key={i}>
              <div onClick={() => this.selectStep(i)} key={i}>
                <div style={step.isFilled ? { backgroundImage: "url(" + checkmarkIMG + ")" } : {}}
                  className={step.isFilled ? "cursor-pointer w-3/5 bg-right bg-no-repeat font-medium text-xl py-3" : "cursor-pointer font-medium text-lightGrey text-xl py-3"}>
                  {(i + 1) + ". " + this.props.t(step.name)}
                </div>
                {(this.state.lastFilledStep !== null && this.state.lastFilledStep + 1) === i || (this.state.lastFilledStep === null && i === 0) ? <JSButton text={i === 0 ? this.props.t('Commencer') : this.props.t('continue')} active={true} action="" /> : ""}
              </div>
            )}
          </div>
        </div>
      </div>
    } else {
      return <div className="flex flex-wrap h-full">
        <div className="relative p-10 w-1/4 bg-paleGreyJS">
          <img className="mt-10" alt="logo jobstory" src={logoIMG} />
          <div className="pt-8">
            {this.state.jobConfig.steps.map((step, i) =>
              <div onClick={() => this.selectStep(i)} key={i}>
                <div style={this.state.step === i ? { color: '#6800ff' } : !step.isFilled && this.state.ko_message ? { color: "red" } : {}}
                  className={step.isFilled ? "cursor-pointer bg-right bg-no-repeat font-medium text-xl py-3" : "cursor-pointer font-medium text-lightGrey text-xl py-3"}>
                  {(i + 1) + ". " + this.props.t(step.name)}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-3/4 p-20 flex items-center flex-wrap">
          <Step isLast={(this.state.step + 1) === this.state.jobConfig.steps.length}
            step={this.state.step}
            values={this.state.datas}
            btnAction={(this.state.step + 1) === this.state.jobConfig.steps.length ? this.finish.bind(this) : this.selectStep.bind(this)}
            action={this.readJobSteps.bind(this)}
            config={this.state.config}
            locked={this.state.ok_message !== false}
            datas={this.state.jobConfig.steps[this.state.step]} />
          {this.state.ko_message ?
            <div className="w-3/4">
              <br /><PopError className="" closeAction={(() => this.closeError.bind(this))} text={this.state.ko_message} /></div>
            : ''}
          {this.state.ok_message ?
            <div className="w-3/4">
              <br /><PopOk className="" text={this.state.ok_message} /></div>
            : ''}
        </div>
      </div>
    }
  };

  tryRender() {
    if (this.state.configReady && this.state.datasReady) {
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    let endPoint = this.apisEndpoint + '/apis/draft-offer/' + this.state.edition_token;
    let json = null;
    try {
      let response = await fetch(endPoint, { method: 'get' });
      json = await response.json();
      if (!json.error && json.offer_json) {
        this.readJobSteps(json.offer_json);
      } else if (json.error) {
        TITLE = "JobStory - " + this.props.t("offer_not_found");
        this.setState({
          error: json.error_message
        });
      } else {
        TITLE = "JobStory - " + this.props.t("offer_not_found");
        this.setState({
          error: this.props.t("offer_not_found")
        });
      }
      this.setState({
        datasReady: true
      });
      this.tryRender();
    } catch (error) {
      this.setState({
        error: this.props.t("offer_not_found")
      });
    }

    const offerLang = json?.offer_json?.lang || 'FR';
    let configEndPoint = this.apisEndpoint + '/apis/config/' + offerLang;
    try {
      let response = await fetch(configEndPoint, { method: 'get' });
      let json = await response.json();
      if (json.job_cat) {
        this.setState({
          config: json
        });
      } else {
        TITLE = "JobStory - Config Error";
        this.setState({
          error: "Error while loading offer config"
        });
      }
      this.setState({
        configReady: true
      });
      this.tryRender();
    } catch (error) {
      this.setState({
        error: error
      });
    }
  }

  render() {

    return (
      <div className="container h-full w-full max-w-none max-h-screen">
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        { this.state.error ?
          <Error text={this.state.error} />
          :
          this.state.loading ?
            <Loading />
            :
            this.renderSwitch()
        }
      </div>
    );
  }
}

export default withTranslation()(JobCreation);