import React from 'react';

import { withTranslation } from 'react-i18next';

import Header from './Header'; // Import a component from another file
import Footer from './Footer'; // Import a component from another file
import Loading from './Loading'; // Import a component from another file
import errorIMG from '../img/error.svg';

import { setLanguage } from './controllers/Language';
import AuthConfig from './AuthConfig';

import { IoCheckmarkCircleSharp  } from "react-icons/io5";

      
class Unsubscribe extends React.Component {
    constructor(props) {
      super(props);
            
      var search = this.props.location.search; // could be '?foo=bar'
      var params = new URLSearchParams(search);
      var lang = params.get('lang'); // bar
      
      if(lang) {
        setLanguage(lang);
      }

      this.token = this.props.match.params.token;
      this.topic = this.props.match.params.topic;

      this.apisEndpoint = AuthConfig.Common.API.endpoints.find((api) => { return api.name === 'apis' }).endpoint;
      
      this.state = {
        unsubscribed: false
      };
      
      this.updateTopicSubscription = this.updateTopicSubscription.bind(this);
    }

    componentDidMount() {
      this.updateTopicSubscription(this.topic);
    }
        
    updateTopicSubscription(topic) {
      fetch(`${this.apisEndpoint}/apis/notifications/subscriptions`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token: this.token,
          topics: [ {topic, value: false} ],
        })
      }).then(response => {
        return response.json()
      }).then(response => {
        this.setState({
          unsubscribed: true
        });
      })
      .catch(error => {
          this.setState({
            error: error.message
          });
      });
    }
    
    render() {
      return (
        <div className="container w-full max-w-none">
          <Header location={this.props.location} />
            { this.state.error ?
              <div className="container w-full max-w-none p-5 md:p-20">
                <div className="w-1/4 mx-auto mt-40 mb-40">
                    <div style={{ backgroundColor: "#f57e79" }} className="p-5 flex text-center items-center">
                      <img className="mx-auto w-6" alt="error" src={errorIMG} />
                    </div>
                    <div className="text-left py-2 pl-3 pr-10">
                        <p className="text-md text-black font-medium mt-3">{this.state.error}</p>
                    </div>
                </div>
              </div>
            : this.state.unsubscribed ? (
              <div className="container w-full max-w-none p-5 md:p-20">
                <div className="w-1/4 mx-auto mt-40 mb-40">
                  <h3><IoCheckmarkCircleSharp className="float-left mr-1 mt-1" />{this.props.t('unsubscribe_topic_notification_success')}</h3>
                </div>
              </div>
            ) : (
              <Loading className="container w-full max-w-none p-5 md:p-20" />
            )
          }
          <Footer />
        </div>
      )
    }
}

export default withTranslation()(Unsubscribe);