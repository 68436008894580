import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Loading from '../../Loading';
import AuthConfig from '../../AuthConfig'
import PlacesAutocomplete, {
    geocodeByAddress,
} from 'react-places-autocomplete';
import geolocationIMG from '../../../img/geolocation.svg';


class AddressInput extends Component {

    constructor(props) {
        super(props);
        let tmpVal = '';
        if(this.props.value.formatted_address) {
            tmpVal = this.props.value.formatted_address;
        } else if (this.props.value.lat) {
            tmpVal = this.props.value.street + ' ' + this.props.value.city + ' ' + this.props.value.zip_code + ' ' + this.props.value.country
        }
        this.state = {
            value: tmpVal,
            scriptReady: false
        }
    }

    handleChange = (address) => {
        this.setState({ value: address });
    }

    handleSelect = addr => {
        geocodeByAddress(addr)
            .then(results => {
                const result = results[0];

                const formatted_address = result.formatted_address;
                const address_components = result.address_components;

                const lat = result.geometry.location.lat();
                const lng = result.geometry.location.lng();

                // const street_number = this.getAddressComponents(address_components, "street_number");
                const route = this.getAddressComponents(address_components, "route");
                const locality = this.getAddressComponents(address_components, "locality");
                const postal_code = this.getAddressComponents(address_components, "postal_code");
                const administrative_area_level_1 = this.getAddressComponents(address_components, "administrative_area_level_1");
                const administrative_area_level_2 = this.getAddressComponents(address_components, "administrative_area_level_2");
                const country = this.getAddressComponents(address_components, "country");

                let addrToInsert = {
                    lat, 
                    lng, 
                    formatted_address,
                    street: route ? route.long_name : '', 
                    city: locality ? locality.long_name :'', 
                    zip_code: postal_code ? postal_code.long_name : '', 
                    area: administrative_area_level_1 ? administrative_area_level_1.long_name : '', 
                    sub_area: administrative_area_level_2 ? administrative_area_level_2.long_name : '', 
                    country: country ? country.long_name : '', 
                    country_iso: country ? country.short_name : '',
                };

                this.props.handleChange({
                    field: this.props.datas.field,
                    value: addrToInsert
                });

                this.setState({ value: formatted_address });
            })
            .catch(error => console.error('Error', error));
    };

    getAddressComponents(address_components, attr) {
        return address_components.find((item) => {
            return (item.types.indexOf(attr) >= 0);
        });
    }

    componentDidMount() {
        if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
            this.setState({ scriptReady: true });
        } else {
            let that = this;
            let gmapscript = window.document.createElement("script");
            gmapscript.src = AuthConfig.Common.API.endpoints.find((api) => { return api.name === 'googlemap' }).endpoint;
            gmapscript.type = "text/javascript";
            gmapscript.addEventListener("load", function () {
                that.setState({ scriptReady: true });
            });
            window.document.body.appendChild(gmapscript);
        }
    }

    render() {
        return (
            <div className="w-full">
                {
                    !this.state.scriptReady ?
                        <Loading />
                        :
                        <PlacesAutocomplete
                            value={this.state.value}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: this.props.t(this.props.datas.placeholder),
                                            style: { backgroundImage: "url(" + geolocationIMG + ")", backgroundPosition: "0.5rem center" },
                                            className: 'mt-5 pl-10 bg-no-repeat px-5 py-3 placeholder-greyBorderJS ' +
                                                'font-greyBorderJS font-regular font-sans border-solid rounded-md border ' +
                                                'border-greyBorderJS w-full block',
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <Loading />}
                                        {suggestions.map((suggestion, i) => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active pl-10 bg-no-repeat px-5 py-3 placeholder-greyBorderJS ' +
                                                'font-greyBorderJS font-regular font-sans border-solid rounded-md border ' +
                                                'border-greyBorderJS w-full block'
                                                : 'suggestion-item pl-10 bg-no-repeat px-5 py-3 placeholder-greyBorderJS ' +
                                                'font-greyBorderJS font-regular font-sans border-solid rounded-md border ' +
                                                'border-greyBorderJS w-full block';
                                            // inline style for demonstration purpose
                                            const style = { backgroundImage: "url(" + geolocationIMG + ")", cursor: 'pointer', backgroundPosition: "0.5rem center" };
                                            return (
                                                <div key={i + "_sugg"}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                }
            </div>
        );
    }
}

export default withTranslation()(AddressInput);
