import React from 'react';
import { withTranslation } from 'react-i18next';
import Error from './Error';
import MetricWidget from './MetricWidget';
                
class DashboardManager extends React.Component {
    constructor(props) {
      super(props);

      this.ref_candidates = props.candidates;
      this.ref_user = props.user;
      this.ref_offers = props.offers;
      this.ref_recruiters = props.recruiters;

      var state = {
        user: props.user,
        error: false,
        error_msg: "",
        recruiters: [],
        offers: [],
        stats: {}
      };
      
      
      if(!state.user) {
        state.error = true;
        state.error_msg = "Vous n'avez pas accès à cette partie du site.";
      }
      
      
      this.state = state;
    
    }
        
    componentDidUpdate(prevProps, prevState) {

      var needUpdate = false;
      
      if(this.props.candidates !== prevProps.candidates) {
        this.ref_candidates = this.props.candidates;
        needUpdate = true;
      }
      
      if(this.props.user !== prevProps.user) {
        this.ref_user = this.props.user;
        needUpdate = true;
      }
      
      if(this.props.offers !== prevProps.offers) {
        this.ref_offers = this.props.offers;
        needUpdate = true;
      }
      
      if(this.props.recruiters !== prevProps.recruiters) {
        this.ref_recruiters = this.props.recruiters;
        needUpdate = true;
      }
      
      if(needUpdate) {
        this.updateComp();
      }
    }
    
    componentDidMount() {
      // console.log('componentDidMount');
      this.updateComp();
    }
    
    updateComp() {
      var newState = {};
      
      newState.stats = {
        wishlist: 0,
        new_candidates: 0,
        candidates: 0,
        rejects: 0,
        shortlist: 0,
      };
      
      var recruitersArray = JSON.parse(JSON.stringify(this.ref_recruiters));
      for(let i = 0; i < recruitersArray.length; i++) {
        recruitersArray[i].shortlist_counter = 0;
      }
      
      var offersArray = JSON.parse(JSON.stringify(this.ref_offers));
      for(let i = 0; i < offersArray.length; i++) {
        offersArray[i].apply_counter = 0;
      }
      
      
      for(let i = 0; i < this.ref_candidates.length; i++) {
        var cand = this.ref_candidates[i];
        if(cand.recruiter_state === "shortlist") {
          newState.stats.shortlist++;
          
          let j = 0;
          while(recruitersArray[j] && recruitersArray[j].sub !== cand.recruiter_id) j++;
          
          if(recruitersArray[j]) {
            recruitersArray[j].shortlist_counter++;
          }
          
        } else if(cand.recruiter_state === "wait_reject" || cand.recruiter_state === "rejected" ) {
          newState.stats.rejects++;
        } else if(cand.recruiter_state === "new" || cand.recruiter_state === "view") {
          newState.stats.new_candidates++;
        }
        
        newState.stats.candidates++;

        let j = 0;
        while(offersArray[j] && offersArray[j].job_code !== cand.job_code) j++;
        
        if(offersArray[j]) {
          offersArray[j].apply_counter++;
        }

      }
      
      recruitersArray.sort((a,b) => (a.shortlist_counter < b.shortlist_counter) ? 1 : ((b.shortlist_counter < a.shortlist_counter) ? -1 : 0))
      newState.recruiters = recruitersArray;
      
      offersArray.sort((a,b) => (a.apply_counter < b.apply_counter) ? 1 : ((b.apply_counter < a.apply_counter) ? -1 : 0))
      newState.offers = offersArray;

      this.setState(newState);
    }
    
    // The render function, where we actually tell the browser what it should show
    render() {  
        return (
          <div className="w-full">
          { this.state.error ?
              <Error text={this.state.error_msg} />
            :
              <div>
                <div className="w-full float-left">
                  <MetricWidget label="Total candidatures" value={this.state.stats.candidates} />
                  <MetricWidget label="Rejetés" value={this.state.stats.rejects} />
                  <MetricWidget label="Shortlist" value={this.state.stats.shortlist} />
                  <MetricWidget label="Non traités" value={this.state.stats.new_candidates} />
                </div>
                <div className="w-full mt-10 float-left">
                
                  <div className="w-full md:w-1/3 bg-white border-2 rounded-lg float-left mr-8">
                    <div className="ml-5 mt-5 text-2xl font-bold text-purpleJS-100">{this.state.offers.length}</div>
                    <div className="ml-5 mb-5 text-gray-500">Annonces</div>
                    <hr />
                    
                    <div className="w-full max-h-screen overflow-auto">
                    {this.state.offers.map((offer, index) => (
                        <div key={offer.job_code} className="w-full float-left p-3 px-5 pb-0">
                          <div className="float-left w-2/3">
                            <a className="font-bold text-purpleJS-100" target="_blank" rel="noopener noreferrer" href={'/candidate/offer/' + offer.job_code}>{offer.title}</a>
                            <p className="text-gray-500">{offer.city} {offer.zip_code}</p>
                            <p className="text-xs text-gray-500">{offer.recruiter_details ? offer.recruiter_details.name : 'not found'}</p>
                          </div>
                          <div className="w-1/3 float-right text-right">
                            <div className="text-lg font-bold">{offer.apply_counter}</div>
                            <div className="text-gray-500">Candidatures</div>
                          </div>
                          <hr className="w-full float-left mt-3 mb-0" />
                        </div>
                    ))}
                    </div>
                    
                  </div>
                  
                  <div className="w-full md:w-1/3 mt-10 md:mt-0 bg-white border-2 rounded-lg float-left mr-8">
                    <div className="ml-5 mt-5 text-2xl font-bold text-purpleJS-100">{this.state.recruiters.length}</div>
                    <div className="ml-5 mb-5 text-gray-500">Recruteurs</div>
                    <hr className="mb-5" />
                    
                    <div className="w-full max-h-screen overflow-auto">
                    {this.state.recruiters.map((recruiter, index) => (
                        <div key={recruiter.sub} className="w-full float-left p-3 px-5 pb-0">
                          <div className="float-left">
                            <div className="font-bold">{recruiter.name}</div>
                            <div className="text-gray-500">{recruiter.email}</div>
                          </div>
                          <div className="w-1/3 float-right text-right">
                            <div className="text-lg font-bold">{recruiter.shortlist_counter}</div>
                            <div className="text-gray-500">Shortlist</div>
                          </div>
                          <hr className="w-full float-left mt-3 mb-0" />
                        </div>
                    ))}
                    </div>
                  </div>
                  
                </div>
              </div>
          }
          </div>
        )
    }
}

export default withTranslation()(DashboardManager);