var JobConfig = {
    "steps": [
        {
            "isFilled": false,
            "name": "jobcreation_job_title",
            "title": "jobcreation_enter_job_offer_title",
            "inputs": [{ "field": "title", "required": true, "type": "input", "label": "jobcreation_job_title", "placeholder": "jobcreation_best_job" }]
        },
        {
            "isFilled": false,
            "name": "jobcreation_company_infos",
            "title": "jobcreation_about_company",
            "inputs": [
                { "required": false, "field": "company_picture", "type": "upload", "label": "jobcreation_company_logo", "placeholder": "" },
                { "required": true, "field": "company_name", "type": "input", "label": "jobcreation_company_name", "placeholder": "jobcreation_company_name" },
                { "required": true, "field": "company_description", "type": "textarea", "label": "jobcreation_company_desc", "placeholder": "jobcreation_company_desc_candidates" }
            ]
        },
        {
            "isFilled": false,
            "name": "jobcreation_job_desc",
            "title": "jobcreation_describe_offer",
            "inputs": [{ "field": "description", "required": true, "type": "textarea", "label": "jobcreation_describe_offer", "placeholder": "jobcreation_describe_offer_text" }]
        },
        {
            "isFilled": false,
            "name": "jobcreation_job_location",
            "title": "jobcreation_job_location",
            "inputs": [
                { "required": true, "field": "lat", "type": "addressInput", "label": "jobcreation_job_address", "placeholder": "jobcreation_job_address_text" }
            ]
        },
        {
            "isFilled": false,
            "name": "jobcreation_contract_type",
            "title": "jobcreation_contract_type",
            "inputs": [
                {
                    "required": true,
                    "field": "contract_type",
                    "type": "radio",
                    "choices": [
                        { "name": "", "id": "" }
                    ],
                    "label": "", "placeholder": ""
                }
            ]
        },
        {
            "isFilled": false,
            "name": "jobcreation_job_cat",
            "title": "jobcreation_job_cat",
            "inputs": [
                {
                    "required": false,
                    "field": "tags",
                    "type": "checkbox",
                    "choices": [
                        { "name": "", "id": "" }
                    ],
                    "label": "jobcreation_choose_job_cat", "placeholder": ""
                }
            ]
        },
        {
            "isFilled": false,
            "name": "jobcreation_start_date",
            "title": "jobcreation_choose_start_date",
            "inputs": [
                { "required": true, "field": "start_date", "type": "dateInput", "label": "jobcreation_start_date", "placeholder": "" }
            ]
        },
        {
            "isFilled": false,
            "name": "jobcreation_mini_exp",
            "title": "jobcreation_candidate_exp",
            "inputs": [
                {
                    "required": true,
                    "field": "candidate_experience",
                    "type": "radio",
                    "choices": [
                        { "name": "", "value": "" }
                    ],
                    "label": "jobcreation_mini_candidate_exp_text", "placeholder": ""
                }
            ]
        },
        {
            "isFilled": false,
            "name": "jobcreation_study_level",
            "title": "jobcreation_study_level",
            "inputs": [
                {
                    "required": true,
                    "field": "candidate_graduation",
                    "type": "radio",
                    "choices": [{ "name": "", "value": "" }],
                    "label": "jobcreation_candidate_study_level_text", "placeholder": ""
                }
            ]
        },
        {
            "isFilled": false,
            "name": "jobcreation_qualifications",
            "title": "jobcreation_candidate_qualifications",
            "inputs": [
                { "field": "candidate_qualification", "required": true, "type": "textarea", "label": "jobcreation_candidate_qualifications_text", "placeholder": "jobcreation_candidate_qualifications" }
            ]
        },
        {
            "isFilled": false,
            "name": "jobcreation_salary",
            "title": "jobcreation_salary_rate",
            "inputs": [
                { "field": "pay_min", "required": true, "type": "numberInput", "label": "jobcreation_salary_from", "placeholder": "1000" },
                { "field": "pay_max", "required": true, "type": "numberInput", "label": "jobcreation_salary_to", "placeholder": "10000" },
                {
                    "field": "pay_currency", "required": true, "type": "select", "label": "jobcreation_salary_currencie", "placeholder": "",
                    "choices": []
                },
                {
                    "field": "pay_period", "required": true, "type": "select", "label": "jobcreation_salary_per", "placeholder": "",
                    "choices": []
                }
            ]
        },
        {
            "isFilled": false,
            "name": "jobcreation_candidate_questions",
            "title": "jobcreation_candidate_questions",
            "inputs": [
                { "field": "questions", "required": true, "type": "questionsInput", "label": "jobcreation_candidate_questions_text", "placeholder": "" }
            ]
        }
    ]
}
export default JobConfig;