import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Listing extends Component {

    constructor(props) {
        super(props);

        this.choices = this.props.config;

        this.handleChange.bind(this);
        this.isChecked.bind(this);
    }

    handleChange = (event) => {
      var newValue = this.props.value;
      
      if(event.target.checked) {
        newValue.push(event.target.value);
      } else {
        const index = newValue.indexOf(event.target.value);
        if (index > -1) {
          newValue.splice(index, 1); // 2nd parameter means remove one item only
        }
      }

      this.props.handleChange({
          field: this.props.datas.field,
          value: newValue
      });
    }
    
    isChecked(item) {
      return this.props.value.includes(item) ? " checked-item" : " not-checked-item";
    }
    
    render() {
        return (
            <div className="w-full">
                <label className="mt-5 w-full block text-lg font-medium">{this.props.t(this.props.datas.label)}</label>
                {this.choices.map((choice, i) => (
                  <div className="mt-3 w-full float-left" key={choice.name}>
                    <input className="ml-3 mr-3 mt-1 float-left" value={choice.name} type="checkbox" id={choice.name} onChange={this.handleChange} />
                    <label className={"float-left" + this.isChecked(choice.name)} htmlFor={choice.name}>{choice.value}</label>
                  </div>
                ))}
            </div>
        )
    }
}

export default withTranslation()(Listing);