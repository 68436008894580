import React, { Component } from 'react';

import AuthConfig from './AuthConfig';
import Miniature from './Miniature';

import { IoSync, IoMicOff, IoMic, IoVideocamOff, IoVideocam } from 'react-icons/io5';


import './Meeting.css';

import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  MeetingSessionStatusCode,
  LogLevel,
  DefaultModality,
  MeetingSessionConfiguration
} from 'amazon-chime-sdk-js';

class Meeting extends Component {
    // Then we add our constructor which receives our props
    constructor(props) {
      super(props);
      
      this.apisEndpoint = AuthConfig.Common.API.endpoints.find((api) => { return api.name === 'apis' }).endpoint;
      
      this.recruiter_id = this.props.match.params.recruiter_id;
      this.candidate_id = this.props.match.params.candidate_id;
      this.current_user_id = this.props.match.params.current_user_id;
      
      this.currentVideoInput = 0;
      this.videoInputDevices = [];
      this.meetingSession = null;
      
      this.lastActivity = 0;
      
      this.state = {
        loading: true,
        error: false,
        ko_message: false,
        isMuted: true,
        isCamDisabled: true,
        showHUD: true,
        showButtons: false,
        multipleVideoDevices: false,
        other_user: {
          show: false,
          thumb: "avatar/icon_recruiter.png",
          state: "",
          name: "",
        }
      };
      
      this.closeError.bind(this);
      this.toggleMuted.bind(this);
      this.toggleCameraDisabled.bind(this);
      this.toggleVideoInput.bind(this);
      this.isActive.bind(this);
      
      this.activityInterval = null;
      
      this.isActive();
    }
    
    isActive() {
      this.lastActivity = new Date().getTime()/1000;
      this.setState({ showHUD: true });
      
      if ( this.activityInterval !== null) {
        window.clearInterval(this.activityInterval);
      }
      
      this.activityInterval = window.setInterval(() => {
        this.setState({
          showHUD: false
        });
      }, 5000);
      
    }
    
    closeError() {
      this.setState({ ko_message: false });
    }
    
    componentDidMount() {
      this.isActive();
      
      fetch(this.apisEndpoint + '/apis/meeting-flight/' + this.recruiter_id + '/' + this.candidate_id + '/' + this.current_user_id, {
        method: 'get',
        headers: {
          contentType: "application/json; charset=utf-8",
        }
      }).then(response => {
        return response.json()
      }).then(response => {
        if (response) {          
          if ( ! response.error && response.Meeting && response.Attendee) {    
            var newState = { loading: false };
            
            if(response.otherProfile) {
              newState.other_user = this.state.other_user;
              
              newState.other_user.show = true;
              newState.other_user.thumb = response.otherProfile.picture;
              newState.other_user.name = response.otherProfile.name;
              newState.other_user.state = 0;
            }
          
            this.setState(newState);
            this.createRoom(response);
          } else {
            var ko_msg = "An error occured";
            
            if( response.error.message ) {
              ko_msg = response.error.message;
            }
            
            this.setState({ loading: false, ko_message: ko_msg });
          }
        }
      })
      .catch(error => {
        this.setState({ loading: false, ko_message: error.message });
      });
    }
    
    async createRoom(response) {
        
        this.isActive();
      
        var statesUpdate = {};
        
        
        const configuration = new MeetingSessionConfiguration(response.Meeting, response.Attendee);
        const logger = new ConsoleLogger('MyLogger', LogLevel.ERROR);
        const deviceController = new DefaultDeviceController(logger);
      
        // In the usage examples below, you will use this meetingSession object.
        const meetingSession = new DefaultMeetingSession(
          configuration,
          logger,
          deviceController
        );
        
        const audioElement = document.getElementById('meeting-my-audio');
        const myVideoElement = document.getElementById('meeting-my-video');
        const otherVideoElement = document.getElementById('meeting-other-video');

        // Make sure you have chosen your camera. In this use case, you will choose the first device.
        const videoInputDevices = await meetingSession.audioVideo.listVideoInputDevices();
        const audioInputDevices = await meetingSession.audioVideo.listAudioInputDevices();

        if(videoInputDevices.length>1) {
          statesUpdate.multipleVideoDevices = true;
        } else {
          statesUpdate.multipleVideoDevices = false;
        }

        // The camera LED light will turn on indicating that it is now capturing.
        // See the "Device" section for details.        
        await meetingSession.audioVideo.chooseVideoInputDevice(videoInputDevices[this.currentVideoInput].deviceId);
        await meetingSession.audioVideo.chooseAudioInputDevice(audioInputDevices[0].deviceId);
        
        audioElement.controls = true;
        
        const observer = {
          audioVideoDidStop: sessionStatus => {
            console.log('sessionStatus changed: ', sessionStatus);
            const sessionStatusCode = sessionStatus.statusCode();
            if (sessionStatusCode === MeetingSessionStatusCode.AudioCallEnded) {
              /*
                - You (or someone else) have called the DeleteMeeting API action in your server application.
                - No audio connections are present in the meeting for more than five minutes.
                - Fewer than two audio connections are present in the meeting for more than 30 minutes.
                - Screen share viewer connections are inactive for more than 30 minutes.
                - The meeting time exceeds 24 hours.
                See https://docs.aws.amazon.com/chime/latest/dg/mtgs-sdk-mtgs.html for details.
              */
              console.log('The session has ended');
            } else {
              console.log('Stopped with a session status code: ', sessionStatusCode);
            }
          },
          // videoTileDidUpdate is called whenever a new tile is created or tileState changes.
          videoTileDidUpdate: tileState => {
            // Ignore a tile without attendee ID and other attendee's tile.
            
            if (!tileState.boundAttendeeId) {
              return;
            }

            console.log(tileState);
            
            var stateUpdate = {};
            
            if (new DefaultModality(tileState.boundAttendeeId).base() !== meetingSession.configuration.credentials.attendeeId) {
              stateUpdate.other_user = this.state.other_user;
              meetingSession.audioVideo.bindVideoElement(tileState.tileId, otherVideoElement);
            } else {
              console.log('Binding my video');
              
              meetingSession.audioVideo.bindVideoElement(tileState.tileId, myVideoElement);
              stateUpdate.isCamDisabled = !tileState.active;
            }
            
            this.setState(stateUpdate);
          },
          eventDidReceive(name, attributes) {
            // Handle a meeting event.
            // console.log(name);
          }
        };

        meetingSession.audioVideo.bindAudioElement(audioElement);
        
        const muted = meetingSession.audioVideo.realtimeIsLocalAudioMuted();
        if (muted) {
          console.log('You are muted');
          statesUpdate.isMuted = true;
        } else {
          statesUpdate.isMuted = false;
          console.log('Other attendees can hear your audio');
        }


        meetingSession.audioVideo.addObserver(observer);
        meetingSession.audioVideo.start();
        meetingSession.audioVideo.startLocalVideoTile();
        
        
        // This is your attendee ID. You can also subscribe to another attendee's ID.
        // See the "Attendees" section for an example on how to retrieve other attendee IDs
        // in a session.
        const presentAttendeeId = meetingSession.configuration.credentials.attendeeId;
        
        // Ca c'est l'utilisateur courrant
        meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(
          presentAttendeeId,
          (attendeeId, volume, muted, signalStrength) => {

            if ( muted === null) {
              return;
            }
            
            // mute state changed
            console.log(`${attendeeId}'s mute state changed: `, {
              muted, // a boolean
            });
            
            this.setState({
              isMuted: muted
            });
          }
        );

        meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence((eventAttendeeId, present) => {
          console.log(`Attendee ID: ${eventAttendeeId} Present: ${present}`);
          if (eventAttendeeId !== presentAttendeeId) {
            console.log('State of other user');
            
            var other_state = this.state.other_user;
            
            if (present) {
              other_state.state = 1;
            } else {
              other_state.state = 0;
            }
            
            this.setState({
              other_user: other_state
            });
          }
        });
        
        
        this.meetingSession = meetingSession;
        this.videoInputDevices = videoInputDevices;
        
        statesUpdate.showButtons = true;
        
        this.setState(statesUpdate);
    }
    
    toggleMuted() {  
      if(this.state.isMuted) {
        this.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
      } else {
        this.meetingSession.audioVideo.realtimeMuteLocalAudio();        
      }
    }
    
    async toggleCameraDisabled() {      
      if(this.state.isCamDisabled) {
        // enable video input
        var currentVideoDevice = this.videoInputDevices[this.currentVideoInput];
        
        var deviceId = currentVideoDevice.deviceId ? currentVideoDevice.deviceId : null;
        
        console.log("trying to add: ", deviceId);
        
        await this.meetingSession.audioVideo.chooseVideoInputDevice(deviceId);
        this.meetingSession.audioVideo.startLocalVideoTile();
        
      } else {
        // disable video input
        this.meetingSession.audioVideo.chooseVideoInputDevice(null); 
      }
    }
    
    async toggleVideoInput() {      
      this.currentVideoInput = this.currentVideoInput + 1;
      if(this.currentVideoInput > this.videoInputDevices.length-1) this.currentVideoInput = 0;
      
      var currentVideoDevice = this.videoInputDevices[this.currentVideoInput];
      var deviceId = currentVideoDevice.deviceId ? currentVideoDevice.deviceId : null;

      await this.meetingSession.audioVideo.chooseVideoInputDevice(deviceId);
      this.meetingSession.audioVideo.startLocalVideoTile();
    }
    
    componentWillUnmount() {
      // destruction de la room

      if ( this.activityInterval !== null) {
        window.clearInterval(this.activityInterval);
        this.activityInterval = null;
      }
    }
    
    render() {
        return (
          <div onClick={() => { this.isActive(); }} onMouseMove={() => { this.isActive(); }} className="w-full h-full relative bg-black overflow-hidden global-meeting-frame">
            { this.state.ko_message ?
              <div className="w-full text-center mt-10">
                <h4 className="text-black font-bold">{this.state.ko_message}</h4>
              </div>
            :
              <div className="w-full h-full relative">
                <video id="meeting-other-video" className="w-full h-full relative bg-red"></video>
                <video id="meeting-my-video" className=
                  { this.state.showHUD ? 
                    "w-1/4 absolute bg-blue shadow-lg rounded-lg"
                  :
                    "w-1/4 absolute bg-blue shadow-lg rounded-lg meeting-hud-hidden"
                  }
                ></video>
                <audio id="meeting-my-audio"></audio>

                { this.state.showHUD ? 
                  <div>
                    { this.state.other_user.show ? 
                      <div className="absolute inset-x-0 top-0">
                        <div className="mt-2 ml-3 w-full flex">
                          <Miniature className="w-16 h-16 rounded-full bg-white shadow" username={this.state.other_user.name} 
                            width="100" height="100" picture={this.state.other_user.thumb} />
                            <div className="text-left ml-5 mt-2">
                              <div className="mt-0 font-semibold">{this.state.other_user.name}</div>
                              <div className="mt-0">{ this.state.other_user.state === 1 ?
                                <div>connected</div>
                                :
                                <div>disconnected</div>
                              }</div>
                            </div>
                        </div>
                      </div>
                    : null }
                    
                    { this.state.showButtons ?
                      <div className="absolute inset-x-0 bottom-0">
                        <div className="mb-5 w-full flex justify-center">
                          <div onClick={() => { this.toggleCameraDisabled(); }} className="w-16 h-16 rounded-full bg-white shadow flex justify-center cursor-pointer">
                            { this.state.isCamDisabled ? 
                              <IoVideocam className="w-8 h-8 mt-4 text-black" />
                            : 
                              <IoVideocamOff className="w-8 h-8 mt-4 text-black" />
                            }
                          </div>
                          <div onClick={() => { this.toggleMuted(); }} className="w-16 h-16 rounded-full bg-white shadow flex justify-center cursor-pointer ml-8">
                            {this.state.isMuted ?
                              <IoMic className="w-8 h-8 mt-4 text-black" />
                            : 
                              <IoMicOff className="w-8 h-8 mt-4 text-black" />
                            }
                          </div>
                          { this.state.multipleVideoDevices ?
                            <div onClick={() => { this.toggleVideoInput(); }} className="w-16 h-16 rounded-full bg-white shadow flex justify-center cursor-pointer ml-8">
                              <IoSync className="w-8 h-8 mt-4 text-black" />
                            </div>
                          : null } 
                        </div>
                      </div>
                    : null }
                  </div>
                : null }
              </div>
            }
          </div>
        );
    }
}

export default Meeting;