import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import previousIMG from '../img/previous.svg';
import nextIMG from '../img/next.svg';
import playIMG from '../img/play.svg';

//import 'videojs-contrib-hls/dist/videojs-contrib-hls.js';
// Workaround for webworkify not working with webpack
window.videojs = videojs;
require('videojs-contrib-hls/dist/videojs-contrib-hls.js');

class VideoStories extends Component {
    // Then we add our constructor which receives our props
    constructor(props) {
        super(props);
        this.player = null;
        this.timelines = [];
        // var source = "https://d2vhxis4wdqzpc.cloudfront.net/story/H3H/de63f1df-fe6f-4e9c-ba61-d7bfdf26296a/q4/de63f1df-fe6f-4e9c-ba61-d7bfdf26296a.m3u8";
        this.datas = this.props.datas;
        this.questions = this.datas.questions;
        this.currentQuestion = this.props.current;
        this.src = this.questions[this.currentQuestion].view;

        this.availabilities = this.datas.candidate.availabilities || []; // ["DAYS", "NIGHTS", "WEEKENDS"]
        this.search_time = this.datas.candidate.search_time || []; // ["PARTTIME", "FULLTIME"]

        this.state = {
            controls: this.props.controls,
            muted: this.props.muted,
            loop: this.props.loop,
            question: this.questions[this.currentQuestion].question
        };

        this.baseButtonClasses = "p-1 px-2 rounded mr-2";

        this.previous.bind(this);
        this.next.bind(this);
        this.loadVideo.bind(this);
        this.play.bind(this);
    }

    loadVideo() {
        this.player.src({ type: "application/x-mpegURL", src: this.src });
        this.updateTimelines();
    }
    play() {
        this.player.play();
    }

    previous() {
        if (this.currentQuestion === 0
            || this.player == null)
            return;
        this.currentQuestion = this.currentQuestion - 1;
        this.src = this.questions[this.currentQuestion].view;
        this.setState({ question: this.questions[this.currentQuestion].question });
        this.loadVideo();

    }
    next() {
        if ((this.currentQuestion + 1) >= this.questions.length
            || this.player == null)
            return;
        this.currentQuestion = this.currentQuestion + 1;
        this.src = this.questions[this.currentQuestion].view;
        this.setState({ question: this.questions[this.currentQuestion].question });
        this.loadVideo();
    }

    updateTimelines() {
        for (let i = 0; i < this.timelines.length; i++) {
            if (i < this.currentQuestion)
                this.timelines[i].style.width = "100%";
            else
                this.timelines[i].style.width = "0%";
        }
    }

    componentDidMount() {
        if (this.player == null) {
            for (let i = 0; i < this.questions.length; i++) {
                this.timelines.push(document.getElementById('timeline-' + i));
            }
            this.player = videojs(document.getElementById('player'), {
                controls: this.state.controls,
                autoplay: true,
                width:"100%",
                // fluid: true,
                aspectRatio: '9:16',
                muted: this.state.muted,
                loop: false, // loop on playlist, not the current video
                "limitRenditionByPlayerDimensions": true,
                "html5": {
                    "hls": {
                        "headers": { Origin: "jobstory.app" },
                        "overrideNative": true,
                        "allowSeeksWithinUnsafeLiveWindow": true
                    },
                }
            });
            let that = this;
            this.player.el().style.backgroundColor = "transparent";
            this.player.on("timeupdate", function (e) {
                that.timelines[that.currentQuestion].style.width = e.target.player.currentTime() * 100 / e.target.player.duration() + 4 + "%";
            });
            this.player.on("ended", function (e) {
                that.next();
            });
            this.player.on("play", function (e) {
                document.getElementById("btn-play").style.display = "none";
            });
            this.player.on("pause", function (e) {
                document.getElementById("btn-play").style.display = "block";
            });
            this.loadVideo();
        }
    }

    componentWillUnmount() {
        if (this.player !== null) this.player.dispose();
    }

    getButtonClass(active) {
        return this.baseButtonClasses + (active ? " bg-purpleJS-100" : " text-greyBorderJS bg-black line-through bg-opacity-50");
    }

    render() {
        return (
            <div className="relative mb-10">
                <div id="timeline" className="my-5 relative flex h-1 w-full">
                    {this.questions.map((data, i) => {
                        return <div key={"timeline-" + i} className="overflow-hidden rounded flex-1 w-auto bg-lightGrey mx-1">
                            <div className="transition-all duration-300 linear rounded h-full bg-white w-0" id={"timeline-" + i}></div>
                        </div>
                    })}
                </div>
                <h1 style={{ textShadow: "0 0 13px #000000a3" }} className="px-3 mt-2 z-10 text-xl absolute font-medium">{this.state.question}</h1>
                <div className="h-full w-full absolute left-0 bottom-0 md:opacity-0 hover:opacity-100 z-10">
                    <div className="cursor-pointer absolute left-0 bottom-0 h-full w-1/2" onClick={this.previous.bind(this)}>
                        <img alt="previous video" style={{ top: "50%", left: "0.5rem" }} className="absolute" src={previousIMG} />
                    </div>
                    <div className="cursor-pointer absolute right-0 bottom-0 h-full w-1/2" onClick={this.next.bind(this)}>
                        <img alt="next video" style={{ top: "50%", right: "0.5rem" }} className="absolute" src={nextIMG} />
                    </div>
                </div>
                {
                this.availabilities.length > 0 || this.search_time.length > 0 ?
                    <div style={{ top: "63%", left: "5%" }} className="z-10 text-xl absolute font-medium">
                        <h2 className="text-xs uppercase">{this.props.t("availabilities")}</h2>
                        {
                        this.availabilities.length > 0 ?
                            <div>
                                <div className="text-xs w-full mt-2 flex">
                                    <span className={this.getButtonClass(this.availabilities.includes("DAYS"))}>{this.props.t("availability_days")}</span>
                                    <span className={this.getButtonClass(this.availabilities.includes("NIGHTS"))}>{this.props.t("availability_nights")}</span>
                                    <span className={this.getButtonClass(this.availabilities.includes("WEEKENDS"))}>{this.props.t("availability_weekend")}</span>
                                </div>
                                <hr className="my-2" />
                            </div>
                        : null
                        }
                        {
                        this.search_time.length > 0 ?
                            <div className="text-xs w-full flex">
                                <span className={this.getButtonClass(this.search_time.includes("FULLTIME"))}>{this.props.t("search_time_full")}</span>
                                <span className={this.getButtonClass(this.search_time.includes("PARTTIME"))}>{this.props.t("search_time_partial")}</span>
                            </div>
                        : null
                        }
                    </div>
                : null
                }
                <img alt="play video" id="btn-play" style={{ transform: "translateX(-50%)", top: "48%", left: "50%" }} onClick={this.play.bind(this)} className="cursor-pointer z-20 absolute" src={playIMG} />
                <video playsInline width="100%" style={this.props.styles} id="player" className="video-js vjs-default-skin"></video>
            </div>
        );
    }
}

export default withTranslation()(VideoStories);