import React from 'react';
import { withTranslation } from 'react-i18next';
import LoginApp from './../login/LoginApp';

class ManagerLogin extends React.Component {
  render() {
    return (
      <div className='w-full'>
        <div className='w-1/4 h-20 float-left'></div>
        <div className='w-1/2 float-left'>
          <LoginApp />
        </div>
      </div>
    )
  }
}

export default withTranslation()(ManagerLogin);