import React from 'react';
import { withTranslation } from 'react-i18next';

import Miniature from './Miniature';

class AdminOfferCard extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {
        loading: false,
        user: this.props.user,
        show_call_details: false
      }

      console.log(this.state);
 
      this.getAttributeValue = this.getAttributeValue.bind(this);
    }
    
    getAttributeValue(attribute, defaultVal) {
      // console.log(this.state.user);
      if(this.state.user[attribute]) defaultVal = this.state.user[attribute];
            
      return defaultVal;
      
      // var userAtt = this.state.user.Attributes.find(att => { return (att.Name === attribute) });
      // if(userAtt) return userAtt.Value;
      // else return defaultVal;
    }

    render() {
        return (
          <div key={this.state.user.Username} className="flex w-full mt-5 rounded-md p-2 bg-gray-100">
            <div className="text-left">
              <Miniature className="h-16 w-16 rounded-md mx-0 mr-6" username={this.getAttributeValue("title", "Offer title")} width="100" height="100" picture={this.getAttributeValue("company_picture", "avatar/icon_company.png")} />
              <div className="text-xs text-left mt-2 hover:underline cursor-pointer" onClick={(e) => { this.props.setWishlistWithFilter({job_code: this.state.user.Sk}); }}>wishlist</div>
              <a className="text-xs text-left mt-2" href={"/admin/user/" + this.state.user.Username} target="_blank" rel="noopener noreferrer">view recruiter</a>
            </div>
            <div className="text-left w-full">
              <div className="hidden sm:block text-lg font-semibold"><a href={"/candidate/offer/" + this.state.user.Sk} target="_blank" rel="noopener noreferrer">{this.getAttributeValue("title", "Offer title")}</a></div>
              <div className="text-xs mt-0">State: <span className="font-semibold">{this.state.user.state}</span></div>
              <div className="text-xs mt-0">Code annonce: <span className="font-semibold">{this.state.user.Sk}</span> - {this.getAttributeValue("city", "City") + ' - ' + this.getAttributeValue("zip_code", "92000")}</div>
              <div className="text-xs mt-0">Company: <span className="font-semibold">{this.state.user.company_name}</span></div>
              <div className="text-xs mt-0">Créé: <span className="font-semibold">{this.state.user.date_creation.toISOString().replace('T', ' ').replace('Z', ' ').split('.')[0]}</span></div>
              <div className="text-xs mt-0">{this.getAttributeValue("description", "Empty description").substr(0, 255) + '...'}</div>
            </div>
          </div>
        )
    }
}

export default withTranslation()(AdminOfferCard);
