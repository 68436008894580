import React from 'react';

import { withTranslation } from 'react-i18next';

import ManagerLogin from './ManagerLogin';
import ManagerHome from './ManagerHome';
import ManagerMenu from './ManagerMenu';
import ManagerTemplates from './ManagerTemplates';
import ManagerTemplateEdit from './ManagerTemplateEdit';
import ManagerSubscriptions from './ManagerSubscriptions';
import ManagerSubscriptionEdit from './ManagerSubscriptionEdit';
import ManagerOffers from './ManagerOffers';
import ManagerOfferCreate from './ManagerOfferCreate';
import ManagerOfferEdit from './ManagerOfferEdit';
import ManagerApplications from './ManagerApplications';
import ManagerShops from './ManagerShops';
import ManagerShopEdit from './ManagerShopEdit';

import logoIMG from '../../img/small-logo-purple.svg';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from "react-router-dom";

import { Hub, Auth, API } from 'aws-amplify';
      
class ManagerApp extends React.Component {
    constructor(props) {
      super(props);

      

      this.state = {
        is_manager: false,
        loading: true,
        user: null
      };
            
      Hub.listen('auth', (data) => {
        this.onAuthEvent(data);
      });
      
      this.signOut = this.signOut.bind(this);
      this.reloadUser = this.reloadUser.bind(this);
    }
    
    componentDidMount() {
      this.onAuthEvent();
      // La faudrait check la page actuelle pour init le state.page
    }
        
    signOut() {
      Auth.signOut();
    }
        
    async reloadUser() {
      var newState = {
        is_manager: false,
        user: null,
        loading: false
      };
   
      try {
        
        // var user_attributes = await API.get('apis', '/apis/user-attributes');
        
        var manager_basics = await API.get('apis', '/apis/manager?user=me');
        
        if(manager_basics.user.user_plan && manager_basics.user.user_plan !== 'free') {
          newState.is_manager = true;
        } else if (manager_basics.manager && manager_basics.manager.length>1) {
          newState.is_manager = true;
        }
        
        if(manager_basics.user) {
          newState.user = manager_basics.user;
          
          if(!newState.user.total_boost) newState.user.total_boost = 0;
          if(!newState.user.av_boost) newState.user.av_boost = 0;
          if(!newState.user.used_boost) newState.user.used_boost = 0;
        }
              
        this.setState(newState);
      
      } catch(e) {
        console.log("error");
        console.log(e);
      }
    }
    
    async onAuthEvent(data) {
      
      // var load_user = true;
      // if(data && data.payload && data.payload.event === "signIn") {
        // newState.user = data.payload.data;
      // } else if(data && data.payload && data.payload.event === "signOut") {
        // newState.user = null;
      // }
      if(data && data.payload && data.payload.event === "signOut") {
        document.location.reload();
      } else {
        this.reloadUser();
      }

    }
    
    render() {
      return (
        <Router>
          <div className="w-full relative">
            <div className="w-full p-10 bg-gray-100 min-h-screen float-left">
                { this.state.user != null ?
                  <div className="w-full">
                    
                      <div className="w-1/5 float-left p-5 bg-white rounded-lg">
                        <div className="w-full">
                          <div className="w-full mb-10 mt-3">
                            <img className="w-32" alt="logo jobstory" src={logoIMG} />
                          </div>
                          { this.state.is_manager ?
                            <div>
                              <Switch>
                                <Route path="/manager/:page" render={(props) => <ManagerMenu {...props} user={this.state.user} />} />
                                <Route render={(props) => <ManagerMenu {...props} user={this.state.user} />} />
                              </Switch>
                            </div>
                          : null }
                          <div onClick={() => { this.signOut()}}  className="float-right text-xs rounded-lg p-2 pl-5 pr-5 bg-red-100 text-red-600 cursor-pointer mt-10">{this.props.t('signout_button_label')}</div>
                        </div>
                      </div>
                      <div className="w-4/5 p-5 float-left">
                      { this.state.is_manager ?
                          <Switch>
                            <Route path="/manager/offers" render={(props) => <ManagerOffers {...props} user={this.state.user} reloadUserCallback={() => {this.reloadUser()}} />} />
                            <Route path="/manager/offer/:entity_id" render={(props) => <ManagerApplications {...props} user={this.state.user} />} />
                            <Route path="/manager/offer-edit/:entity_id" render={(props) => <ManagerOfferEdit {...props} user={this.state.user} />} />
                            <Route path="/manager/offer-create" render={(props) => <ManagerOfferCreate {...props} user={this.state.user} />} />
                            <Route path="/manager/shops" render={(props) => <ManagerShops {...props} user={this.state.user} />} />
                            <Route path="/manager/shop/:entity_id" render={(props) => <ManagerShopEdit {...props} user={this.state.user} />} />
                            <Route path="/manager/subscriptions" render={(props) => <ManagerSubscriptions {...props} user={this.state.user} />} />
                            <Route path="/manager/subscription/:entity_id" render={(props) => <ManagerSubscriptionEdit {...props} user={this.state.user} />} />
                            <Route path="/manager/templates" render={(props) => <ManagerTemplates {...props} user={this.state.user} />} />
                            <Route path="/manager/template/:entity_id" render={(props) => <ManagerTemplateEdit {...props} user={this.state.user} />} />
                            <Route path="/manager/home" render={(props) => <ManagerHome {...props} user={this.state.user} />} />
                            <Route path="/manager/" render={(props) => <ManagerHome {...props} user={this.state.user} />} />
                          </Switch>
                      : 
                        <div className="w-full float-left p-5 bg-red-100 text-red-600 rounded-lg">
                          <h2>{this.props.t('manager_access_not_allowed')}</h2>
                        </div>
                      }
                      </div>
                  </div>
                : 
                  <ManagerLogin />
                }

            </div>
          </div>
        </Router>
      )
    }
}

export default withTranslation()(withRouter(ManagerApp));