import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import MobileMenu from './MobileMenu.js';

import logoIMG from '../img/small-logo-purple.svg';

class Header extends Component {
    render() {
        return (
            <header>
                <div className="w-full px-5 py-8 md:px-20 fixed top-0 left-0 bg-white border-b border-gray-100 z-10">
                    <MobileMenu location={this.props.location} links={[{ 'link': '/', 'name': this.props.t('home') },
                    { 'link': 'mailto:contact@jobstory.app', 'name': this.props.t('contact') },
                    { 'link': '/terms', 'name': this.props.t('term_of_use') },
                    { 'link': '/legals', 'name': this.props.t('legals') },
                    { 'link': '/privacy', 'name': this.props.t('privacy_policy') }]} />
                    <a href="/"><img className="duration-300 ease-in-out transform hover:scale-110 inline-block float-left w-32 md:w-40" alt="logo jobstory" src={logoIMG} /></a>
                    <a className="hidden sm:block hover:underline float-right font-medium text-md ml-5" href="/privacy">{this.props.t('privacy_policy')}</a>
                    <a className="hidden sm:block hover:underline float-right font-medium text-md ml-5" href="/legals">{this.props.t('legals')}</a>
                    <a className="hidden sm:block hover:underline float-right font-medium text-md ml-5" href="/terms">{this.props.t('term_of_use')}</a>
                    {/* <Mailto email='contact@jobstory.app' /> */}
                    <a className="hidden sm:block hover:underline float-right font-medium text-md" target="_blank" rel="noopener noreferrer" href="mailto:alex@jobstory.app">{this.props.t('contact')}</a>
                </div>
            </header>
        )
    }
}

export default withTranslation()(Header);
