import React, { Component } from 'react';

import errorIMG from '../img/error.svg';

export default class PopError extends Component {

    render() {
        return (
            <div className={this.props.className + " relative flex rounded-md overflow-hidden bg-white border-solid border border-greyBorderJS"}>
                <div style={{ backgroundColor: "#f57e79" }} className="p-5 flex text-center items-center">
                    <img className="mx-auto w-6" alt="error" src={errorIMG} />
                </div>
                <div className="text-left py-2 pl-3 pr-10">
                    <p className="text-md text-black font-medium">
                        {
                            this.props.text.split("\n").map(function (item, idx) {
                                return (
                                    <span key={idx}>
                                        {item}
                                        <br />
                                    </span>
                                )
                            })
                        }
                        </p>
                </div>
                <div className="cursor-pointer p-1 text-black absolute top-0 right-0" onClick={this.props.closeAction()}>✖</div>
            </div>
        )
    }
}
