import React from 'react';

import { withTranslation } from 'react-i18next'
import { IoPersonAddSharp, IoCheckmark } from "react-icons/io5";

import { API } from 'aws-amplify';


class ButtonCandidateRecruit extends React.Component {
  constructor(props) {
    super(props);
    
    this.application = this.props.candidate;

    this.candidateId = null;
    if(this.application.Pk) this.candidateId = this.application.Pk;
    else if(this.application.id) this.candidateId = this.application.id;

    this.jobCode = null;
    if(this.application.Sk) this.jobCode = this.application.Sk;
    else if(this.application.job_code) this.jobCode = this.application.job_code;

    this.state = {
      loading: false,
      recruiterState: this.application.recruiter_state 
    };

    this.featureEnabled = true;
    
    this.candidateRecruitedCallback = this.props.recruitCallback;
    
    this.recruitCandidate = this.recruitCandidate.bind(this);
  }
  
  componentDidMount() {

  }
  
  async recruitCandidate() {
      const endpoint = `/apis/application/${this.jobCode}/${this.candidateId}`;
      const apiCall = await API.post('apis', endpoint, {
        body: {
          recruiter_state: "recruited"
        }
      });

      // this.setState({current_story: null, current_feedback: null});
      if(apiCall.recruiter_state) {
        this.setState({recruiterState: apiCall.recruiter_state });
      }

      this.candidateRecruitedCallback();
  }
      
  render() {
    return (
      <div>
        { this.featureEnabled ?
          <div>
            { this.state.recruiterState === "to_recruit" ?
              <div onClick={() => { this.recruitCandidate() }} className="p-1 pl-3 pl-r rounded-lg bg-purpleJS-100 text-white cursor-pointer">
                <IoPersonAddSharp className="float-left mr-2 mt-1" />
                {this.props.t('manager_one_to_recruit')}
              </div>
            : this.state.recruiterState === "recruited" /* recruited */ ?
              <div className="p-1 pl-3 pl-r rounded-lg text-gray-500">
                <IoCheckmark className="float-left mr-2 mt-1" />
                {this.props.t('manager_one_recruited')}
              </div>
            : null }
          </div>
        : null }
      </div>
    )
  }
}

export default withTranslation()(ButtonCandidateRecruit);