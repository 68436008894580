import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Select extends Component {

    constructor(props) {
        super(props);
        this.handleChange.bind(this);
    }

    handleChange = (event) => {
        this.props.handleChange({
            field: this.props.datas.field,
            value: event.target.value
        });
    }

    render() {
        return (
            <div className="w-full">
                <label className="mt-5 w-full block text-lg font-medium">{this.props.t(this.props.datas.label)}</label>
                <select defaultValue={this.props.value} multiple={false} onChange={this.handleChange} className="mt-5 resize-none px-5 py-3 placeholder-greyBorderJS
                font-greyBorderJS font-regular font-sans border-solid rounded-md border
                border-greyBorderJS w-full block">
                    {this.props.config.map((choice, i) => {
                        return <option key={i + ' ' + choice.name} value={choice.name}>{choice.value}</option>
                    })}  
                </select>
            </div>
        )
    }
}

export default withTranslation()(Select);