import React, { Component } from 'react';

import thumbupcolorIMG from '../img/thumbupcolor.svg';
import thumbupIMG from '../img/thumbup.svg';
import thumbdowncolorIMG from '../img/thumbdowncolor.svg';
import thumbdownIMG from '../img/thumbdown.svg';

export default class Thumbs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thumbupIMG: thumbupIMG,
            thumbdownIMG: thumbdownIMG,
        };
        this.changeBG.bind(this);
    }

    changeBG = val => {
        if (val > 0) {
            if (this.state.thumbupIMG === thumbupIMG) {
                this.props.action(this.props.name, 1);
                this.setState({ thumbupIMG: thumbupcolorIMG, thumbdownIMG: thumbdownIMG });
            } else {
                this.props.action(this.props.name, 0);
                this.setState({ thumbupIMG: thumbupIMG, thumbdownIMG: thumbdownIMG });
            }
        } else {
            if (this.state.thumbdownIMG === thumbdownIMG) {
                this.props.action(this.props.name, -1);
                this.setState({ thumbdownIMG: thumbdowncolorIMG, thumbupIMG: thumbupIMG });
            } else {
                this.props.action(this.props.name, 0);
                this.setState({ thumbdownIMG: thumbdownIMG, thumbupIMG: thumbupIMG });
            }
        }
    }

    render() {
        return (
            <div className="flex">
                <div onClick={(e) => { this.changeBG(1); }}
                    style={{ backgroundImage: "url(" + this.state.thumbupIMG + ")" }} className="mt-5 mr-10 cursor-pointer bg-cover w-10 h-10"></div>
                <div onClick={(e) => { this.changeBG(-1); }}
                    style={{ backgroundImage: "url(" + this.state.thumbdownIMG + ")" }} className="mt-5 mr-10 cursor-pointer bg-cover w-10 h-10"></div>
            </div>
        )
    }
}
