import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';

import Header from './Header.js'
import Footer from './Footer.js'

import CFG from './Config';

//assets
import appleStoreIMG from '../img/appstore.svg';
import playStoreIMG from '../img/playstore.svg';
// import appleStoreIMG from '../img/applestore.png';
// import playStoreIMG from '../img/playstore.png';

// import multipostageIMG from '../img/home/multipostage.svg';
// import agendaIMG from '../img/home/agenda.svg';
// import automationIMG from '../img/home/automation.svg';
// import cameraIMG from '../img/home/camera.svg';
import multipostageIMG from '../img/home/clock.svg';
import agendaIMG from '../img/home/group.svg';
import automationIMG from '../img/home/favourite.svg';
import cameraIMG from '../img/home/medal.svg';

import step1IMG from '../img/home/step1.jpg';
import step2aIMG from '../img/home/step2a.jpg';
import step2bIMG from '../img/home/step2b.jpg';
import step3aIMG from '../img/home/step3a.jpg';
import step3bIMG from '../img/home/step3b.jpg';
import step4aIMG from '../img/home/step4a.jpg';
import step4bIMG from '../img/home/step4b.jpg';
import step5aIMG from '../img/home/step5a.jpg';
import step5bIMG from '../img/home/step5b.jpg';
import YouTubePlayer from './YouTubePlayer.js';

const TITLE = 'JobStory - ';

const Home = (props) => {
  useEffect(() => {
    if (document.domain !== "localhost") {
      let fif = document.getElementById("external-newsletter-form");
      let check = setInterval(function () {
        try {
          document.domain = "jobstory.app";
          let doc = fif.contentDocument;
          let forms = doc.getElementsByClassName("final-cta");
          if (forms.length) {
            forms[0].style.zIndex = "9999";
            forms[0].style.position = "fixed";
            forms[0].style.top = "0";
            forms[0].style.left = "0";
            forms[0].style.width = "100%";
            forms[0].style.height = "100%";
            clearInterval(check);
            check = null;
          }
        } catch (e) {
          // fif.style.display = "none";
          console.log(e)
        }
      }, 100);
      setTimeout(function () {
        if (check !== null) {
          clearInterval(check);
          fif.style.display = "none";
        }
      }, 3000);
    }

  });
  return (
    <div className="container h-full w-full max-w-none max-h-screen">
      <Helmet>
        <title>{TITLE + props.t('home_title')}</title>
        <meta property="og:title" content={TITLE + props.t('home_title')} />
        <meta name="description" content={props.t('meta_description')}></meta>
        <meta property="og:description" content={props.t('meta_description')} />
        <meta property="og:image" content={CFG.links.shareIMG} />
        <meta property="og:url" content={CFG.links.root} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Header location={props.location} />
      <article>
        <div className="flex flex-wrap mt-32 lg:mt-48">
          <div className="w-full md:w-6/12 pl-5 md:pl-20 pr-5">
            <h1 className="main-title font-bold text-2xl lg:text-big">{props.t('home_title')}</h1>
            <h2 className="mt-2 md:mt-5 font-regular text-lightGrey text-md lg:text-xl">{props.t('home_subtitle')}</h2>
            <div className="my-10 flex flex-2 gap-5 md:gap-5">
              <a target="_blank" rel="noopener noreferrer" href={CFG.links.appStore}><img style={{ boxShadow: "0 3px 20px 0 rgba(0, 0, 0, 0.2)" }} alt="Jobstory AppleStore" className="duration-200 ease-in-out transition-colors border-2 hover:border-blue-400 rounded-md w-32 md:w-48" src={appleStoreIMG} /></a>
              <a target="_blank" rel="noopener noreferrer" href={CFG.links.playStore}><img style={{ boxShadow: "0 3px 20px 0 rgba(0, 0, 0, 0.2)" }} alt="Jobstory PlayStore" className="duration-200 ease-in-out transition-colors border-2 hover:border-blue-400 rounded-md w-32 md:w-48" src={playStoreIMG} /></a>
            </div>
          </div>
          {/* Old right side version */}
          {/* <div className="flex items-end w-full md:w-6/12 flex-row-reverse">
            <img alt="illustration jobstory" className="" src={topIMG} />
          </div> */}
          <div className="w-full md:w-6/12 relative pr-5 md:pr-20 pl-5">
            <YouTubePlayer />
          </div>
        </div>
        <div className="mt-20 whitespace-pre-line text-white w-full p-5 pb-0 md:p-20 md:pb-0 bg-purpleJS-100 text-center">
          <h2 className="text-3xl lg:text-big font-bold">{props.t('home_save_money_sentence')}</h2>
          {/* <h3 className="mt-3 text-md md:text-lg font-regular">{props.t('home_slogan_sentence')}</h3> */}
          <div className="sm:flex mt-5 sm:mt-10 gap-5 text-xl">
            <div className="mt-10 sm:mt-0 flex-1">
              <img alt={props.t('home_multipost')} src={multipostageIMG} className="inline-block" />
              <h4 className="font-bold mt-3">{props.t('home_multipost')}</h4>
              <p style={{ fontSize: "0.95rem" }} className="font-regular mt-2">{props.t('home_multipost_sentence')}</p>
            </div>
            <div className="mt-10 sm:mt-0 flex-1">
              <img alt={props.t('home_organized')} src={agendaIMG} className="inline-block" />
              <h4 className="font-bold mt-3">{props.t('home_organized')}</h4>
              <p style={{ fontSize: "0.95rem" }} className="font-regular mt-2">{props.t('home_organized_sentence')}</p>
            </div>
            <div className="mt-10 sm:mt-0 flex-1">
              <img alt={props.t('home_automation')} src={automationIMG} className="inline-block" />
              <h4 className="font-bold mt-3">{props.t('home_automation')}</h4>
              <p style={{ fontSize: "0.95rem" }} className="font-regular mt-2">{props.t('home_automation_sentence')}</p>
            </div>
            <div className="mt-10 sm:mt-0 pb-10 flex-1">
              <img style={{ width: "1.2rem" }} alt={props.t('home_video')} src={cameraIMG} className="inline-block" />
              <h4 className="font-bold mt-3">{props.t('home_video')}</h4>
              <p style={{ fontSize: "0.95rem" }} className="font-regular mt-2">{props.t('home_video_sentence')}</p>
            </div>
          </div>
        </div>
        <div className="w-full p-5 md:p-20">
          <center><h1 className="font-bold text-2xl md:text-4xl">{props.t('home_howitworks')}</h1></center>
          <div className="mt-10 lg:flex lg:flex-col lg:items-start">
            {/* STEP 1 */}
            <div className="step1 flex flex-col flex-col-reverse lg:flex-row ">
              <img className="m-auto mt-5 lg:mt-0 w-48 md:w-64 lg:flex" alt="step 1" src={step1IMG} />
              <div className="m-auto lg:flex lg:items-center">
                <div className="flex lg:block gap-4 p-5 bullet-info lg:w-5/6 lg:p-10 bg-gradient-to-r from-bgPurpleLightJS to-bgBlueLightJS">
                  <p className="flex items-center lg:block font-bold text-purpleJS-100 text-4xl">1.</p>
                  <p className="flex items-center lg:block font-medium text-md md:text-xl">{props.t('home_step1_sentence')}</p>
                </div>
              </div>
            </div>
            {/* STEP 2 */}
            <div className="step2 flex flex-col lg:flex-row mt-5 lg:-mt-48 flex self-end">
              <div className="m-auto flex items-center text-left lg:text-right flex-flow-reverse">
                <div className="flex lg:block gap-4 p-5 bullet-info lg:p-10 bg-gradient-to-l from-bgBlueLightJS2 to-bgPurpleLightJS2">
                  <p className="flex items-center lg:block font-bold text-purpleJS-100 text-4xl">2.</p>
                  <p className="flex items-center lg:block font-medium text-md md:text-xl">{props.t('home_step2_sentence')}</p>
                </div>
              </div>
              <div className="images-container m-auto mt-5 lg:mt-0 relative">
                <img className="w-48 md:w-64" alt="step 2a" src={step2aIMG} />
                <img className="absolute w-40 md:w-64" alt="step 2b" src={step2bIMG} />
              </div>
            </div>
            {/* STEP 3 */}
            <div className="step3 flex mt-10 lg:-mt-40 flex flex-col flex-col-reverse lg:flex-row">
              <div className="images-container m-auto mt-5 lg:mt-0 relative">
                <img className="w-48 md:w-64" alt="step 3a" src={step3aIMG} />
                <img className="absolute w-40 md:w-64" alt="step 3b" src={step3bIMG} />
              </div>
              <div className="m-auto lg:flex lg:items-center ">
                <div className="flex lg:block gap-4 p-5 bullet-info lg:w-4/6 lg:p-10 bg-gradient-to-r from-bgPurpleLightJS to-bgBlueLightJS">
                  <p className="flex items-center lg:block font-bold text-purpleJS-100 text-4xl">3.</p>
                  <p className="flex items-center lg:block font-medium text-md md:text-xl">{props.t('home_step3_sentence')}</p>
                </div>
              </div>
            </div>
            {/* STEP 4 */}
            <div className="step2 flex flex-col lg:flex-row mt-5 lg:-mt-48 flex self-end">
              <div className="m-auto flex items-center text-left lg:text-right flex-flow-reverse">
                <div className="flex lg:block gap-4 p-5 bullet-info lg:p-10 bg-gradient-to-l from-bgBlueLightJS2 to-bgPurpleLightJS2">
                  <p className="flex items-center lg:block font-bold text-purpleJS-100 text-4xl">4.</p>
                  <p className="flex items-center lg:block font-medium text-md md:text-xl">{props.t('home_step4_sentence')}</p>
                </div>
              </div>
              <div className="images-container m-auto mt-5 lg:mt-0 relative">
                <img className="w-48 md:w-64" alt="step 4a" src={step4aIMG} />
                <img className="absolute w-40 md:w-64" alt="step 4b" src={step4bIMG} />
              </div>
            </div>
            {/* STEP 5 */}
            <div className="step3 flex mt-10 lg:-mt-40 flex flex-col flex-col-reverse lg:flex-row">
              <div className="images-container m-auto mt-5 lg:mt-0 relative">
                <img className="w-48 md:w-64" alt="step 5a" src={step5aIMG} />
                <img className="absolute w-40 md:w-64" alt="step 5b" src={step5bIMG} />
              </div>
              <div className="m-auto lg:flex lg:items-center ">
                <div className="flex lg:block gap-4 p-5 bullet-info lg:w-4/6 lg:p-10 bg-gradient-to-r from-bgPurpleLightJS to-bgBlueLightJS">
                  <p className="flex items-center lg:block font-bold text-purpleJS-100 text-4xl">5.</p>
                  <p className="flex items-center lg:block font-medium text-md md:text-xl">{props.t('home_step5_sentence')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </article>
    </div>
  )
}

export default withTranslation()(Home);