import React from 'react';

import { withTranslation } from 'react-i18next'

import { API } from 'aws-amplify';

import ManagerLoading from './ManagerLoading';

import SelectField from './template-fields/Select';
import Listing from './template-fields/Listing';

import { v4 as uuidv4 } from 'uuid';

import { IoAdd } from "react-icons/io5";

class ManagerOfferCreate extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      creating: false,
      loading: true,
      errorFields: [],
      offers: []
    }
    
    this.recruiters = [];
    
    // this.changeRouteCallback = this.props.changeRoute;
    
    // this.gotoEntity = this.gotoEntity.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.createOffers = this.createOffers.bind(this);
    
    this.getTemplateById = this.getTemplateById.bind(this);
    this.getShopById = this.getShopById.bind(this);
    
    this.updatePrefJobCode = this.updatePrefJobCode.bind(this);
  }
  
  componentDidMount() {
      this.loadData();
  }
  
  getTemplateById(entity_id) {
    var entity = null;
    
    var found = this.state.data.templates.Items.find((temp) => temp.id === entity_id);
    
    if(found) {
      entity = found.content;
    }
    
    return entity
  }
  
  getShopById(entity_id) {
    var entity = null;
    
    var found = this.state.data.shops.Items.find((temp) => temp.id === entity_id);
    
    if(found) {
      entity = found.content;
      entity.shop_id = entity_id;
    }
    
    return entity
  }
  
  async loadData() {
    var data = {};
    
    data.shops = await API.get('apis', '/apis/shops');
    data.templates = await API.get('apis', '/apis/user-templates?filter-type=offer');

    this.dataLoaded(data);
  }
  
  async createOffers() {
    
    var offers = this.state.offers;
    
    console.log(offers);
    
    var errors = [];
    
    if(!offers.length) errors.push('offers');
    
    for(const offer of offers) {
      if(offer.prefJobCode && offer.prefJobCode.length < 5) {
        errors.push('job codes must have at least 5 characters');
      }
    }
    
    
    if(errors.length === 0) {
      // good on envoie
      this.setState({
        creating: true
      });
      
      for(var i = 0; i < offers.length; i++) {
        var bodyOffer = offers[i];
        
        if(bodyOffer.job_code) delete bodyOffer.job_code;
        if(bodyOffer.tmp_uuid) delete bodyOffer.tmp_uuid;
        if(bodyOffer.shop_id) delete bodyOffer.shop_id;
        
        delete bodyOffer.creation_state;
        
        console.log('---------- offer to create ----------');
        console.log(bodyOffer);
        var res = await API.post('apis', '/apis/offer', { body: bodyOffer});
                
        if(res && res.Item && res.Item.job_code) {
          bodyOffer.job_code = res.Item.job_code;
          bodyOffer.creation_state = "ok";
        } else {
          bodyOffer.creation_state = "error";
        }
        
        offers[i] = bodyOffer;
        
        this.setState({ offers: offers});
      }
      
      // this.gotoEntity();
      
    } else {
      this.setState({
        errorFields: errors
      });
    }
  }
  
  
  async dataLoaded(raw_data) {

    this.recruiters = raw_data.shops.recruiters ? raw_data.shops.recruiters : [];
    
    raw_data.shops_list = [];
    raw_data.templates_list = [{name: '-', value: 'Select template'}];
    
    for(const shop of raw_data.shops.Items) {
      raw_data.shops_list.push({name: shop.id, value: shop.content.shopname + ' - ' + shop.content.address.street + ', ' + shop.content.address.zip_code + ' ' + shop.content.address.city});
    }
    
    for(const template of raw_data.templates.Items) {
      raw_data.templates_list.push({name: template.id, value: template.content.title + " - " + template.content.contract_type});
    }
    
    this.setState({
      loading: false,
      data: raw_data,
      selected: {
        shops: [],
        templates: []
      }
    });
  }
  
  // async gotoEntity() {
    // this.props.history.push('/manager/shops');
  // }
  
  async updatePrefJobCode(index, value) {
    
    var allowedChars = ["0","1", "2","3","4","5","6","7","8","9","A","B","C","D","E","F","G","H","J","K","L","M","N","P","Q","R","S","T","V","W","X","Y","Z"]
    var inputUpperString = value.toUpperCase();
    var outputJobCodeString = "";
    
    for(var i = 0; i < inputUpperString.length; i++) {

      if(allowedChars.includes(inputUpperString[i])) {
        outputJobCodeString += inputUpperString[i];
      } else {
        alert('Not allowed: ' + inputUpperString[i] + " , allowed characters are: " + allowedChars.toString() );
      }
    }
    
    var actual_offers = this.state.offers;
    
    var entry = actual_offers.find(ent => ent.tmp_uuid === index);

    if(entry) {
      var entry_index = actual_offers.indexOf(entry);
      
      actual_offers[entry_index].prefJobCode = outputJobCodeString;
      
      this.setState({
        offers: actual_offers
      });
    }
  }
  
  
  async handleChange(config) {

    var new_state = {
      data: this.state.data,
      selected: this.state.selected
    }

    var sub_field = config.field.split('.');

    if(sub_field.length > 1) {
      if(!new_state.selected[sub_field[0]]) new_state.selected[sub_field[0]] = {};
      new_state.selected[sub_field[0]][sub_field[1]] = config.value;
    } else if(config.field === "templates") {
      new_state.selected[config.field] = [config.value];
    } else {
      new_state.selected[config.field] = config.value;
    }
    
    // new_state.offers = [];
    
    var current_offers = this.state.offers;

    if(new_state.selected.templates.length>0) {

      if(new_state.selected.shops.length>0) {
        
        for(const templateId of new_state.selected.templates) {

          const template = this.getTemplateById(templateId);

          for(const shopId of new_state.selected.shops) {

            const shop = this.getShopById(shopId);

            var found = current_offers.find(el => el.shop_id === shop.shop_id);
            if(!found) {
              var template_copy = JSON.parse(JSON.stringify(template));
              
              let offer = {...template_copy, ...shop.address};
              
              offer.shop_id = shop.shop_id;
              
              offer.shopname = shop.shopname;
              offer.recruiter_id = shop.recruiter_id;
              offer.creation_state = "pending";
      
              offer.tmp_uuid = uuidv4();
              
              current_offers.push(offer);
            }
          }
          
        }
        
      }

      if(current_offers.length > new_state.selected.shops.length) {
        // on doit remove un element
        for(let j = 0; j < current_offers.length; j++) {
          var exists = new_state.selected.shops.find(shop => shop === current_offers[j].shop_id);
          
          if(!exists) {
            current_offers.splice(j, 1);
            break;
          }
        }
      }
    }
    
    new_state.offers = current_offers;
        
    this.setState(new_state);
  }
  
  render() {
    return (
      <div className="w-full">
        { this.state.loading ?
          <div className="w-full flex justify-center">
            <ManagerLoading />  
          </div>
        :
          <div className="w-full">
            <h3 className="mb-10 text-xl font-bold float-left">{this.props.t('manager_create_offers_bulk_label')}</h3>            
            <div className="w-full float-left p-5 bg-white rounded-lg">
              <div className="w-1/2 float-left">
                <div className="w-5/6 float-left">
                  <SelectField value={this.state.selected.templates[0]} handleChange={this.handleChange} 
                    datas={{field: "templates", label: this.props.t('manager_select_offer_template_to_create')}} 
                    config={this.state.data.templates_list} 
                  />
                  <Listing value={this.state.selected.shops} handleChange={this.handleChange} 
                    datas={{field: "shops", label: this.props.t('manager_select_shops_to_apply_offer')}} 
                    config={this.state.data.shops_list} 
                  />
                </div>
              </div>
              <div className="w-1/2 float-left">
                  <div className="mt-5 w-full block text-lg font-medium">{this.props.t('manager_create_offers_bundle_label')}</div>
                  {
                    this.state.offers.length > 0 ?
                      <div className="mt-5 w-full">
                        {
                          this.state.offers.map((offer, i) => (
                            <div className="float-left w-full pb-2 pt-2 pl-5 border-b-2 border-b-purpleJS-100" key={offer.tmp_uuid}>
                              <div className="float-left mr-10 text-xs">
                              {
                                offer.creation_state === "pending" ?
                                  <span className="p-2 pl-5 pr-5 text-xs text-gray-500 bg-gray-100 rounded-full">{this.props.t('manager_offer_tmp_state_pending')}</span>
                                : offer.creation_state === "ok" ?
                                  <span className="p-2 pl-5 pr-5 text-xs text-purpleJS-100 bg-bgPurpleLightJS rounded-full">{this.props.t('manager_offer_tmp_state_ok')}</span>
                                : offer.creation_state === "error" ?
                                  <span className="p-2 pl-5 pr-5 text-xs text-red-500 bg-red-100 rounded-full">{this.props.t('manager_offer_tmp_state_error')}</span>
                                : null
                              }
                              </div>
                              <div className="float-left mr-10 font-bold">{offer.title}</div>
                              <div className="float-left mr-10">{offer.shopname}</div>

                              {
                                offer.creation_state === "pending" ?
                                  <input type="text" className="float-left pl-2 mr-10" onChange={(e) => { this.updatePrefJobCode(offer.tmp_uuid, e.target.value); }} value={offer.prefJobCode} />
                                : offer.creation_state === "ok" ?
                                  <span className="p-2 pl-5 pr-5 text-xs text-purpleJS-100 bg-bgPurpleLightJS rounded-full">{offer.job_code}</span>
                                : offer.creation_state === "error" ?
                                  <span className="p-2 pl-5 pr-5 text-xs text-red-500 bg-red-100 rounded-full">error</span>
                                : null
                              }
                            </div>
                          ))
                        }
                      </div>
                    : 
                    <div className="mt-5">{this.props.t('please_select_template_and_shops')}</div>
                  }
              </div>
              { this.state.errorFields.length > 0 ?
                <div className="w-full mt-10 p-5 rounded-lg border border-red-500 bg-red-100 text-xs float-left">
                  {this.state.errorFields[0]}
                </div>
                : null
              }
              <div className="w-full float-left mt-10">
              { !this.state.creating ?
                <h3 onClick={() => { this.createOffers()}} className="bg-purpleJS-100 cursor-pointer float-right p-5 pt-3 pb-3 rounded-lg text-white">
                  <IoAdd className="float-left mr-3 text-2xl" />
                  {this.props.t('manager_create_offers_bundle')}
                </h3>
                : 
                <h3 className="bg-gray-100 float-right p-5 pt-3 pb-3 rounded-lg text-gray-500">
                  <IoAdd className="float-left mr-3 text-2xl" />
                  {this.props.t('manager_creating_offers_bundle')}
                </h3>
              }
              </div>
              
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(ManagerOfferCreate);


/*
        "tags": [],
        "start_date": new Date().toISOString(),
*/