import React, { Component } from 'react';

export default class MetricWidget extends Component {
    render() {
        return (
            <div className="w-1/6 mt-10 p-5 bg-white rounded-lg float-left mr-8">
              <div className="text-3xl font-bold text-purpleJS-100">
                {this.props.value}
              </div>
              <div className="w-full text-gray-500">
                {this.props.label}
              </div>
            </div>
        )
    }
}
