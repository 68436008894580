import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import addIMG from '../../img/add.svg';
import closeIMG from '../../img/close.svg';

class Questions extends Component {

    constructor(props) {
        super(props);
        this.questionToFill = 0;
        this.qList = null;
        this.q_template = this.props.config.q_template;
        this.handleChange.bind(this);
        this.chooseQuestion.bind(this);
        this.selectTplQuestion.bind(this);
        this.addQuestion.bind(this);
        this.delQuestion.bind(this);
        this.checkValues.bind(this);
    }

    checkValues(q) {
        this.props.handleChange({
            field: this.props.datas.field,
            value: q
        });
    }

    delQuestion(e, i) {
        let q = [...this.props.value];
        q.splice(i, 1);
        this.checkValues(q);
    }

    chooseQuestion(e, i) {
        if (this.qList !== null) {
            this.questionToFill = i;
            this.qList.style.display = "block";
        }
    }

    addQuestion() {
        let q = [...this.props.value];
        q.push({ id: "q" + (q.length + 1), question: "" });
        this.checkValues(q);
    }

    selectTplQuestion(e, i) {
        this.qList.style.display = "none";
        let q = [...this.props.value];
        q[this.questionToFill] = { id: "q" + (this.questionToFill + 1), question: e.target.textContent };
        this.checkValues(q);
    }

    handleChange = (e, i) => {
        let q = [...this.props.value];
        q[i] = { id: "q" + (i + 1), question: e.target.value };
        this.checkValues(q);
    }
    componentDidMount() {
        if (this.qList === null) {
            this.qList = document.getElementById('q-list');
        }
    }

    componentDidUpdate() {
        // if (this.props.value !== null && this.props.value !== this.state.questions) {
        //     this.setState({
        //         nbQuestions: this.props.value.length < 3 ? 3 : this.props.value.length,
        //         questions: this.props.value
        //     });
        // }
    }

    render() {
        return (
            <div className="w-full relative">
                <label className="mt-10 w-full block text-xl text-lightGrey font-medium">{this.props.t(this.props.datas.label)}</label>
                {
                    this.props.value.map((q, i) => {
                        return <div className="relative" key={"q_" + i}>
                            <label className="mt-10 w-full block text-xl text-lightGrey font-medium">{this.props.t('Question ' + (i + 1))}</label>
                            <input data-q={i} value={q.question} type="text" className="mt-5 resize-none px-5 py-3 text-xl placeholder-greyBorderJS
        font-greyBorderJS font-regular font-sans border-solid rounded-md border
        border-greyBorderJS w-full block" placeholder="Tapez votre question" onChange={(e) => this.handleChange(e, i)} />
                            <div data-q={i} onClick={(e) => { this.chooseQuestion(e, i) }} className="cursor-pointer top-0 right-0 absolute font-medium text-purpleJS-100 text-xl">{this.props.t('jobcreation_choose_question_list')}</div>
                            {this.props.value.length > 1 && (i === this.props.value.length - 1) && this.props.value.length > 3 &&
                                <img alt="" style={{ top: "4rem", right: "-2rem" }} data-q={i} onClick={(e) => { this.delQuestion(e, i) }} className="w-6 cursor-pointer absolute font-medium text-purpleJS-100 text-xl" src={closeIMG} />}

                        </div>
                    })
                }
                <div id="q-list" className="overflow-y-auto hidden p-5 bg-white absolute z-10 w-full h-full top-0 left-0">
                    {this.q_template.map((question, i) => {
                        return <div onClick={(e) => { this.selectTplQuestion(e) }} key={"q-tpl-" + i} className="hover:bg-purpleJS-100 hover:text-white cursor-pointer mt-2 resize-none px-5 py-2 text-md placeholder-greyBorderJS
                        font-greyBorderJS font-regular font-sans border-solid rounded-md border
                        border-greyBorderJS w-full block">{question.value}</div>
                    })}
                </div>
                {
                    this.props.value.length < 5 ? <div onClick={() => this.addQuestion()} className="w-56 text-center cursor-pointer mt-10 mx-auto font-medium text-purpleJS-100 text-xl"><img alt="" className="inline-block" src={addIMG} /><span className="inline-block ml-1">{this.props.t('jobcreation_add_question')}</span></div> : ''
                }
            </div>
        )
    }
}

export default withTranslation()(Questions);