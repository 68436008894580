import React from 'react';

import { withTranslation } from 'react-i18next'

import { API } from 'aws-amplify';

import ButtonCandidateRecruit from './ButtonCandidateRecruit';
import ManagerLoading from './ManagerLoading';
import ButtonCV from './ButtonCV';
import ButtonStory from './ButtonStory';
import VideoStories from '../VideoStories';
import Feedback from '../Feedback';

import AuthConfig from '../AuthConfig';

import { IoShareOutline, IoAddOutline, IoCheckmarkSharp } from "react-icons/io5";

class ManagerApplications extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      loading: true, 
      data: null,
      tab: 'todo',
      user: this.props.user,
      current_feedback: null,
      current_story: null,
    }
    
    this.job_code = this.props.match.params.entity_id;
    
    this.dateFormat = this.dateFormat.bind(this);
    this.updateListing = this.updateListing.bind(this);
    this.showStory = this.showStory.bind(this);
    this.recruitCandidate = this.recruitCandidate.bind(this);
    this.changeRecruiterStatus = this.changeRecruiterStatus.bind(this);
  }
  
  async recruitCandidate(candidate) {
      alert(this.props.t('feature_under_development_coming_soon'));
  }


  async changeRecruiterStatus(candidate, new_status, active) {
    console.log(candidate);
    if(active) {
      const body = {
        recruiter_state: new_status
      };

      let candidate_sub = null;
      if(candidate.candidate && candidate.candidate.sub) candidate_sub = candidate.candidate.sub;

      if(candidate_sub !== null) {

        const endpoint = `/apis/application/${this.job_code}/${candidate_sub}`;

        await API.post('apis', endpoint, {
          body: body
        });

        this.setState({current_story: null, current_feedback: null});
        await this.loadData();
      }
    }
  }
  
  async showStory(candidate) {
      if (this.state.current_story != null) {
        this.setState({current_story: null});
      } else {
        // console.log(candidate);
        
        var feedback_request = await API.post('apis', '/apis/share-apply', {
            body: {
              recruiter_id: candidate.recruiter_id,
              job_code: candidate.job_code,
              candidate_id: candidate.candidate.sub,
            }
        });

        if(feedback_request.shareItem && feedback_request.shareItem.Pk) {
          // console.log(feedback_request);
          this.setState({
            current_feedback: {
              candidate: candidate,
              job_title: this.state.data.offer.title,
              key: feedback_request.shareItem.Pk,
              shareURL: feedback_request.shareURL
            },
          })
        }
      }
  }
  
  dateFormat(datestring) {
    var d = new Date(datestring),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('/');
  }
  
  componentDidMount() {
    this.loadData();
  }
  
  async loadData() {
    this.dataLoaded(await API.get('apis', '/apis/application/' + this.job_code + '?fullStates=true'));
  }
  
  async updateListing(new_tab) {
    var current_data = this.sortAndFillDisplay(new_tab, this.state.data);

    this.setState({
      tab: new_tab,
      data: current_data
    });
  }
    
  sortAndFillDisplay(tab, data) {
    var tmp_display = null;
    
    switch(tab) {
      case 'all':
        tmp_display = data.Items;
        break;
      case 'shortlist':
        tmp_display = data.shortlist;
        break;
      case 'rejected':
        tmp_display = data.rejected;
        break;
      case 'wait_reject':
        tmp_display = data.wait_reject;
        break;
      case 'todo':
        tmp_display = data.todo;
        break;
      case 'to_recruit':
        tmp_display = data.to_recruit;
        break;
      default:
        // nothing
        break;
    }
    
    var state_sort = {};
    
    for(let i = 0; i < tmp_display.length; i++) {
      var apply = tmp_display[i];
      if(!state_sort[apply.recruiter_state]) state_sort[apply.recruiter_state] = [];
      
      state_sort[apply.recruiter_state].push(apply);
    }
    
    data.display = [];
    
    var states_list = ['to_recruit', 'new', 'view', 'shortlist', 'recruited', 'wait_reject', 'rejected' ];
    
    for(let i = 0; i < states_list.length; i++) {
      var state = states_list[i];
      if(state_sort[state]) {
        state_sort[state].sort((a,b) => (a.submit_date.submit < b.submit_date.submit) ? 1 : ((b.submit_date.submit < a.submit_date.submit) ? -1 : 0));
        
        data.display = data.display.concat(state_sort[state]);
      }
    }
    
    return data;
  }
  
  dataLoaded(data) {
    // console.log(data);
    
    if(!data.Items) {
      data.Items = [];
      data.Count = 0;
      data.allowed = false;
    } else {
      data.allowed = true;
    }
    
    if(!data.offer) {
      data.offer = {
        title: "Not allowed",
        contract_type: "unknown",
        company_picture: "avatar/icon_company.png",
        shopname: "unknown",
        city: "unknown",
      }
    } else {
      if(!data.offer.company_picture) {
        data.offer.company_picture = "avatar/icon_company.png";
      }
    }
    
    data.wait_reject = [];
    data.rejected = [];
    data.shortlist = [];
    data.to_recruit = [];
    data.todo = [];

    for(var i = 0; i < data.Items.length; i++) {
      var copy_candidate = JSON.parse(JSON.stringify(data.Items[i]));
      if(copy_candidate.recruiter_state === "shortlist") data.shortlist.push(copy_candidate);
      else if(copy_candidate.recruiter_state === "rejected" ) data.rejected.push(copy_candidate);
      else if(copy_candidate.recruiter_state === "wait_reject" ) data.wait_reject.push(copy_candidate);
      else if(copy_candidate.recruiter_state === "to_recruit" ) data.to_recruit.push(copy_candidate);
      else if(["new", "view"].includes(copy_candidate.recruiter_state)) data.todo.push(copy_candidate);
    }
    
    data = this.sortAndFillDisplay(this.state.tab, data);
    
    this.setState({
      loading: false,
      data: data
    });
  }
  
  render() {
    return (
      <div className="w-full">
        { this.state.loading ?
          <div className="w-full flex justify-center">
            <ManagerLoading />  
          </div>
        :
          <div className="w-full">
            { this.state.data.allowed ?
            <div className="w-full">
              <div className="w-full float-left mb-10">
                <h3 className="mt-3 text-3xl font-bold float-left">{this.props.t('manager_applies_menu')}</h3>
              </div>
              <div className="w-full float-left p-5 bg-white rounded-lg">
                <div className="w-full float-left">
                  <div className="float-left rounded-lg bg-gray-100 p-5">
                    <h2 className="font-bold text-lg">{this.state.data.offer.title}</h2>
                    <h4 className="font-bold text-sm text-gray-500 uppercase">{this.state.data.offer.contract_type}</h4>
                  </div>
                  <div className="float-left ml-10 rounded-lg bg-gray-100 p-5">
                    <div className="float-left">
                      <img className="rounded-lg" alt={this.state.data.offer.company_name} src={AuthConfig.CDN.images + '50x50/' + this.state.data.offer.company_picture} />
                    </div>
                    <div className="float-left ml-5">
                      <h2 className="font-bold text-lg">{this.state.data.offer.shopname ? this.state.data.offer.shopname : this.state.data.offer.city }</h2>
                      {
                        this.state.data.offer.recruiter ? 
                          <h4 className="font-bold text-sm text-gray-500">{this.state.data.offer.recruiter.name + ' • ' + this.state.data.offer.recruiter.email}</h4>                    
                        : null
                      }
                    </div>
                  </div>
                </div>
                <div className="w-full float-left mt-10">
                  <div onClick={()=>{this.updateListing('rejected')}} className={"float-left cursor-pointer " + (this.state.tab === "rejected" ? "text-purpleJS-100" : "text-gray-500")}>
                    <div className={"text-lg font-bold float-left " + (this.state.tab === "rejected" ? "border-b-2 border-purpleJS-100" : "")}>{this.props.t('manager_candidates_rejected')}</div>
                    <span className={"float-left rounded-full ml-3 mt-1 pl-2 pr-2 text-xs " + (this.state.tab === "rejected" ? "bg-bgPurpleLightJS" : "bg-gray-100")}>{this.state.data.rejected.length}</span>
                  </div>
                  <div onClick={()=>{this.updateListing('wait_reject')}} className={"float-left cursor-pointer ml-10 " + (this.state.tab === "wait_reject" ? "text-purpleJS-100" : "text-gray-500")}>
                    <div className={"text-lg font-bold float-left " + (this.state.tab === "wait_reject" ? "border-b-2 border-purpleJS-100" : "")}>{this.props.t('manager_candidates_wait_reject')}</div>
                    <span className={"float-left rounded-full ml-3 mt-1 pl-2 pr-2 text-xs " + (this.state.tab === "wait_reject" ? "bg-bgPurpleLightJS" : "bg-gray-100")}>{this.state.data.wait_reject.length}</span>
                  </div>
                  <div onClick={()=>{this.updateListing('todo')}} className={"float-left cursor-pointer ml-10 " + (this.state.tab === "todo" ? "text-purpleJS-100" : "text-gray-500")}>
                    <div className={"text-lg font-bold float-left " + (this.state.tab === "todo" ? "border-b-2 border-purpleJS-100" : "")}>{this.props.t('manager_candidate_state_todo')}</div>
                    <span className={"float-left rounded-full ml-3 mt-1 pl-2 pr-2 text-xs " + (this.state.tab === "todo" ? "bg-bgPurpleLightJS" : "bg-gray-100")}>{this.state.data.todo.length}</span>
                  </div>
                  <div onClick={()=>{this.updateListing('shortlist')}} className={"float-left cursor-pointer ml-10 " + (this.state.tab === "shortlist" ? "text-purpleJS-100" : "text-gray-500")}>
                    <div className={"text-lg font-bold float-left " + (this.state.tab === "shortlist" ? "border-b-2 border-purpleJS-100" : "")}>{this.props.t('manager_shortlist')}</div>
                    <span className={"float-left rounded-full ml-3 mt-1 pl-2 pr-2 text-xs " + (this.state.tab === "shortlist" ? "bg-bgPurpleLightJS" : "bg-gray-100")}>{this.state.data.shortlist.length}</span>
                  </div>
                  <div onClick={()=>{this.updateListing('to_recruit')}} className={"float-left cursor-pointer ml-10 " + (this.state.tab === "to_recruit" ? "text-purpleJS-100" : "text-gray-500")}>
                    <div className={"text-lg font-bold float-left " + (this.state.tab === "to_recruit" ? "border-b-2 border-purpleJS-100" : "")}>{this.props.t('manager_count_to_recruit')}</div>
                    {
                      this.state.data.to_recruit.length > 0 ?
                      <span className="float-left rounded-full ml-3 mt-1 pl-2 pr-2 text-xs text-bgPurpleLightJS bg-purpleJS-100">{this.state.data.to_recruit.length}</span>
                    : <span className={"float-left rounded-full ml-3 mt-1 pl-2 pr-2 text-xs " + (this.state.tab === "to_recruit" ? "bg-bgPurpleLightJS" : "bg-gray-100")}>{this.state.data.to_recruit.length}</span>
                    }
                  </div>
                </div>
                <div className="w-full float-left mt-10">
                  <table className="w-full table-auto">
                    <thead>
                      <tr className="text-left">
                        <th className="p-5 rounded-l-lg bg-bgPurpleLightJS">{this.props.t('manager_candidate_name')}</th>
                        <th className="bg-bgPurpleLightJS">{this.props.t('manager_candidate_contact')}</th>
                        <th className="bg-bgPurpleLightJS">{this.props.t('manager_candidate_date')}</th>
                        <th className="bg-bgPurpleLightJS">{this.props.t('manager_candidate_status')}</th>
                        <th className="bg-bgPurpleLightJS"></th>
                        <th className="bg-bgPurpleLightJS"></th>
                        <th className="rounded-r-lg bg-bgPurpleLightJS"></th>                        
                      </tr>
                    </thead>
                    <tbody className="text-left">
                      { this.state.data.display.map((item) => (
                        <tr key={item.candidate.email + '-' + item.name + '-' + item.job_code + '-' + item.recruiter_state}>
                          <td className="border-b border-slate-100 p-5">
                            <div className="float-left mr-5">
                              <img className="rounded-full" alt={item.name} src={AuthConfig.CDN.images + '40x40/' + item.picture} />
                            </div>
                            <div className="float-left">
                              <p className="mt-2">{item.name}</p>
                            </div>
                            
                          </td>
                          <td className="border-b border-slate-100 p-4 pl-0">
                            <p>{item.candidate.email ? item.candidate.email : this.props.t('manager_no_email_for_this_candidate')}</p>
                            <p className="text-xs text-gray-500 font-bold">{item.candidate.phone ? item.candidate.phone : this.props.t('manager_no_phone_for_this_candidate')}</p>
                          </td>
                          <td className="border-b border-slate-100 p-4 pl-0">
                            {item.submit_date.submit ? this.dateFormat(item.submit_date.submit) : this.props.t('manager_submit_date_error')}
                          </td>
                          <td className="border-b border-slate-100 p-4 pl-0">

                          { item.recruiter_state === "shortlist" ?
                            <span className="p-2 pl-5 pr-5 text-xs text-purpleJS-100 bg-bgPurpleLightJS rounded-full">{this.props.t('manager_candidate_state_shortlist')}</span>
                          : item.recruiter_state === "wait_reject" || item.recruiter_state === "rejected" ?
                            <span className="p-2 pl-5 pr-5 text-xs text-gray-500 bg-gray-100 rounded-full">{this.props.t('manager_candidate_state_' + item.recruiter_state)}</span>
                          : item.recruiter_state === "new" || item.recruiter_state === "view" ?
                            <span className="p-2 pl-5 pr-5 text-xs text-gray-500 bg-gray-100 rounded-full">{this.props.t('manager_candidate_state_todo')}</span>
                          : item.recruiter_state === "to_recruit" ?
                            <span className="p-2 pl-5 pr-5 text-xs text-bgPurpleLightJS bg-purpleJS-100 rounded-full">{this.props.t('manager_candidate_state_to_recruit')}</span>
                          : item.recruiter_state === "recruited" ?
                            <span className="p-2 pl-5 pr-5 text-xs text-green-500 bg-green-100 rounded-full">{this.props.t('manager_candidate_state_recruited')}</span>
                          : null
                          }
                          
                          </td>
                          <td className="border-b border-slate-100 p-4 pl-0"><ButtonStory candidate={item} callback={(cb_item) => { this.showStory(cb_item)}} /></td>
                          <td className="border-b border-slate-100 p-4 pl-0"><ButtonCV cv_url={item.cv_url} /></td>
                          <td className="border-b border-slate-100 p-4 pl-0">
                                {
                                  item.recruiter_state === "shortlist" ?
                                    <div onClick={() => { this.changeRecruiterStatus(item, "to_recruit", true); }} className="p-1 pl-3 pl-r rounded-lg bg-purpleJS-100 text-white cursor-pointer">
                                      <IoAddOutline className="float-left mr-2 mt-1" />
                                      {this.props.t('manager_one_to_recruit')}
                                    </div>
                                  : item.recruiter_state === "to_recruit" ?
                                  <ButtonCandidateRecruit candidate={item} recruitCallback={() => {this.componentDidMount()}} />
                                  :
                                  null
                                }
                          </td>
                        </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            :
              <h3>{this.props.t('not_allowed_to_access_this_page')}</h3>
            }
          </div>
        }
        { this.state.current_feedback !== null ?
          <div className="fixed top-0 left-0 h-full w-full">
            <div onClick={() => this.setState({current_feedback: null})} className="bg-black bg-opacity-75 fixed top-0 left-0 h-full w-full"></div>
            <div className="bg-white absolute rounded-l-lg right-0 z-10 h-full w-3/4">
                <div className="absolute z-50 left-0 bottom-0 pb-10 w-1/2">
                    {
                      ["shortlist", "wait_reject", "new", "view"].includes(this.state.current_feedback.candidate.recruiter_state) ?
                        <div className="flex justify-center w-full">
                          <div onClick={(e) => {this.changeRecruiterStatus(this.state.current_feedback.candidate, "wait_reject", ["shortlist", "new", "view"].includes(this.state.current_feedback.candidate.recruiter_state));}} 
                            className={"rounded-lg p-3 px-5 bg-white" + (["shortlist", "new", "view"].includes(this.state.current_feedback.candidate.recruiter_state) ? " cursor-pointer" : " cursor-default opacity-25")}>{this.props.t('manager_candidate_reject')}</div>
                          <div onClick={(e) => {this.changeRecruiterStatus(this.state.current_feedback.candidate, "shortlist", ["wait_reject", "new", "view"].includes(this.state.current_feedback.candidate.recruiter_state));}} 
                            className={"ml-5 rounded-lg p-3 px-5 bg-gradient-to-r from-purpleJS-100 to-blueLightJS text-white" + (["wait_reject", "new", "view"].includes(this.state.current_feedback.candidate.recruiter_state) ? " cursor-pointer" : " cursor-default opacity-25")}>
                              <div className="flex">
                                {
                                  ["shortlist"].includes(this.state.current_feedback.candidate.recruiter_state) ?
                                  <IoCheckmarkSharp className="mt-1" />
                                  :
                                  <IoAddOutline className="mt-1" />
                                }
                                
                                <div className="ml-1">
                                  {this.props.t('manager_candidate_shortlist')}
                                </div>
                              </div>
                            </div>
                        </div>
                      : null
                    }
                    {
                    ["shortlist", "wait_reject", "new", "view"].includes(this.state.current_feedback.candidate.recruiter_state) ?
                      <div className="flex mt-5 justify-center w-full">
                        <input className="w-2/3 p-3 rounded-lg text-xs" value={this.state.current_feedback.shareURL} readOnly={true} />
                        <IoShareOutline className="ml-3 mt-3 text-white cursor-pointer" onClick={(e) => {
                            window.location.href = `mailto:?subject=${this.state.current_feedback.candidate.name} - ${this.state.data.offer.title.replace('&', '')}&body=${this.state.current_feedback.shareURL}`;
                            e.preventDefault();
                        }} />
                      </div>
                    : null
                    }
                </div>
                <div className="absolute z-10 rounded-lg w-full h-full">
                  <Feedback share_id={this.state.current_feedback.key} feedback_name={this.state.user.name} />
                </div>
            </div>
          </div>
        : null }
        { this.state.current_story !== null ?
          <div className="fixed top-0 left-0 h-full w-full">
            <div onClick={() => this.setState({current_story: null})} className="bg-black bg-opacity-50 fixed top-0 left-0 h-full w-full"></div>
            <div className="bg-white fixed rounded-l-lg top-0 z-10 right-0 h-full p-10 w-2/3 md:w-1/4">
              <div className="w-full float-left">
                <img className="font-bold w-10 mr-2 float-left rounded-full text-purpleJS-100" alt={this.state.current_story.candidate.name} src={AuthConfig.CDN.images + '32x32/' + this.state.current_story.candidate.picture} />
                <div className="float-left">
                  <div className="font-bold text-purpleJS-100">{this.state.current_story.candidate.name}</div>
                  <div className="text-xs">{this.state.current_story.candidate.phone}</div>
                </div>
                <div className="font-bold mt-2 w-full float-left">{this.state.current_story.offer.title}</div>
                {
                  this.state.current_story.offer.shopname ? 
                    <div className="mt-2 w-full float-left font-bold">{this.state.current_story.offer.shopname}</div>                      
                : null }
                <div className="text-xs mt-2 w-full float-left">{this.state.current_story.offer.zip_code}</div>
                <div className="w-full relative float-left">
                  <VideoStories styles={{ borderRadius: "0.5rem" }} loop={true} controls={false} muted={false} current={0} datas={this.state.current_story} />
                </div>
              </div>
            </div>
          </div>
        : null }
      </div>
    )
  }
}

export default withTranslation()(ManagerApplications);