import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Textarea from './fields/Textarea';
import InputText from './fields/InputText';
import ContractType from './fields/ContractType';
import Experience from './fields/Experience';
import StudyLevel from './fields/StudyLevel';
import DateInput from './fields/DateInput';
import AddressInput from './fields/AddressInput';
import JSButton from './JSButton';
import JobCategories from './fields/JobCategories';
import InputNumber from './fields/InputNumber';
import Select from './fields/Select';
import Questions from './fields/Questions';
import UploadImg from './fields/UploadImg';
import PopError from './PopError';

class Step extends Component {

    constructor(props) {
        super(props);
        this.state = { error: false };
        this.handleChange.bind(this);
    }


    handleChange = (infos) => {
        if (this.props.values !== null && this.props.values[infos.field] !== infos.value) {
            let tmpValues = { ...this.props.values };
            if (typeof infos.value === "object" && !(infos.value instanceof Array)) {
                for (let val in infos.value) {
                    tmpValues[val] = infos.value[val];
                }
            } else {
                if (infos.value === '')
                    delete tmpValues[infos.field]
                else
                    tmpValues[infos.field] = infos.value;
            }
            this.props.action(tmpValues);
        }
    };

    render() {
        return (
            <div className="w-3/4">
                <h1 className="text-4xl font-bold">{this.props.t(this.props.datas.title)}</h1>
                <div className={this.props.step === 10 ? "w-full flex gap-10" : ''}>
                    {this.props.datas.inputs.map((input, i) => {
                        switch (input.type) {
                            case "textarea":
                                return <Textarea value={this.props.values !== null && typeof this.props.values[input.field] !== "undefined" ? this.props.values[input.field] : null} key={i} handleChange={this.handleChange} datas={input} />
                            case "input":
                                return <InputText value={this.props.values !== null && typeof this.props.values[input.field] !== "undefined" ? this.props.values[input.field] : null} key={i} handleChange={this.handleChange} datas={input} />
                            case "upload":
                                return <UploadImg value={this.props.values !== null && typeof this.props.values[input.field] !== "undefined" ? this.props.values[input.field] : null} key={i} handleChange={this.handleChange} datas={input} />
                            case "addressInput":
                                return <AddressInput value={this.props.values !== null ? this.props.values : null} key={i} handleChange={this.handleChange} datas={input} />
                            case "dateInput":
                                return <DateInput value={this.props.values !== null && typeof this.props.values[input.field] !== "undefined" ? this.props.values[input.field] : null} key={i} handleChange={this.handleChange} datas={input} />
                            case "checkbox":
                                return <JobCategories value={this.props.values !== null && typeof this.props.values[input.field] !== "undefined" ? this.props.values[input.field] : null} key={i} handleChange={this.handleChange} config={this.props.config} datas={input} />
                            case "numberInput":
                                return <InputNumber value={this.props.values !== null && typeof this.props.values[input.field] !== "undefined" ? this.props.values[input.field] : null} key={i} handleChange={this.handleChange} config={this.props.config} datas={input} />
                            case "select":
                                return <Select value={this.props.values !== null && typeof this.props.values[input.field] !== "undefined" ? this.props.values[input.field] : null} key={i} handleChange={this.handleChange} config={this.props.config[input.field]} datas={input} />
                            case "questionsInput":
                                return <Questions value={this.props.values !== null && typeof this.props.values[input.field] !== "undefined" ? this.props.values[input.field] : null} key={i} handleChange={this.handleChange} config={this.props.config} datas={input} />
                            case "radio":
                                if (input.field === "contract_type")
                                    return <ContractType value={this.props.values !== null && typeof this.props.values[input.field] !== "undefined" ? this.props.values[input.field] : null} key={i} handleChange={this.handleChange} config={this.props.config} datas={input} />
                                if (input.field === "candidate_experience")
                                    return <Experience value={this.props.values !== null && typeof this.props.values[input.field] !== "undefined" ? this.props.values[input.field] : null} key={i} handleChange={this.handleChange} config={this.props.config} datas={input} />
                                if (input.field === "candidate_graduation")
                                    return <StudyLevel value={this.props.values !== null && typeof this.props.values[input.field] !== "undefined" ? this.props.values[input.field] : null} key={i} handleChange={this.handleChange} config={this.props.config} datas={input} />
                                break;
                            default:
                                return <InputText value={this.props.values !== null && typeof this.props.values[input.field] !== "undefined" ? this.props.values[input.field] : null} key={i} handleChange={this.handleChange} datas={input} />
                        }
                        return true;
                    })}
                </div>
                {this.state.error ? <PopError className="m-5" closeAction={this.closeError} text={this.error_form_message} /> : ''}
                <div className="w-full text-right"><JSButton active={this.props.datas.isFilled && !this.props.locked} text={this.props.t(this.props.isLast ? 'finish' : 'next')} action={() => this.props.btnAction((this.props.step + 1))} /></div>
            </div>
        )
    }
}

export default withTranslation()(Step);
