import React from 'react';
import { withTranslation } from 'react-i18next'

import { IoVideocamSharp } from "react-icons/io5";

class ButtonStory extends React.Component {
  
  constructor(props) {
    super(props);
    this.candidate = this.props.candidate;
    this.callback = this.props.callback;
  }
    
  render() {    
    return (
      <div onClick={() => { this.callback(this.candidate)}} className="p-1 pl-3 pl-r rounded-lg border border-purpleJS-100 text-purpleJS-100 cursor-pointer">
        <IoVideocamSharp className="float-left mr-1 mt-1" />
        {this.props.t('manager_story')}
      </div>
    )
  }
}

export default withTranslation()(ButtonStory);