import React from 'react';

import { withTranslation } from 'react-i18next'

import { API } from 'aws-amplify';

import ManagerLoading from './ManagerLoading';
import ManagerEmptyList from './ManagerEmptyList';

import { IoAdd } from "react-icons/io5";

class ManagerSubscriptions extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      loading: true,
      data: null
    }
    
    // this.changeRouteCallback = this.props.changeRoute;

    this.gotoEntity = this.gotoEntity.bind(this);
  }
  
  componentDidMount() {
    this.loadData();
  }
  
  async loadData() {
    this.dataLoaded(await API.get('apis', '/apis/subscription'));
  }

  async gotoEntity(entity_id) {
    if(entity_id === "new") {
      this.props.history.push('/portal/franchise_demo');
      document.location.reload();
    } else {
      this.props.history.push('/manager/subscription/' + entity_id);
    }
  }
  
  dataLoaded(data) {
    var data_collect = data.Items ? data : {Items: []};
    
    this.setState({
      loading: false,
      data: data_collect
    });
  }
  
  render() {
    return (
      <div className="w-full">
        { this.state.loading ?
          <div className="w-full flex justify-center">
            <ManagerLoading />  
          </div>
        :
          <div className="w-full">
            <h3 className="mb-10 text-xl font-bold float-left">{this.props.t('manager_subscription_menu')}</h3>
            <h3 onClick={() => { this.gotoEntity('new')}} className="bg-purpleJS-100 cursor-pointer float-right p-5 pt-3 pb-3 rounded-lg text-white">
              <IoAdd className="float-left mr-3 text-2xl" />
              {this.props.t('manager_add_new_subscription')}
            </h3>
            
            <div className="w-full float-left p-5 bg-white rounded-lg">
              { this.state.data.Items.length > 0 ?
              <table className="w-full table-auto mt-10">
                <thead>
                  <tr className="text-left">
                    <th className="p-5 rounded-l-lg bg-bgPurpleLightJS">{this.props.t('manager_sub_start_date')}</th>
                    <th className="bg-bgPurpleLightJS">{this.props.t('manager_sub_end_date')}</th>
                    <th className="bg-bgPurpleLightJS">{this.props.t('manager_sub_quantity')}</th>
                    <th className="bg-bgPurpleLightJS">{this.props.t('manager_sub_used')}</th>
                    <th className="bg-bgPurpleLightJS">{this.props.t('manager_offer_status')}</th>
                    <th className="bg-bgPurpleLightJS"></th>
                    <th className="p-5 rounded-r-lg bg-bgPurpleLightJS"></th>
                  </tr>
                </thead>
                <tbody>
                  { this.state.data.Items.map((item) => (
                    <tr key={item.sub_id}>
                      <td className="border-b border-slate-100 p-5">{item.created.split("T")[0]}</td>
                      <td className="border-b border-slate-100 p-5">{item.expires.split("T")[0]}</td>
                      <td className="border-b border-slate-100 p-5">{item.sub_quantity}</td>
                      <td className="border-b border-slate-100 p-5">{item.sub_recruiters.length}</td>
                      <td className="border-b border-slate-100 p-5">
                          { item.state === "payed" ?
                            <div className="p-2 pl-5 pr-3 text-xs text-purpleJS-100 bg-bgPurpleLightJS rounded-full float-left relative w-24">
                              <div className="float-left">{this.props.t('offer_active')}</div>
                            </div>
                            :
                            <div className="p-2 pl-5 pr-3 text-xs text-gray-500 bg-gray-100 rounded-full float-left relative">
                              <div className="float-left">{this.props.t('offer_inactive')}</div>
                            </div>
                          } 
                      </td>
                      <td className="border-b border-slate-100 p-5 font-bold">
                        <h3 onClick={() => { this.gotoEntity(item.sub_id)}} className="bg-purpleJS-100 cursor-pointer float-right p-5 pt-3 pb-3 rounded-lg text-white">
                          {this.props.t('manager_manage_subscription')}
                        </h3>
                      </td>
                      <td className="border-b border-slate-100 p-5 pl-0"></td>
                    </tr>
                    ))
                  }
                </tbody>
              </table>
              :
              <ManagerEmptyList handleClick={() => {this.gotoEntity('new')}} empty_message={this.props.t('manager_empty_subscriptions_msg')} create_new_label={this.props.t('manager_add_new_subscription')} />
              }
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(ManagerSubscriptions);