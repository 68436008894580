import React from 'react';

import { withTranslation } from 'react-i18next'

import { API } from 'aws-amplify';

import ManagerLoading from './ManagerLoading';

import InputText from './template-fields/InputText';
import AddressInput from './template-fields/AddressInput';
import UploadImg from './template-fields/UploadImg';

import { IoAdd } from "react-icons/io5";

class ManagerSubscriptionEdit extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      dataSent: false,
      loading: true,
      sub_availables: 0,
      new_recruiter: {
        "shopname": "",
        "address": {},
        "name": "",
        "email": "",
        "picture": "avatar/icon_recruiter.png",
      },
      data: null,
      errors: {},
      errorFields: []
    }
    
    this.entity_id = this.props.match.params.entity_id;
    
    // this.changeRouteCallback = this.props.changeRoute;
    
    this.gotoEntity = this.gotoEntity.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateSubscription = this.updateSubscription.bind(this);
  }
  
  componentDidMount() {
      this.loadData();
  }
  
  async loadData() {
    var templateData = await API.get('apis', '/apis/subscription/' + this.entity_id);
    if(templateData.sub_id) {
      this.dataLoaded(templateData);
    }
  }
  
  
  async dataLoaded(raw_data) {
            
    var data = raw_data;
    
    this.setState({
      loading: false,
      data: data,
      sub_availables: (data.sub_quantity - data.sub_recruiters)
    });
  }
    
  async gotoEntity() {
    this.props.history.push('/manager/subscriptions');
  }
  
  async updateSubscription() {
    return null;
  }
  
  async handleChange(config) {

    var new_state = {
      new_recruiter: this.state.new_recruiter,
      errors: this.state.errors,
    }
    
    new_state.errors[config.field] = false;
    
    if(config.field === "address") {
      if(config.value.street === "" || config.value.zip_code === "") {
        new_state.errors[config.field] = true;
      }
    }

    if(!new_state.errors[config.field]) {
      new_state.new_recruiter[config.field] = config.value;
    }
    
    this.setState(new_state);
    
  }
  
  render() {
    return (
      <div className="w-full">
        { this.state.loading ?
          <div className="w-full flex justify-center">
            <ManagerLoading />  
          </div>
        :
          <div className="w-full">
            <h3 className="mb-10 text-xl font-bold float-left">{this.props.t('manager_sub_name')}</h3>            
            <div className="w-full float-left p-5 bg-white rounded-lg">
              <h3 className="mb-5 font-bold">Il vous reste</h3>
              <p className="mb-5">{ this.state.sub_availables } recruteur à créer</p>
              <div className="w-1/2 float-left">
                <div className="w-11/12">
                  <InputText value={this.state.new_recruiter.shopname} handleChange={this.handleChange} datas={{field: "shopname", label: this.props.t('manager_shop_name_full_label'), placeholder: this.props.t('manager_shop_shopname_placeholder')}} />
                  <div className="w-full">
                    <label className="mt-5 w-full block text-lg font-medium">{this.props.t('manager_shop_raw_address_label')}</label>
                    { this.state.errors.address ?
                      <div className="w-full mt-5 mb-5 p-5 rounded-lg border border-red-500 bg-red-100 text-xs float-left">
                        {this.props.t('invalid_address')}
                      </div>
                      : null
                    }
                    <AddressInput value={this.state.new_recruiter.address} handleChange={this.handleChange} datas={{field: "address", label: this.props.t('manager_shop_raw_address_label'), placeholder: this.props.t('manager_shop_raw_address_placeholder')}} />
                  </div>
                </div>
              </div>
              <div className="w-1/2 float-left">
              <div className="w-full">
                <label className="mt-5 w-full block text-lg font-medium">{this.props.t('manager_shop_infos_recruiter')}</label>
                <InputText value={this.state.new_recruiter.name} handleChange={this.handleChange} datas={{field: "name", label: this.props.t('manager_shop_account_name')}} />
                <InputText value={this.state.new_recruiter.email} handleChange={this.handleChange} datas={{field: "email", label: this.props.t('manager_shop_account_email')}} />
                <UploadImg value={this.state.new_recruiter.picture} handleChange={this.handleChange} datas={{field: "picture", label: this.props.t('manager_user_picture')}} />
              </div>
            
              </div>
              { this.state.errorFields.length > 0 ?
                <div className="w-full mt-10 p-5 rounded-lg border border-red-500 bg-red-100 text-xs float-left">
                  {this.props.t('fill_all_required_fields')}
                </div>
                : null
              }
              <div className="w-full float-left mt-10">
                <h3 onClick={() => { this.updateSubscription()}} className="bg-purpleJS-100 cursor-pointer float-right p-5 pt-3 pb-3 rounded-lg text-white">
                  <IoAdd className="float-left mr-3 text-2xl" />
                  Ajouter ce recruteur
                </h3>
                <h3 onClick={() => { this.gotoEntity()}} className="border-purpleJS-100 cursor-pointer float-left p-5 pt-3 pb-3 rounded-lg text-purpleJS-100">
                  Liste souscriptions
                </h3>
              </div>
              
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(ManagerSubscriptionEdit);


/*
        "tags": [],
        "start_date": new Date().toISOString(),
*/