import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Loading from '../Loading';
import AuthConfig from '../AuthConfig'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import geolocationIMG from '../../img/geolocation.svg';


class AddressInput extends Component {

    constructor(props) {
        super(props);
        let tmpVal = '';
        if (this.props.value.lat) {
            tmpVal = this.props.value.street + ' ' + this.props.value.city + ' ' + this.props.value.zip_code + ' ' + this.props.value.country
        }
        this.state = {
            value: tmpVal,
            scriptReady: false
        }
    }

    handleChange = (address) => {
        this.setState({ value: address });
    }

    handleSelect = addr => {
        geocodeByAddress(addr)
            .then(results => {
                this.setState({ value: results[0]['formatted_address'] });
                getLatLng(results[0]).then(latLng => {
                    let tmpAddr = results[0].address_components;
                    let addrToInsert = {
                        lat: latLng.lat, lng: latLng.lng, street: '', city: '', zip_code: '', area:'', sub_area: '', country: '', country_iso: '',
                    };
                    for(const item of tmpAddr) {
                        if (item.types.indexOf("street_number") >= 0)
                            addrToInsert.street = item.long_name + ' '
                        if (item.types.indexOf("route") >= 0)
                            addrToInsert.street += item.long_name
                        if (item.types.indexOf("locality") >= 0)
                            addrToInsert.city = item.long_name
                        if (item.types.indexOf("postal_code") >= 0)
                            addrToInsert.zip_code = item.long_name
                        if (item.types.indexOf("administrative_area_level_1") >= 0)
                            addrToInsert.area = item.long_name
                        if (item.types.indexOf("administrative_area_level_2") >= 0)
                            addrToInsert.sub_area = item.long_name
                        if (item.types.indexOf("country") >= 0) {
                            addrToInsert.country = item.long_name;
                            addrToInsert.country_iso = item.short_name
                        }
                    }
                    this.props.handleChange({
                        field: this.props.datas.field,
                        value: addrToInsert
                    });
                }).catch(error => console.error('Error', error));
            })
            .catch(error => console.error('Error', error));
    };


    componentDidMount() {
        if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
            this.setState({ scriptReady: true });
        } else {
            let that = this;
            let gmapscript = window.document.createElement("script");
            gmapscript.src = AuthConfig.Common.API.endpoints.find((api) => { return api.name === 'googlemap' }).endpoint;
            gmapscript.type = "text/javascript";
            gmapscript.addEventListener("load", function () {
                that.setState({ scriptReady: true });
            });
            window.document.body.appendChild(gmapscript);
        }
    }

    render() {
        return (
            <div className="w-full">
                {
                    !this.state.scriptReady ?
                        <Loading />
                        :
                        <PlacesAutocomplete
                            value={this.state.value}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: this.props.t(this.props.datas.placeholder),
                                            style: { backgroundImage: "url(" + geolocationIMG + ")", backgroundPosition: "0.5rem center" },
                                            className: 'mt-5 pl-10 bg-no-repeat px-5 py-3 text-xl placeholder-greyBorderJS ' +
                                                'font-greyBorderJS font-regular font-sans border-solid rounded-md border ' +
                                                'border-greyBorderJS w-full block',
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <Loading />}
                                        {suggestions.map((suggestion, i) => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active pl-10 bg-no-repeat px-5 py-3 text-xl placeholder-greyBorderJS ' +
                                                'font-greyBorderJS font-regular font-sans border-solid rounded-md border ' +
                                                'border-greyBorderJS w-full block'
                                                : 'suggestion-item pl-10 bg-no-repeat px-5 py-3 text-xl placeholder-greyBorderJS ' +
                                                'font-greyBorderJS font-regular font-sans border-solid rounded-md border ' +
                                                'border-greyBorderJS w-full block';
                                            // inline style for demonstration purpose
                                            const style = { backgroundImage: "url(" + geolocationIMG + ")", cursor: 'pointer', backgroundPosition: "0.5rem center" };
                                            return (
                                                <div key={i + "_sugg"}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                }
            </div>
        );
    }
}

export default withTranslation()(AddressInput);
