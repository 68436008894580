import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import cddIMG from './../../img/cdd.svg';
import cdiIMG from './../../img/cdi.svg';
import stageIMG from './../../img/stage.svg';
import vieIMG from './../../img/vie.svg';
import alternanceIMG from './../../img/alternance.svg';
import indepIMG from './../../img/indep.svg';
import interimIMG from './../../img/interim.svg';

class ContractType extends Component {

    constructor(props) {
        super(props);
        this.choices = this.props.config.cont_type;
        this.images = {};
        this.images.cdd = cddIMG;
        this.images.cdi = cdiIMG;
        this.images.stage = stageIMG;
        this.images.vie = vieIMG;
        this.images.alternance = alternanceIMG;
        this.images.indep = indepIMG;
        this.images.interim = interimIMG;
        this.handleChange.bind(this);
    }

    handleChange = (event) => {
        this.props.handleChange({
            field: this.props.datas.field,
            value: event.target.value,
        });
    }

    render() {
        return (
            <div className="w-full">
                <h2 className="mt-10 w-full block text-xl text-lightGrey font-medium">{this.props.datas.label}</h2>
                <form>
                    {this.choices.map((choice, i) => {
                        let bgColor = this.props.value === choice.name ? "#f6f7f8" : "";
                        return <label className="cursor-pointer border-solid rounded-md border-2
                        border-greyBorderJS text-center relative inline-block w-32 h-40 bg-no-repeat bg-center mr-5 mb-3" style={{
                                backgroundImage: "url(" + this.images[choice.name] + ")",
                                backgroundSize: "2.5rem", backgroundPosition: "center 2.5rem",
                                backgroundColor: bgColor
                            }} key={i + "-cont_type"}>
                            <div style={{ bottom: "2.5rem" }} className="font-semibold text-lg absolute w-full">{choice.value}</div>
                            <input style={{ bottom: "1rem" }} className="absolute w-full left-0" checked={this.props.value === choice.name} onChange={this.handleChange} type="radio" value={choice.name} />
                        </label>
                    })}
                </form>
            </div>
        )
    }
}

export default withTranslation()(ContractType);
