import React from 'react';

import AuthConfig from './AuthConfig';

class Miniature extends React.Component {

  // Then we add our constructor which receives our props
  constructor(props) {
    super(props);

    this.state = {
      desiredWidth: this.props.width,
      desiredHeight: this.props.height,
      username: this.props.username,
      pictureSrc: AuthConfig.CDN.images + this.props.width.toString() + 'x' + this.props.height.toString() + '/' + this.props.picture,
      hasPic: this.props.picture ? true : false
    }
  }

  shouldComponentUpdate(nextProps, nextState) {

    return new Promise((accept, reject) => {
      if (nextProps.picture !== this.props.picture) {
        this.setState({
          pictureSrc: AuthConfig.CDN.images + this.state.desiredWidth.toString() + 'x' + this.state.desiredHeight.toString() + '/' + nextProps.picture
        });
        accept(true);
      } else {
        accept(false);
      }
    });;
  }

  // The render function, where we actually tell the browser what it should show
  render() {
    return (
      <img alt={this.state.username} className={this.props.className} src={this.state.hasPic ? this.state.pictureSrc : null} />
    )
  }
}

export default Miniature; // Don’t forget to use export default!