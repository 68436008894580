import React from 'react';
import AuthConfig from './AuthConfig';
import { API } from 'aws-amplify';
import { withTranslation } from 'react-i18next';
import Error from './Error';

import VideoStories from './VideoStories';
import ManagerCandidateHeader from './ManagerCandidateHeader';
import ManagerCandidateCard from './ManagerCandidateCard';
import Feedback from './Feedback';

class ManagerCandidates extends React.Component {
    constructor(props) {
      super(props);

      this.ref_candidates = props.candidates;
      this.ref_landing = props.landing;
      this.ref_user = props.user;
      this.ref_offers = props.offers;
      this.ref_recruiters = props.recruiters;

      this.state = {
        error: false,
        current_feedback: null,
        current_story: null,
        error_msg: "",
        localSearch: "",
        landing: [],
        searchFilter: [],
        shortlist: [],
        rejected: [],
        wait_reject: [],
        viewed: [],
      };
        
      this.displayDate = this.displayDate.bind(this);
      this.updateDisplayCandidate = this.updateDisplayCandidate.bind(this);
      this.updateSearch = this.updateSearch.bind(this);
    }
    
    
    componentDidUpdate(prevProps, prevState) {

      var needUpdate = false;
      
      
      if(this.props.candidates !== prevProps.candidates) {
        this.ref_candidates = this.props.candidates;
        needUpdate = true;
      }
      
      if(this.props.landing !== prevProps.landing) {
        this.ref_landing = this.props.landing;
        needUpdate = true;
      }
      
      if(this.props.user !== prevProps.user) {
        this.ref_user = this.props.user;
        needUpdate = true;
      }
      
      if(this.props.offers !== prevProps.offers) {
        this.ref_offers = this.props.offers;
        needUpdate = true;
      }
      
      if(this.props.recruiters !== prevProps.recruiters) {
        this.ref_recruiters = this.props.recruiters;
        needUpdate = true;
      }
      
      if(this.state.searchFilter.length !== prevState.searchFilter.length) {
        needUpdate = true;
      }
      
      if(needUpdate) {
        this.updateComp();
      }
    }

    componentDidMount() {
      // console.log('componentDidMount');
      this.updateComp();
    }
    
    updateComp() {

        var newState = { 
          landing: [],
          shortlist: [],
          rejected: [],
          wait_reject: [],
          viewed: []
        };
        
        var filters = [];
        for(var i = 0; i < this.state.searchFilter.length; i++) {
          filters.push(this.state.searchFilter[i].toLowerCase());
        }
        
        // console.log(filters);
        
        for(let i = 0; i < this.ref_landing.length; i++) {
          var cand = this.ref_landing[i];
          
          if(cand.candidate) {
            
            var active = false;
            
            if(filters.length === 0) active = true;
            else {
              var j = 0;
              while(filters[j] && cand.searchField.includes(filters[j])) j++;
              
              // console.log(filters[j]);
              
              if(!filters[j]) active = true;
            }
            
            if(active) {
              newState.landing.push(cand);
            }
          }
        }
                
        for(let i = 0; i < this.ref_candidates.length; i++) {
          const cand = this.ref_candidates[i];

          if(cand.candidate) {
            
            let active = false;
            
            if(filters.length === 0) active = true;
            else {
              let j = 0;
              while(filters[j] && cand.searchField.includes(filters[j])) j++;
              
              // console.log(filters[j]);
              
              if(!filters[j]) active = true;
            }
            
            if(active) {
            
              switch(cand.recruiter_state) {
                case "shortlist":
                  newState.shortlist.push(cand);
                  break;
                case "rejected":
                case "wait_reject":
                  newState.rejected.push(cand);
                  break;
                  // newState.wait_reject.push(cand);
                  // break;
                case "new":
                case "view":
                  newState.viewed.push(cand);
                  break;
                default:
                  break;
              }
            }
          }
        }


        newState.viewed.sort((a,b) => (a.submit_date.seconds > b.submit_date.seconds) ? 1 : ((b.submit_date.seconds > a.submit_date.seconds) ? -1 : 0))
        newState.shortlist.sort((a,b) => (a.submit_date.seconds > b.submit_date.seconds) ? 1 : ((b.submit_date.seconds > a.submit_date.seconds) ? -1 : 0))
        newState.rejected.sort((a,b) => (a.submit_date.seconds > b.submit_date.seconds) ? 1 : ((b.submit_date.seconds > a.submit_date.seconds) ? -1 : 0))
        newState.landing.sort((a,b) => (a.submit_date.seconds > b.submit_date.seconds) ? 1 : ((b.submit_date.seconds > a.submit_date.seconds) ? -1 : 0))



        this.setState(newState);
      
    }
        
    async updateDisplayCandidate(candidate) {
      if (this.state.current_story != null) {
        this.setState({current_story: null});
      } else {
        // console.log(candidate);
        
        var feedback_request = await API.post('apis', '/apis/share-apply', {
            body: {
              recruiter_id: candidate.recruiter_id,
              job_code: candidate.job_code,
              candidate_id: candidate.candidate.sub,
            }
        });
                
        // console.log(feedback_request);
        
        if(feedback_request.shareItem && feedback_request.shareItem.Pk) {
          this.setState({current_feedback: feedback_request.shareItem.Pk})
          
        }
        // this.res_candidates = applications_results.Items;
        // this.setState({current_story: candidate})
      }
    }
    
    deleteSearch(search_val) {
      var newSearch = [];
      
      for(var i = 0; i < this.state.searchFilter.length; i++) {
        if(this.state.searchFilter[i] !== search_val) {
          newSearch.push(this.state.searchFilter[i]);
        }
      }
      
      this.setState({
        searchFilter: newSearch
      });
    }
    
    updateSearch(search_val) {
      var currentSearch = JSON.parse(JSON.stringify(this.state.searchFilter));
      
      if(typeof search_val == "string") {
        currentSearch.push(search_val);
      } else if (search_val.title) {
        currentSearch.push(search_val.title);
        currentSearch.push(search_val.job_code);
      }
      
      this.setState({
        localSearch: "",
        searchFilter: currentSearch
      });
    }
    
    displayDate(given_date) {
      var returnedDate = given_date.submit;
      
      if(given_date.days > 0) {
        returnedDate = given_date.days + " days";
      } else if(given_date.hours > 0) {
        returnedDate = given_date.hours + " hours";
      } else if(given_date.minutes > 0) {
        returnedDate = given_date.minutes + " min";
      } else if(given_date.seconds > 0) {
        returnedDate = given_date.seconds + " sec";
      }
      
      return returnedDate;
    }
    
    // The render function, where we actually tell the browser what it should show
    render() {  
        return (
          <div className="w-full h-screen">
          { this.state.error ?
              <Error text={this.state.error_msg} />
            :
              <div>
                <div className="w-full float-left">
                  <div className="w-full md:w-1/5">
                    <form className="" onSubmit={(e) => { e.preventDefault(); this.updateSearch(this.state.localSearch); }}>
                      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.localSearch} onChange={(e) => this.setState({localSearch: e.target.value})} type="text" placeholder="Rechercher" />
                    </form>
                  </div>
                  <div className="w-full mt-5">
                    {this.state.searchFilter.map((search, index) => (
                      <div key={search+'-'+index} className="float-left mr-5 p-1 px-3 bg-purpleJS-100 text-white rounded-full">{search} <span onClick={(e) => this.deleteSearch(search)} className="text-white font-normal ml-3 cursor-pointer">x</span></div>
                    ))}
                  </div>
                </div>
                <div className="w-full mt-5 float-left">
                  
                  
                  <div className="w-full md:w-1/5 mt-10 md:mt-0 bg-white border-2 rounded-lg float-left mr-8 pb-3">
                    <ManagerCandidateHeader label="CV reçus sans story" value={this.state.landing.length} />
                    <hr />
                    
                    <div className="w-full max-h-screen overflow-auto">
                    {this.state.landing.map((candidat, index) => (
                      <ManagerCandidateCard key={candidat.candidate.sub+'-'+candidat.job_code} candidat={candidat} filter={(filter_val) => this.updateSearch(filter_val)} />
                    ))}
                    </div>
                  </div>
                  
                  <div className="w-full md:w-1/5 mt-10 md:mt-0 bg-white border-2 rounded-lg float-left mr-8 pb-3">
                    <ManagerCandidateHeader label="Rejetés" value={this.state.rejected.length} />
                    <hr />
                    
                    <div className="w-full max-h-screen overflow-auto">
                    {this.state.rejected.map((candidat, index) => (
                      <ManagerCandidateCard key={candidat.candidate.sub+'-'+candidat.job_code} candidat={candidat} click={(candidat) => this.updateDisplayCandidate(candidat)} filter={(filter_val) => this.updateSearch(filter_val)} />
                    ))}
                    </div>
                  </div>
                  
                  <div className="w-full md:w-1/5 mt-10 md:mt-0 bg-white border-2 rounded-lg float-left mr-8 pb-3">
                    <ManagerCandidateHeader label="Candidatures" value={this.state.viewed.length} />
                    <hr />
                    
                    <div className="w-full max-h-screen overflow-auto">
                    {this.state.viewed.map((candidat, index) => (
                      <ManagerCandidateCard key={candidat.candidate.sub+'-'+candidat.job_code} candidat={candidat} click={(candidat) => this.updateDisplayCandidate(candidat)} filter={(filter_val) => this.updateSearch(filter_val)} />
                    ))}
                    </div>
                  </div>

                
                  <div className="w-full md:w-1/5 bg-white border-2 rounded-lg float-left mr-8 pb-3">
                    <ManagerCandidateHeader label="Shortlist" value={this.state.shortlist.length} />
                    <hr />
                    
                    <div className="w-full max-h-screen overflow-auto">
                    {this.state.shortlist.map((candidat, index) => (
                      <ManagerCandidateCard key={candidat.candidate.sub+'-'+candidat.job_code} candidat={candidat} click={(candidat) => this.updateDisplayCandidate(candidat)} filter={(filter_val) => this.updateSearch(filter_val)} />
                    ))}
                    </div>
                  </div>
                  
                </div>
                { this.state.current_feedback !== null ?
                  <div className="fixed top-0 left-0 h-full w-full">
                    <div onClick={() => this.setState({current_feedback: null})} className="bg-black bg-opacity-75 fixed top-0 left-0 h-full w-full"></div>
                    <div className="bg-white absolute rounded-l-lg right-0 z-10 h-full w-3/4">
                        <Feedback className="absolute z-10 rounded-lg" share_id={this.state.current_feedback} feedback_name={this.ref_user.attributes.name} />
                    </div>
                  </div>
                : null }
                { this.state.current_story !== null ?
                  <div className="fixed top-0 left-0 h-full w-full">
                    <div onClick={() => this.setState({current_story: null})} className="bg-black bg-opacity-50 fixed top-0 left-0 h-full w-full"></div>
                    <div className="bg-white fixed rounded-l-lg top-0 z-10 right-0 h-full p-10 w-2/3 md:w-1/4">
                      <div className="w-full float-left">
                        <img className="font-bold w-10 mr-2 float-left rounded-full text-purpleJS-100" alt="" src={AuthConfig.CDN.images + '32x32/' + this.state.current_story.candidate.picture} />
                        <div className="float-left">
                          <div className="font-bold text-purpleJS-100">{this.state.current_story.candidate.name}</div>
                          <div className="text-xs">{this.state.current_story.candidate.phone}</div>
                        </div>
                        <div className="font-bold mt-2 w-full float-left">{this.state.current_story.offer.title}</div>
                        {
                          this.state.current_story.offer.shopname ? 
                            <div className="mt-2 w-full float-left font-bold">{this.state.current_story.offer.shopname}</div>                      
                        : null }
                        <div className="text-xs mt-2 w-full float-left">{this.state.current_story.offer.zip_code}</div>
                        <div className="w-full relative float-left">
                          <VideoStories styles={{ borderRadius: "0.5rem" }} loop={true} controls={false} muted={false} current={0} datas={this.state.current_story.questions} />
                        </div>
                      </div>
                    </div>
                  </div>
                : null }
                
                
              </div>
          }
          </div>
        )
    }
}

export default withTranslation()(ManagerCandidates);