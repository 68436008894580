import React, { useState } from 'react';

import posterIMG from '../img/home/poster.jpg';

const YouTubePlayer = (props) => {
    const [playVideo, setPlayVideo] = useState(false);
    let launchPlayer = () => {
        setPlayVideo(true);
        document.getElementById("ytplayer").contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
    }
    return (
        <div style={{ borderRadius: "1rem", boxShadow: "0 3px 20px 0 rgba(0, 0, 0, 0.2)" }} className="video-responsive">
            <img alt="youtube player" onClick={launchPlayer}
                className={
                    playVideo ?
                        "hidden"
                        : "scale-105 transition-transform duration-100 hover:scale-110 transform absolute z-10 w-full cursor-pointer object-cover"
                } src={posterIMG} />
            <iframe title="youtube player" id="ytplayer" className=""
                width="560" height="315" src="https://www.youtube.com/embed/9dDV_wDfKl8?rel=0&controls=1&autoplay=0&mute=0&start=0&enablejsapi=1&version=3&playerapiid=ytplayer"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    )
}

export default YouTubePlayer;
