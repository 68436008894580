import React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next'

import CFG from './../Config';

const Legals = (props) => {
    return (
      <div className="w-full p-5 pt-20 md:p-20">
        <Helmet>
          <title>JobStory - Mentions Légales</title>
          <meta property="og:title" content="JobStory - Mentions Légales" />
          <meta name="description" content={props.t('meta_description')}></meta>
          <meta property="og:description" content={props.t('meta_description')} />
          <meta property="og:image" content={CFG.links.shareIMG} />
          <meta property="og:url" content={CFG.links.root} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <h1 className="font-bold text-2xl">Mentions légales</h1>
        <br />
        <p>L’application est éditée par JOBSTORY</p>
        <br />
        <h3 className="font-bold text-xl">Éditeur du site</h3>
        <p>JOBSTORY, société par actions simplifiée au capital social 100.000€ dont le siège social est situé<br />
        14 rue Mandar – 75002 Paris et immatriculée au RCS de Paris sous le numéro : 883 799 603.</p>
        <br />
        Téléphone :   <span style={{direction: "rtl", unicodeBidi: "bidi-override"}}>4388622460</span><br />
        Email : <span style={{direction: "rtl", unicodeBidi: "bidi-override"}}>ppa.yrotsboj@ecirbaf</span><br />
        Numéro individuel d'identification fiscale : SIREN 883799603<br />
        <br />
        Directeur de la publication :  Fabrice JAEGER, Président<br />
        <br />
        <h3 className="font-bold text-xl">Hébergement du site</h3>
        AMAZON WEB SERVICES EMEA SARL, Succursale Française, 31 place des Corolles, Tour Carpe Diem, 92400, Courbevoie, France

      </div>
    )
}

export default withTranslation()(Legals);