import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthConfig from './../AuthConfig';
import { Storage, Auth } from 'aws-amplify';
import PopError from '../PopError';

import uploadIMG from './../../img/upload.svg';

class InputText extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ko_message: false,
            value: this.props.value !== null ? this.props.value : 'avatar/icon_recruiter.png',
            userIdentityId: null
        }
        Auth.currentUserCredentials().then(response => {
            if (response.identityId) {
                this.setState({
                    userIdentityId: response.identityId
                });
            }
        });

        this.uploadSelectedFile = this.uploadSelectedFile.bind(this);
        this.closeError.bind(this);
    }

    closeError() {
        this.setState({ ko_message: false });
    }

    // Upload file to S3 bucket
    uploadSelectedFile(e) {
        let file = e.target.files[0];

        let fileSplit = file.name.split('.');
        let fileExt = fileSplit[fileSplit.length - 1];

        let fileHashName = Math.random().toString(16).slice(-8);
        let objectKey = 'picture/' + fileHashName + '.' + fileExt;

        if (file.type.split('/')[0] !== "image") {
            this.setState({ ko_message: 'image mime type error' });
            return;
        }

        document.getElementById("progressbar").style.transitionProperty = "width";
        document.getElementById("progressbar").style.transitionDuration = "3000s";
        setTimeout(function () {
            try { document.getElementById("progressbar").style.width = "100%"; } catch (e) { }
        }, 10);

        Storage.put(objectKey, file, {
            provider: 'AWSS3',
            level: 'protected',
            contentType: file.type,
            progressCallback(progress) {
                // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            },
        })
            .then(result => {
                // l'image est uploadé, le chemin a stoquer est:
                let pathToStore = this.state.userIdentityId + '/' + result.key;

                this.setState({ value: pathToStore });
                this.props.handleChange({
                    field: this.props.datas.field,
                    value: pathToStore
                });
            })
    }

    componentDidUpdate() {
        document.getElementById("progressbar").style.transition = "none";
        setTimeout(function () {
            try { document.getElementById("progressbar").style.width = "0%"; } catch (e) { }
        }, 1000);
    }

    render() {
        return (
            <div className="w-full">
                <label className="mt-10 w-full block text-xl text-lightGrey font-medium">{this.props.t(this.props.datas.label)}</label>
                <div className="flex align-center justify-center">
                    <img style={{ width: "6rem" }} alt="upload preview" className="mt-5 flex rounded-full" src={AuthConfig.CDN.images + '100x100/' + this.state.value} />
                    {this.state.userIdentityId !== null ?
                        <label style={{ width: "5.6rem" }} className="mt-6 ml-2 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-full border-2 border-dashed border-lightGrey cursor-pointer hover:border-black">
                            <img alt="uploaded img" src={uploadIMG} />
                            <input type="file"
                                accept="image/*"
                                onChange={this.uploadSelectedFile}
                                className="hidden" />
                        </label>
                        : null}
                </div>
                <div style={{ width: "0%", backgroundColor: "#789e78" }} id="progressbar" className="rounded mt-5 h-2"></div>
                {this.state.ko_message ? <PopError closeAction={(() => this.closeError.bind(this))} text={this.state.ko_message} /> : ''}
            </div>
        )
    }
}

export default withTranslation()(InputText);