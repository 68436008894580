import React from 'react';

import { withTranslation } from 'react-i18next'

import { API } from 'aws-amplify';

import ManagerLoading from './ManagerLoading';

import { IoAdd, IoSearch, IoPencil } from "react-icons/io5";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { IoMdMore } from "react-icons/io";


class ManagerOffers extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      loading: true, 
      data: null,
      lastSearchValue: "",
      currentEditingShopname: "",
      currentShopNameValue: "",
      sort_order: "todo",
      sort_type: 0
    }
    
    this.loaded_recruiters = {};
    
    // this.changeRouteCallback = this.props.changeRoute;
    this.reloadUserCallback = this.props.reloadUserCallback;
    
    this.dateFormat = this.dateFormat.bind(this);
    this.boostItem = this.boostItem.bind(this);
    this.gotoApplications = this.gotoApplications.bind(this);
    this.gotoEntity = this.gotoEntity.bind(this);
    this.isBoosted = this.isBoosted.bind(this);
    this.getTimeElapsedString = this.getTimeElapsedString.bind(this);
    this.updateSortOffers = this.updateSortOffers.bind(this);
    this.setSortOrder = this.setSortOrder.bind(this);
    this.showChangeState = this.showChangeState.bind(this);
    this.changeStateOfOffer = this.changeStateOfOffer.bind(this);
    this.changeItemShopNameVisible = this.changeItemShopNameVisible.bind(this);
    this.onBlurEditShopName = this.onBlurEditShopName.bind(this);
    this.getRecruiterDetails = this.getRecruiterDetails.bind(this);
  }
  
  dateFormat(datestring) {
    var d = new Date(datestring),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('/');
  }
  
  componentDidMount() {
    this.loadData();
  }
  
  async loadData() {
    await this.dataLoaded(await API.get('apis', '/apis/offer'));
  }
  
  async getRecruiterDetails(rec_sub) {
    if(!this.loaded_recruiters[rec_sub]) {
      var user_details = await API.get('apis', '/apis/manager?user=' + rec_sub);
      
      // console.log(user_details);
      if(user_details.user) this.loaded_recruiters[rec_sub] = user_details.user;
    }
      
    return this.loaded_recruiters[rec_sub];
  }
  
  async gotoEntity(entity_id) {
    if(entity_id) {
      this.props.history.push('/manager/offer-edit/' + entity_id);
    } else {
      this.props.history.push('/manager/offer-create');
    }
  }
  
  async gotoApplications(offer) {
    this.props.history.push('/manager/offer/' + offer.job_code);
  }
  
  async onBlurEditShopName() {
    // console.log("blur");
    
    var current_offer = this.state.currentEditingShopname;
    var new_shopname = this.state.currentShopNameValue;
    
    var offers = this.state.data;
    
    var offer = offers.Items.find(cur => cur.job_code === current_offer);
    
    var body = false;
    
    if(offer) {
      for(var i = 0; i < offers.Items.length; i++) {
        if(offers.Items[i].job_code === current_offer) {
          if(!offers.Items[i].shopname !== new_shopname) {
            offers.Items[i].shopname = new_shopname;
            
            body = {
              recruiter_id: offer.recruiter_details.sub,
              shopname: new_shopname,
            }
          }
        }
      }
    }
    
    
    this.setState({
      data: offers,
      currentEditingShopname: "",
      currentShopNameValue: ""
    });
    
    if(body) {
      await API.post('apis', '/apis/offer/' + offer.job_code, {
        body: body
      });
    }
  }
    
  isBoosted(offer) {    
    return offer.is_boosted;
  }
  
  async changeStateOfOffer(offer) {
    var current_state = offer.state;
    
    var new_state = "available";
    var new_state_active = true;
    
    if(current_state === "available") {
      // closed
      new_state = "closed";
      new_state_active = false;
    }
    
    var data_offers = this.state.data.Items;
    
    var currentDataIndex = data_offers.indexOf(offer);
    
    data_offers[currentDataIndex].state = new_state;
    data_offers[currentDataIndex].show_change_state = false;
    
    var old_data = this.state.data;
    
    old_data.Items = data_offers;
    
    await API.post('apis', '/apis/offer/' + offer.job_code, {
      body: {
        recruiter_id: offer.recruiter_details.sub,
        active: new_state_active,
      }
    });
    
    this.setState({data: old_data});
  }
  
  
  showChangeState(offer) {
    
    var offers = this.state.data.Items;
    
    for(var i = 0; i < offers.length; i++) {
      if(offers[i].job_code === offer.job_code) {
        if(!offers[i].show_change_state) offers[i].show_change_state = true;
        else offers[i].show_change_state = false;
      }
    }
    
    var old_data = this.state.data;
    
    old_data.Items = offers;
    
    this.setState({data: old_data});
  }
  
  changeItemShopNameVisible(offer) {
    
    var job_code = offer.job_code;
    
    if(job_code !== this.state.currentEditingShopname) {
      var new_default_value = offer.shopname ? offer.shopname : "";
      this.setState({
        currentEditingShopname: job_code,
        currentShopNameValue: new_default_value,
      });
    }
  }
  
  getTimeElapsedString(given_time) {
    var last_co_string = "Date inconnue";
    
    if(given_time !== null) {
      var given_date = new Date(given_time);
      var current_date = new Date();
      var seconds = Math.floor((current_date - given_date)/1000);
      var minutes = Math.floor(seconds/60);
      var hours = Math.floor(minutes/60);
      var days = Math.floor(hours/24);
      
      if(days > 0) last_co_string = `${days}j`;
      else if(hours > 0) last_co_string = `${hours}h`;
      else if(minutes > 0) last_co_string = `${minutes}m`;
      else last_co_string = `${seconds}s`;
      
      last_co_string = `${this.props.t("manager_online_time_ago")} ${last_co_string}`;
    }

    return last_co_string;
  }
  
  async boostItem(offer) {
    if(window.confirm(this.props.t('are_you_sure_to_boost_this_item') + " " + offer.title)) {
      
      this.setState({loading: true});
      
      var res = await API.post('apis', '/apis/boost-offer',{
          body: {
            job_code: offer.job_code
          }
      });
      
      if(!res.error) {
        this.reloadUserCallback();
        this.loadData();
      } else if(res.code) {
        alert(this.props.t(res.code));
      } else {
        alert(this.props.t('Internal Server Error'));
      }
    }
  }
  
  setSortOrder(new_sort) {
    this.updateSortOffers(this.state.data, new_sort);
  }
  
  updateSortOffers(data, new_sort) {
    
    switch(new_sort) {
      case "title":
        data.Items.sort((a,b) => (a.title_lower < b.title_lower) ? 1 : ((b.title_lower < a.title_lower) ? -1 : 0));
      break;
      case "shop":
        data.Items.sort((a,b) => (a.shop_sort < b.shop_sort) ? 1 : ((b.shop_sort < a.shop_sort) ? -1 : 0));
      break;
      case "contract":
        data.Items.sort((a,b) => (a.contract_type < b.contract_type) ? 1 : ((b.contract_type < a.contract_type) ? -1 : 0));
      break;
      case "jobcode":
        data.Items.sort((a,b) => (a.job_code < b.job_code) ? 1 : ((b.job_code < a.job_code) ? -1 : 0));
      break;
      case "creation":
        data.Items.sort((a,b) => (a.offer_created < b.offer_created) ? 1 : ((b.offer_created < a.offer_created) ? -1 : 0));
      break;
      case "recruiter":
        data.Items.sort((a,b) => (a.recruiter_details.name < b.recruiter_details.name) ? 1 : ((b.recruiter_details.name < a.recruiter_details.name) ? -1 : 0));
      break;
      case "candidates":
        data.Items.sort((a,b) => (a.details.pure_candidates < b.details.pure_candidates) ? 1 : ((b.details.pure_candidates < a.details.pure_candidates) ? -1 : 0));
      break;
      case "to_recruit":
        data.Items.sort((a,b) => (a.details.pure_candidates < b.details.pure_candidates) ? 1 : ((b.details.pure_candidates < a.details.pure_candidates) ? -1 : 0));
      break;
      case "todo":
        data.Items.sort((a,b) => (a.details.todo_candidates < b.details.todo_candidates) ? 1 : ((b.details.todo_candidates < a.details.todo_candidates) ? -1 : 0));
      break;
      case "shortlist":
        data.Items.sort((a,b) => (a.details.shortlist < b.details.shortlist) ? 1 : ((b.details.shortlist < a.details.shortlist) ? -1 : 0));
      break;
      case "actives":
        data.Items.sort((a,b) => (a.state < b.state) ? 1 : ((b.state < a.state) ? -1 : 0));
      break;
      default:
        // nothing
      break;
    }
    
    var sort_type = this.state.sort_type;
    
    if(new_sort === this.state.sort_order) {
      if(sort_type===1) sort_type = 0;
      else sort_type = 1;
    } else {
      sort_type = 1;
    }
    
    if(sort_type === 0) {
      data.Items = data.Items.reverse();
    }
    
    
    this.setState({
      loading: false,
      data: data,
      sort_type: sort_type,
      sort_order: new_sort
    });
  }
    
  async dataLoaded(data) {

    // var DOUBLES = {};

    var final_items = [];

    for(var i = 0; i < data.Items.length; i++) {
      var item = data.Items[i];
      
      var title_low = item.title.toLowerCase();
      var job_code_low = item.job_code.toLowerCase();
      var city_low = item.city ? item.city.toLowerCase() : "";
      var zip_low = item.zip_code ? item.zip_code.toLowerCase() : "";
      var shop_low = item.shopname ? item.shopname.toLowerCase() : "";
      var comp_low = item.company_name ? item.company_name.toLowerCase() : "";
      var area_low = item.area ? item.area.toLowerCase() : "";
      
      // var recruiter_sub = item.recruiter_details.sub;

      // item.recruiter_details = await this.getRecruiterDetails(recruiter_sub);
      
      var recruiter_name_low = item.recruiter_details.name ? item.recruiter_details.name.toLowerCase() : "";
      var recruiter_email_low = item.recruiter_details.email ? item.recruiter_details.email.toLowerCase() : "";
      var recruiter_sub_low = item.recruiter_details.sub ? item.recruiter_details.sub.toLowerCase() : "";
            
      item.shop_sort = item.shopname ? item.shopname : item.company_name;
      item.title_lower = item.title.toLowerCase();
      
      item.search_string = `${job_code_low} ${title_low} ${city_low} ${zip_low} ${shop_low} ${comp_low} ${area_low} ${recruiter_name_low} ${recruiter_email_low} ${recruiter_sub_low}`;
      
      
      var view_candidates = item.details.view_candidates ? item.details.view_candidates : 0;
      
      item.details.todo_candidates = view_candidates + item.details.new_candidates;
      
      item.details.pure_candidates = item.details.total_candidates - item.details.total_reco;

      if(!item.details.shortlist) item.details.shortlist = 0;
     
      // console.log(item.details);
     
      // if(!DOUBLES[item.job_code]) {
        final_items.push(item);
        // DOUBLES[item.job_code] = true;
      // }
    }
    
    data.Items = final_items;

    this.updateSortOffers(data, this.state.sort_order);
  }
  
  render() {
    return (
      <div className="w-full">
        { this.state.loading ?
          <div className="w-full flex justify-center">
            <ManagerLoading />  
          </div>
        :
          <div className="w-full">
            <div className="w-full float-left mb-10">
              <h3 className="mt-3 text-3xl font-bold float-left">{this.props.t('manager_offers_menu')}</h3>
              <h3 onClick={() => { this.gotoEntity()}} className="bg-purpleJS-100 cursor-pointer float-right p-5 pt-3 pb-3 rounded-lg text-white">
                <IoAdd className="float-left mr-3 text-2xl" />
                {this.props.t('manager_add_new_offers')}
              </h3>
            </div>
            <div className="w-full float-left p-5 bg-white rounded-lg">
              <div className="w-full float-left mb-10">
                <h3 className="float-left text-purpleJS-100 font-bold">{this.props.t('manager_your_offers')}</h3>
                <span className="float-left bg-purpleJS-100 rounded-full ml-3 mt-1 pl-2 pr-2 text-white text-xs">{this.state.data.Items.length}</span>
                
                <div className="relative float-right w-1/4">
                  <input type="text" id="search_string" value={this.lastSearchValue} className="px-5 pl-10 py-3 text-xl placeholder-greyBorderJS
                font-greyBorderJS font-regular font-sans border-solid rounded-md border w-full border-greyBorderJS block" placeholder={this.props.t('manager_search_offer')} onChange={(e) => { this.setState({lastSearchValue: e.target.value}); }} />
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center text-xl leading-5">
                    <IoSearch />
                  </div>
                </div>
                
              </div>
              <table className="w-full table-auto">
                <thead className="">
                  <tr className="text-left">
                    <th onClick={() => { this.setSortOrder("title")}} className="p-5 rounded-l-lg bg-bgPurpleLightJS select-none"><div className="float-left mr-1">{this.props.t('manager_template_title')}</div><div>{this.state.sort_order === "title" ? this.state.sort_type ? <FaSortDown /> : <FaSortUp /> : null}</div></th>
                    <th onClick={() => { this.setSortOrder("jobcode")}} className="bg-bgPurpleLightJS select-none"><div className="float-left mr-1">{this.props.t('manager_template_jobcode')}</div><div>{this.state.sort_order === "jobcode" ? this.state.sort_type ? <FaSortDown /> : <FaSortUp /> : null}</div></th>
                    <th onClick={() => { this.setSortOrder("shop")}} className="bg-bgPurpleLightJS select-none"><div className="float-left mr-1">{this.props.t('manager_shop_name')}</div><div>{this.state.sort_order === "shop" ? this.state.sort_type ? <FaSortDown /> : <FaSortUp /> : null}</div></th>
                    <th onClick={() => { this.setSortOrder("recruiter")}} className="bg-bgPurpleLightJS select-none"><div className="float-left mr-1">{this.props.t('manager_recruiter_label')}</div><div>{this.state.sort_order === "recruiter" ? this.state.sort_type ? <FaSortDown /> : <FaSortUp /> : null}</div></th>
                    <th onClick={() => { this.setSortOrder("creation")}} className="bg-bgPurpleLightJS select-none"><div className="float-left mr-1">{this.props.t('manager_template_creation_date')}</div><div>{this.state.sort_order === "creation" ? this.state.sort_type ? <FaSortDown /> : <FaSortUp /> : null}</div></th>
                    <th onClick={() => { this.setSortOrder("todo")}} className="bg-bgPurpleLightJS select-none"><div className="float-left mr-1">{this.props.t('manager_count_todo_candidates')}</div><div>{this.state.sort_order === "todo" ? this.state.sort_type ? <FaSortDown /> : <FaSortUp /> : null}</div></th>
                    <th onClick={() => { this.setSortOrder("shortlist")}} className="bg-bgPurpleLightJS select-none"><div className="float-left mr-1">{this.props.t('manager_count_shortlist')}</div><div>{this.state.sort_order === "shortlist" ? this.state.sort_type ? <FaSortDown /> : <FaSortUp /> : null}</div></th>
                    <th onClick={() => { this.setSortOrder("to_recruit")}} className="bg-bgPurpleLightJS select-none"><div className="float-left mr-1">{this.props.t('manager_count_to_recruit')}</div><div>{this.state.sort_order === "to_recruit" ? this.state.sort_type ? <FaSortDown /> : <FaSortUp /> : null}</div></th>
                    <th onClick={() => { this.setSortOrder("actives")}} className="rounded-r-lg bg-bgPurpleLightJS select-none"><div className="float-left mr-1">{this.props.t('manager_offer_status')}</div><div>{this.state.sort_order === "actives" ? this.state.sort_type ? <FaSortDown /> : <FaSortUp /> : null}</div></th>
                  </tr>
                </thead>
                <tbody className="text-left">
                  { this.state.data.Items.map((item) => (
                  
                    (this.state.lastSearchValue.length === 0 || item.search_string.includes(this.state.lastSearchValue.toLowerCase())) ?
                      <tr key={item.job_code}>
                        <td onClick={() => { this.gotoApplications(item)}} className="border-b border-slate-100 p-5 hover:underline cursor-pointer">
                          {item.title}
                        </td>
                        <td className="border-b border-slate-100 p-4 pl-0">{item.job_code}</td>
                        <td className="border-b border-slate-100 p-4 pl-0">
                        { item.job_code !== this.state.currentEditingShopname ?
                          <p className="cursor-pointer" onClick={() => {this.changeItemShopNameVisible(item);}}>
                            {item.shopname ? item.shopname : this.props.t('manager_no_shopname')}
                          </p>
                        :
                          <form onSubmit={(e) => { e.preventDefault();this.onBlurEditShopName();}}>
                            <input type="text" value={this.state.currentShopNameValue} className="p-3 py-3 mb-3 text-xl placeholder-greyBorderJS
                  font-greyBorderJS font-regular font-sans border-solid rounded-md border w-full border-greyBorderJS block" 
                            onChange={(e) => {this.setState({currentShopNameValue: e.target.value})}}
                            onBlur={(e) => { this.onBlurEditShopName() }}
                            />
                          </form>
                        }
                          <p className="text-xs text-gray-500 font-bold">{item.company_name}</p>
                        </td>
                        <td className="border-b border-slate-100 p-4 pl-0">
                          <p className="font-bold">{item.recruiter_details.name}</p>
                          <p className="text-gray-500">{this.getTimeElapsedString(item.recruiter_details.last_co)}</p>
                        </td>
                        <td className="border-b border-slate-100 p-4 pl-0">{this.dateFormat(item.offer_created)}</td>
                        <td className="border-b border-slate-100 p-4 pl-0">{item.details.todo_candidates}</td>
                        <td className="border-b border-slate-100 p-4 pl-0">{item.details.shortlist}</td>
                        <td className="border-b border-slate-100 p-4 pl-0">{item.details.to_recruit}</td>
                          { /*
                        <td className="border-b border-slate-100 p-4 pl-0">{item.details.to_recruit ? item.details.to_recruit : 0}</td>
                        <td className="border-b border-slate-100 p-4 pl-0">{item.details.recruited ? item.details.recruited : 0}</td>
                          */ }
                        <td className="border-b border-slate-100 p-4 pl-0">
                          { item.state === "available" ?
                            <div className="p-2 pl-5 pr-3 text-xs text-purpleJS-100 bg-bgPurpleLightJS rounded-full float-left relative w-24">
                              <div className="float-left">{this.props.t('offer_active')}</div>
                              <IoMdMore onClick={(() => { this.showChangeState(item) })} className="float-right ml-2 mt-1 cursor-pointer" />
                              
                              { item.show_change_state ?
                                <div className="border rounded-lg p-3 px-5 bg-white absolute z-50 mt-8 right-0 shadow-lg">
                                  <div onClick={() => {this.changeStateOfOffer(item)}} className="top-0 left-0 right-0 bottom-0 absolute z-50 cursor-pointer"></div>
                                  <p className="font-bold text-black">Désactiver</p>
                                </div>
                              : null }
                            </div>
                            :
                            <div className="p-2 pl-5 pr-3 text-xs text-gray-500 bg-gray-100 rounded-full float-left relative">
                              <div className="float-left">{this.props.t('offer_inactive')}</div>
                              <IoMdMore onClick={(() => { this.showChangeState(item) })} className="float-right ml-2 mt-1 cursor-pointer" />
                              
                              { item.show_change_state ?
                                <div className="border rounded-lg p-3 px-5 bg-white absolute z-50 mt-8 right-0 shadow-lg">
                                  <div onClick={() => {this.changeStateOfOffer(item)}} className="top-0 left-0 right-0 bottom-0 absolute z-50 cursor-pointer"></div>
                                  <p className="font-bold text-black">Activer</p>
                                </div>
                              : null }
                            </div>
                          } 
                          <div onClick={() => { this.gotoEntity(item.job_code) }} className="p-1 pl-2 text-xs pr-2 ml-5 mt-1 float-left rounded-lg border border-purpleJS-100 text-purpleJS-100 cursor-pointer">
                            <IoPencil className="float-left mr-1 mt-1" />
                            {this.props.t('manager_update_template')}
                          </div>                        
                        </td>
                      </tr>
                    : null
                    
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        }
        
        {
        // <div className="bg-bgPurpleLightJS fixed top-0 right-0 left-0 bottom-0 z-50 w-full">
          // <div className="">
          // </div>
          // <div className="border rounded-lg absolute top-1/2 left-1/2">
            // Désactiver l'annonce
          // </div>
        // </div>
        }
      </div>
    )
  }
}

export default withTranslation()(ManagerOffers);