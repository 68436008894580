import React, { Component } from 'react';

import validateIMG from '../img/validate.svg';

export default class PopOk extends Component {

    render() {
        return (
            <div className={this.props.className + " relative flex rounded-md overflow-hidden bg-white border-solid border border-greyBorderJS"}>
                <div className="bg-purpleJS-100 p-5 flex text-center items-center">
                    <img className="mx-auto w-6" alt="error" src={validateIMG} />
                </div>
                <div className="text-left py-2 pl-3 pr-10">
                    <p className="text-md text-black font-medium">{this.props.text}</p>
                </div>
                {this.props.closeAction
                    ?
                    <div className="cursor-pointer p-1 text-black absolute top-0 right-0" onClick={this.props.closeAction()}>✖</div>
                    : ''}
            </div>
        )
    }
}
