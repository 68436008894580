import React, { Component } from 'react';

export default class MobileMenu extends Component {
    constructor(props) {
        super(props);
        this.datas = this.props.links;
        this.notSelectedClass = "mt-1 block px-3 py-2 text-base text-white hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out";
        this.selectedClass = "mt-1 block px-3 py-2 text-base font-medium text-white bg-purpleJS-100 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out";
        this.state = {
            mobileMenuOpened: false,
            openedButtonClass: 'hidden h-6 w-6',
            closedButtonClass: 'block h-6 w-6',
            dropDownClass: 'hidden absolute sm:hidden'
        }
        this.menuClick.bind(this);
    }

    menuClick() {
        let tmpState;
        if (!this.state.mobileMenuOpened) {
            tmpState = {
                mobileMenuOpened: true,
                openedButtonClass: 'block h-6 w-6',
                closedButtonClass: 'hidden h-6 w-6',
                dropDownClass: 'block absolute sm:hidden bg-black rounded-md'
            }
        } else {
            tmpState = {
                mobileMenuOpened: false,
                openedButtonClass: 'hidden h-6 w-6',
                closedButtonClass: 'block h-6 w-6',
                dropDownClass: 'hidden absolute sm:hidden bg-black rounded-md'
            }

        }
        this.setState(tmpState);
    }
    render() {
        return (
            <div>
                <div className="menu-button absolute flex items-center sm:hidden">
                    <button onClick={(e) => { this.menuClick() }} id="menu-btn" className="inline-flex items-center justify-center p-2 rounded-md text-black hover:text-white hover:bg-purpleJS-100 focus:outline-none focus:bg-purpleJS-100 focus:text-white transition duration-150 ease-in-out" aria-label="Main menu">
                        <svg id="menu-btn-closed" className={this.state.closedButtonClass} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        <svg id="menu-btn-opened" className={this.state.openedButtonClass} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div id="dropdown-menu" className={this.state.dropDownClass}>
                    <div className="">
                        {this.datas.map((item, i) => {
                            return <a key={i + "-link"} href={item.link}
                                className={this.props.location.pathname === item.link ? this.selectedClass : this.notSelectedClass}>{item.name}</a>
                        })}
                    </div>
                </div>
            </div>
        )
    }
}
