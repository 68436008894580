import { Auth } from 'aws-amplify';




var baseCFG = {
  Common: {
    "Auth": {
      "identityPoolId": "xx-xxxx-x:xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
      "region": "eu-west-1",
      "userPoolId": "eu-west-1_xxxxxxxxx",
      "userPoolWebClientId": "xxxxxxxxxxxxxxxxxxxxxxxxxx",
      "authenticationFlowType": "USER_SRP_AUTH"
    },
    "Storage": {
      "AWSS3": {
        "bucket": "xxxxxx-xx-xxxxxxx-xxxx",
        "region": "eu-west-1"
      }
    },
    "API": {
      "endpoints": [
        {
          "name": "apis",
          "endpoint": "https://xxxxx.xxxxxxx.xxx",
          "custom_header": async () => {

            // with Cognito User Pools use this:
            return {
              Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
          }
        }
      ]
    }
  },
  CONF: {},
  DDB: {
    USERS_INDEXE: 'list_profiles',
    LIMIT_TABLE: 'xxxxxxxxxxxxxx',
    APPLY_TABLE: 'xxxxxxxxxxxxxx',
    WISH_TABLE: 'xxxxxxxxxxxxxx',
    USERS_TABLE: 'xxxxxxxxxxxxxx',
    INTEREST_TABLE: 'xxxxxxxxxxxxxx',
    MANAGERS_TABLE: 'xxxxxxxxxxxxxx',
    LANDING_CUSTOMS: 'xxxxxxxxxxxxxx',
    OFFER_TABLE: 'xxxxxxxxxxxxxx'
  },
  CDN: {
    images: "https://xxxxxxxxxxx.cloudfront.net/"
  }
}

switch (document.domain) {
  // case 'localhost':
  case 'jobstory.app':
  case 'www.jobstory.app':
    // prod old
    // baseCFG.Common.Auth.identityPoolId = "eu-west-1:0745b894-e53b-4d74-963a-89994a2daa8c";
    // baseCFG.Common.Auth.userPoolId = "eu-west-1_QRZTFeo7H";
    // baseCFG.Common.Auth.userPoolWebClientId = "6p5ianrv7mkt4fdec26b8qsf17";

    // prod new
    baseCFG.Common.Auth.identityPoolId = "eu-west-1:2871b8c0-1604-43c8-a3a5-48e82fd280e8";
    baseCFG.Common.Auth.userPoolId = "eu-west-1_hcZ3t0oXl";
    baseCFG.Common.Auth.userPoolWebClientId = "6ftn5ml7uocsqv6eng6j6igb9s";

    baseCFG.Common.Auth.authenticationFlowType = "USER_PASSWORD_AUTH";

    
    baseCFG.Common.Auth.oauth = {
        scope: ["phone","email","openid","profile","aws.cognito.signin.user.admin"],
        domain: "prod-jobstory-sso-auth.auth.eu-west-1.amazoncognito.com",
        redirectSignIn: "https://jobstory.app/login",
        redirectSignOut: "https://jobstory.app/login",
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }


    baseCFG.Common.Storage.AWSS3.bucket = "prod-01-jobstory-profiles";

    baseCFG.Common.API.endpoints[0].endpoint = "https://jobstory.app";

    baseCFG.CDN.images = "https://d2f5b1rb45eulb.cloudfront.net/";
        
    baseCFG.DDB.LIMIT_TABLE = "prod_01_jobstory_users_limits";
    baseCFG.DDB.OFFER_TABLE = "prod_01_jobstory_offer";
    baseCFG.DDB.APPLY_TABLE = "prod_01_jobstory_application";
    baseCFG.DDB.USERS_TABLE = "prod_01_jobstory_users";
    baseCFG.DDB.WISH_TABLE = "prod_01_jobstory_wishlist";
    baseCFG.DDB.INTEREST_TABLE = "prod_01_jobstory_job-interest";
    baseCFG.DDB.MANAGERS_TABLE = "prod_01_jobstory_managers";
    baseCFG.DDB.LANDING_CUSTOMS = "prod_01_jobstory_landings";
    
    baseCFG.Common.API.endpoints[1] = {
      "name": "configfr",
      "endpoint": "https://www.jobstory.app/apis/config/FR"
    };
    
    baseCFG.Common.API.endpoints[2] = {
      "name": "googlemap",
      "endpoint": "https://maps.googleapis.com/maps/api/js?key=AIzaSyBMDd7oIe2QxXeXjZMHhiAnlGOafUTzWEs&libraries=places"
    };

    break;
  case 'staging-www.jobstory.app':
  case 'localhost':
    // prod
    // baseCFG.Common.Auth.identityPoolId = "eu-west-1:e6ac17b9-94fb-4289-9d96-6eef4bd14ace";
    // baseCFG.Common.Auth.userPoolId = "eu-west-1_z6obDngVK";
    // baseCFG.Common.Auth.userPoolWebClientId = "6j5k3frgajtm8psf2kdr31u0ud";



    baseCFG.Common.Auth.identityPoolId = "eu-west-1:5c10ab1b-d0f9-4144-95fa-b7ea09c4dbe7";
    baseCFG.Common.Auth.userPoolId = "eu-west-1_Xc33nzyMw";
    // baseCFG.Common.Auth.userPoolWebClientId = "7qkais8nf3smhqkq7s2pq8ta3a";
    baseCFG.Common.Auth.userPoolWebClientId = "7qkais8nf3smhqkq7s2pq8ta3a";
    
    baseCFG.Common.Auth.authenticationFlowType = "USER_PASSWORD_AUTH";

    
    baseCFG.Common.Auth.oauth = {
        scope: ["phone","email","openid","profile","aws.cognito.signin.user.admin"],
        domain: "staging-jobstory-sso-auth.auth.eu-west-1.amazoncognito.com",
        redirectSignIn: "https://staging-www.jobstory.app/login",
        redirectSignOut: "https://staging-www.jobstory.app/login",
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }







    baseCFG.Common.Storage.AWSS3.bucket = "staging-01-jobstory-profiles";

    baseCFG.Common.API.endpoints[0].endpoint = "https://staging-www.jobstory.app";

    baseCFG.CDN.images = "https://d2mn0kd3ru546t.cloudfront.net/";
    
    baseCFG.DDB.LIMIT_TABLE = "staging_01_jobstory_users_limits";
    baseCFG.DDB.OFFER_TABLE = "staging_01_jobstory_offer";
    baseCFG.DDB.APPLY_TABLE = "staging_01_jobstory_application";
    baseCFG.DDB.USERS_TABLE = "staging_01_jobstory_users";
    baseCFG.DDB.WISH_TABLE = "staging_01_jobstory_wishlist";
    baseCFG.DDB.INTEREST_TABLE = "staging_01_jobstory_job-interest";
    baseCFG.DDB.MANAGERS_TABLE = "staging_01_jobstory_managers";
    baseCFG.DDB.LANDING_CUSTOMS = "staging_01_jobstory_landings";

    baseCFG.Common.API.endpoints[1] = {
      "name": "configfr",
      "endpoint": "https://staging-www.jobstory.app/apis/config/FR"
    };
    baseCFG.Common.API.endpoints[2] = {
      "name": "googlemap",
      "endpoint": "https://maps.googleapis.com/maps/api/js?key=AIzaSyC0u3flT3xtOd5_4c2n1_iEi22ddZp8APQ&libraries=places"
    };

    break;
  default:
    // New UP Migration
  
    baseCFG.Common.Auth.identityPoolId = "eu-west-1:b8834f35-011f-461d-903d-8346e18cdf16";
    baseCFG.Common.Auth.userPoolId = "eu-west-1_A9cnPxorX";
    baseCFG.Common.Auth.userPoolWebClientId = "90sgf1nmhbde0a2glbv3hctq4";
    baseCFG.Common.Auth.authenticationFlowType = "USER_PASSWORD_AUTH";

    
    baseCFG.Common.Auth.oauth = {
        scope: ["phone","email","openid","profile","aws.cognito.signin.user.admin"],
        domain: "preprod-jobstory-sso-auth.auth.eu-west-1.amazoncognito.com",
        redirectSignIn: "http://"+document.domain+":3000/login",
        redirectSignOut: "http://"+document.domain+":3000/login",
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }


    // OLD UP Migration

    // baseCFG.Common.Auth.identityPoolId = "eu-west-1:0c347f68-c134-4308-82f8-ff0979081e76";
    // baseCFG.Common.Auth.userPoolId = "eu-west-1_cnGBEcpRm";
    // baseCFG.Common.Auth.userPoolWebClientId = "793e9ori8gcnos349qh60t787l";
    // baseCFG.Common.Auth.authenticationFlowType = "USER_SRP_AUTH";
    

    baseCFG.Common.Storage.AWSS3.bucket = "preprod-01-jobstory-profiles";

    baseCFG.Common.API.endpoints[0].endpoint = "https://preprod-www.jobstory.app";
    
    baseCFG.DDB.LIMIT_TABLE = "preprod_01_jobstory_users_limits";
    baseCFG.DDB.OFFER_TABLE = "preprod_01_jobstory_offer";
    baseCFG.DDB.APPLY_TABLE = "preprod_01_jobstory_application";
    baseCFG.DDB.USERS_TABLE = "preprod_01_jobstory_users";
    baseCFG.DDB.WISH_TABLE = "preprod_01_jobstory_wishlist";
    baseCFG.DDB.INTEREST_TABLE = "preprod_01_jobstory_job-interest";
    baseCFG.DDB.MANAGERS_TABLE = "preprod_01_jobstory_managers";
    baseCFG.DDB.LANDING_CUSTOMS = "preprod_01_jobstory_landings";
    
    baseCFG.CDN.images = "https://d1ytrmsqvbpyt4.cloudfront.net/";
    baseCFG.Common.API.endpoints[1] = {
      "name": "configfr",
      "endpoint": "https://preprod-www.jobstory.app/apis/config/FR"
    };
    baseCFG.Common.API.endpoints[2] = {
      "name": "googlemap",
      "endpoint": "https://maps.googleapis.com/maps/api/js?key=AIzaSyC0u3flT3xtOd5_4c2n1_iEi22ddZp8APQ&libraries=places"
    };
}

export default baseCFG;