import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class JobCategories extends Component {

    constructor(props) {
        super(props);
        this.choices = this.props.config.job_cat;
        this.handleChange.bind(this);
    }

    handleChange = (event) => {
        let tmpValues;
        if (this.props.value !== null)
            tmpValues = [...this.props.value];
        else tmpValues = [];
        // eslint-disable-next-line eqeqeq
        if (event.target.getAttribute('data-selected') == "true") {
            let idx = tmpValues.indexOf(event.target.getAttribute('data-jobcat'));
            if (idx !== -1)
                tmpValues.splice(idx, 1);
        } else {
            tmpValues.push(event.target.getAttribute('data-jobcat'));
        }
        this.props.handleChange({
            field: this.props.datas.field,
            value: tmpValues
        });
    }

    render() {
        return (
            <div className="w-full">
                <h2 className="mt-10 w-full block text-xl text-lightGrey font-medium">{this.props.t(this.props.datas.label)}</h2>
                <div className="w-full mt-3">
                    {this.choices.map((choice, i) => {
                        return <div onClick={this.handleChange}
                            data-jobcat={choice.name} key={i + "_job_cat"}
                            data-selected={this.props.value !== null && this.props.value.indexOf(choice.name) > -1 ? "true" : "false"}
                            className={
                                this.props.value !== null && this.props.value.indexOf(choice.name) > -1 ?
                                    "bg-purpleJS-100 text-white mt-5 ml-5 cursor-pointer rounded-full " +
                                    "px-5 py-2 inline-block font-semibold text-sm border-solid border border-purpleJS-100"
                                    : "mt-5 ml-5 cursor-pointer border-solid rounded-full border " +
                                    "border-greyBorderJS px-5 py-2 inline-block font-semibold text-sm"
                            }>{choice.value}</div>
                    })}
                </div>
            </div>
        )
    }
}

export default withTranslation()(JobCategories);
