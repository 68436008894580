import React from 'react';

import { AmplifyAuthenticator, AmplifySignIn, AmplifyForgotPassword } from '@aws-amplify/ui-react';
import { withTranslation } from 'react-i18next';

import Header from './Header'; // Import a component from another file
import Footer from './Footer'; // Import a component from another file
import JSButton from './JSButton'; // Import a component from another file

import AdminDashboard from './AdminDashboard'; // Import a component from another file
import AdminUser from './AdminUser'; // Import a component from another file
import AdminCustomLandings from './AdminCustomLandings'; // Import a component from another file

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { Hub, Auth } from 'aws-amplify';
      
class AdminApp extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {
        user: null
      };
      
      Hub.listen('auth', (data) => {
        this.onAuthEvent(data);
      });
      
      this.signOut = this.signOut.bind(this);
    }
    
    componentDidMount() {
      this.onAuthEvent();
    }
    
    signOut() {
      Auth.signOut();
    }
    
    async onAuthEvent(data) {
      var newUser = null;
      
      if(data && data.payload && data.payload.event === "signIn") {
        newUser = data.payload.data;
      } else if(data && data.payload && data.payload.event === "signOut") {
        newUser = null;
      } else {
        // Load auth
        newUser = await Auth.currentUserInfo();
      }

      this.setState({
        user: newUser
      });
    }
    
    render() {
      return (
        <div className="container w-full max-w-none">
          <Header location={this.props.location} />
          <div className="container w-full max-w-none p-5 md:p-20">
            { this.state.user != null ?
              <div className="w-full">
                <div className="w-full text-right">
                  <JSButton active={true} text={this.props.t('signout_button_label')} action={this.signOut} />
                </div>
                <Router>
                  <Switch>
                    <Route path="/admin/custom-landings" render={(props) => <AdminCustomLandings {...props} />} />
                    <Route path="/admin/user/:user_id" render={(props) => <AdminUser {...props} />} />
                    <Route render={(props) => <AdminDashboard {...props} user={this.state.user} />} />
                  </Switch>
                </Router>
              </div>
            :
              <div align="center">
                <AmplifyAuthenticator className="amplify-components" usernameAlias="email">
                  <AmplifySignIn 
                    headerText="Identification" 
                    slot="sign-in" 
                    hideSignUp="true" 
                    submitButtonText="S'identifier" 
                    formFields={[
                      { 
                        type: "username", 
                        placeholder: "Entrez votre email", 
                        label: "Email"
                      },
                      { 
                        type: "password", 
                        placeholder: "Mot de passe", 
                        label: "Mot de passe" 
                      }
                    ]}
                  />
                  <AmplifyForgotPassword
                    headerText="Mot de passe oublié"
                    slot="forgot-password"
                  ></AmplifyForgotPassword>
                </AmplifyAuthenticator>
              </div>
            }   
          </div>
          <Footer />
        </div>
      )
    }
}

export default withTranslation()(AdminApp);