import React from 'react';
import { withTranslation } from 'react-i18next'

import { IoDocumentText } from "react-icons/io5";

class ButtonCV extends React.Component {
  
  constructor(props) {
    super(props);
    this.cv_url = this.props.cv_url;
  }
    
  render() {    
    return (
      <div>
      { this.cv_url ?
        <a href={this.cv_url} target="_blank" rel="noopener noreferrer" className="p-1 pl-3 pr-3 float-left rounded-lg border border-purpleJS-100 text-purpleJS-100 cursor-pointer">
          <IoDocumentText className="float-left mr-1 mt-1" />
          {this.props.t('manager_cv')}
        </a>
      : 
        <div className="p-1 pl-3 pr-3 float-left rounded-lg border border-gray-500 text-gray-500">
          <IoDocumentText className="float-left mr-1 mt-1" />
          {this.props.t('manager_cv')}
        </div>
      }
      </div>
    )
  }
}

export default withTranslation()(ButtonCV);