import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";


class DateInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formatedValue: this.props.value !== null ? moment(new Date(this.props.value))._d : moment()._d,
            value: this.props.value,
        }
        this.handleChange.bind(this);
    }

    handleChange = (date) => {
        let val = date ? date : this.state.formatedValue;
        if (typeof val == "object") {
            val = val.toISOString();
        }
        this.props.handleChange({
            field: this.props.datas.field,
            value: val,
            ready: this.props.datas.required ? typeof val == "string" : true
        });
        if (date && date !== '') {
            this.setState({ formatedValue: moment(new Date(val))._d, value: val });
        }
    }

    componentDidUpdate() {
        if (this.state.value !== this.props.value && this.props.value != null) {
            this.setState({ formatedValue: moment(new Date(this.props.value))._d, value: this.props.value });
        }
    }

    render() {
        return (
            <div className="w-full">
                <label className="mt-5 w-full block text-lg font-medium">{this.props.t(this.props.datas.label)}</label>
                <DatePicker dateFormat="dd/MM/yyyy" className="mt-5 resize-none px-5 py-3 placeholder-greyBorderJS
                        font-greyBorderJS font-regular font-sans border-solid rounded-md border
                        border-greyBorderJS w-full block" placeholder={this.props.t(this.props.datas.placeholder)} selected={this.state.formatedValue} onChange={date => this.handleChange(date)} />

            </div>
        )
    }
}

export default withTranslation()(DateInput);
